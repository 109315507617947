/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProSubscriptionComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaProSubscriptionComponent( props ) {
    const [montValue, setMonthValue] = React.useState(null);
    const [yearValue, setYearValue] = React.useState(null);
    const [monthCurrency, setMonthCurrency] = React.useState(null);
    const [yearCurrency, setYearCurrency] = React.useState(null);

    React.useEffect(() => {
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'month') ){
            setMonthValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').value );
            setMonthCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').currency );
        }else{
            setMonthValue(null);
            setMonthCurrency(null);
        }
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'year') ){
            setYearValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').value );
            setYearCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').currency );
        }else{
            setYearValue(null);
            setYearCurrency(null);
        }
    }, [props.prices]);// eslint-disable-line

    return(
        <div className='baloa-pro-subscription__container'>
            {!isMobile &&
                <div className='baloa-pro-subscription__content'>
                    <div className='baloa-pro-subscription__title'>{props.t('baloaProSubscriptionTitle')}</div>
                    <div className='baloa-pro-subscription__buttons'>
                        {!props.is_new_baloa_pro_user &&
                            <React.Fragment>
                                {/* <PrimaryButtonComponent
                                    type={'button'}
                                    className={''}
                                    onClick={()=>{ props.handleOnClickYearly(yearValue) }}
                                    disable={ !yearValue }
                                >
                                    <span>{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue })}</span>
                                </PrimaryButtonComponent> */}
                                <SecondaryButtonComponent
                                    type={'button'}
                                    className={'large'}
                                    onClick={()=>{ props.handleOnClickMonthly(montValue) }}
                                    disable={ !montValue }
                                >
                                    <span>{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue })}</span>
                                </SecondaryButtonComponent>
                            </React.Fragment>
                        }
                        {props.is_new_baloa_pro_user &&
                            <PrimaryButtonComponent
                                type={'button'}
                                className={''}
                                onClick={()=>{ props.handleOnClickSubscription() }}
                                disable={ false }
                            >
                                <span>{props.t('baloaProHeader.freeTrialBtnLbl')}</span>
                            </PrimaryButtonComponent>
                        }
                    </div>
                    {props.is_new_baloa_pro_user &&
                        <React.Fragment>
                            <div className='baloa-pro-subscription__title-price'>{props.t('baloaProHeader.subscriptionMsg2')}</div>
                            {/* <div className='baloa-pro-subscription__title-price'>&bull;{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue, _currency: yearCurrency })}</div> */}
                            <div className='baloa-pro-subscription__title-price'>&bull;{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue, _currency: monthCurrency })}</div>
                        </React.Fragment>
                    }
                    <div className='baloa-pro-subscription__message baloa-username'>{props.t('baloaProHeader.autoRenewMsg')}</div>
                </div>
            }
            {isMobile &&
                <div className='baloa-pro-subscription__content'>
                    {!props.is_new_baloa_pro_user &&
                        <React.Fragment>
                            <div className='baloa-pro-subscription__message baloa-username'>{props.t('baloaProHeader.autoRenewMsg')}</div>
                            <div className='baloa-pro-subscription__buttons'>
                                {/* <PrimaryButtonComponent
                                    type={'button'}
                                    className={''}
                                    onClick={()=>{ props.handleOnClickYearly(yearValue) }}
                                    disable={ !yearValue }
                                >
                                    <span>{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue })}</span>
                                </PrimaryButtonComponent> */}
                                <SecondaryButtonComponent
                                    type={'button'}
                                    className={''}
                                    onClick={()=>{ props.handleOnClickMonthly(montValue) }}
                                    disable={ !montValue }
                                >
                                    <span>{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue })}</span>
                                </SecondaryButtonComponent>
                            </div>
                        </React.Fragment>
                    }
                    {props.is_new_baloa_pro_user &&
                        <React.Fragment>
                            <div className='baloa-pro-subscription__title'><span className='hubicon-award_star' />{props.t('baloaProSubscriptionTitle2')}</div>
                            <div className='baloa-pro-subscription__title-price'>{props.t('baloaProHeader.subscriptionMsg2')}</div>
                            <div className='baloa-pro-subscription__buttons'>
                                <PrimaryButtonComponent
                                    type={'button'}
                                    className={''}
                                    onClick={()=>{ props.handleOnClickSubscription() }}
                                    disable={ false }
                                >
                                    <span>{props.t('baloaProHeader.freeTrialBtnLbl')}</span>
                                </PrimaryButtonComponent>
                            </div>
                        </React.Fragment>
                    }
                </div>
            }
            
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProSubscriptionComponent);