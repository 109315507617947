import React, { useState, useEffect } from 'react';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US'

//i18
import { withTranslation } from 'react-i18next';
//Styles
import './TournamentMatchAdminFilterComponent.styles.css';
import TournamentMatchAdminFilterItemComponent from '../tournament-match-admin-filter-item/TournamentMatchAdminFilterItemComponent';

function TournamentMatchAdminFilterComponent(props) {
    registerLocale('es', es);
    registerLocale('en', en);
    const FORM_INITIAL_VALUES_TO_FILTER = {
        match_team: [],
        match_stadium: [],
        match_day: [],
        match_status: [],
        match_date: null,
        match_order_by: []
    };

    const FILTER_TYPES = [
        { value: 'match_team', content: props.t("matchAdminFilter.filterLabels.teamFilter"), filterCounter: 0 },
        { value: 'match_stadium', content: props.t("matchAdminFilter.filterLabels.stadiumFilter"), filterCounter: 0 },
        { value: 'match_referee', content: props.t("matchAdminFilter.filterLabels.refereeFilter"), filterCounter: 0 },
        { value: 'match_day', content: props.t("matchAdminFilter.filterLabels.dayFilter"), filterCounter: 0 },
        { value: 'match_status', content: props.t("matchAdminFilter.filterLabels.stateFilter"), filterCounter: 0 },
        { value: 'match_date', content: props.t("matchAdminFilter.filterLabels.dateFilter"), filterCounter: 0 },
        { value: 'match_order_by', content: props.t("matchAdminFilter.filterLabels.orderByFilter"), filterCounter: 0 },
    ];

    const MATCHING_STATE = [
        { value: 'Finalized', caption: props.t("matchAdminFilter.filterLabels.Finalized"), checked: false },
        { value: 'Scheduled', caption: props.t("matchAdminFilter.filterLabels.Scheduled"), checked: false },
        { value: 'Not_scheduled', caption: props.t("matchAdminFilter.filterLabels.Not_scheduled"), checked: false },
    ]

    const [listOfFilter, setListOfFilter] = useState(FILTER_TYPES);
    const [boxPosition, setBoxPosition] = useState(false);
    const [isBoxOpen, setBoxOpen] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [listMatchTeams, setListMatchTeams] = useState(props.phase_teams);
    const [listMatchState, setListMatchState] = useState(MATCHING_STATE);
    const [listMatchStadium, setListMatchStadium] = useState(props.tournament_fields);
    const [listMatchReferees, setListMatchReferees] = useState(props.tournament_staff);
    const [listMatchDay, setMatchDay] = useState([]);
    const [matchDate, setMatchDate] = useState(null);
    const [orderDay, setOrderDay] = useState(null);
    const [orderMatch, setOrderMatch] = useState(null);
    const [selectedTempDate, setSelectedTempDate] = useState(null);
    const datePickerRef = React.useRef();

    useEffect( () => {
        setListMatchTeams(props.phase_teams);
    }, [ props.phase_teams ] );// eslint-disable-line

    const handleBoxOpen = (filterType, actionType) => {
        if (filterType?.match_date !== 'match_date') {
            setFilterType(filterType);
            if (!isBoxOpen) {
                setBoxPosition(filterType.value)
                setBoxOpen(true)
            } else {
                setBoxPosition(false);
                setBoxOpen(false)
                setFilterType('');
                if (filterType?.filterCounter === 0 && actionType !== 'submit' && actionType !== 'cancel') {
                    clearFilterStatus(filterType.value);
                }
            }
        }
    };

    const handleDatePicker = () => {
        datePickerRef.current.setOpen(true);
    }

    const reformatDataArray = (array) => {
        let queryParams = '';
        if (array.length > 0) {
            queryParams += array.map((filter) => {
                return `${filter}|`;
            });
        }
        return queryParams.replace(/(,)/gm, "").slice(0, -1);
    }

    const handleChangesFilterCounters = ({ match_date }) => {
        setListOfFilter((prevList) => {
            const updatedList = [...prevList];
            const teamFilter = updatedList.find((filter) => filter.value === 'match_team');
            if (teamFilter) {
                teamFilter.filterCounter = listMatchTeams.filter((state) => state.checked === true)?.length;
            }
            const teamFilterStadium = updatedList.find((filter) => filter.value === 'match_stadium');
            if (teamFilterStadium) {
                teamFilterStadium.filterCounter = listMatchStadium.filter((state) => state.checked === true)?.length;
            }
            const teamFilterReferee = updatedList.find((filter) => filter.value === 'match_referee');
            if (teamFilterReferee) {
                teamFilterReferee.filterCounter = listMatchReferees.filter((state) => state.checked === true)?.length;
            }
            const teamFilterState = updatedList.find((filter) => filter.value === 'match_status');
            if (teamFilterState) {
                teamFilterState.filterCounter = listMatchState.filter((state) => state.checked === true)?.length;
            }
            const teamFilterDay = updatedList.find((filter) => filter.value === 'match_day');
            if (teamFilterDay) {
                teamFilterDay.filterCounter = listMatchDay?.length;
            }
            const teamFilterDate = updatedList.find((filter) => filter.value === 'match_date');
            if (teamFilterDate && match_date) {
                teamFilterDate.filterCounter = 1;
            }
            return updatedList;
        });
    }

    const handleFilterButtonsFormat = ({ newFormats, typeFilter }) => {
        const filterMappings = {
            'match_team': { setFunction: setListMatchTeams },
            'match_stadium': { setFunction: setListMatchStadium },
            'match_referee': { setFunction: setListMatchReferees },
            'match_status': { setFunction: setListMatchState },
            'match_day': { setFunction: setMatchDay },
        };
        const mapping = filterMappings[typeFilter];
        if (mapping) {
            if (typeFilter === 'match_day') {
                mapping.setFunction(newFormats);
            } else {
                mapping.setFunction((prevList) =>
                    prevList.map((item) =>
                        item.value === newFormats.value ? { ...item, checked: newFormats.checked } : item
                    )
                );
            }
        }
        // To Refactored code:
        // case 'match_team':
        //         setListMatchTeams((prevList) => {
        //             const newList = prevList.map((item) =>
        //                 item.value === newFormats.value ? { ...item, checked: newFormats.checked } : item
        //             );
        //             return newList;
        //         });
        //         break;
    };

    const handleSubmitForm = (filterData) => {
        filterData.match_day = listMatchDay;
        setOrderDay(filterData?.order_day)
        setOrderMatch(filterData?.order_match);
        const filterParams = {
            team_id: reformatDataArray((listMatchTeams?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined))),
            field_id: reformatDataArray(listMatchStadium?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            official_referee: reformatDataArray(listMatchReferees?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            state: reformatDataArray(listMatchState?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            matchday: reformatDataArray(listMatchDay),
            match_date: filterData?.matchDate ?? matchDate,
            order_by_day: filterData?.order_day,
            order_by_match: filterData?.order_match
        }
        handleChangesFilterCounters(filterParams);
        props.handleFilterSearchMatchAdmin(filterParams);
    };



    const clearFilterStatus = (filterType) => {
        let filterParams = {    
            team_id: reformatDataArray((listMatchTeams?.map(state => {  
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined))),
            field_id: reformatDataArray(listMatchStadium?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            official_referee: reformatDataArray(listMatchReferees?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            state: reformatDataArray(listMatchState?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            matchday: reformatDataArray(listMatchDay),
            match_date: null,
            match_order_by: (orderMatch || orderDay) ? `${orderMatch}-${orderDay}`: null,
        }
        const filterMappings = {
            'match_team': { setFunction: setListMatchTeams, filter: props?.phase_teams },
            'match_stadium': { setFunction: setListMatchStadium, filter: props?.tournament_fields },
            'match_referee': { setFunction: setListMatchReferees, filter: props?.tournament_staff },
            'match_status': { setFunction: setListMatchState, filter: MATCHING_STATE },
            'match_day': { setFunction: setMatchDay, filterCounter: 0 },
            'match_date': { setFunction: setMatchDate, filterCounter: 0 },
            'match_order_by': { setFunction: () => { setOrderDay(null); setOrderMatch(null); }, filterCounter: 0 },
        };
        const mapping = filterMappings[filterType];  
        
        filterParams[filterType] = '';
        if (mapping) {
            if (filterType === 'match_date') {
                setMatchDate(null);
                setSelectedTempDate(null)
            } else {
                mapping.setFunction(mapping.filter || []);
            }
            listOfFilter.find((filter) => filter.value === filterType).filterCounter = mapping.filterCounter || 0;
        }

        switch (filterType) {
            case 'match_team':
                filterParams['team_id'] = '';                
                break;
            case 'match_stadium':
                filterParams['field_id'] = '';                
                break;
            case 'match_referee':
                filterParams['official_referee'] = '';                
                break;
            case 'match_day':
                filterParams['matchday'] = '';                
                break;
            case 'match_status':
                filterParams['state'] = '';                
                break;
            case 'match_date':
                filterParams['match_date'] = '';                
                break;            
            default:
                break;
        }
        
        props.handleFilterSearchMatchAdmin(filterParams);
    };

    const loadTypeFilterComponent = (filterType, index) => {
        switch (filterType.value) {
            case 'match_team'://teams
                return (
                    <TournamentMatchAdminFilterItemComponent
                        filterType={filterType?.value}
                        selectedFilter={listMatchTeams}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_team'}
                    />
                )
            case 'match_stadium'://fields
                return (
                    <TournamentMatchAdminFilterItemComponent
                        filterType={filterType?.value}
                        selectedFilter={listMatchStadium}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_stadium'}
                    />
                )
            case 'match_referee'://referee
                return (
                    <TournamentMatchAdminFilterItemComponent
                        filterType={filterType?.value}
                        selectedFilter={listMatchReferees}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_referee'}
                    />
                )
            case 'match_status'://status
                return (
                    <TournamentMatchAdminFilterItemComponent
                        filterType={filterType?.value}
                        selectedFilter={listMatchState}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_status'}
                    />
                )
            case 'match_day'://matchday
                return (
                    <TournamentMatchAdminFilterItemComponent
                        generalOptions={props?.tournament_dates}
                        filterType={filterType?.value}
                        selectedFilter={listMatchDay}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_day'}
                    />
                )
            case 'match_order_by'://order by
                return (
                    <TournamentMatchAdminFilterItemComponent
                        filterType={filterType?.value}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        field_id={'match_date'}
                        orderDay={orderDay}
                        orderMatch={orderMatch}
                    />
                )
            default: return (
                <div>Default General</div>
            )
        }
    }

    const handleDateChange = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const newFormatDate = `${year}-${month}-${day}`;
        setMatchDate(newFormatDate);
        handleSubmitForm({ matchDate: newFormatDate })
        setSelectedTempDate(date);
    };

    return (
        <div className='tournament_match_admin_filter_container'>
            <div className='tournament_match_admin_filter_buttons'>
                {listOfFilter.map((filterItem, index) =>
                    <div className={`tournament_match_all_filters_button_container ${filterItem.value}`} key={index}>
                        <div className={`tournament_match_all_filters_buttons ${filterItem.filterCounter > 0 ? '_active' : ''}`}
                            key={index}>
                            <div key={index}
                                className={'tournament_match_filter_button small'}
                                onClick={() => {
                                    if (filterItem.value === 'match_date') {
                                        handleDatePicker();
                                    } else handleBoxOpen(filterItem)
                                }}>
                                <div className={'tournament_match_filter_button_callsign'}>
                                    {filterItem.value === 'match_date'
                                        ? <div>
                                            <span className='baloa-table'>{matchDate ?? filterItem?.content}</span>
                                            <div className='tournament-match-admin-filter__date-picker__container'>
                                                <DatePicker
                                                    id='match_date'
                                                    name='match_date'
                                                    locale={props.language_code }
                                                    ref={datePickerRef}
                                                    selected={selectedTempDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    calendarClassName="custom-calendar"
                                                    popperPlacement="bottom-center"
                                                    //maxDate={new Date()}
                                                    //className='tournament-match-admin-filter__date-picker__input'
                                                    popperClassName={'tournament-match-admin-filter__date-picker__input'}
                                                />
                                            </div>
                                        </div>
                                        : <div className='tournament_match_filter_button_callsign__used'>
                                            <span className='baloa-table'>{filterItem.content} </span>
                                            <span className='baloa-table number_circle'>{filterItem.filterCounter > 0 && filterItem.filterCounter}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            {filterItem.filterCounter === 0
                                ? filterItem.value !== 'generalFilter' && <span className="hubicon-expand_more" onClick={() => {
                                    if (filterItem.value === 'match_date') {
                                        handleDatePicker();
                                    } else handleBoxOpen(filterItem)
                                }} />
                                : filterItem.value !== 'generalFilter' && <span className='icon-x baloa-names' onClick={() => clearFilterStatus(filterItem.value)} />
                            }
                        </div>
                        <div className={`tournament_match_dropdown-content ${boxPosition === filterItem.value ? '_active' : ''}`}>
                            <div className='tournament_match_dropdown-content_title'>
                                <p className='baloa-table'>{filterType?.content}</p>
                                <span className='icon-x baloa-names' onClick={() => {
                                    if (filterItem.value === 'match_date') {
                                        // setFilterType(filterItem);
                                        handleDatePicker();
                                    } else handleBoxOpen(filterType)
                                }} />
                            </div>
                            <FormComponent
                                formInitialValues={FORM_INITIAL_VALUES_TO_FILTER}
                                onSubmit={(values) => { 
                                    handleBoxOpen(filterType, 'submit');
                                    handleSubmitForm(values);
                                }}
                                className=""
                                functionDisabled={() => { }}
                                enableReinitialize={false}
                            >
                                {/*  Render different filter section by component*/}
                                {boxPosition && loadTypeFilterComponent(filterType, index)}

                                <div className='tournament_match_admin_filter_actions'>
                                    <SecondaryButtonComponent
                                        type={'button'}
                                        className="small"
                                        onClick={() => {
                                            handleBoxOpen(filterType, 'cancel');
                                        }}>
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelCacelFilter')}</span>
                                    </SecondaryButtonComponent>&nbsp;
                                    <PrimaryButtonComponent
                                        className="small"
                                        type='submit'
                                        // onClick={() => { handleBoxOpen(filterType, 'submit') }}
                                    >
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelApplyFilter')}</span>
                                    </PrimaryButtonComponent>
                                </div>
                            </FormComponent>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentMatchAdminFilterComponent)