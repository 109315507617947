// Action creators
import { 
    changeBaloaPlaySubSectionsLoadingAction,
    loadBaloaPlaySubSectionsAction,
    loadBaloaPlaySubSectionsArrayAction,
    loadSearchMatchVideosAction,
    changeSearchMatchVideosLoadingAction,
    loadBaloaPlayMatchAction,
    loadBaloaPlayMatchClipsAction,
    setMatchPaymentUrlAction,
    setClipPaymentUrlAction,
    changeBaloaPlayClipsLoadingAction,
    loadBaloaPlayClipsAction,
    loadBaloaPlayClipAction,
    changeClaimClipLoadingAction,
    changeBaloaPlayCreateClipConfirmationModalStateAction,
}  from './BaloaPlayActionsCreators';

import { 
    changeErrorMessageSnackBar
} from 'app/home/actions/HomeActionsCreators';

// Apis
import { 
    GetBaloaPlaySubSectionsApi,
    GetBaloaPlaySubSectionApi,
    SearchMatchVideosApi,
    GetBaloaPlayMatchApi,
    GetBaloaPlayMatchClipsApi,
    SetMatchPaymentApi,
    //SetClipPaymentApi,
    GetBaloaPlayClipsApi,
    GetBaloaPlayClipApi,
    SetClipClaimApi,
    UpdateClipApi,
    PostClipApi,
    CreateMatchClipApi,
} from 'app/baloa-play/apis/BaloaPlayApi';
import { deleteProfileVideoApi } from 'app/profiles/apis/ProfileApi';

// i18next 
import i18n from 'i18next';

export const loadBaloaPlaySubSections = (  ) => {

    return async( dispatch ) => {
        dispatch( changeBaloaPlaySubSectionsLoadingAction( true ) );  
        await GetBaloaPlaySubSectionsApi(  )
            .then( response => {
                dispatch( loadBaloaPlaySubSectionsAction( response.data ) );
                response.data.results.map( subsection =>
                    dispatch( loadBaloaPlaySubSection( subsection.id, subsection.filter_types, 6))//TODO: dejar en 10 cuando se implementen los video en vivo
                )
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
            
    }
}

export const loadBaloaPlaySubSection = ( id_subsection, filter_type, pager, next_url ) => {

    return async ( dispatch ) => {
        dispatch( changeBaloaPlaySubSectionsLoadingAction( true ) );  
        await GetBaloaPlaySubSectionApi( id_subsection, pager, next_url )
            .then( response => {
                dispatch( loadBaloaPlaySubSectionsArrayAction( response.data, filter_type ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            }).then( () => {
                dispatch( changeBaloaPlaySubSectionsLoadingAction( false ) );
            } );
    }
}

export const searchMatchVideos = ( search ) => {

    return async ( dispatch ) => {
        dispatch( changeSearchMatchVideosLoadingAction( true ) );  
        await SearchMatchVideosApi( search )
            .then( response => {
                dispatch( loadSearchMatchVideosAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            }).then( () => {
                dispatch( changeSearchMatchVideosLoadingAction( false ) );
            } );
    }
}

export const loadBaloaPlayMatch = ( match_id ) => {

    return async ( dispatch ) => {

        await GetBaloaPlayMatchApi( match_id )
            .then( response => {
                dispatch( loadBaloaPlayMatchAction( response.data, false ) );
                if(response.data.post_clip_recording_id){
                    dispatch( loadBaloaPlayMatchClips( response.data.post_clip_recording_id ) );
                }else {
                    dispatch( loadBaloaPlayMatchClipsAction( {results:[]} ) );
                }
            } )
            .catch( error => {
                if(error.response?.data?.detail === 'You do not have permission to view this match recording.'){
                    dispatch( loadBaloaPlayMatchAction( undefined, true ) );
                }else if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

export const loadBaloaPlayMatchClips = ( post_clip_recording_id ) => {

    return async ( dispatch ) => {

        await GetBaloaPlayMatchClipsApi( post_clip_recording_id )
        .then( response => {
            dispatch( loadBaloaPlayMatchClipsAction( response.data ) );           
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'),
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        })
    }
}

export const matchPayment = ( match_id, metadata ) => {

    return async ( dispatch ) => {

        await SetMatchPaymentApi( match_id, metadata )
            .then( response => {
                dispatch( setMatchPaymentUrlAction(response.data) )           
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

/* export const clipPayment = ( clip_id, metadata ) => {

    return async ( dispatch ) => {

        await SetClipPaymentApi( clip_id, metadata )
            .then( response => {
                dispatch( setClipPaymentUrlAction(response.data) )
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
} */

export const clipClaim = ( clip_id ) => {

    return async ( dispatch ) => {
        dispatch( changeClaimClipLoadingAction( true ) ); 
        await SetClipClaimApi( clip_id )
            .then( response => {
                dispatch( loadBaloaPlayClip(clip_id) )
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

export const loadBaloaPlayMyClips = ( page_size ) => {

    return async( dispatch ) => {
        dispatch( changeBaloaPlayClipsLoadingAction( true ) );  
        await GetBaloaPlayClipsApi( page_size )
            .then( response => {
                dispatch( loadBaloaPlayClipsAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            }).then( () => {
                dispatch( changeBaloaPlayClipsLoadingAction( false ) );
            } );
            
    }
}

export const loadBaloaPlayClip = ( clip_id ) => {

    return async( dispatch ) => {
        await GetBaloaPlayClipApi( clip_id )
            .then( response => {
                dispatch( loadBaloaPlayClipAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            }).then( () => {
                dispatch( changeClaimClipLoadingAction( false ) );
            } );             
    }
}

export const updateClip = ( clip_id, title ) => {

    return async ( dispatch ) => {
        await UpdateClipApi( clip_id, title )
            .then( response => {
                dispatch( loadBaloaPlayClip(clip_id) )
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

export const postClip = ( clip_id, body ) => {

    return async ( dispatch ) => {
        await PostClipApi( clip_id, body )
            .then( response => {
                dispatch( loadBaloaPlayClip(clip_id) )
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

export const deletePostClip = ( post_id, clip_id) => {

    return async ( dispatch ) => {

        await deleteProfileVideoApi( post_id )
            .then( response => {
                dispatch( loadBaloaPlayClip(clip_id) )          
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}

export const createMatchClip  = (match_id, match_recording, clip_start_time, clip_end_time, clip_title ) => {

    return async ( dispatch ) => {
        await CreateMatchClipApi( match_recording, clip_start_time, clip_end_time, clip_title )
            .then( response => {
                dispatch( loadBaloaPlayMatch(match_id) );
                dispatch( changeBaloaPlayCreateClipConfirmationModalStateAction(true) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
    }
}