/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './BaloaPlayMenuComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

class BaloaPlayMenuComponent extends Component {

    constructor( props ) {
        super(props);
        this.state = {
            tab_selected: 0,
        }
    }

    componentDidMount () {
        
    }

    nextPath = (path) => {
        this.props.history.push(path)
    }

    selectedTab (tab) {
        
        var patt;

        if(tab === 0){
            patt = new RegExp("^\/BaloaPlay$"); // eslint-disable-line
        }

        if(tab === 1){
            patt = new RegExp("^\/BaloaPlay\/mis-clips"); // eslint-disable-line
        }

        if(tab === 2){
            patt = new RegExp("^\/BaloaPlay\/en-vivo$"); // eslint-disable-line
        }

        if(tab === 3){
            patt = new RegExp("^\/BaloaPlay\/transmisiones-finalizadas$"); // eslint-disable-line
        }
        
        return patt.test(this.props.location?.pathname);

    }

    render() {

        return(
            <React.Fragment>

                { isMobile 
                    ?
                    <div className='baloa-play-menu__container' >
                        
                    </div>
                    :
                    <React.Fragment>
                        <div className={"baloa-play-menu"}>
                            <div className='baloa-play-menu__menu-links' >
                                <div className="baloa-play-menu__title">
                                    <p className="baloa-play-menu__title__text baloa-headline-2">{this.props.t('baloaPlayTitle')}</p>
                                </div>
                                <div className={"baloa-play-menu__field " +(this.selectedTab(0) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:0});
                                        this.nextPath(`/BaloaPlay`);                            
                                    }}
                                >                        
                                    <div className="baloa-play-menu__field__data">
                                        <div className={"baloa-play-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(0) ? <span className='hubicon-home-1' /> : <span className='hubicon-home' /> }
                                            <div>{this.props.t('menu.starLbl')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"baloa-play-menu__field "+(this.selectedTab(1) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:1});
                                        this.nextPath(`/BaloaPlay/mis-clips`);                            
                                    }}
                                >
                                    <div className={"baloa-play-menu__field__data"}>
                                        <div className={"baloa-play-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(1) ? <span className='hubicon-video_library' /> : <span className='hubicon-video_library1' /> }
                                            <div>{this.props.t('menu.myClipsLbl')}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={"baloa-play-menu__field "+(this.selectedTab(2) ? "active":"")} //TODO:se oculta mientras se implementa funcionalidad
                                    onClick={() => {
                                        this.setState({tab_selected:2});
                                        this.nextPath(`/BaloaPlay/en-vivo`);                            
                                    }}
                                >
                                    <div className={"baloa-play-menu__field__data"}>
                                        <div className={"baloa-play-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(2) ? <span className='hubicon-sensors' /> : <span className='hubicon-sensors' /> }
                                            <div>{this.props.t('menu.liveLbl')}</div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className={"baloa-play-menu__field "+(this.selectedTab(3) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:3});
                                        this.nextPath(`/BaloaPlay/transmisiones-finalizadas`);
                                    }}
                                >
                                    <div className="baloa-play-menu__field__data">
                                        <div className={"baloa-play-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(3) ? <span className='hubicon-sync_saved_locally-1' /> : <span className='hubicon-sync_saved_locally' /> }
                                            <div>{this.props.t('menu.transCompletedLbl')}</div>
                                        </div>
                                    </div>
                                </div>        

                            </div>
                           
                        </div>
                        
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}    

export default compose(
    withRouter,
    withTranslation("baloaplay"),
    connect(mapStateToProps, mapDispatchToProps)
)(BaloaPlayMenuComponent);

