// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-pro__content {
    padding: 45px 236px 166px 236px;
    background: var(--white-color);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .baloa-pro__content {
        padding: 10px 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/baloa-pro/views/BaloaProView.styles.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".baloa-pro__content {\n    padding: 45px 236px 166px 236px;\n    background: var(--white-color);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .baloa-pro__content {\n        padding: 10px 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
