
// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetHomePostsApi = ( next_page ) => {

    return Axios.instance.get( next_page ? next_page : `${api_url}/home-feed/?version=2`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetSuggestionsApi = ( pagetype ) => {

    return Axios.instance.get( `${api_url}/page-suggestions/?page_type=${ pagetype }`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetProfileSuggestionsApi = () => {

    return Axios.instance.get( `${api_url}/profile-suggestions/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

/*export const GetPageSuggestionsApi = () => {

    return Axios.instance.get( `${api_url}/page-suggestions/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}*/

/*export const GetGroupsSuggestionsApi = () => {

    return Axios.instance.get( `${api_url}/friends-suggestions/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}*/

/*export const GetLeaguesSuggestionsApi = () => {

    return Axios.instance.get( `${api_url}/league-suggestions/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}*/

/* export const GetHomePostsNextApi = ( posts_next ) => {
//  console.log('aquii',posts_next);
    return Axios.instance.get( `https://dev.baloa.com/api/home-feed/?cursor=cD0yMDIwLTEyLTE4KzAxJTNBNTklM0ExMS4yMDE2NTklMkIwMCUzQTAw`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}
 */

export const GetHomePagesShortcutsApi = () => {

    return Axios.instance.get( `${api_url}/pages-shortcuts/?pagination=False`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetUnauthenticatedSuggestionsApi = ( pagetype ) => {

    return Axios.instance.get( `${api_url}/page-suggestions/?page_type=${ pagetype }`,{});

}

export const GetFollowedPagesApi = ( username, next_page  ) => {

    return Axios.instance.get(
        next_page ? next_page :`${api_url}/profiles/${ username }/following_pages/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetExplorePagesApi = ( pagename, page_type, categories, next_page ) => {

    return Axios.instance.get(
        next_page ? next_page :`${api_url}/portal-pages/?search=${ pagename }${page_type? '&'+page_type :''}${categories? '='+categories : ''}&pagination=True`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetHomePlayersApi = ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) => {
    /* eslint-disable */
    return Axios.instance.get(
        next_page ? next_page :(`${api_url}/portal-players/?search=${ player }${video? '&post_video='+video : '' }${nationality? '&nationality='+nationality : '' }${location? '&location='+location : '' }${age? '&age='+age : '' }
        ${principalPos? '&primary_position='+principalPos : '' }${secondaryPos? '&secondary_position='+secondaryPos : '' }${laterality? '&laterality='+laterality : '' }${goalsRange? '&goals='+goals+'&goals_range='+goalsRange : '' }
        ${assistancesRange? '&assistance='+assistances+'&assistance_range='+assistancesRange : '' }${playedMatchesRange? '&games_played='+playedMatches+'&games_played_range='+playedMatchesRange : '' }${starterMatchesRange? '&played_as_titular='+starterMatches+'&played_as_titular_range='+starterMatchesRange : '' }
        ${playedMinutesRange? '&minutes_played='+playedMinutes+'&minutes_played_range='+playedMinutesRange : '' }&pagination=True`).replace(/  /g, ''),{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    /* eslint-enable */
}

export const GetHomeFeaturedClipsApi = ( next_page ) => {

    return Axios.instance.get(
        next_page ? next_page :`${api_url}/featured-videos/`,{});//se deja publica para todo el sitio 
}
