// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';

//Material UI
import { Skeleton } from '@mui/material';

// Styles
import './TournamentStatisticsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';


function TournamentStatisticsComponent(props){ 

    return(        
        <div className='tournament-statistics__container'>
            <div className='tournament-statistics__scorers'>
                <div className='tournament-statistics__scorers-title'>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.scorersLabel')}</div>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals')}</div>
                </div>
                {props.loading_tournament_scorers && isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={17} height={11} style={{  }}/>
                        </div>
                    )
                }
                {props.loading_tournament_scorers && !isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton className='tournament-statistics__scorers-row__number baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                            <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                                <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                        </div>
                    )
                }
                { (!props.loading_tournament_scorers && props.tournament_scorers?.length > 0)
                    ?
                        props.tournament_scorers?.slice(0, 5).map( (sccorer, scoIndex) => 
                            <div className='tournament-statistics__scorers-row' key={scoIndex}>
                                <div className='tournament-statistics__scorers-row__number baloa-normal-medium'>{scoIndex + 1}</div>
                                <div><Link to={`/profile/${sccorer.name}/`}><TournamentPlayerInfoComponent full_name={`${sccorer.first_name} ${sccorer.last_name}`} photo={sccorer.photo} team_name={sccorer.team} /></Link></div>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{sccorer.goals}</div>
                            </div>
                        )                        
                    :
                        <div className='tournament-statistics__no-data'>
                            <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noScorersText')}</div>
                        </div>
                }
                {(props.next_tournament_scorers || props.tournament_scorers?.length > 5) &&
                    <div className='tournament-statistics__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics('scorer') } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
                }
            </div>

            <div className='tournament-statistics__amonished__cards'>
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardYellow')}</div>
                {props.loading_tournament_yellow_cards && isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={17} height={11} style={{  }}/>
                        </div>
                    )
                }
                {props.loading_tournament_yellow_cards && !isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton className='tournament-statistics__scorers-row__number baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                            <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                                <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                        </div>
                    )
                }
                {(!props.loading_tournament_yellow_cards && props.tournament_yellow_cards?.length > 0)
                    ?
                        props.tournament_yellow_cards?.slice(0, 5).map( (card, cardIndex) => 
                            <div className='tournament-statistics__scorers-row' key={cardIndex}>
                                <div className='tournament-statistics__scorers-row__number baloa-normal-medium'>{cardIndex + 1}</div>
                                <div><Link to={`/profile/${card.name}/`}><TournamentPlayerInfoComponent full_name={`${card.first_name} ${card.last_name}`} photo={card.photo} team_name={card.team} /></Link></div>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{card.cards}</div>
                                <div className='yellow-card'/>
                            </div>
                        )                                                                  
                    :
                        <div className='tournament-statistics__no-data'>
                            <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noYellowCardsText')}</div>
                        </div>
                }
                {(props.next_tournament_yellow_cards || props.tournament_yellow_cards?.length > 5) &&
                    <div className='tournament-statistics__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics('yellow-card') } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
                }
            </div>
            <div className='tournament-statistics__amonished__cards'>
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardRed')}</div>
                {props.loading_tournament_red_cards && isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={17} height={11} style={{  }}/>
                        </div>
                    )
                }
                {props.loading_tournament_red_cards && !isMobile &&
                    [...Array(Number(5))].map((e, i) => 
                        <div className='tournament-statistics__scorers-row' key={i}>
                            <Skeleton className='tournament-statistics__scorers-row__number baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                            <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                            <div className='tournament-statistics__scorers-row__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                                <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                            </div>
                            <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                        </div>
                    )
                }
                {(!props.loading_tournament_red_cards && props.tournament_red_cards?.length > 0 )
                    ?
                        props.tournament_red_cards?.slice(0, 5).map( (card, cardIndex) => 
                            <div className='tournament-statistics__scorers-row' key={cardIndex}>
                                <div className='tournament-statistics__scorers-row__number baloa-normal-medium'>{cardIndex + 1}</div>
                                <div><Link to={`/profile/${card.name}/`}><TournamentPlayerInfoComponent full_name={`${card.first_name} ${card.last_name}`} photo={card.photo} team_name={card.team} /></Link></div>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{card.cards}</div>
                                <div className='red-card'/>
                            </div>
                        )                        
                    :
                        <div className='tournament-statistics__no-data'>
                            <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noRedCardsText')}</div>
                        </div>
                }
                {(props.next_tournament_red_cards || props.tournament_red_cards?.length > 5) &&
                    <div className='tournament-statistics__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics('red-card') } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
                }
            </div>
                 
        </div>
    )
}

export default withTranslation('league')(TournamentStatisticsComponent);