// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-pro-subscription-widget__container {
    border: 1px solid var(--new-gray-background-color);
    border-radius: 8px;
    padding: 12px;
    margin-top: var(--space);
    background: var(--white-color);
}

.baloa-pro-subscription-widget__title {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.baloa-pro-subscription-widget__title .baloa-subtitle-2 {
    color: var(--primary-very-dark-color);
    line-height: unset;
}

.baloa-pro-subscription-widget__title .hubicon-award_star {
    font-size: 28px;
    margin-right: 4px;
}

.baloa-pro-subscription-widget__container .baloa-names {
    color: var(--primary-very-dark-color);
    font-weight: var(--font-regular);
    margin-top: 6px;
}

.baloa-pro-subscription-widget__container .button {
    font-size: calc(var(--space) + var(--space-eighth));
    padding: 10px 20px;
    margin-top: 14px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .baloa-pro-subscription-widget__title .baloa-subtitle-2 {
        font-size: 16px;
    }

    .baloa-pro-subscription-widget__title .hubicon-award_star {
        font-size: 22px;
    }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/baloa-pro-subscription-widget/BaloaProSubscriptionWidgetComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,kBAAkB;IAClB,aAAa;IACb,wBAAwB;IACxB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;IACrC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,mDAAmD;IACnD,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".baloa-pro-subscription-widget__container {\n    border: 1px solid var(--new-gray-background-color);\n    border-radius: 8px;\n    padding: 12px;\n    margin-top: var(--space);\n    background: var(--white-color);\n}\n\n.baloa-pro-subscription-widget__title {\n    margin-top: 6px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.baloa-pro-subscription-widget__title .baloa-subtitle-2 {\n    color: var(--primary-very-dark-color);\n    line-height: unset;\n}\n\n.baloa-pro-subscription-widget__title .hubicon-award_star {\n    font-size: 28px;\n    margin-right: 4px;\n}\n\n.baloa-pro-subscription-widget__container .baloa-names {\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-regular);\n    margin-top: 6px;\n}\n\n.baloa-pro-subscription-widget__container .button {\n    font-size: calc(var(--space) + var(--space-eighth));\n    padding: 10px 20px;\n    margin-top: 14px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .baloa-pro-subscription-widget__title .baloa-subtitle-2 {\n        font-size: 16px;\n    }\n\n    .baloa-pro-subscription-widget__title .hubicon-award_star {\n        font-size: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
