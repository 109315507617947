
import React from 'react';

//Styles
import './LeagueTournamentReportCalendarComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/material';

//assets
import empty_baloa_play_matches from 'assets/images/empty_baloa_play_matches.png';

function LeagueTournamentReportCalendarComponent(props) {
    const [expanded, setExpanded] = React.useState('matchday0');
    const [groupExpanded, setGroupExpanded] = React.useState('matchday0_group0');

    const handleMatchdayChange = (panel, matchday) => (event, newExpanded) => {
        if(newExpanded){
            //console.log('matchday',matchday.date_number, matchday.groups[0].id);
            props.handleOnMatchday( matchday.groups[0].id, matchday.date_number)
        }
        setExpanded(newExpanded ? panel : false);
        setGroupExpanded(newExpanded ? panel+'_group0' : false);
    }

    const handleGroupsChange = ( panel, date_number , group ) => (event, newGroupExpanded) => {
        if(newGroupExpanded){
            //console.log('group matches', date_number, group.id);
            props.handleOnMatchday( group.id, date_number)
        }
        setGroupExpanded(newGroupExpanded ? panel : false);
    }
 
    return (
        <div className={`league-tournament-report-calendar ${(props.calendar_matchdays?.calendar?.length === 0 && !props.loading_phase_calendar)? 'emtpy' : ''}`}>
            {props.calendar_matchdays?.calendar?.length > 0 &&
                props.calendar_matchdays?.calendar?.map((matchday, indMd) => (
                    <React.Fragment>
                        {matchday.groups.length === 1 && 
                            <Accordion 
                                key={`accordion_liga_${indMd}`} 
                                onChange={ handleMatchdayChange(`matchday${indMd}`, matchday) } 
                                expanded={ expanded === `matchday${indMd}` }
                                className='league-tournament-report-calendar__accordion-item'
                            >
                                <AccordionSummary
                                    expandIcon={ <ExpandMoreIcon /> }
                                    aria-label="Expand"
                                    aria-controls={ `aria_controls_md_${indMd}` }
                                    id={ `matchday_${indMd}` }
                                    className='league-tournament-report-calendar__accordion-item__summary'
                                >
                                    <div className='baloa-normal-medium'>{matchday.match_date}</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <React.Fragment>
                                        <div className={`league-tournament-report-calendar__date-by-group ${(props.calendar_group_matches.length === 0 && !props.loading_phase_calendar)? 'hidden' : ''}`}>
                                            {props.calendar_group_matches.length > 0 && props.calendar_group_matches?.map((match) => (
                                                <div key={match.id} className={props.tournament_active_phase.phase_type === 'Groups' ? "league-tournament-report-calendar__match-detail-container" : "league-tournament-report-calendar__match-detail-container_margin"} onClick={() => props.handleOnPostMatch(match)} >
                                                    <div className="league-tournament-report-calendar__match-info-container">
                                                        {match.state === "Finalized" ?
                                                            <div className="league-tournament-report-calendar__teams-result-container">
                                                                <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                                            </div>
                                                            :match.match_date ?
                                                                <div className="league-tournament-report-calendar__teams-result-container">
                                                                    <span className='hubicon-finish_date' />
                                                                    <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                                                    <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                                                </div>
                                                                :
                                                                <div className="league-tournament-report-calendar__teams-result-container">
                                                                    <span className='hubicon-finish_date' />
                                                                    <div className="baloa-table">N/A</div>
                                                                </div>
                                                        }
                                                        <div className='league-tournament-report-calendar__teams-stadium-container'>
                                                            <span className='hubicon-stadium' />
                                                            <div className='baloa-table' >
                                                                {match.state !== "Not_scheduled"
                                                                    ? match.field
                                                                    : ' N/A'
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="league-tournament-report-calendar__teams-names-container">
                                                        <div className="league-tournament-report-calendar__teams_local-container">
                                                            <div className='league-tournament-report-calendar__teams-container '>
                                                                <div className="baloa-names">{match.local_team_name}</div>
                                                                {match.local_team_photo === null
                                                                    ?
                                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                    :
                                                                    <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="league-tournament-report-calendar-score__teams-container">
                                                            {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                                            {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                                            {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                                        </div>
                                                        <div className="league-tournament-report-calendar__teams_visitant-container">
                                                            <div className="league-tournament-report-calendar__teams-container">

                                                                {match.visitant_team_photo === null
                                                                    ?
                                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                    :
                                                                    <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                                                }
                                                                <div className="baloa-names">{match.visitant_team_name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                                       
                                            ))}
                                            {props.calendar_group_matches.length === 0 && props.loading_phase_calendar && 
                                                [...Array(Number(4))].map((e, i) =>
                                                    <div className='league-tournament-report-calendar__skeleton'>
                                                        <div className='league-tournament-report-calendar__skeleton__match'>
                                                            <Skeleton animation="wave" component={'div'} width='25%' />
                                                            <Skeleton animation="wave" component={'div'} width='40%' />
                                                        </div>
                                                        <div className='league-tournament-report-calendar__skeleton__results'>
                                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                            <Skeleton animation="wave" component={'div'} width='5%' />
                                                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {props.calendar_group_matches.length > 0 && props.loading_phase_calendar && 
                                                [...Array(Number(2))].map((e, i) =>
                                                    <div className='league-tournament-report-calendar__skeleton'>
                                                        <div className='league-tournament-report-calendar__skeleton__match'>
                                                            <Skeleton animation="wave" component={'div'} width='25%' />
                                                            <Skeleton animation="wave" component={'div'} width='40%' />
                                                        </div>
                                                        <div className='league-tournament-report-calendar__skeleton__results'>
                                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                            <Skeleton animation="wave" component={'div'} width='5%' />
                                                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                            <Skeleton animation="wave" component={'div'} width='30%' />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {props.calendar_group_matches_next && !props.loading_phase_calendar &&
                                                <div className='baloa-names view-more' onClick={()=>{props.handleViewMoreMatches(props.calendar_group_matches_next)}}>{props.t('matchByCalendar.viewMoreLbl')}</div>
                                            }
                                        </div>
                                        {props.calendar_group_matches.length === 0 && !props.loading_phase_calendar && 
                                            <div className='league-tournament-report-calendar__empty-message'>
                                                <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                                                <div className='baloa-table-column'>{props.t('matchByCalendar.emptyMatchday')}</div>
                                            </div>
                                        }
                                    </React.Fragment>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {matchday.groups.length > 1 && 
                            <Accordion 
                            key={`accordion_grupos_${indMd}`}
                                onChange={ handleMatchdayChange(`matchday${indMd}`, matchday) } 
                                expanded={ expanded === `matchday${indMd}` }
                                className='league-tournament-report-calendar__accordion-item'
                            >
                                <AccordionSummary
                                    expandIcon={ <ExpandMoreIcon /> }
                                    aria-label="Expand"
                                    aria-controls={ `aria_controls_md_${indMd}` }
                                    id={ `matchday_${indMd}` }
                                    className='league-tournament-report-calendar__accordion-item__summary'
                                >
                                    <div className='baloa-normal-medium'>{matchday.match_date}</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {matchday.groups.map((match, indGroups) =>(
                                        <Accordion
                                            key={indMd} 
                                            onChange={ handleGroupsChange(`matchday${indMd}_group${indGroups}`, matchday.date_number, match) } 
                                            expanded={ groupExpanded === `matchday${indMd}_group${indGroups}` }
                                            className='league-tournament-report-calendar__accordion-item-group'
                                        >
                                            <AccordionSummary
                                                expandIcon={ <ExpandMoreIcon /> }
                                                aria-label="Expand"
                                                aria-controls={ `aria_controls_md_${indMd}_group_${indGroups}` }
                                                id={ `md_${indMd}_group_${indGroups}` }
                                                className='league-tournament-report-calendar__accordion-item-group__summary'
                                            >                                        
                                                <div className='baloa-normal-medium'>
                                                    {((props.tournament_active_phase.phase_type === 'Groups' || props.tournament_active_phase.phase_type === 'Free' ) && !match.group_name.includes('Draw') && !props.loading_phase_calendar )
                                                        ?
                                                            props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${match.group_name.split(" ")[0]}`,{_number: match.group_name.split(" ")[1]})
                                                        :
                                                            (props.tournament_active_phase.phase_name && !match.group_name.includes('Group')&& !props.loading_phase_calendar)?
                                                                props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${match.group_name.split(" ")[0]}`, {phase_name: props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase.phase_name}`), _number: match.group_name.split(" ")[1] })
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={`league-tournament-report-calendar__date-by-group ${(props.calendar_group_matches.length === 0 && !props.loading_phase_calendar)? 'hidden' : ''}`}>
                                                    {props.calendar_group_matches.length > 0 && props.calendar_group_matches?.map((match) => (
                                                        <div key={match.id} className={props.tournament_active_phase.phase_type === 'Groups' ? "league-tournament-report-calendar__match-detail-container" : "league-tournament-report-calendar__match-detail-container_margin"} onClick={() => props.handleOnPostMatch(match)} >
                                                            <div className="league-tournament-report-calendar__match-info-container">
                                                                {match.state === "Finalized" ?
                                                                    <div className="league-tournament-report-calendar__teams-result-container">
                                                                        <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                                                    </div>
                                                                    :match.match_date ?
                                                                        <div className="league-tournament-report-calendar__teams-result-container">
                                                                            <span className='hubicon-finish_date' />
                                                                            <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                                                            <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                                                        </div>
                                                                        :
                                                                        <div className="league-tournament-report-calendar__teams-result-container">
                                                                            <span className='hubicon-finish_date' />
                                                                            <div className="baloa-table">N/A</div>
                                                                        </div>
                                                                }
                                                                <div className='league-tournament-report-calendar__teams-stadium-container'>
                                                                    <span className='hubicon-stadium' />
                                                                    <div className='baloa-table' >
                                                                        {match.state !== "Not_scheduled"
                                                                            ? match.field
                                                                            : ' N/A'
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="league-tournament-report-calendar__teams-names-container">
                                                                <div className="league-tournament-report-calendar__teams_local-container">
                                                                    <div className='league-tournament-report-calendar__teams-container '>
                                                                        <div className="baloa-names">{match.local_team_name}</div>
                                                                        {match.local_team_photo === null
                                                                            ?
                                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                            :
                                                                            <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="league-tournament-report-calendar-score__teams-container">
                                                                    {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                                                    {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                                                    {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                                                </div>
                                                                <div className="league-tournament-report-calendar__teams_visitant-container">
                                                                    <div className="league-tournament-report-calendar__teams-container">

                                                                        {match.visitant_team_photo === null
                                                                            ?
                                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                            :
                                                                            <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                                                        }
                                                                        <div className="baloa-names">{match.visitant_team_name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {props.calendar_group_matches.length === 0 && props.loading_phase_calendar && 
                                                        [...Array(Number(4))].map((e, i) =>
                                                            <div className='league-tournament-report-calendar__skeleton'>
                                                                <div className='league-tournament-report-calendar__skeleton__match'>
                                                                    <Skeleton animation="wave" component={'div'} width='25%' />
                                                                    <Skeleton animation="wave" component={'div'} width='40%' />
                                                                </div>
                                                                <div className='league-tournament-report-calendar__skeleton__results'>
                                                                    <Skeleton animation="wave" component={'div'} width='30%' />
                                                                    <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                                    <Skeleton animation="wave" component={'div'} width='5%' />
                                                                    <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                                    <Skeleton animation="wave" component={'div'} width='30%' />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {props.calendar_group_matches.length > 0 && props.loading_phase_calendar && 
                                                        [...Array(Number(2))].map((e, i) =>
                                                            <div className='league-tournament-report-calendar__skeleton'>
                                                                <div className='league-tournament-report-calendar__skeleton__match'>
                                                                    <Skeleton animation="wave" component={'div'} width='25%' />
                                                                    <Skeleton animation="wave" component={'div'} width='40%' />
                                                                </div>
                                                                <div className='league-tournament-report-calendar__skeleton__results'>
                                                                    <Skeleton animation="wave" component={'div'} width='30%' />
                                                                    <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                                    <Skeleton animation="wave" component={'div'} width='5%' />
                                                                    <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                                    <Skeleton animation="wave" component={'div'} width='30%' />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {props.calendar_group_matches_next && !props.loading_phase_calendar &&
                                                        <div className='baloa-names view-more' onClick={()=>{props.handleViewMoreMatches(props.calendar_group_matches_next)}}>{props.t('matchByCalendar.viewMoreLbl')}</div>
                                                    }                                            
                                                </div>
                                                {props.calendar_group_matches.length === 0 && !props.loading_phase_calendar && 
                                                    <div className='league-tournament-report-calendar__empty-message'>
                                                        <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                                                        <div className='baloa-table-column'>{props.t('matchByCalendar.emptyGroup')}</div>
                                                    </div>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        }
                    </React.Fragment>
                ))
            }
            {props.calendar_matchdays?.calendar?.length === 0 && props.loading_phase_calendar &&
                <div className={`league-tournament-report-calendar__date-by-group`}>
                {[...Array(Number(4))].map((e, i) =>
                    <div className='league-tournament-report-calendar__skeleton'>
                        <div className='league-tournament-report-calendar__skeleton__match'>
                            <Skeleton animation="wave" component={'div'} width='25%' />
                            <Skeleton animation="wave" component={'div'} width='40%' />
                        </div>
                        <div className='league-tournament-report-calendar__skeleton__results'>
                            <Skeleton animation="wave" component={'div'} width='30%' />
                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                            <Skeleton animation="wave" component={'div'} width='5%' />
                            <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                            <Skeleton animation="wave" component={'div'} width='30%' />
                        </div>
                    </div>
                )}
                </div>
            }

            {props.calendar_matchdays?.calendar?.length === 0 && !props.loading_phase_calendar &&
                <div className='league-tournament-report-calendar__empty-message'>
                    <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                    <div className='baloa-table-column'>{props.t('matchByCalendar.emptyPhase')}</div>
                </div>
            }
        </div>
    );
}

export default withTranslation(["tournament", "league"])(LeagueTournamentReportCalendarComponent);