// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import InputFieldTeamAutocompleteComponent from 'shared/components/input-field-team-autocomplete/InputFieldTeamAutocompleteComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './TournamentTransferPlayerModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentTransferPlayerModalComponent( props ) {

    const [ teamName, setTeamName ] = React.useState('');
    const [ teamId, setTeamId ] = React.useState('') ;
    const formInitialValues = {team:''}

    const handleTeam = (selected_team) => {
        setTeamName(selected_team?.teamname);
        setTeamId(selected_team?.tournament_team_id);
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose }
            title={props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.tranferPlayer')} >  
            <div className='tournament-transfer-player__container'>
                <div className='tournament-transfer-player__player'>
                    <div className="tournament-transfer-player__player-img">
                        { !props.player.profile_photo
                            ?
                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                            :
                            <img alt={`${props.player.full_name}`} src={props.player.profile_photo} />
                        }
                    </div> 
                    <div className="tournament-transfer-player__player-info">
                        <div className="baloa-names">{props.player.full_name}</div>
                        <div className="baloa-username">@{props.player.username}</div>
                    </div>
                </div>   
                <div className='tournament-transfer-player__title'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.fromTeam')}</div>
                <div className='tournament-transfer-player__from-team'>
                    <div className="tournament-transfer-player__from-team-img">
                        { !props.team?.team_logo
                            ?
                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                            :
                            <img alt={`${props.team.team}`} src={props.team?.team_logo} />
                        }
                    </div> 
                    <div className="tournament-transfer-player__from-team-info">
                        <div className="baloa-names">{props.team?.team}</div>
                        <div className="baloa-username">{props.team?.teamname}</div>
                    </div>
                </div>  
                <div className='tournament-transfer-player__title'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.toTeam')}</div>
                <FormComponent
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { }}
                    className="tournament-transfer-player__team-select"
                    functionDisabled={()=>{}}
                >
                    <InputFieldTeamAutocompleteComponent
                        name= 'team'
                        options={ props.tournament_teams? props.tournament_teams : null }
                        noOptionsText=''                        
                        optionSelectedField={ "teamname" }
                        className="tournament-transfer-player__team-selector"
                        placeholder={props.t('tournament:inscriptionModal.teamsSearchPlaceholder')}
                        onChange={ (selectedTeam) => {handleTeam(selectedTeam)} }
                        is_transfer_player={true}
                    />
                </FormComponent>
                <div className='tournament-transfer-player_button'></div>
                <PrimaryButtonComponent 
                    className="tournament-transfer-player_button-btn"
                    disabled={ !teamName || !teamId }
                    onClick={ () =>{ props.handleOnTransferPlayer(props.player.id, teamName, teamId) }}>
                    {props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.transferLabel')}
                </PrimaryButtonComponent>
            </div>   
        </Modal2Component>
    )
}

export default withTranslation(['league', 'tournament'])(TournamentTransferPlayerModalComponent);
