// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './BaloaProPaymentMethodsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


// Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function BaloaProPaymentMethodsModalComponent ( props ){
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');
    const [changePrincipal, setChangePrincipal] = React.useState(false);
    
    React.useEffect(() => {
        if(selectedPaymentMethod !== props.default_payment_method){
            setSelectedPaymentMethod(props.default_payment_method);
        }
    },[props.default_payment_method])

    const handleOnPaymentMethod = (event) => {
        setSelectedPaymentMethod(event.target.value);
        if(event.target.value !== props.default_payment_method ){
            setChangePrincipal(true);
        }else{
            setChangePrincipal(false);
        }
    };
    return(
        <Modal2Component             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-payment-methods-modal'}
            title={props.t('paymentsMethodsModal.title')}
        >
            <div className='baloa-pro-payment-methods-modal__container'>
                <div className='baloa-pro-payment-methods-modal__content'>
                    <div className='baloa-pro-payment-methods-modal__label baloa-username'>{props.t('paymentsMethodsModal.subTitle')}</div>
                    <RadioGroup aria-labelledby="payment_method" name="payment_method" value={ selectedPaymentMethod } onClick={ handleOnPaymentMethod } className="" >
                        {props.payment_methods?.map( method => 
                            <div className='baloa-pro-payment-methods-modal__payment-method' key={method.id}>
                                <div>                        
                                    <Radio
                                        id={method.id}
                                        name={method.id}
                                        disabled={false}
                                        className="baloa-pro-payment-methods-modal__radio"
                                        //checked={ method.principal }
                                        value={method.id}
                                        //onChange={ (e) => {handleOnChangePaymentMethod(method.id)} }                                                    
                                    />
                                </div>
                                <div>
                                    <div className='baloa-pro-payment-methods-modal__payment-method-name baloa-table'>{method.card.brand}:&nbsp;{`**** ${method.card.last4}`}</div>
                                    {method.id === props.default_payment_method &&
                                        <div className='baloa-pro-payment-methods-modal__method-principal baloa-username'>{props.t('paymentsMethodsModal.principal')}</div>
                                    } 
                                </div>
                                                    
                            </div>
                        )}
                    </RadioGroup>
                    <div className='baloa-pro-payment-methods-modal__buttons'>
                        <SecondaryButtonComponent
                            input_type={'button'}
                            className={'small'}
                            onClick={ ()=>{props.handleOnAddPaymentMethod()} }
                        >
                            <span className='hubicon-add_option icon'/>                
                            <span>{props.t('paymentsMethodsModal.addPaymentBtnLbl')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div>
                {changePrincipal &&
                    <div className='baloa-pro-payment-methods-modal__save-button'>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            className={'small'}
                            onClick={ ()=>{props.handleOnChangePrincipalMethod(selectedPaymentMethod)} }
                        >     
                            <span>{props.t('paymentsMethodsModal.saveBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                }
            </div>
        </Modal2Component>
    )

}

export default withTranslation('baloapro')(BaloaProPaymentMethodsModalComponent);
