/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayVideoMatchesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import BaloaPlayMatchCardComponent from '../baloa-play-match-card-component/BaloaPlayMatchCardComponent';

//Components

function BaloaPlayVideoMatchesComponent( props ) {

    const setActiveMatch = ( match ) => {
        if (props.next_url && (match.id === props.match_videos[props.match_videos.length - 1]?.id)) {
            props.handleOnLoadMore(props.next_url);
        }
    }

    return(
        <div className='baloa-play-video-matches__container'>
            {props.match_videos?.map((match, index) => (                
                <BaloaPlayMatchCardComponent
                    key={index}
                    match={match}
                    is_live_video={props.is_live_video}
                    onEnterViewport={() => {
                        setActiveMatch(match);
                    }}
                    onExitViewport={() => { }}
                    handleOnMatch={props.handleOnMatch}
                />                
            ))}
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlayVideoMatchesComponent);