// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hub-tournament-view-more-teams-modal .modal2__content {
    padding: 24px 36px;
}

.hub-tournament-view-more-teams-modal .baloa-subtitle-2 {
    color: var(--primary-very-dark-color);
    text-align: right;
}

.hub-tournament-view-more-teams-modal__skeleton-row {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-right: 24px;
}

.hub-tournament-view-more-teams-modal__skeleton-group {
    margin-left: 16px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .hub-tournament-view-more-teams-modal .modal2__container {
        margin: 0;
        height: 100%;
        max-height: unset;
        border-radius: 0;
    }

    .hub-tournament-view-more-teams-modal .modal2__header {
        padding-bottom: 10px;
    }

    .hub-tournament-view-more-teams-modal .modal2__content {
        padding: 12px;
        height: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/hub-tournament-view-more-teams-modal/HubTournamentViewMoreTeamsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,SAAS;QACT,YAAY;QACZ,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,oBAAoB;IACxB;;IAEA;QACI,aAAa;QACb,YAAY;IAChB;AACJ","sourcesContent":[".hub-tournament-view-more-teams-modal .modal2__content {\n    padding: 24px 36px;\n}\n\n.hub-tournament-view-more-teams-modal .baloa-subtitle-2 {\n    color: var(--primary-very-dark-color);\n    text-align: right;\n}\n\n.hub-tournament-view-more-teams-modal__skeleton-row {\n    display: flex;\n    align-items: center;\n    margin-top: 16px;\n    margin-right: 24px;\n}\n\n.hub-tournament-view-more-teams-modal__skeleton-group {\n    margin-left: 16px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .hub-tournament-view-more-teams-modal .modal2__container {\n        margin: 0;\n        height: 100%;\n        max-height: unset;\n        border-radius: 0;\n    }\n\n    .hub-tournament-view-more-teams-modal .modal2__header {\n        padding-bottom: 10px;\n    }\n\n    .hub-tournament-view-more-teams-modal .modal2__content {\n        padding: 12px;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
