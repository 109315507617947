/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './HomeMenuProfileWidget.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function HomeMenuProfileWidget( props ) {

    return(
        <div className='home-menu-profile-widget__container'>
            <div className='home-menu-profile-widget__image'>
                {props.profile_photo?
                    <img src={props.profile_photo} alt={props.profile_name}/>
                :
                    <span className='icon-ball' color={getRandonIconBallColor()} />
                }
            </div>
            <div className='baloa-normal-medium'>{props.profile_name}</div>
            {props.is_baloa_pro &&
                <span className='hubicon-award_star'/>
            }
        </div>
    )
}

export default withTranslation('baloapro')(HomeMenuProfileWidget);