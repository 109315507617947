import React from 'react';
//Component
import { Link } from 'react-router-dom';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import BaloaProSubscriptionWidgetComponent from 'shared/components/baloa-pro-subscription-widget/BaloaProSubscriptionWidgetComponent';

//Styles
import './HomeShortCutsComponent.styles.css';
// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HomeShortCutsComponent(props) {

  const handleOpenBaloaLanding = () => {
    const urlToOpen = 'https://info.baloa.com/';
    window.open(urlToOpen, '_blank');
  }

  return (
    <div className="homepage-shortcut-list__container">
      <div className="homepage-shortcut-list__list">
        <div className="homepage-shortcut-list__section">
          <span className="hubicon-pages">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </span>
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleGoLink('pages')}>
            {props.t('quickAccess.pageAccess')}
          </p>
        </div>
        {/* <div className="homepage-shortcut-list__section">
          <span className="hubicon-groups">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleGoLink('groups')}>
            {props.t('quickAccess.groupAccess')}
          </p>
        </div> */}
        <div className="homepage-shortcut-list__section">
          <span className="hubicon-tournaments">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
          {/* Se comenta codigo para que redirija a hub torneos */}
          {/* { !props.is_home_anon_shortcuts
            ?
              <Link to={'/tournament/hub'} className={"header-auth__actions__home "} onClick={()=>{}}>
                <p className="homepage-shortcut_item baloa-names">
                  {props.t('quickAccess.tournamentAccess')}
                </p>
              </Link>
            :
              <p className="homepage-shortcut_item baloa-names" onClick={() => {props.handleAnonShortCuts()}}>
                {props.t('quickAccess.tournamentAccess')}
              </p>
          } */}
          <Link to={'/tournament/hub'} className={"header-auth__actions__home "} onClick={()=>{}}>
            <p className="homepage-shortcut_item baloa-names">
              {props.t('quickAccess.tournamentAccess')}
            </p>
          </Link>
        </div>
        <div className="homepage-shortcut-list__section">
          <span className="hubicon-create_team">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleCreateTeam()}>
            {props.t('quickAccess.teamCreateAccess')}
          </p>
        </div>
        <div className="homepage-shortcut-list__section">
          <span className="hubicon-join_team">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleJoinToTeam()}>
            {props.t('quickAccess.joinToTeam')}
          </p>
        </div>
        {/* Players */}
        <div className="homepage-shortcut-list__section">
          <span className="icon-player"/>
            
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleGoLink('players')}>
            {props.t('quickAccess.players')}
          </p>
        </div>
        {/* BaloaPlay */}
        <div className="homepage-shortcut-list__section">
          <span className="hubicon-live_tv1"/>
            
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleGoLink('BaloaPlay')}>
            {props.t('quickAccess.baloaPlay')}
          </p>
        </div>
        {/* BaloaPro */}
        {props.is_baloa_pro &&
          <div className="homepage-shortcut-list__section">
            <span className="hubicon-award_star"/>
              
            <p className="homepage-shortcut_item baloa-names"
              onClick={() => props.handleGoLink('BaloaPro')}>
              {props.t('quickAccess.baloaPro')}
            </p>
          </div>
        }
        
        {/** Subscription Widget */}
        {/* isMobile && !props.is_baloa_pro && props.profile_type === 'player' &&
          <BaloaProSubscriptionWidgetComponent
              handleOnBaloaProSubscription={()=>{ props.handleOnBaloaProSubscription() }}
              is_new_baloa_pro_user={props.is_new_baloa_pro_user}
          /> */
        }
        { isMobile &&
          <SecondaryButtonComponent 
            type="button" 
            onClick={ () => handleOpenBaloaLanding() }
            className="home-shortcuts__baloa-landing"
          >
            {props.t('quickAccess.landingBaloa')}<span className="hubicon-open_in_new" /> 
          </SecondaryButtonComponent>
        }
        {/* Professionals */}
        {/* <div className="homepage-shortcut-list__section"> //TODO: a la espera de la funcionalidad
          <span className="icon-coach"/>
            
          <p className="homepage-shortcut_item baloa-names"
            onClick={() => props.handleGoLink('professionals')}>
            {props.t('quickAccess.professionals')}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default withTranslation('home')(HomeShortCutsComponent);