/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayUpdateClipModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayUpdateClipModalComponent( props ) {

    const [title, setTitle] = React.useState('');
    const formInitialValues = {
        title: props.clip_title
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('editClipModal.title')}
            className="baloa-play-update-clip-modal"
        >
            <div className={`baloa-play-update-clip-modal__container `}>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { }}
                        className = "baloa-play-update-clip-modal__form"                        
                    >
                        <InputFieldComponent
                            label={props.t('editClipModal.titleLbl')}
                            input_id=""
                            field_name="title"
                            className={'baloa-play-update-clip-modal__title'}
                            validationFunction={()=>{}}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={{'title':''}}
                            message=""
                            onChange={(v) => { setTitle(v)}}
                            defaultValue={props.clip_title? props.clip_title :''}
                        />                        
                    </FormComponent>
                    <div className='baloa-play-update-clip-modal__buttons'>
                        <TextButtonComponent
                            input_type={'button'}
                            onClick={()=>{ props.onClose()}}
                            className="small"
                        >
                            <span>{props.t('editClipModal.cancelBtnLbl')}</span>
                        </TextButtonComponent>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{ props.handleOnSave(title)}}
                            className="small"
                        >
                            <span>{props.t('editClipModal.saveBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>  
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayUpdateClipModalComponent);