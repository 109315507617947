// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-video-matches__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 24px 64px 0 64px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 

    .baloa-play-video-matches__container {
        grid-template-columns: 1fr;
        margin: 0;
        margin-top: 8px;
        grid-gap: 8px;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-video-matches-component/BaloaPlayVideoMatchesComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,cAAc;IACd,wBAAwB;AAC5B;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,0BAA0B;QAC1B,SAAS;QACT,eAAe;QACf,aAAa;IACjB;;AAEJ","sourcesContent":[".baloa-play-video-matches__container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 20px;\n    margin: 24px 64px 0 64px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n\n    .baloa-play-video-matches__container {\n        grid-template-columns: 1fr;\n        margin: 0;\n        margin-top: 8px;\n        grid-gap: 8px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
