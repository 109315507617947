
export const changeBaloaProStripePaymentModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_STRIPE_PAYMENT_MODAL_STATE", new_state }
}

export const loadStripePricesAction = ( data ) => {
    return { type: "BALOAPRO:LOAD_STRIPE_PRICES", data}
}

export const createStripePaymentIntentAction = ( data ) => {
    return { type: "BALOAPRO:CREATE_STRIPE_PAYMENT_INTENT", data}
}

export const searchCountriesAction  = ( data ) => {
    return { type: "BALOAPRO:SET_COUNTRIES_SEARCH", data}
}

export const createStripeSubscriptionAction = ( data ) => {
    return { type: "BALOAPRO:CREATE_STRIPE_SUBSCRIPTION", data}
}

export const changeBaloaProStripeResumeModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_STRIPE_RESUME_MODAL_STATE", new_state }
}

export const resetStripeSubscriptionAction = ( data ) => {
    return { type: "BALOAPRO:RESET_STRIPE_SUBSCRIPTION", data}
}

export const changeBaloaProPaymentMethodsModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_PAYMENT_METHODS_MODAL_STATE", new_state }
}

export const changeBaloaProCancelSubscriptionModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_CANCEL_SUBSCRIPTION_MODAL_STATE", new_state }
}

export const changeBaloaProAddPaymentMethodModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_ADD_PAYMENT_METHOD_MODAL_STATE", new_state }
}

export const changeBaloaProUnauthSubscriptionModalStateAction = ( new_state ) => {
    return { type: "BALOAPRO:CHANGE_UNAUTH_SUBSCRIPTION_MODAL_STATE", new_state }
}

export const loadingUnauthStripePricesAction= ( new_state ) => {
    return { type: "BALOAPRO:LOADING_UNAUTH_STRIPE_PRICES", new_state }
}

export const loadStripeUserAccountAction = ( data ) => {
    return { type: "BALOAPRO:LOAD_STRIPE_USER_ACCOUNT", data}
}

export const createStripeSetupIntentAction = ( data ) => {
    return { type: "BALOAPRO:CREATE_STRIPE_SETUP_INTENT", data}
}