/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayClaimClipsOptionsModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayClaimClipsOptionsModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className="baloa-play-claim-clips-options-modal"
        >
            <div className={`baloa-play-claim-clips-options-modal__container `}>
                <span className='hubicon-info' />
                <div className='baloa-normal-medium'>{props.t('claimModalTitle')}</div>
                <div className='baloa-names'>{props.t('claimModalMessage')}</div>               
            </div>
            <div className='baloa-play-claim-clips-options-modal__buttons'>
                <TextButtonComponent
                    input_type={'button'}
                    onClick={()=>{props.onClose()}}
                    disabled={ props.claim_clips_loading }
                >
                    <span>{props.t('claimModalCancelBtnLbl')}</span>
                </TextButtonComponent>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={()=>{props.handleOnClaim()}}
                    disabled={ props.claim_clips_loading }
                >
                    {props.claim_clips_loading?
                        <div className='baloa-play-claim-clips-options-modal__loader'/>
                        :
                        <span>{props.t('clipsPaymentBtnLbl')}</span>
                    }
                    
                </PrimaryButtonComponent>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayClaimClipsOptionsModalComponent);