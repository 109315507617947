import React from 'react';
import CurrencyFormat from 'react-currency-format';

// Styles
import './TournamentInformationComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentInformationComponent( props ) {

    return (
        <div className='tournament-information__container'>
            <div className='tournament-information-title baloa-names'>{props.tournament_information_title}</div>
            <div className='tournament-information'>
                { ( props.tournament?.registration_price_per_team !== null  ) && <div className='tournament-information__detail'><span className={"hubicon-inscription"} ></span>{props.t('viewTournament.details.valInsTeam')}&nbsp;<b>
                    <CurrencyFormat
                        value={props.tournament?.registration_price_per_team!== ''? props.tournament?.registration_price_per_team: ''} 
                        displayType={'text'} 
                        thousandSeparator={','} 
                        decimalSeparator={"."} 
                        prefix={'$ '} 
                        suffix={' ' + props.tournament?.currency_payment}
                        onValueChange ={ props.tournament?.registration_price_per_team}
                        renderText={value => <div>{value}</div>}
                    />
                    {/* {props.tournament?.registration_price_per_team}</b></div> } */}
                    </b></div> }
                    
                { ( props.tournament?.registration_price_per_player !== null  ) && <div className='tournament-information__detail'><span className={"hubicon-inscription"} ></span>{props.t('viewTournament.details.valInsPlayer')}&nbsp;<b>
                    <CurrencyFormat
                        value={props.tournament?.registration_price_per_player!== ''? props.tournament?.registration_price_per_player: ''} 
                        displayType={'text'} 
                        thousandSeparator={','} 
                        decimalSeparator={"."} 
                        prefix={'$ '} 
                        suffix={' ' + props.tournament?.currency_payment}
                        onValueChange ={ props.tournament?.registration_price_per_player}
                        renderText={value => <div>{value}</div>}
                    />
                    {/* {props.tournament?.registration_price_per_player} */}
                    </b></div> }
                { props.tournament?.count_teams !== 0 && <div className='tournament-information__detail'><span className={"hubicon-participants"} ></span>{props.t('viewTournament.details.insTeams')}&nbsp;<b>{props.tournament?.count_teams}</b></div> }
                { ( props.tournament?.main_city || props.tournament?.main_country ) && <div className='tournament-information__detail'><span className={"hubicon-location"} ></span><b>{props.tournament?.main_city} - {props.tournament?.main_country}</b></div> }
                { props.tournament?.registration_deadline && <div className='tournament-information__detail'><span className={"hubicon-date_inscription"} ></span>{props.t('viewTournament.details.insLimitdate')}&nbsp;<b>{props.tournament?.registration_deadline}</b></div> }
                { props.tournament?.start_date && <div className='tournament-information__detail'><span className={"hubicon-start_date"} ></span>{props.t('viewTournament.details.startTournamentDate')}&nbsp;<b>{props.tournament?.start_date}</b></div> }
                { props.tournament?.end_date && <div className='tournament-information__detail'><span className={"hubicon-finish_date"} ></span>{props.t('viewTournament.details.endTournamentDate')}&nbsp;<b>{props.tournament?.end_date}</b></div> }
                { (props.tournament?.description) && <div className='tournament-information__detail__subTitle baloa-table-column'>{props.t('viewTournament.details.descriptionTitle')}</div> }
                { props.tournament?.description && <div className='tournament-information__detail__text baloa-username'>{props.tournament?.description}</div> }
                { props.tournament?.rules && <div className='tournament-information__detail__subTitle baloa-table-column'>{props.t('viewTournament.details.rulesTitle')}</div> }
                { props.tournament?.rules && <div className='tournament-information__detail__text baloa-username'>{props.tournament?.rules}</div> }
                { props.tournament?.material_prize && <div className='tournament-information__detail__subTitle baloa-table-column'>{props.t('viewTournament.details.prizeTitle')}</div> }
                { props.tournament?.material_prize && <div className='tournament-information__detail__text baloa-username'>{props.tournament?.material_prize? props.tournament?.material_prize : props.tournament?.monetary_prize? props.tournament?.monetary_prize : ''}</div>                     }
            </div>                         
        </div>
    )
}
                
export default withTranslation("tournament")(TournamentInformationComponent);