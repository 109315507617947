/*eslint eqeqeq: "off"*/
// React
import React, { useEffect } from 'react';
import ReactHlsPlayer from 'react-hls-player';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayFullVideoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayFullVideoModalComponent( props ) {

    const videoRef = React.useRef();
    const [disablePrev, setDisablePrev] = React.useState(true);
    const [disableNext, setDisableNext] = React.useState(false);
    const [createClipEnabled, setCreateClipEnabled] = React.useState(false);
    
    const [startClipTime, setStartClipTime] = React.useState(null);
    const [endClipTime, setEndClipTime] = React.useState(null);
    const [startRecording, setStartRecording] = React.useState(false);
    const [time, setTime] = React.useState(0);

    const resetModalState = () => {
        setStartClipTime(null);
        setEndClipTime(null);
        setTime(0);
        props.resetClipClean();
    }

    const handleOnCloseCreateClip = () => {
        setStartRecording(false);
        videoRef.current.pause();
        videoRef.current.controls = true;

        setCreateClipEnabled(false);
        setStartClipTime(null);
        setEndClipTime(null);
        setTime(0);
        props.resetClipReset();
    }

    //Se emplea para contabilizar el tiempo de grabacion
    React.useEffect(() => {
        let intervalId;
        if (startRecording) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setTime(time + 1), 10);
            //Finaliza el clip cuando completa 1 minuto
            if(time === 6000){
                endCreateClip();
            }
        }
        return () => clearInterval(intervalId);
    }, [startRecording, time]);

    // Minutes calculation
    //const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Milliseconds calculation
    const milliseconds = time % 100;

    const [isPlaying, setIsPlaying] = React.useState(false); // State to track if video has started playing

    const handleVideoEnd = () => {
        setIsPlaying(false);
        if(isPlaying){
            //Detiene la grabacion del clip cuando se acaba el video
            endCreateClip();
        }
    };

    const handleVideoStart = () => {
        setIsPlaying(true);
    };

    const handleVideoPause = () => {
        setIsPlaying(false);
    };
    
    React.useEffect(() => {
        if(props.assets_number === 1){
            setDisablePrev(true);
            setDisableNext(true);
        }
    },[props.click_type]); // eslint-disable-line

    const handleBackClip = ( ev ) => {
        ev.preventDefault( ev );
        if(props.selected_video_index > 0){
            let new_index = props.selected_video_index -1;
            props.handleOnPlayVideo(new_index);
            if(new_index === 0){
                setDisablePrev(true);
            }else{
                setDisablePrev(false);
            }
            if(new_index < (props.assets_number -1)){
                setDisableNext(false);
            }
        }
    }
    
    const handleNextClip = ( ev ) => {
        ev.preventDefault();
        if(props.selected_video_index >= 0){
            let new_index = props.selected_video_index + 1;
            if(new_index < props.assets_number){
                props.handleOnPlayVideo(new_index);
            }            
            if(new_index === (props.assets_number -1)){
                setDisableNext(true);
            }else{
                setDisableNext(false);
            }
            if(new_index > 0){
                setDisablePrev(false);
            }
        }
    }

    const handleVideoWidth = ( vw, vh) => {
        if(vw >= vh){
            return '100%';
        }else{
            if(isMobile){
                return '100%';
            }else{
                return 'auto';
            }
        }
    }

    const handleVideoHeight= (vw, vh) => {
        if(vh >= vw){
            return '100%';
        }else{
            return '100%'
        }
    }

    const startCreateClip = (  ) => {
        setStartRecording(true);
        videoRef.current.controls = false;
        videoRef.current.play();
        setStartClipTime(videoRef.current.currentTime * 1000);
    }

    const endCreateClip = () => {
        setStartRecording(false);
        setEndClipTime(videoRef.current.currentTime * 1000);
        props.handleOnStopClipRecording(props.match_video?.id, startClipTime, videoRef.current.currentTime * 1000);
        videoRef.current.pause();
        videoRef.current.controls = true;
        
    }

    const videoPlayer = React.useMemo(() => (
        <ReactHlsPlayer
            className=''
            playerRef={videoRef}
            src={props.match_video?.post_stream?.hls}
            preload="auto"
            autoPlay={false}
            playsInline
            onPlaying={handleVideoStart}//Evento cuando se ejecuta el video
            onEnded={handleVideoEnd}//Evento cuando se finaliza el video
            onPause={handleVideoPause}//evento cuando se pausa el video
            width={handleVideoWidth( props.match_video?.post_stream?.width, props.match_video?.post_stream?.height )}
            height={handleVideoHeight(  props.match_video?.post_stream?.width, props.match_video?.post_stream?.height )}                                
            loop={false}
            poster={props.match_video?.post_stream?.thumbnail ? props.match_video?.post_stream?.thumbnail : ""}
            controls={true}
            hlsConfig={{
                startLevel: 4, // El índice de la calidad deseada
                //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
            }}
        />
    ), [props.match_video?.post_stream?.hls]);

    useEffect(() => {
        if (props.create_clip_clean) {
            resetModalState();
        }
    }, [props.create_clip_clean]);

    useEffect(() => {
        if (props.create_clip_reset) {
            handleOnCloseCreateClip();
        }
    }, [props.create_clip_reset]);

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={createClipEnabled? handleOnCloseCreateClip : props.onClose}
            title={createClipEnabled? props.t('createClipBtnLbl') : props.match_video?.post_video_caption}
            className="baloa-play-full-video-modal"
        >
            <div className={`baloa-play-full-video-modal__container `}>
                {!createClipEnabled &&
                    <div className='baloa-play-full-video-modal__create-clip-button'>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={props.is_maximum_claimed_clips? ()=>{ props.handleOnMaxClips() } :()=>{setCreateClipEnabled(true)}}
                        >
                            <span className='hubicon-video_library-1 icon' />
                            <span>{props.t('createClipBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                }
                {!createClipEnabled &&
                    <div className={`baloa-play-full-video-modal__slide-button left ${disablePrev? 'disabled':''}`} onClick={disablePrev? ()=>{} : (e)=>{ handleBackClip(e)}} >
                        <span class="hubicon-arrow_circle_left">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }                
                <div className={`baloa-play-full-video-modal__media-container ${createClipEnabled? 'create-clip' : ''}`}>

                    <div className={`baloa-play-full-video-modal__video-container`}>
                        {/* Video Player */}
                        {props.match_video?.post_stream?.hls &&
                            /* <ReactHlsPlayer
                                className=''
                                //playerRef={videoRef}
                                src={props.match_video?.post_stream?.hls}
                                preload="auto"
                                autoPlay={false}
                                playsInline
                                onPlaying={ ()=>{console.log('arranco video')} }
                                onPlay={()=>{console.log('hizo clic en el boton play video') }}
                                onEnded={()=>{console.log('finalizo video')}}
                                width={handleVideoWidth( props.match_video?.post_stream?.width, props.match_video?.post_stream?.height )}
                                height={handleVideoHeight(  props.match_video?.post_stream?.width, props.match_video?.post_stream?.height )}                                
                                loop={false}
                                poster={props.match_video?.post_stream?.thumbnail ? props.match_video?.post_stream?.thumbnail : ""}
                                controls={startRecording? false : true}
                                hlsConfig={{
                                    startLevel: 4, // El índice de la calidad deseada
                                    //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                                    capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                                    maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                                    maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                                    maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                                    maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                                    lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                                    highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                                    nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                                    nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
                                }}
                            /> */
                            videoPlayer
                        }

                    </div>

                </div>
                {!createClipEnabled &&
                    <div className={`baloa-play-full-video-modal__slide-button right ${disableNext? 'disabled':''}`} onClick={disableNext? ()=>{} : (e)=>{ handleNextClip(e)}} >
                        <span class="hubicon-arrow_circle_right">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }
                {createClipEnabled &&
                    <div className='baloa-play-full-video-modal__create-clip-widget__container'>
                        <div className='baloa-normal-medium'>{props.t('createClipBtnLbl')}</div>
                        <div className='baloa-play-full-video-modal__create-clip-widget__step baloa-names'>{props.t('createClipWidget.createClipStep1')}</div>
                        <div className='baloa-play-full-video-modal__create-clip-widget__step baloa-names'>{props.t('createClipWidget.createClipStep2')}</div>
                        <div className='baloa-play-full-video-modal__create-clip-widget__step baloa-names'>{props.t('createClipWidget.createClipStep3')}</div>
                        {startRecording &&
                            <div className='baloa-play-full-video-modal__create-clip-widget__counter'>
                                <div className='baloa-table'>{props.t('createClipWidget.timerLbl')}&nbsp;</div>
                                <div className='baloa-table seconds'>{props.t('createClipWidget.timerUnits',{_time: `${seconds}.${milliseconds}`})}</div>                            
                            </div>
                        }
                        <div className='baloa-play-full-video-modal__create-clip-widget__button'>
                            {startRecording?
                                <PrimaryButtonComponent
                                    input_type={'button'}
                                    onClick={()=>{ endCreateClip() }}
                                    className={'button-red'}
                                >
                                    <span className='hubicon-stop_circle icon' />
                                    <span>{props.t('createClipWidget.stopRecordingBtnLbl')}</span>
                                </PrimaryButtonComponent>
                                :
                                <PrimaryButtonComponent
                                    input_type={'button'}
                                    onClick={()=>{ startCreateClip() }}
                                >
                                    <span className='hubicon-play_arrow icon' />
                                    <span>{props.t('createClipWidget.startRecordingBtnLbl')}</span>
                                </PrimaryButtonComponent>
                            }
                            {/* <div>{time}</div>
                            <div>{startClipTime}</div>
                            <div>{endClipTime}</div>
                            {isPlaying ? (
                                <p>The video has started playing!</p>
                            ) : (
                                <p>Video is not playing yet...</p>
                            )} */}
                        </div>
                    </div>
                }
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayFullVideoModalComponent);