// React
import React from 'react';

import TournamentPhaseCreatedComponent from 'app/leagues/components/tournament-phase-created/TournamentPhaseCreatedComponent';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

// Styles
import './TournamentPhasesCreatedComponent.styles.css';

//i18
import { useTranslation } from 'react-i18next';


function TournamentPhasesCreatedComponent ( props ){

   
    const { t } = useTranslation('league');

    
    /* eslint-disable */
    React.useEffect( () => {

        if(props.tournament_phases?.length > 0){
            
            let aux_phases = props.tournament_phases?.filter(phase => phase.state === "Active");

            if(aux_phases.length === 0){
                onClickPhaseTab(props.tournament_phases[props.tournament_phases.length - 1]);
            }else{
                onClickPhaseTab(aux_phases[0]);
            }
        }
        

    }, [ props.tournament_phases ] )
    /* eslint-enable */

    function onClickPhaseTab( phase ){
        
        props.handleOnClickPhaseTab(phase , 'tabPositionTable');

        if(phase.state !== 'Finalized') {
            props.finishedMatchesByPhase(props.tournament_phases[props.tournament_phases.length - 1])            
        }

    }
    
    return(
        <div className="phases-created__phases">
            {props.is_loaded_tournament_phases &&
                <div className="baloa-subtitle-2">{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhases')}</div>
            }
            <div className="phases-created__phases__content">
                
                    {/* { props.tournament_phases.map( (phase,index) =>  */}
                    { props.tournament_phases?.filter(phase => phase.phase_type !== null).map( (phase, index) =>

                        <div key={phase.id} className="phases-created__phases__content__add">
                            <TournamentPhaseCreatedComponent
                                phase={phase}
                                index={index}
                                selectedPhase={ props.tournament_active_phase }
                                edit_tournament={props.edit_tournament}
                                // showAddButton = { 
                                //     props.edit_tournament && index === props.tournament_phases.length - 1 
                                //     ?
                                //         phase.state === "Finalized" && props.selected_tournament.state !== "Finalized"
                                //     : 
                                //         phase.phase_type != null && index === props.tournament_phases.length - 1 
                                // }
                                showAddButton = { 
                                    props.edit_tournament && index === props.tournament_phases.length - 1 && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ?
                                        true
                                    : 
                                        false
                                }
                                showOptionMenu = { 
                                    props.edit_tournament && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ?
                                        phase.state === "Active"
                                    :
                                        phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    
                                }
                                showDeletePhaseMenu = { 
                                    props.edit_tournament && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ?
                                        phase.state === "Active" && index >= 1
                                    :
                                        phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController( props.pageRoles, ['TOURNAMENTAD'])

                                }
                                showEndPhaseMenu = {
                                    index === 0
                                    ?
                                        props.tournament_phases.length === 1 && phase.state === "Active" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                        ? 
                                            true
                                        : 
                                            phase.state === "Active" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])   
                                    :
                                        phase.state === "Active" && props.tournament_phases[index - 1].state === "Finalized" && phase.phase_type && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                }
                                showAddTeams = { 
                                    /* phase.groups[0]?.teams.length === 0 &&  */phase.group_0_teams_count === 0 && props.tournament_phases[index - 1]?.state === "Finalized" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])//Change new api phases
                                    ? 
                                        true
                                    : 
                                        false
                                }
                                onClickPhaseTab = { ( phase ) => {onClickPhaseTab( phase )} }
                                tournament = { props.tournament }                                
                                deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}                                
                                handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}   
                                onClickNewPhase = { props.onClickNewPhase } 
                                AddTeamToGroupModal = {() => props.AddTeamToGroupModal()} 
                                tournament_dates = {props.tournament_dates}
                                unfinished_matches = { props.unfinished_matches }  
                                handleTournamentFinalizedSuggestion={ props.handleTournamentFinalizedSuggestion }
                                is_tournament_finalized = { props.is_tournament_finalized }
                            />
                        </div>

                    )}

            </div>

        </div>
    )
}
 
export default TournamentPhasesCreatedComponent;