// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-menu-profile-widget__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--white-color);
    padding: 10px;
}

.home-menu-profile-widget__image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}

.home-menu-profile-widget__image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.home-menu-profile-widget__image .icon-ball {
    color: var( --white-color );
    font-size: var( --space-x2 );
    border-radius: 50%;
    padding: var( --space-quarter );  
}

.home-menu-profile-widget__container .baloa-normal-medium {
    color: var(--primary-very-dark-color);
}

.home-menu-profile-widget__container .hubicon-award_star {
    font-size: 18px;
    margin-left: 6px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .home-menu-profile-widget__image {
        width: 28px;
        height: 28px;
        margin-right: 8px;
    }

    .home-menu-profile-widget__image img{
        width: 28px;
        height: 28px;
    }

    .home-menu-profile-widget__image .icon-ball {
        font-size: 20px;
        padding: 4px;  
    }

    .home-menu-profile-widget__container {
        padding: 10px 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-menu-profile-widget/HomeMenuProfileWidget.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,YAAY;IAChB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".home-menu-profile-widget__container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    background: var(--white-color);\n    padding: 10px;\n}\n\n.home-menu-profile-widget__image {\n    width: 40px;\n    height: 40px;\n    margin-right: 10px;\n    border-radius: 50%;\n}\n\n.home-menu-profile-widget__image img {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n}\n\n.home-menu-profile-widget__image .icon-ball {\n    color: var( --white-color );\n    font-size: var( --space-x2 );\n    border-radius: 50%;\n    padding: var( --space-quarter );  \n}\n\n.home-menu-profile-widget__container .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n}\n\n.home-menu-profile-widget__container .hubicon-award_star {\n    font-size: 18px;\n    margin-left: 6px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .home-menu-profile-widget__image {\n        width: 28px;\n        height: 28px;\n        margin-right: 8px;\n    }\n\n    .home-menu-profile-widget__image img{\n        width: 28px;\n        height: 28px;\n    }\n\n    .home-menu-profile-widget__image .icon-ball {\n        font-size: 20px;\n        padding: 4px;  \n    }\n\n    .home-menu-profile-widget__container {\n        padding: 10px 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
