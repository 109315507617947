// React
import React from 'react';

//Styles
import './BaloaPlayMaxClipsInfoModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayMaxClipsInfoModalComponent ( props ){

    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={}
            //title={}
            className="baloa-play-max-clip-info-modal"
        >
            <div className='baloa-play-max-clip-info-modal__container'>
                <span className='hubicon-info'/>
                <div className='baloa-subtitle-2'>{props.t('maxClipsInfoModal.title')}</div>
                <div className='baloa-names'>{props.t('maxClipsInfoModal.message')}</div>
                <div className='baloa-play-max-clip-info-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{ props.onClose() }}
                    >
                        <span>{props.t('createClipWelcomeModal.understandBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloaplay')(BaloaPlayMaxClipsInfoModalComponent);
