// React
import React from 'react';

// Material UI
import Box from '@mui/material/Box';

// Formik
import { useFormik } from 'formik';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from "shared/components/select-field/SelectFieldComponent";
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";

// Validators
import { AnyValidator } from "shared/validators/SignUpValidator";

//i18
import { useTranslation } from 'react-i18next';

// Styles
import './AddMemberWithAccountModalComponent.styles.css';

// Assets
import { getRandonIconBallColor } from "shared/functions/GetRandomIconColor";
import LeagueSearchMemberTeamComponent from 'app/leagues/components/league-search-member-team/LeagueSearchMemberTeamComponent';

function AddMemberWithAccountModalComponent( props ) {


    const formikvalues = useFormik({
        initialValues: {
            member: "",
            roles: "",
            number: "",
            positions: "",
        },
    });

    const { t } = useTranslation('league','team');
    const [searchedValue, setSearchedValue] = React.useState(null);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);

	const [rolSelected, setRolSelected] = React.useState();

    const [memberSelected, setMemberSelected] = React.useState(null);
    const handleSelectMemberTeam = (item) => {
        setMemberSelected(item)
    }

	const handleChangeRol = (idrol) => {
        if(idrol){
            let rol = props.roles.find((rol) => rol?.value == idrol);            
            setRolSelected(rol);
            setIsBtnDisabled(false); 
        }
	};

	const selectRolPlayer = (member_rol) => {
		props.onSelectRolPlayer(member_rol);
        handleClose();
	};

    const handleClose = () =>{
        setMemberSelected(null);
        setIsBtnDisabled(true);        
        setRolSelected(false);
        props.handleCleanMemberSearch();
        props.onClose();
    }

    const handleCleanMember = () => {
        setMemberSelected(null);
    }

    return (
        <Modal2Component 
        isOpen={props.isOpen} 
        onClose={() => handleClose()}
        title={props.title}
    >
        <div className="scroll-parent">
        <FormComponent 
            formInitialValues={ formikvalues.initialValues } 
            onSubmit={( values, actions ) => {
               
                let new_member_data = values['member'];
                props.onNewData( new_member_data );
                let member_rol = {
                    username: memberSelected?.username,
                    role: {
                        role: values.roles,
                        position:
                            values.positions == "" ? null : values.positions,
                        player_number:
                            values.number == "" ? null : values.number,
                    },
                };
                
                //Call Api
                selectRolPlayer(member_rol);
            }}

            onKeyDown={(event) => {                    
                if ((event.charCode || event.keyCode) === 13 ) {
                    if(searchedValue === null){
                        event.preventDefault();    
                    } else {
                        setSearchedValue(null)
                    }
                }
            }}
            
            className="add-member-form__form form scroll-parent"
            autocomplete="off"
        >
            <Box className="add-member-form__box">
                    <div className='add-member-form__box__container'>
                    <div className='add-member__grid__left-col'> 
                        <div className='add-member__grid__left-col__title'> {t("options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelsubtitle")} </div>
                    { memberSelected &&
                                    <div className="add-member__data">
                                        <div className='add-member__data__user'>
                                            <div className="add-member__data__user__photo">
                                                {
                                                memberSelected?.photo &&
                                                    <img className="add-member__data__user__photo__img" src={memberSelected.photo} alt="" />
                                                }
                                                {
                                                    !memberSelected?.photo &&
                                                    <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                                }                   
                                                        </div>
                                                    <div className="add-member__data__data">
                                                        <span className="add-member__data__data__name">
                                                                {memberSelected?.first_name
                                                                    ? memberSelected?.first_name + " "
                                                                    : " "}
                                                                {memberSelected?.last_name
                                                                    ? memberSelected?.last_name
                                                                    : ""}
                                                            </span>
                                                            <span className="add-member__data__data__username">
                                                                @
                                                                {memberSelected != null
                                                                    ? memberSelected?.username 
                                                                    : ""}
                                                            </span>
                                            </div>
                                        </div>
                                        <div className='add-member__data__delete' onClick={() => {handleCleanMember()}}>
                                            <span className='hubicon-clear_option'></span>
                                        </div>
                                    </div>
                                }

                       { memberSelected === null &&
                            <LeagueSearchMemberTeamComponent
                                className="add-players-to-team__search"
                                handleSearchMember={ (word)=>{props.handleSearchMember(word);} }
                                profiles={ props.profiles }
                                onSelectMemberTeam={ (item)=>{handleSelectMemberTeam(item)}}
                                handleCleanMemberSearch={ props.handleCleanMemberSearch }
                                set_type={"member"}
                                modal_search_placeholder={t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelSearch')}
                                id={'add-players-to-team__search'}
                                removeWordLenghtValidation={false}
                            />
                       }

                    </div>
                          <div className='add-member__grid__right-col'>
                            <div className="add-member__roles__data-select">
                                    <SelectFieldComponent
                                        label={""}
                                        className="add-member__roles"
                                        field_name="roles"
                                        validationFunction={AnyValidator}
                                        disabled={false}
                                        resetError={() => props.onResetError({ roles: "" })}
                                        error={props.errors}
                                        message=""
                                        placeholder=""
                                        options={props.roles}
                                        onChange={(value) => handleChangeRol(value)}
                                        defaultValue={ props.roles[0]}
                                    />
                                </div>
                          </div>
                    </div>
                    {rolSelected?.value === "player" && (
                        <div className='add-member-form__box__container__two'>                  
                            <div className="add-member-form__box__container__two__grid__left-col">
                                <div className="add-member__positions">
                                    <div className="add-member__positions__positions">
                                        <SelectFieldComponent
                                            label={t("options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelPosition")}
                                            field_name="positions"
                                            validationFunction={AnyValidator}
                                            disabled={false}
                                            resetError={() =>
                                                props.onResetError({ positions: "" })
                                            }
                                            error={props.errors}
                                            message=""
                                            placeholder=""
                                            options={props.positions}
                                            className="add-member__positions__text"
                                        />
                                    </div>
                                </div>                               
                            </div>
                            <div className='add-member-form__box__container__two__grid__right-col'> 
                                <div className="add-member__positions__number">
                                        <InputFieldComponent
                                            label={t("options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelNumberPosition")}
                                            field_name="number"
                                            //validationFunction={AnyValidator}
                                            disabled={false}
                                            input_type="number"
                                            resetError={() => {}}
                                            error={props.errors}
                                            message=""
                                            placeholder="#"
                                            className="add-member__positions__number__text"
                                        />
                                </div>
                            </div>                         
                        </div>
                    )}
            </Box>
            <div className="league-add-member__footer">
              <PrimaryButtonComponent 
                  type="submit"
                  disabled={isBtnDisabled}
                  onClick={()=>{}} 
                  className="league-add-member__actions__submit"
              >
                  <span>{t('options.teamsAndMembers.tabs.tabStaff.labelButtonAdd')}</span>
              </PrimaryButtonComponent>
            </div>
        </FormComponent>
        </div>
    </Modal2Component>
    )
}
export default AddMemberWithAccountModalComponent;