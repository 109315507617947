import React from 'react';

//Components
import FormComponent from 'shared/components/form/FormComponent';
import InputDynamicFormComponent from 'app/tournaments/components/dynamic-fields-tournament-form/input-dynamic-form/InputDynamicFormComponent';
import RadioButtonDynamicFormComponent from 'app/tournaments/components/dynamic-fields-tournament-form/radioButton-dynamic-form/RadioButtonDynamicFormComponent';
import FileTournamentFormComponent from 'app/tournaments/components/dynamic-fields-tournament-form/file-dynamic-form/FileTournamentFormComponent';
import CheckTournamentFormComponent from 'app/tournaments/components/dynamic-fields-tournament-form/check-tournament-form/CheckTournamentFormComponent';
import ListDynamicComponent from 'app/tournaments/components/dynamic-fields-tournament-form/list-tournament-form/ListDynamicComponent';
import ImageTournamentFormComponent from 'app/tournaments/components/dynamic-fields-tournament-form/image-dynamic-form/ImageTournamentFormComponent';

// Styles
import './TournamentDynamicFormsPreviewComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentDynamicFormsPreviewComponent(props) {

    const [state, setState] = React.useState( // eslint-disable-line
        {
            submit_button_ref: null,
            formInitialValues:{},
            errors:{},
            submitObj:{},
            files:[],
        }
    );

    React.useEffect(() => { 
        if(props.questions){
            let initalValues={};
            let initalErrors={};
            let initalObject={};
            let initialAnswers=[];
            props.questions.map((question,index)=>{ // eslint-disable-line       
                let defAnswer = getDefaultAnswer(question.answers, question.input_type);
                initalValues[question.id]= defAnswer;
                initalErrors[question.id]= '';
                let newanswer = setAnswerStructure(question);    
                initialAnswers.push(newanswer);
            })
            state.formInitialValues = initalValues;
            state.errors = initalErrors;
            initalObject['questions'] = initialAnswers;
            state.submitObj = initalObject;
        }
    }, [props.questions]);// eslint-disable-line 

    function setAnswerStructure(question){
        let answers = [];
        if(question.answers.length > 0){
            question.answers.map( answ => { // eslint-disable-line
                answers.push({id: answ.id, is_checked: false })
            })
        }
        return {
            question_type: question.input_type,
            form_question_template: question.id,
            text_answer: "",
            file_document: null,
            date_field: null,
            boolean_answer: null,
            answers: answers
        }
    }

    function getDefaultAnswer(answers, type){
        if(answers.length > 0){
            let checkedAnswers = answers.filter(ans => ans.is_default == true);
            if(checkedAnswers.length > 0){
                if(type=='single_choice'){
                    return checkedAnswers[0].id
                }
                if(type=='list'){
                    return checkedAnswers[0].id
                }
                if(type=='multi_choice'){
                    let multiAnswer =[];
                    checkedAnswers.map(answ =>
                        multiAnswer.push(answ.id)
                    )
                    return multiAnswer
                }
                return null;
            }else{
                return null
            }            
        }else{
            return null;
        }
    }

    return (
        <div className="compose-inscription-form">
            <div className="compose-inscription-form__container">
            <FormComponent
                formInitialValues={state.formInitialValues} 
                //onSubmit={(values) => { handleSubmitForm(values)}}
                className="compose-inscription-form__form-container"
                functionDisabled={()=>{}}
                enableReinitialize={false}
            >
                {//eslint-disable-next-line array-callback-return
                props.questions.map((question, index) => {
                    if( question.input_type ==="text" || 
                        question.input_type ==="date" || 
                        question.input_type ==="email" ||
                        question.input_type ==="phone" ) {
                        return (
                            <InputDynamicFormComponent
                                form_question={{question_type:{caption: question.input_type}, is_required: question.is_required, caption: question.caption}}
                                resetError={()=>{}}
                                errors={state.errors}
                                className={'tournament-dynamic-forms-preview'}
                                //label={props.label}
                                input_id={index}
                                field_name={index}
                                disabled={false}
                                //handleInputDynamicForm={props.handleInputDynamicForm}
                                handleInputDynamicForm={()=>{}}
                            />
                        );
                    }
                
                    if(question.input_type === "single_choice" || question.input_type === "boolean") {
                        return (
                            <RadioButtonDynamicFormComponent
                                form_question={question}
                                id={index}
                                name={index}
                            />
                        );
                    }
        
                    if(question.input_type ==="file") {
                        return (
                            <FileTournamentFormComponent
                                id={index}
                                caption={question.caption}
                                question_type={question.input_type}
                                multiple={false}
                                is_required={question.is_required}
                                //onFilesChosen={(value) => uploadFileInState(value, id)}
                                t={props.t}
                                disabled={true}
                                className="tournament-dynamic-forms-preview__container-file"
                            />
                        );
                        }

                    if(question.input_type ==="image") {
                        return (
                            <ImageTournamentFormComponent
                                id={index}
                                caption={question.caption}
                                question_type={question.input_type}
                                multiple={false}
                                is_required={question.is_required}
                                //onFilesChosen={(value) => uploadFileInState(value, id)}
                                t={props.t}
                                disabled={true}
                                className="tournament-dynamic-forms-preview__container-file"
                            />
                        );
                        }
        
                    if(question.input_type === "multi_choice"){
                        return (
                            <CheckTournamentFormComponent
                                id={question.id}
                                caption={question.caption}
                                question_type={{caption: question.input_type}}
                                is_required={question.is_required}
                                answers={question.answers}
                                onhandleCheked={()=>{}}
                                disabled={false}
                            />
                        );
                    }
        
                    if(question.input_type === "list") {
                        return(
                            <ListDynamicComponent
                                id={index}
                                caption={question.caption}
                                question_type={question.input_type}
                                is_required={question.is_required}
                                answers={question.answers}
                                t={props.t}
                            />
                        );
                    }                    
                })
                }
            </FormComponent>  
        </div>
    </div>
    );
}

export default withTranslation('tournament')(TournamentDynamicFormsPreviewComponent)