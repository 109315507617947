// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './BaloaProCancelSubscriptionModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';


function BaloaProCancelSubscriptionModalComponent ( props ){
    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-cancel-subscription-modal'}
        >
            <div className='baloa-pro-cancel-subscription-modal__container'>
                <div className='baloa-pro-cancel-subscription-modal__title baloa-normal-medium'>
                    {props.t('cancelSubscriptionModal.subTitle')}
                </div>
                <div className='baloa-pro-cancel-subscription-modal__message baloa-table' dangerouslySetInnerHTML={{
                    __html: props.t('cancelSubscriptionModal.message',{_date: moment(props.renewal_date).format('DD MMMM, YYYY')})
                }}>
                </div>
                <div className='baloa-pro-cancel-subscription-modal__buttons'>
                    <TextButtonComponent
                        input_type={'button'}
                        className={'baloa-pro-cancel-subscription-modal__cancel-button small'}
                        onClick={ ()=>{props.onClose()} }
                    >
                        <span>{props.t('cancelSubscriptionModal.closeBtnLbl')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={'small red'}
                        onClick={ ()=>{props.handleCancel()} }
                    >
                        <span>{props.t('cancelSubscriptionModal.cancelBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaProCancelSubscriptionModalComponent);
