// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './ModalDecisionDialogComponent.styles.css';

function ModalDecisionDialogComponent(props) {
    const {
        modalTitle,
        modalContent,
        modalType,
        secondaryButton,
        principalButtonActionLink
    } = props;

    return (
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className={'modal-decision-dialog'}
        >
            <p className="modal-decision-dialog__title baloa-headline-5">
                {modalTitle}
            </p>
            <div className="modal-decision-dialog__text baloa-body-2"
                dangerouslySetInnerHTML={{
                    __html: modalContent
                }}>
            </div>
            <div className="modal-decision-divider" />
            <div className="modal-decision-dialog__actions">
                {secondaryButton &&
                    <SecondaryButtonComponent
                        className="modal-decision-dialog__button secondary_button small"
                        onClick={props.onSecondaryButtonClick}>
                        <span>{props.secondaryButtonLabel}</span>
                    </SecondaryButtonComponent>
                }
                {modalType.includes('linkHub')
                    ?
                    <Link to={{
                        pathname: props.principalButtonActionLink,
                        state: { typeActionLink: modalType.split('-')[1]}
                    }}>
                        <PrimaryButtonComponent
                            className={`modal-decision-dialog__button ${modalType === 'waringHub' ? 'primary_button_waring' : ''} small`}
                            onClick={() => { console.log(principalButtonActionLink) }}>
                            <span>{props.principalButtonLabel}</span>
                        </PrimaryButtonComponent>
                    </Link>
                    : <PrimaryButtonComponent
                        className={`modal-decision-dialog__button ${modalType === 'waringHub' ? 'primary_button_waring' : ''} small`}
                        onClick={() => props.onPrincipalButtonClick(modalType)}>
                        <span>{props.principalButtonLabel}</span>
                    </PrimaryButtonComponent>
                }

            </div>
        </ModalComponent>
    )

}

export default (ModalDecisionDialogComponent);
