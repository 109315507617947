// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';
import InfiniteScroll from "react-infinite-scroll-component";

//Material UI
import { Skeleton } from '@mui/material';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HubTournamentViewMoreTeamsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HubTournamentViewMoreTeamsModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.tournament_teams_next){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.tournament_teams_next]); // eslint-disable-line

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose }
            title={ props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.labelAddedTeams') }
            className='hub-tournament-view-more-teams-modal' 
        >
            {props.tournament_teams.length === 0 && props.loading_tournament_teams && isMobile &&
                <React.Fragment>
                    {[...Array(Number(8))].map((e, i) => 
                    <div className='hub-tournament-view-more-teams-modal__skeleton-row' >
                        <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                        <div className='hub-tournament-view-more-teams-modal__skeleton-group'>
                            <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                        </div>
                    </div>
                    )}
                </React.Fragment>
            }
            {props.tournament_teams.length === 0 && props.loading_tournament_teams && !isMobile &&
                <React.Fragment>
                    {[...Array(Number(8))].map((e, i) => 
                    <div className='hub-tournament-view-more-teams-modal__skeleton-row' >
                        <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                        <div className='hub-tournament-view-more-teams-modal__skeleton-group'>
                            <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                        </div>
                    </div>
                    )}
                </React.Fragment>
            }
            {props.tournament_teams?.length > 0 &&
                <InfiniteScroll
                    dataLength={props.tournament_teams.length}
                    next={()=>{ props.loadMoreTeams(props.tournament_teams_next) }}
                    hasMore={loadMore}
                    loader={
                        isMobile?
                        <div className='hub-tournament-view-more-teams-modal__skeleton-row' >
                            <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                            <div className='hub-tournament-view-more-teams-modal__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                            </div>
                        </div>
                        :
                        <div className='hub-tournament-view-more-teams-modal__skeleton-row' >
                            <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                            <div className='hub-tournament-view-more-teams-modal__skeleton-group'>
                                <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                                <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                            </div>
                        </div>
                    }
                    height={isMobile? 'calc(100vh - 90px)':'600px'}
                    endMessage={''}
                >
                    {props.tournament_teams?.map( (team, index) => 
                        <div key={index} className='tournament-detail__tournament-team'  onClick={() => props.handleTeamMembersModal(team)}>
                            <div className='tournament-detail__tournament-team__image'>
                                {team.team_logo?
                                    <img src={team.team_logo} alt={team.team} />
                                :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                            </div>
                            <div className='tournament-detail__tournament-team__data'>
                                <div className='baloa-names'>{team.team}</div>
                                <div className='baloa-username'>
                                    {team.team_gender? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+team.team_gender):''}
                                    &nbsp;&bull;&nbsp;{team.team_category? props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.'+ team.team_category):''}</div>
                            </div>
                        </div>
                    )}
                </InfiniteScroll>
            }
        </Modal2Component>
    )
}

export default withTranslation(['tournament', 'league'])(HubTournamentViewMoreTeamsModalComponent);