// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';
import InfiniteScroll from "react-infinite-scroll-component";

//Material UI
import { Skeleton } from '@mui/material';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './TournamentViewMoreStatisticsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function TournamentViewMoreStatisticsModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.statistics_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.statistics_next_page]); // eslint-disable-line

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose }
            title={
                props.card_type === 'scorer'?
                    props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.scorersLabel')
                :
                    props.card_type === 'yellow-card'?
                        props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardYellow')
                    :
                        props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardRed')
            }
            className='tournament-view-more-statistics-modal' 
        >
            {props.card_type === 'scorer' &&
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals')}</div>
            }
            <InfiniteScroll
                dataLength={props.statistic_data.length}
                next={()=>{ props.loadMoreStatistics(props.statistics_next_page) }}
                hasMore={loadMore}
                loader={
                    isMobile?
                    <div className='tournament-statistics__scorers-row' >
                        <Skeleton animation="wave" variant="circular" component="div" width={30} height={30} style={{  }}/>
                        <div className='tournament-statistics__scorers-row__skeleton-group'>
                            <Skeleton animation="wave" variant="rounded" component="div" width={127} height={11} style={{  }}/>
                        </div>
                        <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={17} height={11} style={{  }}/>
                    </div>
                    :
                    <div className='tournament-statistics__scorers-row' >
                        <Skeleton className='tournament-statistics__scorers-row__number baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                        <Skeleton animation="wave" variant="circular" component="div" width={42} height={42} style={{  }}/>
                        <div className='tournament-statistics__scorers-row__skeleton-group'>
                            <Skeleton animation="wave" variant="rounded" component="div" width={208} height={12} style={{  }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={162} height={11} style={{ marginTop: 8 }}/>
                        </div>
                        <Skeleton className='tournament-statistics__scorers-row__goals baloa-normal-medium' animation="wave" variant="squared" component="div" width={24} height={24} style={{  }}/>
                    </div>
                }
                height={isMobile? 'calc(100vh - 90px)':'600px'}
                endMessage={''}
            >
                {props.statistic_data?.map( (data, index) => 
                    <div className='tournament-statistics__scorers-row' key={index}>
                        <div className='tournament-statistics__scorers-row__number baloa-normal-medium'>{index + 1}</div>
                        <div><Link to={`/profile/${data.name}/`}><TournamentPlayerInfoComponent full_name={`${data.first_name} ${data.last_name}`} photo={data.photo} team_name={data.team} /></Link></div>
                        {props.card_type === 'scorer'?
                            <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.goals}</div>                        
                        :
                            <React.Fragment>
                                <div className='tournament-statistics__scorers-row__goals baloa-normal-medium'>{data.cards}</div>
                                <div className={props.card_type}/>
                            </React.Fragment>
                        }
                        
                    </div>
                )}
            </InfiniteScroll>
        </Modal2Component>
    )
}

export default withTranslation(['league', 'tournament'])(TournamentViewMoreStatisticsModalComponent);