/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayDeletePresentationPostModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayDeletePresentationPostModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className="baloa-play-delete-presentation-post-modal"
        >
            <div className={`baloa-play-delete-presentation-post-modal__container `}>
                <div className='baloa-play-delete-presentation-post-modal__content'>
                    <span className='hubicon-info' />
                    <div className='baloa-normal-medium'>{props.t('deletePostModal.title')}</div>
                    <div className='baloa-names'>{props.t('deletePostModal.message')}</div>
                </div>            
                <div className='baloa-play-delete-presentation-post-modal__buttons'>
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.onClose()}}
                    >
                        <span>{props.t('deletePostModal.cancelBtnLbl')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnDelete()}}
                    >
                        <span>{props.t('deletePostModal.deleteBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayDeletePresentationPostModalComponent);