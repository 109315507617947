/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

//Material
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styles
import './BaloaProManageSubscriptionWidgetComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

function BaloaProManageSubscriptionWidgetComponent( props ) {

    return(
        <div className='baloa-pro-manage-subscription-widget__container'>
            <Accordion className='baloa-pro-manage-subscription-widget__accordion' /* expanded={expanded2} onChange={()=>{ handleExpanded2() }} */>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="payments-content"
                    id="payments-header"
                    className=''                
                >                                    
                    <div className=''>
                        <div className='baloa-pro-manage-subscription-widget__title'>
                            <span className='hubicon-award_star'/>
                            <div className='baloa-subtitle-1'>{props.t('baloaProHeader.title')}</div>
                        </div>
                        {props.payment_status === 'trialing'?
                            <React.Fragment>
                                <div className='baloa-pro-manage-subscription-widget__subtitle'>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.trialEndDateLbl')}&nbsp;</div>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal-medium'>{moment(props.expiration_date).format('LL') }</div>
                                </div>
                                <div className='baloa-pro-manage-subscription-widget__subtitle'>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.valueAfterTrailLbl')}&nbsp;</div>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal-medium'>{props.t('manageSubscriptionWidget.usdValue',{_value: props.payment?.amount_total, _currency: props.payment?.currency, _interval: props.t(`manageSubscriptionWidget.paymentInterval.${props.subscription_type}`)}) }</div>
                                </div>
                            </React.Fragment>
                            :
                            <div className='baloa-pro-manage-subscription-widget__subtitle'>
                                <div className='baloa-pro-manage-subscription-widget__subtitle-name'>{props.t('manageSubscriptionWidget.value')}&nbsp;</div>
                                <div className='baloa-pro-manage-subscription-widget__subtitle-value'>{props.customer !== 'Free'? props.t('manageSubscriptionWidget.usdValue',{_value: props.payment?.amount_total, _currency: props.payment?.currency, _interval: props.t(`manageSubscriptionWidget.paymentInterval.${props.subscription_type}`)}) : props.t(`manageSubscriptionWidget.freeAmount`) }</div>
                            </div>
                        }
                        
                    </div>
                    <div className='baloa-pro-manage-subscription-widget__title-acction'>{props.t('manageSubscriptionWidget.manageSubscriptionBtnLbl')}</div>
                </AccordionSummary>
                <AccordionDetails>
                    {!props.cancel_at_period_end && props.subscription_plan_channel === 'Web' &&
                        <div className='baloa-pro-manage-subscription-widget__payment-item'>
                            <div className='baloa-pro-manage-subscription-widget__payment-subtitle baloa-normal-medium'>{props.t('manageSubscriptionWidget.paymentMethod')}</div>
                            {props.selected_payment_method &&
                                <div className='baloa-pro-manage-subscription-widget__payment-option'>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t(`manageSubscriptionWidget.paymentTypes.${props.selected_payment_method?.type}`)}&nbsp;{props.selected_payment_method?.card?.brand}:&nbsp;</div>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal-medium'>{`**** **** **** ${props.selected_payment_method?.card?.last4}`}</div>
                                </div>
                            }
                            <div className='baloa-pro-manage-subscription-widget__payment-item__option' onClick={()=>{ props.handleOnEditSuscription() }}>{props.t('manageSubscriptionWidget.editBtnLbl')}</div>
                        </div>
                    }
                    {props.customer !== 'Free' &&
                        <div className='baloa-pro-manage-subscription-widget__payment-item'>
                            <div className='baloa-pro-manage-subscription-widget__payment-subtitle baloa-normal-medium'>{(isMobile && !props.cancel_at_period_end)? props.t('manageSubscriptionWidget.nextPayment'):props.t('manageSubscriptionWidget.paymentInformation')}</div>
                            <div className='baloa-pro-manage-subscription-widget__payment-option'>
                                {!isMobile &&
                                    <React.Fragment>
                                        {props.cancel_at_period_end?
                                            <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.yourEndPayment')}&nbsp;</div>
                                            :
                                            <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.yourNextPayment')}&nbsp;</div>
                                        }
                                        <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal-medium'>{moment(props.renewal_date).format('LL')}</div>
                                    </React.Fragment>
                                }
                                {isMobile && props.cancel_at_period_end &&
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.yourEndPayment')}&nbsp;</div>
                                }                                                        
                                {isMobile &&
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal'>{moment(props.renewal_date).format('LL')}</div>
                                } 
                            </div>
                            {!props.cancel_at_period_end && props.subscription_plan_channel === 'Web' &&
                                <div className='baloa-pro-manage-subscription-widget__payment-item__option cancel' onClick={()=>{ props.handleOnCancelSuscription() }}>{props.t('manageSubscriptionWidget.cancelSubscriptionBtnLbl')}</div>
                            }
                        </div>
                    }
                    {props.customer === 'Free' &&
                        <div className='baloa-pro-manage-subscription-widget__payment-item'>
                            <div className='baloa-pro-manage-subscription-widget__payment-subtitle baloa-normal-medium'>{props.t('manageSubscriptionWidget.paymentInformation')}</div>
                            <div className='baloa-pro-manage-subscription-widget__payment-option'>
                                <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.freeeAccessLbl')}</div>
                            </div>
                            {props.expiration_date &&
                                <div className='baloa-pro-manage-subscription-widget__payment-option'>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-name baloa-normal'>{props.t('manageSubscriptionWidget.freeExpirationDateLbl')}&nbsp;</div>
                                    <div className='baloa-pro-manage-subscription-widget__payment-method-number baloa-normal-medium'>{moment(props.expiration_date).format('LL')}</div>
                                </div>
                            }
                        </div>
                    }
                    
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default withTranslation('baloapro')(BaloaProManageSubscriptionWidgetComponent);