// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './BaloaProStripePaymentResumeModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';



function BaloaProStripePaymentResumeModalComponent ( props ){
    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-stripe-payment-resume-modal'}
        >
            <div className='baloa-pro-stripe-payment-resume-modal__container'>
                <span className='hubicon-check_circle'/>
                <div className='baloa-pro-stripe-payment-resume-modal__title baloa-headline-2'>{props.t('baloaProStripeResumeModal.title')}</div>
                <div className='baloa-pro-stripe-payment-resume-modal__greetings baloa-names'>{props.t('baloaProStripeResumeModal.greetings')}</div>
                <div className='baloa-pro-stripe-payment-resume-modal__message baloa-names'>{props.t('baloaProStripeResumeModal.message')}</div>
                <div className='baloa-pro-stripe-payment-resume-modal__resume-container'>
                    <div className='baloa-pro-stripe-payment-resume-modal__resume-container-title baloa-names'>{props.t('baloaProStripeResumeModal.itemsTitle')}</div>
                    <div className='baloa-pro-stripe-payment-resume-modal__resume-container-items'>
                        <div className='baloa-pro-stripe-payment-resume-modal__resume-item'>
                            <div className='baloa-pro-stripe-payment-resume-modal__resume-item-title baloa-names'>{props.t('baloaProStripeResumeModal.productLbl')}</div>
                            <div className='baloa-names'>{props.t('baloaProStripeResumeModal.product')}</div>
                        </div>
                        <div className='baloa-pro-stripe-payment-resume-modal__resume-item'>
                            <div className='baloa-pro-stripe-payment-resume-modal__resume-item-title baloa-names'>{props.t('baloaProStripeResumeModal.idTransaccionLbl')}</div>
                            <div className='baloa-names'>{props.subscription_state?.payment_intent?.id}</div>
                        </div>
                        <div className='baloa-pro-stripe-payment-resume-modal__resume-item'>
                            <div className='baloa-pro-stripe-payment-resume-modal__resume-item-title baloa-names'>{props.t('baloaProStripeResumeModal.dateLbl')}</div>
                            <div className='baloa-names'>{moment.unix(props.subscription_state?.payment_intent?.created).format("DD/MM/YYYY hh:mm A")}</div>
                        </div>
                        <div className='baloa-pro-stripe-payment-resume-modal__resume-item'>
                            <div className='baloa-pro-stripe-payment-resume-modal__resume-item-title baloa-names'>{props.t('baloaProStripeResumeModal.paymentMethodLbl')}</div>
                            <div className='baloa-names'>{props.t(`baloaProStripeResumeModal.paymentMethod.${props.subscription_state?.payment_intent?.payment_method_types[0]}`)}</div>
                        </div>
                        <div className='baloa-pro-stripe-payment-resume-modal__resume-item'>
                            <div className='baloa-pro-stripe-payment-resume-modal__resume-item-title baloa-names'>{props.t('baloaProStripeResumeModal.totalLbl')}</div>
                            <div className='baloa-names'>{'$'}{props.subscription_state?.subscription_price}{' USD'}</div>
                        </div>
                    </div>
                </div>
                <div className='baloa-pro-stripe-payment-resume-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={'small'}
                        onClick={ ()=>{props.onClose()} }
                    >
                        <span>{props.t('baloaProStripeResumeModal.btnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaProStripePaymentResumeModalComponent);
