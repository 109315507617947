// React
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';

import TextField from '@mui/material/TextField';

// Styles
import './InputFieldAutocompleteWithSearchComponent.styles.css';
import { withStyles } from '@mui/styles';

function InputFieldAutocompletePaymentCountries2( props ) {
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const [searchWord, setSearchWord] = React.useState( props.value? props.value : null);
    const field_name =  props.field_name;
 
    const [input_change, setInputChange] = React.useState("");
    
    /* eslint-disable */
    React.useEffect(() => {
        if(input_change==props.id){
            document.getElementById(props.id)?.focus();
        }        
    }, [ props.options]);
    /* eslint-enable */

    /* eslint-disable */
    React.useEffect(() => {
        if(input_change==props.id){
            document.getElementById(props.id)?.focus();
        }        
    }, [ ignored ]);
    /* eslint-enable */

    /* React.useEffect(() => {
        if(props.value) {
            setSearchWord(props.value); 
        } 
    }, [props.value]); */

    React.useEffect(() => { 
        if(input_change!==""){ 
            document.getElementById(input_change)?.focus();
        }
     }, [input_change]);// eslint-disable-line

    const AutocompleteTextField = withStyles({
        // root: {
        //     '& label.Mui-focused': {
        //         color: '#2a302a',
        //     },          
        //     '& .MuiOutlinedInput-root': {
        //         '& fieldset': {             
        //             border: 'var(--input-field-border)',
        //         },      
        //     },
        // },
    })(TextField);
    
    return(

            <Field name={ props.field_name } validate={ props.validationFunction }>
        
            {  ( data ) => {
                    if ( props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = props.error[ field_name ];                        
                    }
                    //console.log('dentro del input ', searchWord, props.value)
                    
                    return(
                        <div className= { "input-field-auto-search " + ( props.className ? props.className : '' )  }>
                        {/* { props.label && <label className="input-autocomplete-label" htmlFor={ props.input_id }>{ props.label }</label> } */}
                            <Autocomplete
                                //key={ props.key }
                                name={ props.field_name}
                                //component={Autocomplete}
                                disabled={props.disabled}
                                options={props.options}
                                noOptionsText= { props.noOptionsText }
                                getOptionLabel={(option) => option[props.optionLabelField] }
                                isOptionEqualToValue={(option, values) => option[props.optionSelectedField] === values[props.optionSelectedField] }
                                getOptionKey={option => option[props.optionSelectedField] }
                                disablePortal={true}
                                value={ searchWord }
                                onInputChange={(event, word) => {
                                    setInputChange(props.id); //se guardar el id del input donde se está escribiendo
                                    if(word.length > props.lettercount ){                        
                                        props.handleSearchWord( word );  
                                    }
                                    forceUpdate();
                                    //data.form.setFieldValue( props.field_name, "" )                                    
                                }}
                                onChange={ (e,values)=>{                               
                                    setSearchWord(values);
                                    data.form.setFieldValue( props.field_name, values? values[props.optionSelectedField] : "" )      
                                    props.onInputChange(values);
                                }}

                                id = {props.id}
                                
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // className={ props.className + " input-field-autocomplete"}
                                        className={ 
                                            'input-field-autocomplete-search ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                        }
                                        label={ props.label }
                                        variant="outlined"
                                        margin="normal"
                                        placeholder={ props.placeholder }
                                        onKeyDown={ () => { props.resetError() } }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <p className={"input-field-autocomplete-search__message " + (data.form.errors[ field_name ] && data.form.touched[ field_name ] ? 'error' : '' )}>
                                { data.form.errors[ field_name ] ? data.form.errors[ field_name ] :  props.message }
                            </p>
        
                        </div>
                    )

            }
            }
            </Field>
    )
}

// Types
InputFieldAutocompletePaymentCountries2.propTypes = {
    validationFunction: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
}

export default InputFieldAutocompletePaymentCountries2;
