// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from 'config/GlobalData';

//https://api.stripe.com/v1/payment_intents

export const GetStripePricesApi = (profile) => {

    return Axios.instance.get(`${api_url}/profiles/${profile}/subscription_prices/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateStripePaymentIntentKeyApi = (profile, interval) => {

    return Axios.instance.post(`${api_url}/profiles/${profile}/payment_intent/`,
        {
            redirect: `/confirm_payment`,
            mobile: false,
            interval: interval
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateStripeSubscriptionApi = (profile, subscription_type, name, email, country_code) => {

    return Axios.instance.post(`${api_url}/profiles/${profile}/create_subscription/`,
        {
            redirect: `/confirm_payment`,
            mobile: false,
            interval: subscription_type,
            full_name: name,
            email: email,
            country_code: country_code
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetUnauthStripePricesApi = () => {

    return Axios.instance.get(`${api_url}/subscription-prices/`,
        {}
    );
}

export const GetStripeUserAccountApi = (profile) => {

    return Axios.instance.get(`${api_url}/profiles/${profile}/subscription_plan/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CreateStripeSetupIntentApi = (profile, customer_id = null, stripe_account_id = null) => {

    return Axios.instance.post(`${api_url}/profiles/${profile}/setup_intent/?customer_id=${customer_id}&stripe_account_id=${stripe_account_id}`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SetPredefinedSubscriptionPaymentMethodApi = (profile, payment_id) => {

    return Axios.instance.post(`${api_url}/profiles/${profile}/predefined_payment_method/`,
        {
            payment_method_id:  payment_id
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const CancelStripeSubscriptionApi = (profile) => {

    return Axios.instance.post(`${api_url}/profiles/${profile}/cancel_subscription/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}