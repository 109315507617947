// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './HomeFeaturedClipsUnauthPlayModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function HomeFeaturedClipsUnauthPlayModalComponent ( props ){
    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'home-featured-clips-unauth-subscription-modal'}
        >
            <div className='home-featured-clips-unauth-subscription-modal__container'>
                <span className='hubicon-video_library'/>
                <div className='baloa-headline-2'>{props.t('featuredClipsUnauthPlayModal.title')}</div>
                <div className='baloa-names'>{props.t('featuredClipsUnauthPlayModal.message')}</div>
                
                
                <div className='home-featured-clips-unauth-subscription-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={''}
                        onClick={ ()=>{props.handleLogin()} }
                    >
                        <span>{props.t('featuredClipsUnauthPlayModal.loginBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('home')(HomeFeaturedClipsUnauthPlayModalComponent);
