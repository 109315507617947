//React
import React, { useState } from 'react';

//Components
import TournamentPositionGoalScorerTableComponent from 'app/leagues/components/tournament-position-goal-scorer-table/TournamentPositionGoalScorerTableComponent';
import LeagueTournamentReportDatesComponent from '../league-tournament-report-dates-component/LeagueTournamentReportDatesComponent';
import LeagueMatchReportComponent from '../league-match-report-component/LeagueMatchReportComponent';
import TournamentPodiumTeamsComponent from '../tournament-podium-teams/TournamentPodiumTeamsComponent';
import TournamentRecognitionPodiumComponent from '../tournament-recognition-podium/TournamentRecognitionPodiumComponent';
import TournamentInformationComponent from 'app/tournaments/components/tournament-information/TournamentInformationComponent';
import PageTournamentTeamsListComponent from 'app/pages/components/page-tournament-teams-list/PageTournamentTeamsListComponent';
import TournamentStatisticsComponent from '../tournament-statistics/TournamentStatisticsComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './LeagueTournamentReportComponent.styles.css';
import estirando1 from 'assets/images/estirando1.png';

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation, useTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function TabPanel(props) {

    const { children, value, index, ...other } = props;
    const [activePhase, setActivePhase] = useState('Phase 1'); //eslint-disable-line
    const [phaseList, setPhaseList] = useState();
    const { t } = useTranslation('league');

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            { value === 0 && props.selected_tournament.state === 'Finalized' &&            
                <div className='league-tournament-report__podium__container'>
                    <div className="league-tournament-report__podium">
                        {/* <div className="league-tournament-report__podium__title">
                            <p className="baloa-headline-5 league-tournament-report__podium__title__color">
                                {t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelTitlePodium')}
                            </p>
                        </div> */}
                        <TournamentPodiumTeamsComponent
                            selected_tournament = { props.selected_tournament }
                            handleOnProfileClick = { props.handleOnProfileClick } 
                            handleOnPageClick = { props.handleOnPageClick }
                        />
                    </div>
                    <div className="league-tournament-report__recognition">
                        <div className="league-tournament-report__recognition__title">
                            <p className="league-tournament-report__recognition__title__color baloa-subtitle-1">
                                {t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelTitleRecognition')}
                            </p>
                        </div>
                        <TournamentRecognitionPodiumComponent
                            selected_tournament = { props.selected_tournament }
                            handleOnProfileClick = { props.handleOnProfileClick }              
                            handleOnPageClick = { props.handleOnPageClick }
                        />
                    </div>
                </div>
            }

            { value === 0 && props.selected_tournament.state === 'Scheduled' &&
                <React.Fragment>
                    { props.tournament_phases?.length > 0 &&  
                        <div className='league-tournament-report__phases__list'>
                            <FormControl >
                                <Select
                                    value={phaseList? phaseList: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id }
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant='standard'
                                    >  
                                        { props.tournament_phases?.map((phase, index) => { // eslint-disable-line
                                            if(phase.calendar_dates.length !== 0) {
                                                return (
                                                    <MenuItem 
                                                        key={`Phase ${index+1}`}
                                                        value={phase.id} 
                                                        onClick = {() => {
                                                            //if(phase.groups.length !== 0){
                                                            if(phase.groups_count > 0){//change new api phases
                                                                setActivePhase(`Phase ${index+1}`)                                                                                                      
                                                            }
                                                            setPhaseList(phase.id);
                                                            props.handlePhase(phase);
                                                        }} 
                                                    >
                                                        {`${t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                    </MenuItem>
                                                )
                                            }
                                            })
                                        }
                                </Select>                                
                            </FormControl>
                            <SecondaryButtonComponent
                                className='tournament-edit-tournament_iframe_share-btn small'
                                input_type={'button'}
                                onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.selected_tournament.id, 'matches')}}
                            >
                                <span className='hubicon-code'/>
                                <span className="baloa-table">{t('options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                            </SecondaryButtonComponent>
                        </div>
                    }

                    {  props.tournament_phases?.length > 0 && props.phase_dates?.length > 0 && props.phase_dates !== undefined &&
                        <div className='league-tournament-report__dates__btns'>
                            <LeagueTournamentReportDatesComponent 
                                phase_dates = { props.phase_dates }
                                selected_tournament = { props.selected_tournament }
                                handleOnPostMatch = { props.handleOnPostMatch }
                                handleOnPhaseDate = { props.handleOnPhaseDate }
                                phase_matches_by_date = { props.phase_matches_by_date }
                                groups_matches_by_date = { props.groups_matches_by_date }
                                setDateTab = { props.setDateTab }
                                dateTab = { props.dateTab }
                                active_phase_id={props.active_phase_id}
                                tournament_active_phase={props.tournament_active_phase}
                            />
                        </div>
                    }
                    { props.phase_dates?.length <= 0 &&
                        <div className='league__tournament__no-dates-phases__container'>
                            <img  src={estirando1} alt=""/>
                            <p className='baloa-body-1'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseWithoutDates')}</p>
                            
                        </div>
                    }
                    { props.tournament_phases?.length <= 0 && !props.phase_dates?.length <= 0 &&
                        <div className='league__tournament__report-msg-container'>
                            <p className='baloa-headline-6'>{t('options.tournaments.tabs.tabsTournamentsList.noDataToShowTitle')}</p>
                            <p className='baloa-body-1'>{t('options.tournaments.tabs.tabsTournamentsList.noDataToShowText')}</p>
                        </div>
                    }
                </React.Fragment>
            }

            { value === 0 && props.selected_tournament.state === 'Registration_Open' &&
                <TournamentInformationComponent 
                    tournament={props.selected_tournament}
                    tournament_information_title={t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDetailTournamentInfo')}
                />
                
            }

            
            { value === 1 && props.selected_tournament.state === 'Finalized' &&
                <React.Fragment>
                    { props.tournament_phases?.length > 0 &&  
                        <div className='league-tournament-report__phases__list'>  
                            <FormControl >
                                <Select
                                    value={phaseList? phaseList: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant='standard'
                                    >  
                                        { props.tournament_phases?.map((phase, index) => { // eslint-disable-line
                                            if(phase.calendar_dates.length !== 0) {
                                                return (
                                                    <MenuItem 
                                                        key={`Phase ${index+1}`}
                                                        value={phase.id} 
                                                        onClick = {() => {
                                                            //if(phase.groups.length !== 0){
                                                            if(phase.groups_count > 0){//change new api phases
                                                                setActivePhase(`Phase ${index+1}`)                                                                                                      
                                                            }
                                                            setPhaseList(phase.id);
                                                            props.handlePhase(phase);
                                                        }} 
                                                    >
                                                       {`${t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                    </MenuItem>
                                                )
                                                    }
                                                }
                                            )
                                        }
                                </Select> 
                            </FormControl>
                            <SecondaryButtonComponent
                                className='tournament-edit-tournament_iframe_share-btn small'
                                input_type={'button'}
                                onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.selected_tournament.id, 'matches')}}
                            >
                                <span className='hubicon-code'/>
                                <span className="baloa-table">{t('options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                            </SecondaryButtonComponent>                            
                        </div>  
                        
                    }

                    {  props.tournament_phases?.length > 0 && props.phase_dates?.length > 0 && props.phase_dates !== undefined &&
                        <div className='league-tournament-report__dates__btns'>
                            <LeagueTournamentReportDatesComponent 
                                phase_dates = { props.phase_dates }
                                selected_tournament = { props.selected_tournament }
                                handleOnPostMatch = { props.handleOnPostMatch }
                                handleOnPhaseDate = { props.handleOnPhaseDate }
                                phase_matches_by_date = { props.phase_matches_by_date }
                                groups_matches_by_date = { props.groups_matches_by_date }
                                setDateTab = { props.setDateTab }
                                dateTab = { props.dateTab }
                                active_phase_id={props.active_phase_id}
                                tournament_active_phase={props.tournament_active_phase}
                            />
                        </div>
                    }
                    { props.phase_dates?.length <= 0 &&
                        <div className='league__tournament__no-dates-phases__container'>
                            <img  src={estirando1} alt=""/>
                            <p className='baloa-body-1'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseWithoutDates')}</p>
                            
                        </div>
                    }

                </React.Fragment>
            }

            { value === 1 && props.selected_tournament.state === 'Scheduled' &&

                <div>
                    { props.tournament_phases?.length > 0 &&
                        <div className='league-tournament-report__tab'>
                            <div className='league-tournament-report_phases_list_options'>
                                <div className='league-tournament-report__phases__list table-list '>
                                    <FormControl >
                                        <Select
                                            value={phaseList? phaseList: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id }
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            variant='standard'
                                            >  
                                                { props.tournament_phases?.map((phase, index) => { // eslint-disable-line
                                                    if(phase.calendar_dates.length !== 0) {
                                                        return (
                                                            <MenuItem 
                                                                key={`Phase ${index+1}`}
                                                                value={phase.id} 
                                                                onClick = {() => {
                                                                    //if(phase.groups.length !== 0){
                                                                    if(phase.groups_count > 0){//change new api phases
                                                                        setPhaseList(phase.id);
                                                                        setActivePhase(`Phase ${index+1}`);
                                                                        props.handleOnPhaseClick(phase);                                                       
                                                                    } else {
                                                                        console.log('la fase no tiene grupos')
                                                                    }
                                                                }} 
                                                            >
                                                                {`${t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                            </MenuItem>
                                                        )
                                                    }}
                                                    )
                                                }
                                        </Select> 
                                    </FormControl>
                                    <div className="league-tournament-report__classified classified">
                                        <div className="league-tournament-report__classified-square" />
                                        <div className="baloa-table">{t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamClasified')}</div>                                                      
                                    </div>
                                    { props.is_best_third &&
                                        <div className="league-tournament-report__classified">
                                            <div className="league-tournament-report__best-third-square" />
                                            <div className="baloa-table">{t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamBestThird')}</div>
                                        </div>
                                    }
                                    
                                </div>
                                <SecondaryButtonComponent
                                    className='tournament-edit-tournament_iframe_share-btn small'
                                    input_type={'button'}
                                    onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.selected_tournament.id, 'results_table')}}
                                >
                                    <span className='hubicon-code'/>
                                    <span className="baloa-table">{t('options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                                </SecondaryButtonComponent>
                            </div>
                            { 
                                <TournamentPositionGoalScorerTableComponent
                                    className='league-tournament-report__tab__positions-table'
                                    phase_groups_results_table={ props.phase_groups_results_table }
                                    tournament_results_table = { props.tournament_results_table }                        
                                    phase_type = { props.tournament_active_phase?.phase_type }
                                    phase_name = { props.tournament_active_phase?.phase_name }
                                    handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                                    loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                                />
                            }
                        </div>
                    }

                    { props.tournament_phases?.length <= 0 &&
                        <div className='league__tournament__report-msg-container'>
                            <p className='baloa-headline-6'>{t('options.tournaments.tabs.tabsTournamentsList.noDataToShowTitle')}</p>
                            <p className='baloa-body-1'>{t('options.tournaments.tabs.tabsTournamentsList.noDataToShowText')}</p>
                        </div>
                    }
                </div>
            }
            
            { value === 1 && props.selected_tournament.state === 'Registration_Open' && 

                <PageTournamentTeamsListComponent 
                    tournament_teams={props.tournament_teams}
                    handleTeamMembersModal={props.handleTeamMembersModal}
                />

            }
            
            { value === 2 && props.selected_tournament.state === 'Finalized' && 
                    <div>
                        { props.tournament_phases?.length > 0 &&
                            <div className='league-tournament-report__tab'>
                                <div className='league-tournament-report_phases_list_options'>
                                    <div className='league-tournament-report__phases__list table-list'>
                                        <FormControl >
                                            <Select
                                                value={phaseList? phaseList: props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]?.id }
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                variant='standard'
                                                >  
                                                    { props.tournament_phases?.map((phase, index) => { // eslint-disable-line
                                                        if(phase.calendar_dates.length !== 0) {
                                                            return (
                                                                <MenuItem 
                                                                    key={`Phase ${index+1}`}
                                                                    value={phase.id} 
                                                                    onClick = {() => {
                                                                        //if(phase.groups.length !== 0){
                                                                        if(phase.groups_count > 0){//change new api phases
                                                                            setActivePhase(`Phase ${index+1}`)                                                                                                      
                                                                        }
                                                                        setPhaseList(phase.id);
                                                                        // props.handlePhase(phase);
                                                                        props.handleOnPhaseClick(phase);  
                                                                    }} 
                                                                >
                                                                    {`${t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')} ${index + 1} - ${t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}`}
                                                                </MenuItem>
                                                                )
                                                        }
                                                        })
                                                    }
                                            </Select>                                         
                                        </FormControl>
                                        
                                        <div className="league-tournament-report__classified classified">
                                            <div className="league-tournament-report__classified-square" />
                                            <div className="baloa-table">{t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamClasified')}</div>                                                      
                                        </div>
                                        { props.is_best_third &&
                                            <div className="league-tournament-report__classified">
                                                <div className="league-tournament-report__best-third-square" />
                                                <div className="baloa-table">{t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamBestThird')}</div>
                                            </div>
                                        }
                                    </div>
                                    <SecondaryButtonComponent
                                        className='tournament-edit-tournament_iframe_share-btn small'
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.selected_tournament.id, 'results_table')}}
                                    >
                                        <span className='hubicon-code'/>
                                        <span className="baloa-table">{t('options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                                    </SecondaryButtonComponent>
                                </div>
                                { 
                                    <TournamentPositionGoalScorerTableComponent
                                        className='league-tournament-report__tab__positions-table'
                                        phase_groups_results_table={ props.phase_groups_results_table }
                                        tournament_results_table = { props.tournament_results_table }                        
                                        phase_type = { props.tournament_active_phase?.phase_type }
                                        phase_name = { props.tournament_active_phase?.phase_name }
                                        handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                                        loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                                    />
                                }
                            </div>
                        }
                        
                    </div>
                
            }
            { value === 2 && props.selected_tournament.state === 'Scheduled' &&
                <TournamentStatisticsComponent 
                    tournament_results_table = { props.tournament_results_table ? props.tournament_results_table : [] }
                    tournament_type = { props.tournament_active_phase?.phase_type ? props.tournament_active_phase.phase_type : "Free"}
                />
            }

            { value === 3 && props.selected_tournament.state === 'Finalized' && 
                <TournamentStatisticsComponent 
                    tournament_results_table = { props.tournament_results_table ? props.tournament_results_table : [] }
                    tournament_type = { props.tournament_active_phase?.phase_type ? props.tournament_active_phase.phase_type : "Free"}
                />
            }

            { value === 3 && props.selected_tournament.state === 'Scheduled' &&
                <PageTournamentTeamsListComponent 
                    tournament_teams={props.tournament_teams}
                    handleTeamMembersModal={props.handleTeamMembersModal}
                />
            }

            { value === 4 && props.selected_tournament.state === 'Finalized' &&
                <PageTournamentTeamsListComponent 
                  tournament_teams={props.tournament_teams}
                  handleTeamMembersModal={props.handleTeamMembersModal}
                />
            }

            { value === 4 && props.selected_tournament.state === 'Scheduled' &&
                <TournamentInformationComponent 
                    tournament={props.selected_tournament}
                    tournament_information_title={t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDetailTournamentInfo')}
                />
            }

            { value === 5 && props.selected_tournament.state === 'Finalized' &&
                <TournamentInformationComponent 
                    tournament={props.selected_tournament}
                    tournament_information_title={t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDetailTournamentInfo')}
                />
            }
        </div>
    );
}

function LeagueTournamentReportComponent(props) {

    const [tabSelected, setTabSelected] = useState(0);
    //const [postMatch, setPostMatch] = useState();
    const [postMatchDetail, setPostMatchDetail] = useState(false); 
    const [activePhaseDates, setActivePhaseDates] = useState();
    const [statisticsMatch, setStatisticsMatch] =  useState(false);
    const [dateTab, setDateTab] =  useState(0);
    const [isRefereesPaymentResponse, setIsRefereesPaymentResponse] = React.useState(false);

    React.useEffect(()=>{
        if(props.tournament_phases.length > 0){
            if(props.referees_payment_response && props.referees_payment_response.type === 'referee') {
                props.handleOnPhaseClick(props.tournament_phases[props.tournament_phases.findIndex(phase => phase.id === props.referees_payment_response.phase_id)]);
                props.handleMatchStatistics(props.referees_payment_response.match_id);
                setPostMatchDetail(false);
                setStatisticsMatch(true);
                const timeout = setTimeout(() => {
                    setIsRefereesPaymentResponse(true);
                }, 2000);
                return () => clearTimeout(timeout);
            } else {
                props.handleOnPhaseClick(props.tournament_phases[props.tournament_phases.findIndex(phase => phase.is_last_active_phase === true)]);
            }
        }
    }, [props.tournament_phases]); // eslint-disable-line

    const  handleOnPostMatch =  (match) => {
        props.handleMatchStatistics(match.id);
        if(match.state === 'Finalized') {            
            setPostMatchDetail(false);
            setStatisticsMatch(true);
        } else {
            if(props.match_report !== undefined){
                //props.handleOnMatchDetailModal(props.match_report);
                setPostMatchDetail(false);
                setStatisticsMatch(true);
            } else {
                props.handleMatchPhaseError();
            }
            
            // setPostMatchDetail(true);
            // setStatisticsMatch(false);
        }
    }

    function handleOnBackBtn() {
        props.handle_back_btn('false')         
    } 

    const handleOnPostMatchBackBtn = () => {
        setPostMatchDetail(false);
        setStatisticsMatch(false);
    }
    
    const handlePhase = (phase) => {
        let phase_filter = props.page_events?.filter(el => el.post_match.phase_id ===  phase.id.replaceAll('-',''));
        setActivePhaseDates(phase_filter);
        props.handleOnPhaseClick(phase);
    }

    function handleIdSelectedString(id){
        let formated_id = id.replaceAll('-','');
        return formated_id;
    }

    return (

        <React.Fragment>
            {  !statisticsMatch &&
                <div className='league-tournament-report__container'>
                    <div className='league-tournament-report__title-nav'>
                        <span className='icon-arrow-left' onClick={()=>{handleOnBackBtn()}}></span>
                        <h5 className='baloa-headline-2'>{props.t('options.tournaments.pages.tournamentInfo.labelBack')}</h5>
                    </div>
                    <div className="league-tournament-report__container__card">
                        { props.selected_tournament?.tournament_photo && 
                            <div className='league-tournament-report__image__background-img_container' >
                                <img className='league-tournament-report__image__background-img' src={props.selected_tournament?.tournament_photo} alt={props.selected_tournament?.name}/> {/*eslint-disable-line*/}
                            </div>  
                        }
                        <div className='league-tournament-report__image'>
                            {props.selected_tournament?.tournament_photo?
                                <img src={props.selected_tournament?.tournament_photo} alt={props.selected_tournament?.name}/>// eslint-disable-line
                                :
                                <span className={"icon-trophy"}></span>
                            }
                            <div className='league-tournament-report__image__tags'>
                                { props.selected_tournament?.football_type && <div className='league-tournament-report__image__tags-tag'>{props.selected_tournament?.football_type? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.'+props.selected_tournament?.football_type):''}</div>}
                                { props.selected_tournament?.tournament_gender && <div className='league-tournament-report__image__tags-tag'>{props.selected_tournament?.tournament_gender? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.'+props.selected_tournament?.tournament_gender):''}</div>}
                                { props.selected_tournament?.category && <div className='league-tournament-report__image__tags-tag'>{props.selected_tournament?.category? props.t('options.tournaments.pages.newTournament.tabs.tabTeams.'+ props.selected_tournament?.category):''}</div>                }
                            </div>                            
                        </div>
                        <div className='league-tournament-report__tournament-name__container' >
                            { props.selected_tournament?.tournament_logo?
                                <img src={props.selected_tournament?.tournament_logo} alt={props.selected_tournament?.name}/>// eslint-disable-line
                                :
                                <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                            }
                            <div className='league-tournament-report__tournament-name baloa-headline-2'>{props.selected_tournament.name}</div>
                        </div>
                        <div className='league-tournament-report__container__card__separator'></div>
                        <Tabs
                            variant= {`${isMobile ? "scrollable" : "fullWidth"}` }
                            value={tabSelected}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={ ( ev, new_value ) => { setTabSelected(new_value) } }              
                            aria-label="Selection between "
                            className={"league-tournament-report__basic-info__tabs"}
                        >
                        
                            { props.selected_tournament.state === 'Finalized' && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelPodium')}/>}
                            { (props.selected_tournament.state === 'Finalized' || props.selected_tournament.state === 'Scheduled') && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCalendarResults')} />}  
                            { (props.selected_tournament.state === 'Finalized' || props.selected_tournament.state === 'Scheduled') && <Tab label={props.t('options.tournaments.tabs.tabsTournamentsList.tabTables')} />}
                            { (props.selected_tournament.state === 'Finalized' || props.selected_tournament.state === 'Scheduled') && <Tab label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelMatchStatistics')} /> }
                            { (props.selected_tournament.state === 'Registration_Open') && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitle')} />}  
                            { (props.selected_tournament.state === 'Registration_Open') && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelParticipantTeams')} />}  
                            { (props.selected_tournament.state === 'Finalized' || props.selected_tournament.state === 'Scheduled') && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelParticipantTeams')} />}  
                            { (props.selected_tournament.state === 'Finalized' || props.selected_tournament.state === 'Scheduled') && <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitle')} />}  
                            
                        </Tabs>

                        {/* Podium */}
                        { props.selected_tournament.state === 'Finalized' ?
                            <TabPanel
                                index={0}
                                value={tabSelected}
                                selected_tournament = { props.selected_tournament }
                                handleOnProfileClick = { props.handleOnProfileClick }
                                handleOnPageClick = { props.handleOnPageClick } 
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_results_table = { props.tournament_results_table }
                                active_phase_id={props.active_phase_id}
                            />
                        :
                            <TabPanel 
                                index={0}
                                value={tabSelected} 
                                tournament_info = { props.page_events }
                                tournament_id = {handleIdSelectedString(props.selected_tournament.id)}
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }                 
                                handlePhase = { handlePhase }
                                handleOnPostMatch={ handleOnPostMatch }
                                tournament_active_phase_dates = {activePhaseDates}
                                phase_dates = {props.phase_dates}
                                handleOnPhaseDate={ props.handleOnPhaseDate }
                                phase_matches_by_date = { props.phase_matches_by_date }
                                groups_matches_by_date = { props.groups_matches_by_date }
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_results_table = { props.tournament_results_table }
                                handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                                active_phase_id={props.active_phase_id}                                
                            />
                        }
                        { props.selected_tournament.state === 'Finalized' ?
                            <TabPanel 
                                index={1}
                                value={tabSelected} 
                                tournament_info = { props.page_events }
                                tournament_id = {handleIdSelectedString(props.selected_tournament.id)}
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }                 
                                handlePhase = { handlePhase }
                                handleOnPostMatch={ handleOnPostMatch }
                                tournament_active_phase_dates = {activePhaseDates}
                                phase_dates = {props.phase_dates}
                                handleOnPhaseDate={ props.handleOnPhaseDate }
                                phase_matches_by_date = { props.phase_matches_by_date }
                                groups_matches_by_date = { props.groups_matches_by_date }
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_results_table = { props.tournament_results_table }
                                tournament_teams={props.tournament_teams}
                                handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                                active_phase_id={props.active_phase_id}
                            />
                        :
                            <TabPanel 
                                index={1}
                                value={tabSelected} 
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }
                                tournament_results_table = { props.tournament_results_table }
                                handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_teams={props.tournament_teams}
                                handleTeamMembersModal={props.handleTeamMembersModal}
                                is_best_third={props.is_best_third}
                                handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                                active_phase_id={props.active_phase_id}
                            />
                        }

                        { props.selected_tournament.state === 'Finalized' &&
                            <TabPanel 
                                index={2}
                                value={tabSelected} 
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }
                                tournament_results_table = { props.tournament_results_table }
                                handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                                is_best_third={props.is_best_third}
                                handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                            />
                        }
                        { props.selected_tournament.state === 'Scheduled' &&
                            <TabPanel 
                                index={2}
                                value={tabSelected}
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }
                                tournament_results_table = { props.tournament_results_table }
                                handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_teams={props.tournament_teams}
                                active_phase_id={props.active_phase_id}
                            />
                        }
                        { props.selected_tournament.state === 'Scheduled' &&
                            <TabPanel 
                                index={3}
                                value={tabSelected}
                                tournament_teams = {props.tournament_teams}
                                selected_tournament={props.selected_tournament}
                                handleTeamMembersModal={props.handleTeamMembersModal}
                            />
                        }
                        { props.selected_tournament.state === 'Finalized' &&
                            <TabPanel 
                              index={3}
                              value={tabSelected}
                              tournament_info = { props.page_events }
                              tournament_id = {handleIdSelectedString(props.selected_tournament.id)}
                              tournament_phases = { props.tournament_phases }
                              selected_tournament = { props.selected_tournament }  
                              tournament_active_phase = { props.tournament_active_phase } 
                              handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                              tournament_teams = {props.tournament_teams}
                              tournament_results_table = { props.tournament_results_table }
                              handleTeamMembersModal={props.handleTeamMembersModal}
                            />
                        }
                        { props.selected_tournament.state === 'Finalized' &&
                            <TabPanel 
                                index={4}
                                value={tabSelected}
                                tournament_teams = {props.tournament_teams}
                                selected_tournament={props.selected_tournament}
                            />
                        }
                        { props.selected_tournament.state === 'Scheduled' &&
                            <TabPanel 
                                index={4}
                                value={tabSelected}
                                tournament_phases = { props.tournament_phases }
                                selected_tournament = { props.selected_tournament }  
                                tournament_active_phase = { props.tournament_active_phase }
                                tournament_results_table = { props.tournament_results_table }
                                handleOnPhaseClick = {(phase) => {props.handleOnPhaseClick(phase)}}
                                setDateTab = { (tab)=>{setDateTab( tab ) } }
                                dateTab = { dateTab }
                                tournament_teams={props.tournament_teams}
                                active_phase_id={props.active_phase_id}
                            />
                        }

                        { props.selected_tournament.state === 'Finalized' &&
                            <TabPanel 
                                index={5}
                                value={tabSelected}
                                tournament_teams = {props.tournament_teams}
                                selected_tournament={props.selected_tournament}
                            />
                        }

                    </div>
                </div>
            }

            {!postMatchDetail && statisticsMatch &&
                <div className='league-tournament-report__event-container'>
                        <LeagueMatchReportComponent 
                            data = { props?.match_report }
                            handleBackButton = { () => {handleOnPostMatchBackBtn()} } 
                            backButtonTitle =  {props.t(!isMobile ?'options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelMatchStatistics' : 'options.tournaments.pages.matchInfo.labelTitle')} 
                            tournament={props.selected_tournament}

                            resetError={()=>{}}                
                            errors={props.errors}
                            onResetError={props.onResetError}               
                            tournament_staff={props.tournament_staff}
                            handleOnLoadRefereeingPaymentUrl={props.handleOnLoadRefereeingPaymentUrl}
                            handleUpdateRefereesMatch={props.handleUpdateRefereesMatch}
                            isRefereesPaymentResponse={isRefereesPaymentResponse}
                            handleOnAcceptCrewPayment={props.handleOnAcceptCrewPayment}
                            handleOnShareMatchLinkClick={props.handleOnShareMatchLinkClick}
                            teams_match_assistance={props.teams_match_assistance}
                            updatePlayersAssistanceAligment={props.updatePlayersAssistanceAligment}
                        />
                </div>
            }
        </React.Fragment>
    )
}

export default withTranslation('league')(LeagueTournamentReportComponent);