// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-update-clip-modal .modal2__container {
    max-width: 443px;
}

.baloa-play-update-clip-modal__form {
    padding: 0 40px;
}

.baloa-play-update-clip-modal__form .input-field {
    margin: 46px 0 18px 0;
}

.baloa-play-update-clip-modal__form .input-field-label {
    font-size: 14px;
    font-weight: var(--font-regular);
    color: var(--primary-very-dark-color);
}

.baloa-play-update-clip-modal__form .input-field-input {
    background: var(--white-color);
    border: 1px solid var(--new-gray-background-color);
    color: var(--primary-very-dark-color);
}

.baloa-play-update-clip-modal__buttons {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 6px;
    justify-content: flex-end;
    border-top: 1px solid var(--new-gray-background-color);
}

.baloa-play-update-clip-modal__buttons .button {
    padding: 10px 24px;
}

.baloa-play-update-clip-modal__buttons .text-button {
    color: var(--primary-dark-color);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 
    .baloa-play-update-clip-modal .modal2__header {
        padding-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-update-clip-modal-component/BaloaPlayUpdateClipModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gCAAgC;IAChC,qCAAqC;AACzC;;AAEA;IACI,8BAA8B;IAC9B,kDAAkD;IAClD,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,QAAQ;IACR,yBAAyB;IACzB,sDAAsD;AAC1D;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".baloa-play-update-clip-modal .modal2__container {\n    max-width: 443px;\n}\n\n.baloa-play-update-clip-modal__form {\n    padding: 0 40px;\n}\n\n.baloa-play-update-clip-modal__form .input-field {\n    margin: 46px 0 18px 0;\n}\n\n.baloa-play-update-clip-modal__form .input-field-label {\n    font-size: 14px;\n    font-weight: var(--font-regular);\n    color: var(--primary-very-dark-color);\n}\n\n.baloa-play-update-clip-modal__form .input-field-input {\n    background: var(--white-color);\n    border: 1px solid var(--new-gray-background-color);\n    color: var(--primary-very-dark-color);\n}\n\n.baloa-play-update-clip-modal__buttons {\n    display: flex;\n    align-items: center;\n    padding: 16px;\n    gap: 6px;\n    justify-content: flex-end;\n    border-top: 1px solid var(--new-gray-background-color);\n}\n\n.baloa-play-update-clip-modal__buttons .button {\n    padding: 10px 24px;\n}\n\n.baloa-play-update-clip-modal__buttons .text-button {\n    color: var(--primary-dark-color);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n    .baloa-play-update-clip-modal .modal2__header {\n        padding-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
