// React
import React, { useState, useMemo, useEffect } from "react";

// Components
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";
import SelectFieldCleanComponent from "shared/components/select-field/SelectFieldCleanComponent";
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import Switch from "@mui/material/Switch";
import TournamentDynamicAnswers from './TournamentDynamicFormAnswerComponent';
// Validators
import { RequiredValidator } from "shared/validators/RequiredValidator";

//i18
import { withTranslation } from "react-i18next";

// Styles
import "./TournamentDynamicFormQuestionComponent.styles.css";

function TournamentDynamicFormQuestionComponent (props) {
    const [captionField, setCaptionField] = useState(props.question.caption? props.question.caption: "");
    const [inputType, setInputType] = useState(props.question.input_type?.length > 0? props.question.input_type: "");
    const [listAnswers, setListAnswers] = useState(props.question.answers.length > 0? props.question.answers: []);
    const [optionsAnswers, setOptionsAnswers] = useState(props.question.answers.length > 0? true: false); 
    const LIST_AVALAIBLE_OPTIONS = ['list','multi_choice', 'single_choice'];
    const [stringTest, setStringTest] =  useState("");
    
    const inputTypeOptions = [
        { value: " ", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionSelect') },
        { value: "text", content:  props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionText'), icon: 'hubicon-answer' },
        { value: "email", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionEmail'), icon: 'hubicon-mail' },
        { value: "phone", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionPhone'), icon: 'hubicon-phone' },
        { value: "date", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionDate'), icon: 'hubicon-date' },
        { value: "single_choice", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionSingleChoice'), icon: 'hubicon-radio_button' },
        { value: "multi_choice", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionMultiChoice'), icon: 'hubicon-check_box'},
        { value: "boolean", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionBoolean'), icon: 'hubicon-radio_button' },
        { value: "image", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionImage'), icon: 'hubicon-photo' },
        { value: "file", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionFile'), icon: 'hubicon-upload_file' },
        { value: "list", content: props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputTypeOptions.optionList'), icon: 'hubicon-dropdown' }
    ];

    const defaultList= [
                {
                    id:'',
                    caption: '', 
                    defaultValue: false,
                },
                {
                    id:'',
                    caption: '', 
                    defaultValue: false,
                },
                {
                    id:'',
                    caption: '', 
                    defaultValue: false,
                }
            ];

    useMemo(() => {
        if (captionField && inputType) {
            const question = {
                id: props.question.id,
                input_type: inputType,
                is_required: props.question.is_required,
                caption: captionField,
                answers: listAnswers,
            };
            props.setQuestionObject(question);
        }
        
    }, [captionField, inputType, stringTest]); // eslint-disable-line
    
    const handleRequiredChange = (event) => {
        
        let reqId = event.target.name.split("_");
        const question = props.arrayQuestions?.filter((question) => question.id === Number(reqId[1]) );
        question[0].is_required = event.target.checked;
        props.setQuestionObject(question[0]);
        
    };

    const checkedValueSelectField = (id, value_type) => {
        if (props.arrayQuestions.some((question) => question.id === id)) {
            const inputType = props.arrayQuestions?.filter((question) => question.id === id);
            
            return value_type === 'input_type' ? inputType[0].input_type : inputType[0].is_required
        } else return '';
    };

    useEffect(()=>{
        
        if(inputType) {
            if(LIST_AVALAIBLE_OPTIONS.includes(inputType)) {
                if( listAnswers.length <= 0 ){
                    setListAnswers(defaultList);
                }                
                defaultList.forEach((ans, index) => props.buildValuesToAnswer((index), props.question.id));
                setOptionsAnswers(true);
            } else {     
                setListAnswers([]);
                setOptionsAnswers(false);
            }
        }
    },[inputType]); // eslint-disable-line

    const addNewAnswer = () =>{
        const current_id = listAnswers.length;
        setListAnswers((oldArray) => [...oldArray, {
            id: '',
            caption: '', 
            defaultValue: false,
        }]);
        props.buildValuesToAnswer((current_id),props.question.id);
    };

    const buildAnswer = (answer) => {
        const { id, caption, defaultValue} = answer;
        let obj =  Object.assign({}, listAnswers);
        let obj2 = {}
        listAnswers.forEach((element, index) => {
        if (index === id) {
                element.id = id;
                element.caption = caption;
                element.defaultValue = defaultValue;
                obj2 = {
                    id: element.id,
                    caption: element.caption,
                    defaultValue: defaultValue
                }
                obj[index] = obj2
            } else {
                if(defaultValue !== false ) {
                    obj2 = {
                        id: element.id,
                        caption: element.caption,
                        defaultValue: false
                    }
                    obj[index] = obj2
                } 
            }            
        });
        let obj3 = Object.assign(listAnswers, obj)    
        setStringTest(JSON.stringify(obj3));
        setListAnswers(obj3);
        
    }

    function handleInputMessage(input_type) {
        switch(input_type) {
            case 'text':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputText");
            case 'date':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputDate");
            case 'single_choice':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputSingleChoice");
            case 'multi_choice':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputMultiChoice");
            case 'image':
                return <div className="input-field__tip-message__wicon"><span className="hubicon-info"/>{props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputImage")}</div>;
            case 'file':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputFile");
            case 'boolean':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputBoolean");
            case 'list':
                return props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputHelperMessage.inputList");
            default:
                return ''
        }
    }

    return (
        <div className="dynamic-form-question__element">
            <div className="dynamic-form-question__question">
                <InputFieldComponent
                    //label={}
                    input_id={"question_" + props.question.id}
                    field_name={"question_" + props.question.id}
                    validationFunction={RequiredValidator}
                    //className={}
                    input_type={"text"}
                    resetError={() => { }}
                    error={props.errors}
                    
                    placeholder={props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.inputPlaceholder")}
                    message={handleInputMessage(props.question.input_type)}
                    value={captionField}
                    onChange={(e) => { setCaptionField(e)} }
                />
                <SelectFieldCleanComponent
                    field_name={"input_type_" + props.question.id}
                    input_id={"input_type_" + props.question.id}
                    validationFunction={RequiredValidator}
                    className=""
                    resetError={() => { }}
                    error={props.errors}
                    onChange={(e) => setInputType(e) }
                    message=""
                    placeholder=""
                    options={inputTypeOptions}
                    value={checkedValueSelectField(props.question.id, 'input_type')}
                />
            </div>
            <div className="dynamic-form-question__answers">
            {optionsAnswers && 
                <div className={'dynamic-form-question__list_answers'}  >
                    {listAnswers?.map( (answer, index) =>                        
                        <TournamentDynamicAnswers
                            key={index}
                            answer={answer}
                            index={index}
                            idQuestion={props.question.id}
                            errors={props.errors}
                            buildAnswer={(answer) => {buildAnswer(answer)}}
                            input_type={props.question.input_type}
                            
                        />
                        )}
                
                    <div
                        className="tournament-dynamic-form-question__add-btn"
                        onClick={() => addNewAnswer()}>
                            <span className="hubicon-add_option" />
                            <div className="baloa-table-column">{props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.addOptionBtn")}</div>
                    </div>
                
                </div>
            }
            </div>
            { props.question.input_type === 'boolean' &&
                <div className="tournament-dynamic-form-question__boolean-type__container">
                    <div className="tournament-dynamic-form-question__boolean-type">
                        <span />
                        <div className="baloa-username">{props.t('tournament:registerPlayer.viewForm.trueAnswer')}</div>
                    </div>
                    <div className="tournament-dynamic-form-question__boolean-type">
                        <span />
                        <div className="baloa-username">{props.t('tournament:registerPlayer.viewForm.falseAnswer')}</div>
                    </div>
                    
                </div>

            }
            <div className="dynamic-form-question__options">
                <label class="dynamic-form-question__options_switch">
                    {props.t("options.tournaments.pages.newTournament.teamPlayerDynamicForm.requiredLabel")}
                    <Switch id={props.question.id} key={props.question.id}
                        checked={checkedValueSelectField(props.question.id, 'is_required')}
                        onChange={handleRequiredChange}
                        name={"isrequired_" + props.question.id}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        color="secondary"
                        
                    />
                </label>
                {props.question.id !== 0 ? 
                    <div 
                        class="dynamic-form-question__remove-element"
                        onClick={() => props.removeElement(props.question.id) }
                    >
                        <span className="hubicon-delete_question" />
                        {/* <div className="baloa-table">{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.deleteLabel')}</div> */}
                        
                    </div>
                    : <div></div>
                }
            </div>
        </div>
    );
}

export default withTranslation(["league", "tournament"])(
    TournamentDynamicFormQuestionComponent
);
