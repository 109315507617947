// React
import React from 'react';

//Styles
import './BaloaPlayCreateClipModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlayCreateClipModalComponent ( props ){

    const formInitialValues = {clip_title: ''}
    const [clipTitle, setClipTitle] = React.useState('');
    return(
        <Modal2Component
            isOpen={props.isOpen}
            //onClose={props.onClose}
            title={props.t('createClipBtnLbl')}
            className="baloa-play-create-clip-modal"
        >
            <div className='baloa-play-create-clip-modal__container'>
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions)=>{
            
                    }}
                    className="baloa-play-create-clip-modal__form"
                    autocomplete="off"
                >
                    <InputFieldComponent
                        field_name="clip_title"
                        input_id="clip_title"
                        label={props.t('createClipModal.inputTitle')}
                        className=""
                        disabled={false}
                        input_type="text"   
                        resetError={()=>{}}
                        error ={{'clip_title':''}}
                        message=""
                        placeholder={props.t('createClipModal.inputPlaceholder')}
                        value={clipTitle}
                        onChange={(value) => {
                            setClipTitle(value);
                        }}
                    />
                    <div className='baloa-play-create-clip-modal__form__info-container'>
                        <span className='hubicon-info'/>
                        <li className='baloa-table'>{props.t('createClipModal.conditions1',{_clipsAvailable: props.clips_available_to_claim})}</li>
                        <li className='baloa-table'>{props.t('createClipModal.conditions2')}</li>
                        <li className='baloa-table'>{props.t('createClipModal.conditions3')}</li>                        
                    </div>
                </FormComponent>
                <div className='baloa-play-create-clip-modal__buttons'>
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.onClose()}}
                    >
                        <span>{props.t('createClipModal.cancelBtnLbl')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{ props.handleOnCreateClip(clipTitle) }}
                        disabled={clipTitle === ''}
                    >
                        <span>{props.t('createClipBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('baloaplay')(BaloaPlayCreateClipModalComponent);
