/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

// Actions
import { deleteAttribute } from 'app/generalattributes/actions/AttributeActions';
import {
    loadPage,
    loadPagePosts,
    createOrEditAttributeOnPage,
    followPage,
    unfollowPage,
    loadPageFollowers,
    followPageByPagename,
    unfollowPageByPagename,
    followProfileByPagename,
    unfollowProfileByPagename,
    addAdminToPage,
    addMemberToPage,
    loadPageMembers,
    searchMembersPage,
    removeMemberPage,
    removeAdminPage,
    addPageNationality,
    blockPage,
    followPlayerTeam,
    unFollowPlayerTeam,
    loadPageInscriptionTeams,
} from 'app/pages/actions/PageActions';
import { 
    loadLeagueTournaments, 
    loadTournamentPhases, 
    loadPhaseResultsTable, 
    loadTournamentPhaseGroupDates, 
    loadTournamentTeams, 
    loadTournamentPosts, 
    loadPhaseMatchesByDate, 
    loadPhaseDates,
    loadfilterTournament,
    loadTournamentTeamPlayers,
    loadTournamentMembersTeams,
    loadTournamentStaff,
    assignRefereesMatch,
    loadRefeeringPaymentUrl,
    makeRefereePayment,
    loadTeamsToMatchAssistanceAligment,
    updatePlayersAssistanceAligment,
} from 'app/leagues/actions/LeagueTournamentActions'
import {
    clapPost,
    deletePost,
    loadPostClapsById,
    commentPostById,
    reportPostById,
    sendReaction,
    overrideReaction,
    loadPostReactionsById,
    loadPostReactionsNext,
    loadPostReactionsByReactionType,
    deleteReaction,
    followPostProfile,
    unfollowPostProfile,
    followPostPage,
    unfollowPostPage,
    answerQuiz,
    answerPoll,
    uploadPost,
} from 'app/posts/actions/PostActions';
import {
    changePageExperiencesModalStateAction,
    changePageUploadPhotoModalStateAction,
    changePageDescriptionModalStateAction,
    loadPageErrorsAction,
    changePageFollowersModalStateAction,
    changePageAddMemberToPageModalStateAction,
    changePageAddAdminToPageModalStateAction,
    changeTeamMemberDetailModalStateAction,
    changeTeamMembersModalStateAction,
    changeAdminResponsiveModalStateAction,
    changeAdminResponsiveLockModalStateAction,
    changeSelectTeamInscriptionModalStateAction,
} from 'app/pages/actions/PageActionsCreators';
import {
    changePostClapsModalStateAction,
    changeReportPostModalStateAction,
    changeBlockPostModalStateAction,
    changeAcceptReportPostModalStateAction,
    changePostReactionsModalStateAction,
    changeMatchDetailModalStateAction,
    changeQuotePostModalStateAction,
    postToQuoteAction,
    changePostIsFollowingStateAction,
    changePostIsFollowingStateFromReactionTypeAction,
} from 'app/posts/actions/PostsActionsCreators';
import {
    changeProfileNationalityModalStateAction,
} from 'app/profiles/actions/ProfileActionsCreators';
import {
    searchProfileNationality,
    searchProfileLocations,
    switchProfileToPage,
    followProfile,
    blockProfile,
    unfollowProfile,
} from 'app/profiles/actions/ProfileActions';

import { makeMemberRequest, rejectMemberRequest, checkMemberRequest, leaveGroup, acceptMemberRequest, kickoutGroupMember } from 'app/pages/actions/PageGroupsActions';
import { changeCancelMemberRequestDialogModalStateAction, changeNewGroupPagePost } from 'app/pages/actions/PageGroupsActionsCreators';
import { loadPageTeams, getTeamPlayers, loadTeamData, addMemberWithRolToTeam } from 'app/leagues/actions/LeagueTeamsActions';
import { changeHomeNewPostModal, changeHomeEditPostModal, changeErrorMessageSnackBar, changeMobileMenuStateAction } from 'app/home/actions/HomeActionsCreators';
import { addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';
import { loadHomePageSuggestions, /*loadGroupsSuggestions,*/ } from 'app/home/actions/HomeActions';
import { loadMatchReport } from 'app/leagues/actions/LeagueMatchActions';

import { changeShareIframeLinkStateAction, changeShareInscriptionLinkModalStateAction } from 'app/tournaments/actions/TournamentActionsCreators';
// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

// Styles
import './PageView.styles.css';

// Components
import PageFeedComponent from 'app/pages/components/page-feed/PageFeedComponent';
import PageInformationComponent from 'app/pages/components/page-information/PageInformationComponent';
import PageSuggestionsComponent from 'app/pages/components/page-suggestions/PageSuggestionsComponent';
// import ProfileCompletionComponent from 'app/profiles/components/profile-completion/ProfileCompletionComponent';
import ProfileDescriptionModalComponent from 'app/profiles/components/profile-description-modal/ProfileDescriptionModalComponent';
import PageBasicInfoComponent from 'app/pages/components/page-basic-info/PageBasicInfoComponent';
// import ProfileSkillsComponent from 'app/profiles/components/profile-skills/ProfileSkillsComponent';
import ProfileExperiencesComponent from 'app/profiles/components/profile-experiences/ProfileExperiencesComponent';
import PageMembersComponent from 'app/pages/components/page-members/PageMembersComponent';
import PageAdminMembersComponent from 'app/pages/components/page-admin-members/PageAdminMembersComponent';
import PageAdminGroupsRequestComponent from 'app/pages/components/page-admin-groups-requests/PageAdminGroupsRequestComponent';

import LeagueTournamentsListComponent from 'app/leagues/components/league-tournaments-list-component/LeagueTournamentsListComponent';
// import LeagueEventsComponent from 'app/leagues/components/league-events-component/LeagueEventsComponent';
import LeagueTournamentReportComponent from 'app/leagues/components/league-tournament-report-component/LeagueTournamentReportComponent';

import SeparatorComponent from 'shared/components/separator/SeparatorComponent';
// import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
import LeagueTeamListComponent from 'app/leagues/components/league-team-list/LeagueTeamListComponent';

// Modals
import PageUploadPhotoModalComponent from 'app/pages/components/page-upload-photo-modal/PageUploadPhotoModalComponent';
import ProfileExperiencesModalComponent from 'app/profiles/components/profile-experiences-modal/ProfileExperiencesModalComponent';
import PageFollowersModalComponent from 'app/pages/components/page-followers-modal/PageFollowersModalComponent';
import LeagueAddMemberModalComponent from 'app/leagues/components/league-add-member-modal/LeagueAddMemberModalComponent';
import ProfileNationalityModalComponent from 'app/profiles/components/profile-nationality-modal/ProfileNationalityModalComponent';
import ModalConfirmDialogComponent from 'shared/components/modal-confirm-dialog/ModalConfirmDialogComponent';
import ReportModalComponent from 'app/posts/components/report-modal/ReportModalComponent';
import BlockModalComponent from 'app/posts/components/block-modal/BlockModalComponent';
import AcceptReportModalComponent from 'app/posts/components/acceptance-modal/AcceptReportModalComponent';
import ReactionsModalComponent from 'app/posts/components/reactions-modal/ReactionsModalComponent';
import MatchDetailModalComponent from 'app/posts/components/match-detail-modal/MatchDetailModalComponent';
import PageTeamDetailModalComponent from '../components/page-team-detail-modal/PageTeamDetailModalComponent';
import QuoteModalComponent from 'app/posts/components/quote-modal/QuoteModalComponent';
import TournamentTeamMembersModalComponent from 'app/leagues/components/tournament-team-members-modal/TournamentTeamMembersModalComponent';
import TournamentValidationResponsiveModalComponent from 'app/leagues/components/tournament-validationResposive-modal/TournamentValidationResponsiveModalComponent';
import ShareIframeLinkModalComponent from 'app/tournaments/components/share-iframe-link-modal/ShareIframeLinkModalComponent';
import SelectTeamInscriptionComponent from 'app/pages/components/select-team-inscription/SelectTeamInscriptionComponent';
import ShareInscriptionLinkModalComponent from 'app/tournaments/components/share-inscription-link-modal/ShareInscriptionLinkModalComponent';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import { compose } from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// React device detect
import { isMobile } from 'react-device-detect';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';


function TabPanel(props) {

    const { children, value, index, ...other } = props;

    // if ( props.profile.loading ) { { !this.props.state.page.page.id &&
    if (!props.page?.pagename) {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }} />
                    <Skeleton animation="wave" variant="rect" component="div" height={200} style={{ marginTop: 30 }} />
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }} />
                </React.Fragment>
            </div>
        );
    } else {
        return (            
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {/* Page Feed */}
                {index === 0 && (
                    <div className="page_tab_feed">
                        <PageFeedComponent
                            me_photo={props.me_photo}
                            handleFollow={(page) => console.log(page)}
                            handleClap={(post) => props.handleClap(post)}
                            handleOnCommentsClick={(post) => props.handleOnCommentsClick(post)}
                            handleOnComment={(post, comment, pagename, page_id) => props.handleOnComment(post.id, comment, pagename, page_id)}
                            handleOnLoadMorePosts={() => { }}
                            handleOnPageClick={() => { }}
                            handleOnMessageIconClick={(post) => props.handleOnMessageIconClick(post)}
                            handleShareMenuClick={(is_open) => console.log(is_open)}
                            handleOnCopyLinkClick={(post) => console.log(post)}
                            onCloseClapsModal={() => { props.onCloseClapsModal(true) }}
                            loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}
                            changeHomeNewPostModal={props.changeHomeNewPostModal}
                            changeHomeEditPostModal={props.changeHomeEditPostModal}
                            changeNewGroupPagePost={props.changeNewGroupPagePost}
                            //changeNewHomePostAction = {props.changeNewHomePostAction}
                            handleOnDeletePostClick={props.handleOnDeletePostClick}
                            handleOnAuthorClick={props.handleOnAuthorClick}
                            isOpen={props.isOpen}
                            profile={props.profile}
                            user={props.user}
                            is_group={props.page?.attributes.is_group}

                            menu_is_open={props.menu_is_open}
                            sendReaction={props.sendReaction}
                            overrideReaction={props.overrideReaction}
                            handleReactionsDetails={props.handleReactionsDetails}
                            loadPostReactionsById={props.loadPostReactionsById}
                            deleteReaction={props.deleteReaction}
                            followPostProfile = { props.followPostProfile }                            
                            unfollowPostProfile = { props.unfollowPostProfile }                            
                            followPostPage = { props.followPostPage }                            
                            unfollowPostPage = { props.unfollowPostPage }

                            handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                            handleOnClickPollAnswer={ props.handleOnClickPollAnswer }
                            handleOnQuoteIconClick={props.handleOnQuoteIconClick}
                        />
                    </div>
                )}
                {/* Page Tournaments */}
                {/* this.props.state.page.page.page_type === "league" */}
                {index === 1 && (
                    <React.Fragment>
                            <LeagueTournamentsListComponent
                                page_tournaments={props.page_tournaments ?? []}
                                page_events={props.page_events}
                                on_click_tournament_report={(selected_tournament) => props.on_click_tournament_report(selected_tournament)}
                                on_selected_report={props.on_selected_report}
                                tournament_tag ={ props.tournament_tag }
                                pagename={props.pagename}
                                errors={props.errors}
                                onResetError={props.onResetError}
                                handleFilterTournament={props.handleFilterTournament}
                                handleBackButton={props.handleBackButton}
                                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            />
                    </React.Fragment>
                )}

                {/* Page Teams */}
                {index === 2 && (
                    <LeagueTeamListComponent
                        page_events={props.page_events}
                        page_teams={props.pague_teams}
                        pagename={props.page?.pagename}
                        is_page_admin={ props.is_page_admin }
                        permissions_controller={ props.permissions_controller }
                        handleOnTeamInfoModal={ props.handleOnTeamInfoModal }
                        handleOnShareTeamPageInscription={ props.handleOnShareTeamPageInscription}
                        addTeam={props.addTeam}
                    />
                )}

                {/* Admins and members */}
                {index === 3 && (
                    <React.Fragment>
                        <div className="page-admin__content">
                            {props.page_type == "group" && props.is_private &&
                                <PageAdminGroupsRequestComponent
                                    className={"page-admin-members__container"}
                                    page_member_request={props.page_member_request}
                                    handleOnProfileClick={(profile) => props.handleOnProfileClick(profile)}
                                    onAcceptButtonClick={props.onAcceptButtonClick}
                                    onRejectButtonClick={props.onRejectButtonClick}
                                />
                            }

                            <PageAdminMembersComponent
                                className={''}
                                onNewButton={props.onNewAdminMemberButton}
                                page_admin_members={props.page_admin_members}
                                is_admin={props.is_admin}
                                handleOnProfileClick={(profile) => props.handleOnProfileClick(profile)}
                                deleteAdminMemberFromPage={(id_member) => props.deleteAdminMemberFromPage(id_member)}
                                kickoutGroupAdminMember={(id_member) => props.kickoutGroupMember(id_member)}
                                page_type={props.page_type}
                            />
                            <PageMembersComponent
                                className={''}
                                onNewButton={props.onNewMemberButton}
                                page_members={props.page_members}
                                is_admin={props.is_admin}
                                handleOnProfileClick={(profile) => props.handleOnProfileClick(profile)}
                                deleteMemberFromPage={(id_member) => props.deleteMemberFromPage(id_member)}
                                kickoutGroupMember={(id_member) => props.kickoutGroupMember(id_member)}
                                page_type={props.page_type}
                            />
                        </div>
                    </React.Fragment>

                )}

                {/* Page Data */}
                {index === 4 && (

                    <React.Fragment>

                        {/* Page Completion */}
                        {/* <ProfileCompletionComponent 
                            percentage={ 15 }
                            className="page__completion"
                            is_owner={ props.is_admin } 
                            message="de la información completada"
                        /> */}

                        {/* Page Basic Information */}
                        <PageBasicInfoComponent
                            className="page__basic-info"
                            page={props.page}
                            is_owner={props.page?.is_admin}
                            onNewButton={props.onNewBasicInfoButton}
                        //onNewButton={ () =>  { console.log("Add button") } }
                        />

                        {/* Page Skills */}
                        {/* { ( props.page?.is_admin ) && 
                            <ProfileSkillsComponent 
                                className="page__skills"
                                profile={ props.page }
                                is_owner={ props.page?.is_admin } 
                            />
                        } */}

                        {/* Page Experiences */}
                        {/* {(props.page?.is_admin || props.page?.attributes.experiences.length > 0) && */}
                        {/* <ProfileExperiencesComponent
                            className="page__experiences"
                            profile={props.page}
                            is_owner={props.page?.is_admin}
                            onNewButton={props.onNewExperiencesButton}
                        /> */}
                        {/* } */}
                    </React.Fragment>
                )}

                {/* Page Events
                {index === 5 && (
                    <React.Fragment>
                        {props.page_events.length > 0 &&
                            <LeagueEventsComponent
                                page_events={props.page_events}
                            />
                        }
                    </React.Fragment>

                )}
                */}
            </div>
        );
    }

}


class PageView extends Component {

    constructor(props) {
        super(props);
        this.pagename = this.props.match.params.pagename;
        this.showtournament = this.props.match.params.showtournament? this.props.match.params.showtournament : false ; 
        this.setshowtournament = this.props.match.params.setshowtournament? this.props.match.params.setshowtournament : undefined ;
        this.showteam = this.props.match.params.showteam? this.props.match.params.showteam : false ; 
        this.setshowteam = this.props.match.params.setshowteam? this.props.match.params.setshowteam : undefined ;
        this.state = {
            tab_selected: 0,
            tab_group_selected: 0,
            show_report_tournament: false,
            selected_tournament: [],
            member_request_to_cancel: undefined,
            check_memeber_request_intent: 0,
            show_report_team: false,
            active_phase_id: '',
            phase_matches_by_date: [],
            match_detail: {},
            groups_matches_by_date: [],
            tournament_tag: [
                { value: 'Free', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            modal_selected_team:{},
            is_best_third: false,
            share_iframe_url : undefined,
        }
    }

    componentDidMount() {
        this.props.loadPage(this.pagename);
        this.props.loadPagePosts(this.pagename);
        this.props.loadPageFollowers(this.pagename);
        this.props.loadPageMembers(this.pagename);
        this.props.loadLeagueTournaments(this.pagename);
        this.props.loadPageTeams(this.pagename);
        this.props.loadHomePageSuggestions();

        // if(this.props.state.page.page.is_admin){
        //     this.props.switchProfileToPage( this.pagename );
        // }

        /*this.interval = setInterval(() => {
            this.props.checkMemberRequest( this.pagename );
          }, 60000);*/
        this.props.changeMobileMenuStateAction(false);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    componentDidUpdate(prevProps) {

        if (this.props.match.params.pagename !== prevProps.match.params.pagename) {

            this.pagename = this.props.match.params.pagename;

            this.props.loadPage(this.pagename);
            this.props.loadPagePosts(this.pagename);
            this.props.loadPageFollowers(this.pagename);
            this.props.loadPageMembers(this.pagename);

            // if(this.props.state.page.page.is_admin){
            //     this.props.switchProfileToPage( this.pagename );  
            // }

            //if(this.props.state.page.page.page_type === "league"){
            this.props.loadLeagueTournaments(this.pagename);
            this.props.loadPageTeams(this.pagename);
            this.setState({ tab_selected: 0 })
            //}

        }

        if(isMobile &&( prevProps.state.page.page.is_admin !== this.props.state.page.page.is_admin )){
            this.checkAdministratorValidation()
        }

        if (this.props.state.page.page.is_admin && this.props.state.page.page.page_type == "group") {

            if (this.props.state.pageGroups.member_request === undefined && this.state.check_memeber_request_intent == 0) {
                this.props.checkMemberRequest(this.pagename);
                this.setState({ check_memeber_request_intent: 1 });
                this.interval = setInterval(() => {
                    this.props.checkMemberRequest(this.pagename);
                }, 60000);
            }



        }

        if (this.props.state.page.page.is_admin && (this.props.state.profile.page_active == undefined || this.props.state.page.page != prevProps.state.page.page)) {

            //this.props.switchProfileToPage(this.props.state.page.page); Se comenta linea para que no traiga Baloa-Page en la consulta de un equipo en un torneo

        }
        if (prevProps.state.page.page?.pagename !== this.props.state.page.page?.pagename) {
            this.props.addEventAction({ Datetime: new Date().toISOString(), EventData: { EventType: 'page-visit', PageId: this.props.state.page.page.id } });  //set Clickstream profile-visit event            
        }

        if(this.showtournament && this.setshowtournament && this.props.state.leagueTournaments.league_tournaments && (prevProps.state.leagueTournaments.league_tournaments !== this.props.state.leagueTournaments.league_tournaments)){
            let filtered_tournament =this.props.state.leagueTournaments.league_tournaments?.filter( tournament => tournament.id == this.setshowtournament);
            this.handleOnTournamentSelected(filtered_tournament[0]);
            this.setState({ show_report_tournament: true });
            
        }
        if(this.props.match.params.showtournament === 'false' && this.props.match.params.setshowtournament && this.props.state.leagueTournaments.league_tournaments && (prevProps.match.params.setshowtournament !== this.props.match.params.setshowtournament)  ){
            let filtered_tournament =this.props.state.leagueTournaments.league_tournaments?.filter( tournament => tournament.id == this.props.match.params.setshowtournament);
            this.handleOnTournamentSelected(filtered_tournament[0]);
            this.setState({ show_report_tournament: true });
        }
        if(this.props.match.params.showteam && this.props.match.params.setshowteam && !this.props.state.page.modals?.team_member_detail_is_open){
            this.handleOnTeamInfoModal({teamname: this.props.match.params.setshowteam});
        }
        if( this.props.match.params && this.props.match.params.type === 'referee' && this.props.state.leagueTournaments.league_tournaments && (prevProps.state.leagueTournaments.league_tournaments !== this.props.state.leagueTournaments.league_tournaments)  ){
            let filtered_tournament =this.props.state.leagueTournaments.league_tournaments?.filter( tournament => tournament.id == this.props.match.params.tournamentid);
            this.handleOnTournamentSelected(filtered_tournament[0]);
            this.setState({ show_report_tournament: true });
        }
        /** Validamos si es el link de unirse a un equipo de pagina */
        let team_inscription = this.props.location.search.split('?teams_inscription=');
        if(team_inscription.length > 1 && !this.props.state.page.modals.select_team_inscription_is_open){
            this.props.changeSelectTeamInscriptionModalStateAction(true);
            this.props.loadPageInscriptionTeams(this.pagename);
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: '',
            }); 
        }
    }


    handleOnProfileClick = (profile) => {
        this.props.history.push(`/profile/${profile.user?.username ? profile.user.username : profile.username ? profile.username : profile}`);
    }

    handleOnPageClick = (page, teampagename) => {
        if (page.pagename) {
            this.props.history.push(`/page/${page.pagename}`);
            this.props.changePageFollowersModalStateAction(false);
            this.props.loadPage(page.pagename);
            this.props.loadPagePosts(page.pagename);
            this.props.loadPageFollowers(page.pagename);
            // this.props.loadPageFollowing( page.pagename );
        }
        else if (page && teampagename) {
            //this.props.history.push("/league/team/" + page + "&setshowteam=" + teampagename + "&showteam=true");
            this.props.getTeamPlayers(teampagename);
            this.props.loadTeamData(teampagename);
            this.props.changeTeamMemberDetailModalStateAction(true);
        }
        else {
            this.props.history.push(`/page/${page}`);
            this.props.changePageFollowersModalStateAction(false);
            this.props.loadPage(page);
            this.props.loadPagePosts(page);
            this.props.loadPageFollowers(page);
        }

    }

    handleOnAuthorClickV2 = (author) => {
        this.props.history.push(`/${author.type_object}/${author.identifier}`);
        if (author.type_object == "page") {
            this.props.changePageFollowersModalStateAction(false);
            this.props.loadPage(author.identifier);
            this.props.loadPagePosts(author.identifier);
            this.props.loadPageFollowers(author.identifier);
        }
    }

    handleOnDeleteAdmin = (member) => {
        this.props.removeAdminOfPage(this.pagename, member.username);
    }

    handleOnDeleteMember = (member) => {
        this.props.removeMemberOfPage(this.pagename, member.username);
    }

    handleOnLoadMorePosts = () => {
        // if(this.props.state.home.posts_next_page !== null){
        //     this.props.loadHomePosts( this.props.state.home.loading_posts, this.props.state.home.posts_next_page );
        // }
    }

    handleOnMessageIconClick = (post) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCommentsClick = (post) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnTournamentClicked = (is_tournament_clicked) => {

        if (is_tournament_clicked === 'true') {
            this.setState({ show_report_tournament: true });
        } else {
            this.props.history.push(`/page/${this.pagename}`);
            this.showtournament = false;
            this.setshowtournament = undefined;
            this.setState({ show_report_tournament: false });
        }

    }

    handleOnTeamClicked = (is_team_clicked) => {

        if (is_team_clicked === 'true') {
            this.setState({ show_report_team: true });
        } else {
            this.setState({ show_report_team: false });
        }

    }

    handleOnPhaseClick = async (phase) => {
        if(phase.best_third){
            this.setState({is_best_third: true});
        } else {
            this.setState({is_best_third: false});
        }
        this.props.loadPhaseResultsTable(phase?.id);
        this.setState({ active_phase_id: phase.id });
        await this.props.loadPhaseDates(phase?.id)
        //await this.props.loadPhaseMatchesByDate(phase?.id, this.props.state.leagueTournaments.tournament_results.tournament_dates?.tournament_dates[0].calendar_date);
        this.handleGroupsMatchByDate();
    }

    handleOnPhaseDate = async (date) => {
        await this.props.loadPhaseMatchesByDate(this.state.active_phase_id, date);
        this.handleGroupsMatchByDate();
    }

    handleOnTournamentDates = (group_id) => {
        this.props.loadTournamentPhaseGroupDates(group_id)
    }

    handleOnTournamentSelected = (tournament) => {     
        /* Se cambia redireccion al hub de torneos 
        this.props.loadTournamentStaff(tournament.id);
        this.setState({ selected_tournament: tournament });
        this.props.loadTournamentPhases(tournament.id);
        this.props.loadTournamentTeams(tournament.id);
        this.props.loadTournamentPosts(tournament.league_page, tournament.id.replaceAll('-', '')); */

        this.props.history.push(`/tournament/hub/${tournament.tournament_id ? tournament.tournament_id: tournament.id}`);
    }

    handleOnTeamSelected = (team) => {
        this.setState({ selected_team: team });
        this.props.loadPageTeams(team.id);

    }

    handleOnClickCancelRequest = (member_request_id) => {
        this.setState({ member_request_to_cancel: member_request_id });
        this.props.changeCancelMemberRequestDialogModalStateAction(true);
    }

    handleMatchStatistics = (match_id) => {
        this.props.loadMatchReport(match_id);
    }

    handleOnTeamInfoModal = (team) => {
        this.props.getTeamPlayers(team.teamname);
        this.props.loadTeamData(team.teamname);
        this.props.changeTeamMemberDetailModalStateAction(true);
    }
    
    handleOnMatchDetailModal = () => {
        this.props.changeMatchDetailModalStateAction(true);
    }

    handleGroupsMatchByDate = () => {
        let groups_by_date = [];
        for(let i = 0; i < this.props.state.leagueTournaments.tournament_results.selected_date_matches.length; i++ ) {
            groups_by_date.push(this.props.state.leagueTournaments.tournament_results.selected_date_matches[i].group_match);            
        }
        let filtered_groups = [...new Set(groups_by_date)]
        this.setState({groups_matches_by_date: filtered_groups})
    }

    handleMatchPhaseError = () => {
        this.props.changeErrorMessageSnackBar( {
            message: `${this.props.t('groups.snackBarMessages.matchPhaseError')}`,
            error_message_is_open: true
        } );
    }

    handleQuoteClick = ( post ) => {
        this.props.changeQuotePostModalStateAction( true );
        this.props.postToQuoteAction( post );
    }

    handleFilterTournament=(filter)=>{
        this.props.loadfilterTournament(filter.league_pagename, filter.type_filter);
    }

    handleCleanFilterTournament=()=>{
        this.props.loadLeagueTournaments(this.pagename);
    }

    handleOnClosePageTeamModal=()=>{
        this.props.history.push(`/page/${this.pagename}`);
        this.showteam= false;
        this.setshowteam= undefined;
        this.props.changeTeamMemberDetailModalStateAction(false);
    }

    handleTeamMembersModal = (team) => {
        this.setState({modal_selected_team: team});
        this.props.loadTournamentMembersTeams(team.id);
        this.props.changeTeamMembersModalStateAction(true);
    }

    handleValidationResponsiveModalState(state){
        this.props.changeAdminResponsiveModalStateAction(state);
    };

    checkAdministratorValidation=()=>{
        if(this.props.state.page.page.is_admin && !this.props.state.page.modals?.admin_lock_modal){
            this.props.changeAdminResponsiveModalStateAction(true);
        }
    };

    handleBlockValidationModalState=(block_state)=>{
        this.props.changeAdminResponsiveLockModalStateAction(block_state);
    };

    handleOpenShareIframeLinkModal(tournament_id, iframe){
        this.props.changeShareIframeLinkStateAction(true);
        let codeIframe = "";
        if(iframe === 'results_table'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/team-positions-table/${tournament_id}/">
            </iframe>`
        }else if(iframe === 'matches'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/tournament-matches/${tournament_id}/">
            </iframe>`
        }
        
        this.setState( {share_iframe_url: codeIframe } );
    };

    handleOnShareIFramePositionTableLink = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_iframe_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnLoadRefereeingPaymentUrl = async (payment_data, match_id, match_date_id) => {
        payment_data.redirect = `/page/${this.props.state.page.page?.pagename}?tournamentid=${this.state.selected_tournament?.id}?phase=${this.state.active_phase_id}?match_date=${match_date_id}?match=${match_id}?type=referee`;
        await this.props.loadRefeeringPaymentUrl(payment_data, match_id)
        if(this.props.state.leagueTournaments?.refereeing_payment_url){
            const newWindow = window.open(this.props.state.leagueTournaments.refereeing_payment_url.url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    handleOnShareMatchLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/match/${this.props.state.leagueMatches.match_report.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnSharePageButtonClick = async () => {
        try {
            const url = `${web_url}/api/social/pages/${this.pagename}`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnTeamButtonClick(pagename, teamname, isplayer){
        if(!isplayer){
            this.props.addMemberWithRolToTeam(teamname, { username: this.props.state.user.me.username, role:{ role: 'player', position: null, player_number: null}});
        }
        this.props.changeSelectTeamInscriptionModalStateAction(false);
        this.props.history.push(`/page/${pagename}&setshowteam=${teamname}&showteam=true`)
    }

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareTeamPageInscription(pagename){
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/pages/${pagename}/teams/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnShareInscriptionTournamentLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_inscription_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    render() {

        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: ${this.props.state.page.page.name} (${this.pagename})`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${this.props.state.page.page.name} (@${this.pagename})`} />
                    <meta property="og:description" content={`${this.props.state.page?.page?.attributes?.description?.value}`} />
                    <meta property="og:image" content={this.props.state.page.page?.photo ? this.props.state.page.page.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta property="og:url" content={`${web_url}/page/${this.pagename}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${this.props.state.page.page.name} (@${this.pagename})`} />
                    <meta name="twitter:description" content={`${this.props.state.page?.page?.attributes?.description?.value}`} />
                    <meta name="twitter:image" content={this.props.state.page.page?.photo ? this.props.state.page.page.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta name="twitter:url" content={`${web_url}/page/${this.pagename}`} />
                </Helmet>

                {/* Page Modals */}

                {/* Page Photo Modal */}
                <PageUploadPhotoModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.upload_photo_is_open}
                    onClose={() => { this.props.changePageUploadPhotoModalStateAction(false) }}
                />

                {/* Page Description Modal */}
                <ProfileDescriptionModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.description_is_open}
                    onClose={() => { this.props.changePageDescriptionModalStateAction(false) }}
                    errors={this.props.state.page.errors.general}
                    model={this.props.state.page.page}
                    onNewData={
                        (new_attribute_data) => {
                            this.props.createOrEditAttributeOnPage(this.props.state.page.page, new_attribute_data);
                            this.props.changePageDescriptionModalStateAction(false)
                        }
                    }
                    onResetError={(new_errors) => this.props.loadPageErrorsAction(new_errors, 'general')}
                />

                {/* Page Experiences Modal */}
                <ProfileExperiencesModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.experiences_is_open}
                    onClose={() => { this.props.changePageExperiencesModalStateAction(false) }}
                    errors={this.props.state.page.errors.experiences}
                    experience={null}
                    onNewData={(new_attribute_data) => this.props.createOrEditAttributeOnPage(this.props.state.page.page, new_attribute_data)}
                    onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.experiences, ...new_errors }, 'experiences')}
                    is_profile={false}
                />

                {/* Page followers Modal */}
                <PageFollowersModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.followers_is_open}
                    onClose={() => { this.props.changePageFollowersModalStateAction(false) }}
                    followers={this.props.state.page.followers}
                    onFollowProfile={(profile_username, pagename) => { this.props.followProfileByPagename(profile_username, pagename) }}
                    current_username={this.pagename}
                    onUnfollowProfile={(profile_username, pagename) => { this.props.unfollowProfileByPagename(profile_username, pagename) }}
                    onFollowPage={(page_pagename, pagename) => { this.props.followPageByPagename(page_pagename, pagename) }}
                    onUnfollowPage={(page_pagename, pagename) => { this.props.unfollowPageByPagename(page_pagename, pagename) }}
                    handleOnProfileClick={(profile) => this.handleOnProfileClick(profile)}
                    handleOnPageClick={(page) => this.handleOnPageClick(page)}
                />

                {/* Page Add Admin Member */}
                <LeagueAddMemberModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.add_to_page_admin_is_open}
                    onClose={() => { this.props.changePageAddAdminToPageModalStateAction(false) }}
                    title={this.props.t('tabs.members.followers.new.title1')}
                    profiles={this.props.state.page?.searched_members}
                    handleSearchMember={(member_username) => this.props.searchMembersPage(member_username)}
                    onNewData={(new_member) => {
                        this.props.changePageAddAdminToPageModalStateAction(false)
                        this.props.addAdminToPage(this.pagename, new_member.username)
                    }}
                />

                {/* Page Add Member */}
                <LeagueAddMemberModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.add_to_page_member_is_open}
                    onClose={() => { this.props.changePageAddMemberToPageModalStateAction(false) }}
                    title={this.props.t('tabs.members.followers.new.title2')}
                    profiles={this.props.state.page?.searched_members}
                    handleSearchMember={(member_username) => this.props.searchMembersPage(member_username)}
                    onNewData={(new_member) => {
                        this.props.changePageAddMemberToPageModalStateAction(false)
                        this.props.addMemberToPage(this.pagename, new_member.username)
                    }}
                />

                {/* Profile Nationality Modal */}
                <ProfileNationalityModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.profile.modals?.nationality_is_open}
                    onClose={() => { this.props.changeProfileNationalityModalStateAction(false) }}
                    handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality(new_nationality_search) }}
                    handleSearchLocation={(nationality, new_location_search) => { this.props.searchProfileLocations(nationality, new_location_search) }}
                    model={this.props.state.profile.profile}
                    nationalities={this.props.state.profile.nationalities_search_results}
                    locations={this.props.state.profile.locations_search_results}
                    onNewData={(new_nationality_data) => {
                        this.props.changeProfileNationalityModalStateAction(false)
                        this.props.addPageNationality(this.props.state.page.page, new_nationality_data)

                    }}
                />

                {/* Confirm Dialog Cancel member request */}
                <ModalConfirmDialogComponent
                    onPrincipalButtonClick={() => { this.props.changeCancelMemberRequestDialogModalStateAction(false) }}
                    principalButtonLabel={this.props.t('groups.modalButtons.back')}
                    secondaryButton={true}
                    onSecondaryButtonClick={() => {
                        this.props.rejectMemberRequest(this.state.member_request_to_cancel, this.props.state.user.me.username, this.pagename, this.props.state.page?.page?.is_admin);
                        this.props.changeCancelMemberRequestDialogModalStateAction(false);
                    }}
                    secondaryButtonLabel={this.props.t('groups.modalButtons.cancelRequest')}
                    isOpen={this.props.state.pageGroups.modals.reject_member_request_dialog_is_open}
                    onClose={() => { this.props.changeCancelMemberRequestDialogModalStateAction(false) }}
                    modalTitle={this.props.t('groups.modalLabels.cancelRequestTitle')}
                    modalContent={this.props.t('groups.modalLabels.cancelRequestText')}

                />

                {/* Reactions Post Modal */}
                <ReactionsModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.post.modals?.reactions_is_open}
                    onClose={() => { this.props.changePostReactionsModalStateAction(false) }}
                    reactions={this.props.state.post.reactions}
                    page={this.props.state.profile.page_active}
                    user_me={this.props.state.user.me}
                    reactions_next_page={this.props.state.post.reactions_next_page}
                    followProfile={(profile) => { this.props.followProfile(profile) }}
                    unfollowProfile={(profile) => { this.props.unfollowProfile(profile) }}
                    changePostIsFollowing={(username) => { this.props.changePostIsFollowingStateAction(username) }}
                    changePostIsFollowingStateFromReactionTypeAction
                    loadPostReactionsNext={(next_page, reaction_type) => this.props.loadPostReactionsNext(next_page, reaction_type)}
                    loadPostReactionsByType={(reaction_type) => { this.props.loadPostReactionsByReactionType(this.props.state.post.post.id, reaction_type) }}
                    reactions_by_type={this.props.state.post.reactions_by_type}
                    changePostIsFollowingFromReactionType={(username, reaction_type) => {this.props.changePostIsFollowingStateFromReactionTypeAction(username, reaction_type)}}
                />

                {/* Report Post Modal */}
                <ReportModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.post.modals?.report_is_open}
                    onClose={() => { this.props.changeReportPostModalStateAction(false) }}
                    post_id={this.state.post_id}
                    reportPostById={(post_id, denunciation) => { this.props.reportPostById(post_id, denunciation) }}
                    openAcceptReport={() => this.props.changeAcceptReportPostModalStateAction(true)}
                />

                {/* Block Profile Modal */}
                <BlockModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.post.modals?.block_is_open}
                    onClose={() => { this.props.changeBlockPostModalStateAction(false) }}
                    profile_username={this.state.profile_username}
                    blockProfile={(username) => { this.props.blockProfile(username) }}
                    page_pagename={this.state.page_pagename}
                    blockPage={(pagename) => { this.props.blockPage(pagename) }}

                />

                {/* Accept Report Modal */}
                <AcceptReportModalComponent
                    onClick={() => { }}
                    isOpen={this.props.state.post.modals?.accept_report_is_open}
                    onClose={() => { this.props.changeAcceptReportPostModalStateAction(false) }}
                />

                {/* Team Detail Modal */}
                <PageTeamDetailModalComponent
                    isOpen={this.props.state.page.modals?.team_member_detail_is_open}
                    onClose={() => { this.handleOnClosePageTeamModal() }}
                    team= { this.props.state.teams.team }
                    tournaments = { this.props.state.teams.team.tournaments }
                    followProfile={(profile) => { this.props.followProfile(profile) }}
                    unfollowProfile={(profile) => { this.props.unfollowProfile(profile) }}
                    is_page_admin={this.props.state.page.page?.is_admin}
                    handleOnShareTeamPageInscription= { ()=>{ this.handleOnShareTeamPageInscription(this.props.state.page.page?.pagename)} }
                    handleOnTournamentSelected={ (tournament)=>{ this.handleOnTournamentSelected( tournament) }}
                />
                 
                <MatchDetailModalComponent 
                    isOpen={this.props.state.post.modals?.match_detail_is_open}
                    onClose={() => { this.props.changeMatchDetailModalStateAction(false) }}
                    match_detail = { this.props.state.leagueMatches.match_report }
                />

                {/* Repost Modal */}
                <QuoteModalComponent
                    isOpen={ this.props.state.post.modals?.quote_modal_is_open } 
                    onClose={() => { this.props.changeQuotePostModalStateAction( false ) } }
                    post={this.props.state.post.post_to_quote}
                    profile = { this.props.state.profile }
                    user = { this.props.state.user }
                    page = { this.props.state.page }
                    onNewData = {(data)=>{ this.props.uploadPost(data) }}

                    followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id ) } }                            
                    unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id ) } }                            
                    followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                    unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }  
                />
                
                {/* Tournament Team Members Modal */}
                <TournamentTeamMembersModalComponent 
                    isOpen={ this.props.state.page.modals?.team_members_is_open } 
                    onClose={() => { this.props.changeTeamMembersModalStateAction( false ) } }
                    selected_team={this.state.modal_selected_team}
                    team_members={this.props.state.leagueTournaments.tournament?.tournament_members_team_players}
                    followPlayerTeam={(player) => { this.props.followPlayerTeam(player) }}
                    unFollowPlayerTeam={(player) => { this.props.unFollowPlayerTeam(player) }}
                />

                <TournamentValidationResponsiveModalComponent 
                    isOpen={ this.props.state.page.modals?.admin_responsive_is_open }
                    onClose={() => { this.handleValidationResponsiveModalState( false ) } }
                    handleBlockModal={(state)=> this.handleBlockValidationModalState( state )}
                    shortcut_type={this.props.match.params.showtournament === "true"? "tournament": "page"}
                />

                <ShareIframeLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_iframe_link_modal_is_open}
                    onClose={() => {this.props.changeShareIframeLinkStateAction(false);}}
                    share_url={this.state.share_iframe_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareIFramePositionTableLink()}}
                />

                {/** Share player inscription to page teams */}
                <ShareInscriptionLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_incription_link_modal_is_open}
                    onClose={() => {this.props.changeShareInscriptionLinkModalStateAction(false)}}
                    share_url={this.state.share_inscription_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareInscriptionTournamentLinkClick()}}
                />

                <div className="page">

                    {!this.props.state.page.page.id &&

                        <div className={"page__center-container"}>
                            <div className="page__left-container__info">
                                <Skeleton animation="wave" variant="rect" component="div" className="profile-info__photo_skeleton" />
                                <Skeleton animation="wave" className="profile-info__fullname baloa-headline-5" />
                                <Skeleton animation="wave" className="profile-info__username baloa-subtitle-2" />
                                <SeparatorComponent className="profile-info__separator" />
                                <div className="profile-info__follows">
                                    <Skeleton animation="wave" width="40%" />&nbsp;&nbsp;&nbsp;
                                    <Skeleton animation="wave" width="40%" />
                                </div>
                            </div>
                        </div>

                    }

                    {this.props.state.page.modals.select_team_inscription_is_open && //Link de inscripcion a equipos de pagina
                        <div className={"page__center-container "} >
                            <SelectTeamInscriptionComponent
                                onClose={() => {this.props.changeSelectTeamInscriptionModalStateAction(false)}}
                                page={this.props.state.page.page_inscription_teams}
                                handleOnTeamButtonClick={ (pagename, teamname, isplayer)=>{ this.handleOnTeamButtonClick(pagename, teamname, isplayer) }}
                            />
                        </div>
                    }

                    {(this.props.state.page.page?.is_private == false && !this.props.state.page.modals.select_team_inscription_is_open) && (
                        <React.Fragment>
                            {this.state.show_report_tournament === false &&
                                <div className={"page__center-container "  + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin )) ? "adminview" :"" ) }>

                                    {/* Page Info */}
                                    { //(this.props.state.page.page.page_type === "league" && this.props.state.page.page.is_admin === true) && (
                                        (this.props.state.page.page.page_type != "group" && (this.props.state.page.page.is_admin === true || this.props.state.page.page.is_member === true)) && (
                                            <PageInformationComponent
                                                className="page__center-container__info-horizontal"
                                                orientation="vertical"
                                                page={this.props.state.page.page}
                                                is_admin={this.props.state.page.page.is_admin}
                                                onEditDescription={() => { this.props.changePageDescriptionModalStateAction(true) }}
                                                onFollow={(page) => { this.props.followPage(page) }}
                                                onUnfollow={(page) => { this.props.unfollowPage(page) }}
                                                onNewPhoto={() => this.props.changePageUploadPhotoModalStateAction(true)}
                                                onFollowers={() => this.props.changePageFollowersModalStateAction(true)}
                                                handleOnSharePageButtonClick={()=>{ this.handleOnSharePageButtonClick() } }
                                            />
                                        )}

                                    {(this.props.state.page.page.page_type != "group" && this.props.state.page.page.is_admin !== true && this.props.state.page.page.is_member !== true &&
                                        <PageInformationComponent
                                            className="page__center-container__info-horizontal"
                                            orientation="vertical"
                                            page={this.props.state.page.page}
                                            is_admin={this.props.state.page.page.is_admin}
                                            onEditDescription={() => { this.props.changePageDescriptionModalStateAction(true) }}
                                            onFollow={(page) => { this.props.followPage(page) }}
                                            onUnfollow={(page) => { this.props.unfollowPage(page) }}
                                            onNewPhoto={() => this.props.changePageUploadPhotoModalStateAction(true)}
                                            onFollowers={() => this.props.changePageFollowersModalStateAction(true)}
                                            handleOnSharePageButtonClick={()=>{ this.handleOnSharePageButtonClick() } }
                                        />
                                    )}

                                    {this.props.state.page.page.page_type == "group" &&
                                        <PageInformationComponent
                                        className="page__center-container__info-horizontal"
                                        orientation="vertical"
                                        page={this.props.state.page.page}
                                        is_admin={this.props.state.page.page.is_admin}
                                        onEditDescription={() => { this.props.changePageDescriptionModalStateAction(true) }}
                                        onClickMemberRequest={(pagename) => this.props.makeMemberRequest(pagename)}
                                        onClickCancelRequest={(member_request_id) => { this.handleOnClickCancelRequest(member_request_id) }}
                                        onClickLeaveGroup={(pagename) => this.props.leaveGroup(pagename, this.props.state.user.me.username)}
                                        onNewPhoto={() => this.props.changePageUploadPhotoModalStateAction(true)}
                                        onFollowers={() => this.props.changePageFollowersModalStateAction(true)}
                                        handleOnSharePageButtonClick={()=>{ this.handleOnSharePageButtonClick() } }
                                    />
                                    }

                                    <Tabs
                                        variant="fullWidth"
                                        className="page__center-container__tabs"
                                        value={this.state.tab_selected}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={(ev, new_value) => { this.setState({ tab_selected: new_value }) }}
                                        aria-label="Selection between "
                                    >
                                        {/* Con torneos */}
                                        {this.props.state.page.page?.tournaments_per_page_count > 0 && this.props.state.page.page.page_type !== "group" &&                                           
                                            <Tab label={this.props.t('tabs.tournaments')}/> /* Torneos id=1*/                                      
                                        }
                                        {this.props.state.page.page?.tournaments_per_page_count > 0 && this.props.state.page.page.page_type !== "group" &&
                                           <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/                                       
                                        }
                                        {/* Fin con torneos */}
                                        {/* Sin torneos */}
                                        {this.props.state.page.page?.tournaments_per_page_count === 0 && this.props.state.page.page.page_type !== "group" &&
                                                <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/
                                        }
                                        {this.props.state.page.page?.tournaments_per_page_count === 0 && this.props.state.page.page.page_type !== "group" &&
                                                <Tab label={this.props.t('tabs.tournaments')}/> /* Torneos id=1*/
                                        }
                                        {/* Fin sin torneos */}
                                        {/* Solo para grupos */}
                                        {this.props.state.page.page.page_type === "group" &&
                                            <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/                                        
                                        }
                                        {/* Fin solo para grupos */}
                                        {this.props.state.page.page.page_type !== "group" &&
                                            <Tab label={this.props.t('tabs.teamsTab')}/>
                                        } { /* Equipos id=2*/}
                                        <Tab label={this.props.t('tabs.memberTab')} /> {/*Miembros id=3*/}
                                        {this.props.state.page.page.page_type !== "group" &&
                                            <Tab label={this.props.t('tabs.dataTab')} />
                                        }{/*Informacion Basica id =4*/}

                                        {/* {this.props.state.page.page.page_type !== "group" &&
                                            <Tab label={this.props.t('tabs.events.title')}/>
                                        } Eventos id=5 */}

                                    </Tabs>

                                    {/* Page Feed */}
                                    <TabPanel
                                        index={0}
                                        value={this.props.state.page.page.page_type !== "group" ? (this.props.state.page.page?.tournaments_per_page_count > 0 ? (this.state.tab_selected -1) :this.state.tab_selected) :  (this.state.tab_selected )}
                                        page={this.props.state.page.page}
                                        handleOnMessageIconClick={(post) => this.handleOnMessageIconClick(post)}
                                        handleOnCommentsClick={(post) => this.handleOnCommentsClick(post)}
                                        handleClap={(post) => this.props.clapPost(post)}
                                        onCloseClapsModal={() => { this.props.changePostClapsModalStateAction(true) }}
                                        loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                                        handleOnComment={(post, comment, pagename, page_id) => this.props.commentPostById(post, comment, this.props.state.page.page.pagename, page_id)}
                                        me_photo={this.props.state.user.me.photo}
                                        page_events={this.props.state.page.posts}
                                        changeHomeNewPostModal={(new_modal_state) => this.props.changeHomeNewPostModal(new_modal_state)}
                                        changeHomeEditPostModal={(new_modal_state, post) => this.props.changeHomeEditPostModal(new_modal_state, post)}
                                        changeNewGroupPagePost={(new_modal_state) => this.props.changeNewGroupPagePost(new_modal_state)}
                                        handleOnDeletePostClick={(post) => this.props.deletePost(post)}
                                        handleOnAuthorClick={(author) => this.handleOnAuthorClickV2(author)}
                                        isOpen={this.props.state.home.modals.new_post_is_open}
                                        profile={this.props.state.profile}
                                        user={this.props.state.user}

                                        menu_is_open={true}
                                        sendReaction={(post_id, reaction) => { this.props.sendReaction(post_id, reaction, 'page', this.props.state.page.page.pagename) }}
                                        overrideReaction={(post_id, reaction, reaction_id) => { this.props.overrideReaction(post_id, reaction, reaction_id, 'page', this.props.state.page.page.pagename) }}
                                        handleReactionsDetails={(new_state) => { this.props.changePostReactionsModalStateAction(new_state) }}
                                        loadPostReactionsById={(post_id) => this.props.loadPostReactionsById(post_id)}
                                        deleteReaction={(post_id, reaction_id) => { this.props.deleteReaction(post_id, reaction_id, 'page', this.props.state.page.page.pagename) }}
                                        followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id ) } }                            
                                        unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id ) } }                            
                                        followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                                        unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }

                                        handleOnClickQuizAnswer={ (post_id, answer_id)=>{ this.props.answerQuiz(post_id, answer_id, 'page', this.props.state.page.page.pagename) } }
                                        handleOnClickPollAnswer={ (post_id, answer_id)=>{ this.props.answerPoll(post_id, answer_id, 'page', this.props.state.page.page.pagename) } }
                                        handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                                    />
                                    {/* Page Tournaments */}
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel
                                            index={1}
                                            value={this.props.state.page.page?.tournaments_per_page_count > 0 ? (this.state.tab_selected + 1) :this.state.tab_selected}
                                            page={this.props.state.page.page}
                                            page_tournaments={this.props.state.leagueTournaments.league_tournaments}
                                            on_click_tournament_report={(is_tournament_clicked) => { this.handleOnTournamentClicked(is_tournament_clicked) }}
                                            on_selected_report={(on_selected_report) => { this.handleOnTournamentSelected(on_selected_report) }}
                                            page_events={this.props.state.page.posts}
                                            tournament_tag={this.state.tournament_tag}
                                            pagename={this.pagename}
                                            errors={this.props.state.page.errors.list_tournament_filters}
                                            onResetError={(new_errors) => this.props.loadPageErrorsAction(new_errors, 'list_tournament_filters')}
                                            handleFilterTournament={(value)=> this.handleFilterTournament(value)}
                                            handleBackButton={()=> this.handleCleanFilterTournament()}
                                            handleOnShareTournamentLinkClick={(tournament)=>{this.handleOnShareTournamentLinkClick(tournament)}}        
                                            />
                                    }
                                    {/* Teams */}
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel
                                            index={2}
                                            value={this.state.tab_selected}
                                            page={this.props.state.page.page}
                                            pague_teams={this.props.state.teams.page_teams}
                                            is_page_admin={this.props.state.page.page?.is_admin}
                                            permissions_controller={ permissionsController(this.props.state.page.page.roles, ['TEAMAD']) }
                                            handleOnTeamInfoModal = { (team) => this.handleOnTeamInfoModal(team) }
                                            handleOnShareTeamPageInscription = { ()=>{ this.handleOnShareTeamPageInscription(this.props.state.page.page?.pagename)} }
                                            addTeam={ ()=>{ this.props.history.push(`/league/team/${this.props.state.page.page?.pagename}?new_team`) } }
                                        />
                                    }
                                    {/* Page Members */}
                                    <TabPanel
                                        index={3}
                                        value={this.props.state.page.page.page_type !== "group" ? this.state.tab_selected : (this.state.tab_selected + 2)}
                                        page={this.props.state.page.page}
                                        is_admin={this.props.state.page.page.is_admin}
                                        onNewAdminMemberButton={() => { this.props.changePageAddAdminToPageModalStateAction(true) }}
                                        onNewMemberButton={() => { this.props.changePageAddMemberToPageModalStateAction(true) }}
                                        page_admin_members={this.props.state.page.page.admins}
                                        page_members={this.props.state.page.page_members}
                                        handleOnProfileClick={(profile) => this.handleOnProfileClick(profile)}
                                        deleteAdminMemberFromPage={(member) => this.handleOnDeleteAdmin(member)}
                                        page_events={this.props.state.page.posts}
                                        deleteMemberFromPage={(member) => this.handleOnDeleteMember(member)}
                                        page_type={this.props.state.page.page.page_type}
                                        kickoutGroupMember={(username) => { this.props.kickoutGroupMember(username, this.pagename) }}
                                        is_private={this.props.state.page.page.is_private}
                                    />
                                    {/* Page Data */}
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel
                                            index={4}
                                            value={this.state.tab_selected}
                                            page={this.props.state.page.page}
                                            onNewExperiencesButton={() => { this.props.changePageExperiencesModalStateAction(true) }}
                                            onNewBasicInfoButton={() => { this.props.changeProfileNationalityModalStateAction(true) }}
                                            errors={this.props.state.page.errors}
                                            page_events={this.props.state.page.posts}
                                        />
                                    }
                                    {/* Page Events
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel
                                            index={5}
                                            value={this.state.tab_selected}
                                            page={this.props.state.page.page}
                                            page_events={this.props.state.page.posts}
                                        />
                                    } */}
                                </div>
                            }

                            {this.state.show_report_tournament &&
                                <div className={"page__center-container " + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin )) ? "adminview" :"" )}>
                                    <LeagueTournamentReportComponent
                                        selected_tournament={this.state.selected_tournament}
                                        handle_back_btn={(is_back_btn) => { this.handleOnTournamentClicked(is_back_btn) }}
                                        page_events={this.props.state.leagueTournaments.tournament.posts}
                                        tournament_phases={this.props.state.leagueTournaments.tournament.tournament_phases}
                                        tournament_active_phase={this.props.state.leagueTournaments.tournament.tournament_phases[0]}
                                        phase_groups_results_table={ this.props.state.leagueTournaments?.tournament?.phase_groups_results_table }
                                        tournament_results_table={this.props.state.leagueTournaments.tournament.phase_results_table}
                                        handleOnPhaseGroupPositionTable={ ()=>{ console.log('llamar api')} }
                                        handleOnPhaseClick={(id_phase) => { this.handleOnPhaseClick(id_phase) }}
                                        handleOnTournamentDates={(id_group) => { this.handleOnTournamentDates(id_group) }}
                                        tournament_teams={this.props.state.leagueTournaments.tournament.tournament_teams}
                                        handleOnProfileClick={(profile) => { this.handleOnProfileClick(profile) }}
                                        handleOnPageClick={(page, team) => {
                                            this.handleOnPageClick(page, team)
                                            this.handleOnTournamentClicked(false)
                                        }}
                                        handleMatchStatistics={(match_id) => this.handleMatchStatistics(match_id)}
                                        match_report={this.props.state.leagueMatches.match_report}
                                        phase_dates={this.props.state.leagueTournaments.tournament_results.tournament_dates}
                                        phase_matches_by_date={this.props.state.leagueTournaments.tournament_results?.selected_date_matches}
                                        handleOnPhaseDate = { (date) => this.handleOnPhaseDate(date)}
                                        handleOnMatchDetailModal = { (match) => this.handleOnMatchDetailModal(match) }
                                        groups_matches_by_date = {this.state.groups_matches_by_date}
                                        handleMatchPhaseError = { () => this.handleMatchPhaseError() }
                                        handleTeamMembersModal={(team)=>{this.handleTeamMembersModal(team)}}
                                        is_best_third={this.state.is_best_third}
                                        handleOpenShareIframeLinkModal={(tournament_id, iframe)=>this.handleOpenShareIframeLinkModal(tournament_id, iframe)}
                                        active_phase_id={this.state.active_phase_id}
                                        tournament_staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                                        handleUpdateRefereesMatch={(match_id, referees_match_data, match_date_id) => { this.props.assignRefereesMatch(match_id, referees_match_data, match_date_id) }}
                                        handleOnLoadRefereeingPaymentUrl={(payment_data, match_id, match_date_id) => { this.handleOnLoadRefereeingPaymentUrl(payment_data, match_id, match_date_id) }}
                                        referees_payment_response={this.props.match.params}
                                        handleOnAcceptCrewPayment={ (match_id) => {this.props.makeRefereePayment(match_id)} }
                                        handleOnShareMatchLinkClick={ ()=>{ this.handleOnShareMatchLinkClick() } }
                                        teams_match_assistance={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                                        updatePlayersAssistanceAligment={(playerArray)=> {
                                            this.props.updatePlayersAssistanceAligment(this.props.state.leagueTournaments.tournament.teams_match_assistance?.match_id, playerArray)
                                        }}
                                    />
                                </div>
                            }
                            {this.props.match.showtournament && isMobile &&
                                <div className={"page__center-container " + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin )) ? "adminview" :"" )}>
                                    <LeagueTournamentReportComponent
                                        selected_tournament={this.state.selected_tournament}
                                        handle_back_btn={(is_back_btn) => { this.handleOnTournamentClicked(is_back_btn) }}
                                        page_events={this.props.state.leagueTournaments.tournament.posts}
                                        tournament_phases={this.props.state.leagueTournaments.tournament.tournament_phases}
                                        tournament_active_phase={this.props.state.leagueTournaments.tournament.tournament_phases[0]}
                                        phase_groups_results_table={ this.props.state.leagueTournaments?.tournament?.phase_groups_results_table }
                                        tournament_results_table={this.props.state.leagueTournaments.tournament.phase_results_table}
                                        handleOnPhaseGroupPositionTable={ ()=>{ console.log('llamar api')} }
                                        handleOnPhaseClick={(id_phase) => { this.handleOnPhaseClick(id_phase) }}
                                        handleOnTournamentDates={(id_group) => { this.handleOnTournamentDates(id_group) }}
                                        tournament_teams={this.props.state.leagueTournaments.tournament.tournament_teams}
                                        handleOnProfileClick={(profile) => { this.handleOnProfileClick(profile) }}
                                        handleOnPageClick={(page, team) => {
                                            this.handleOnPageClick(page, team)
                                            this.handleOnTournamentClicked(false)
                                        }}
                                        handleMatchStatistics={(match_id) => this.handleMatchStatistics(match_id)}
                                        match_report={this.props.state.leagueMatches.match_report}
                                        phase_dates={this.props.state.leagueTournaments.tournament_results.tournament_dates}
                                        phase_matches_by_date={this.props.state.leagueTournaments.tournament_results?.selected_date_matches}
                                        handleOnPhaseDate = { (date) => this.handleOnPhaseDate(date)}
                                        handleOnMatchDetailModal = { (match) => this.handleOnMatchDetailModal(match) }
                                        groups_matches_by_date = {this.state.groups_matches_by_date}
                                        handleMatchPhaseError = { () => this.handleMatchPhaseError() }
                                        handleTeamMembersModal={(team)=>{this.handleTeamMembersModal(team)}}
                                        is_best_third={this.state.is_best_third}
                                        handleOpenShareIframeLinkModal={(tournament_id, iframe)=>this.handleOpenShareIframeLinkModal(tournament_id, iframe)}
                                        active_phase_id={this.state.active_phase_id}
                                        tournament_staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                                        handleUpdateRefereesMatch={(match_id, referees_match_data, match_date_id) => { this.props.assignRefereesMatch(match_id, referees_match_data, match_date_id) }}
                                        handleOnLoadRefereeingPaymentUrl={(payment_data, match_id, match_date_id) => { this.handleOnLoadRefereeingPaymentUrl(payment_data, match_id, match_date_id) }}
                                        referees_payment_response={this.props.match.params}
                                        handleOnAcceptCrewPayment={ (match_id) => {this.props.makeRefereePayment(match_id)} }
                                        handleOnShareMatchLinkClick={ ()=>{ this.handleOnShareMatchLinkClick() } }
                                    />
                                </div>
                            }
                        </React.Fragment>

                    )}

                    {(this.props.state.page.page?.is_private == true && this.props.state.page.page?.is_member == false && this.props.state.page.page?.is_admin == false) && (

                        <div className="page__center-container">
                            <div className="page__private-message">
                                <span className="page__private-message__icon icon-lock-open"></span>
                                <p className="page__private-message__title baloa-headline-6">{this.props.t('follower.request.title')}</p>
                                <p className="page__private-message__subtitle baloa-body-2">{this.props.t('follower.request.text')}</p>
                            </div>
                        </div>

                    )}

                    {(this.props.state.page.page?.is_private == true && (this.props.state.page.page?.is_member == true || this.props.state.page.page?.is_admin == true)) && (

                        <div className={"page__center-container " + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin )) ? "adminview" :"" )}>
                            <PageInformationComponent
                                className="page__center-container__info-horizontal"
                                orientation="vertical"
                                page={this.props.state.page.page}
                                is_admin={this.props.state.page.page.is_admin}
                                onEditDescription={() => { this.props.changePageDescriptionModalStateAction(true) }}
                                onClickMemberRequest={(pagename) => this.props.makeMemberRequest(pagename)}
                                onClickCancelRequest={(member_request_id) => { this.handleOnClickCancelRequest(member_request_id) }}
                                onClickLeaveGroup={(pagename) => this.props.leaveGroup(pagename, this.props.state.user.me.username)}
                                onNewPhoto={() => this.props.changePageUploadPhotoModalStateAction(true)}
                                onFollowers={() => this.props.changePageFollowersModalStateAction(true)}
                                handleOnSharePageButtonClick={()=>{ this.handleOnSharePageButtonClick() } }
                            />
                            <Tabs
                                variant="fullWidth"
                                className="page__center-container__tabs"
                                value={this.state.tab_group_selected}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(ev, new_value) => { this.setState({ tab_group_selected: new_value }) }}
                                aria-label="Selection between "
                            >

                                <Tab label={this.props.t('tabs.postTab')} />
                                <Tab label={this.props.t('tabs.memberTab')} />

                            </Tabs>
                            {/* Page Feed */}
                            <TabPanel
                                index={0}
                                value={(this.state.tab_group_selected )}
                                page={this.props.state.page.page}
                                handleOnMessageIconClick={(post) => this.handleOnMessageIconClick(post)}
                                handleOnCommentsClick={(post) => this.handleOnCommentsClick(post)}
                                handleClap={(post) => this.props.clapPost(post)}
                                onCloseClapsModal={() => { this.props.changePostClapsModalStateAction(true) }}
                                loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                                handleOnComment={(post, comment, pagename, page_id) => this.props.commentPostById(post, comment, pagename, page_id)}
                                changeHomeNewPostModal={(new_modal_state) => this.props.changeHomeNewPostModal(new_modal_state)}
                                changeHomeEditPostModal={(new_modal_state, post) => this.props.changeHomeEditPostModal(new_modal_state, post)}
                                changeNewGroupPagePost={(new_modal_state) => this.props.changeNewGroupPagePost(new_modal_state)}
                                handleOnDeletePostClick={(post) => this.props.deletePost(post)}
                                handleOnAuthorClick={(author) => this.handleOnAuthorClickV2(author)}

                                menu_is_open={true}
                                profile={this.props.state.profile}
                                user={this.props.state.user}
                                sendReaction={(post_id, reaction) => { this.props.sendReaction(post_id, reaction, 'page', this.props.state.page.page.pagename) }}
                                overrideReaction={(post_id, reaction, reaction_id) => { this.props.overrideReaction(post_id, reaction, reaction_id, 'page', this.props.state.page.page.pagename) }}
                                handleReactionsDetails={(new_state) => { this.props.changePostReactionsModalStateAction(new_state) }}
                                loadPostReactionsById={(post_id) => this.props.loadPostReactionsById(post_id)}
                                deleteReaction={(post_id, reaction_id) => { this.props.deleteReaction(post_id, reaction_id, 'page', this.props.state.page.page.pagename) }}

                                handleOnClickQuizAnswer={ (post_id, answer_id)=>{ this.props.answerQuiz(post_id, answer_id, 'page', this.props.state.page.page.pagename) } }
                                handleOnClickPollAnswer={ (post_id, answer_id)=>{ this.props.answerPoll(post_id, answer_id, 'page', this.props.state.page.page.pagename) } }
                                handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                            />

                            {/* Page Members */}
                            <TabPanel
                                index={3}
                                value={(this.state.tab_group_selected + 2)}
                                page={this.props.state.page.page}
                                is_admin={this.props.state.page.page.is_admin}
                                onNewAdminMemberButton={() => { this.props.changePageAddAdminToPageModalStateAction(true) }}
                                onNewMemberButton={() => { this.props.changePageAddMemberToPageModalStateAction(true) }}
                                page_admin_members={this.props.state.page.page.admins}
                                page_members={this.props.state.page.page_members}
                                handleOnProfileClick={(profile) => this.handleOnProfileClick(profile)}
                                deleteAdminMemberFromPage={(member) => this.handleOnDeleteAdmin(member)}
                                deleteMemberFromPage={(member) => this.handleOnDeleteMember(member)}
                                page_member_request={this.props.state.pageGroups.member_request}
                                page_type={this.props.state.page.page.page_type}
                                onAcceptButtonClick={(member_request_id, username) => { this.props.acceptMemberRequest(member_request_id, username, this.pagename) }}
                                onRejectButtonClick={(member_request_id, username) => { this.props.rejectMemberRequest(member_request_id, username, this.pagename, this.props.state.page?.page?.is_admin) }}
                                kickoutGroupMember={(username) => { this.props.kickoutGroupMember(username, this.pagename) }}
                                is_private={this.props.state.page.page.is_private}
                            />
                        </div>

                    )}


                    {/* <div className={"page__right-container " + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin )) ? "adminview" :"" ) }>
                        <div>
                            <PageSuggestionsComponent
                                title={this.props.t('suggestions.title')}
                                pages={this.props.state.home.page_suggestions.slice(0, this.number_of_showing_pages)}
                                className="home__right-container__pages-suggestions sm"
                                handleFollow={(page) => { this.props.followPage(page) }}
                                loading_page_suggestions={this.props.state.home.loading_page_suggestions}
                                go_link={this.props.t('suggestions.goText')}
                                handleGoLink={() => { }}
                            />
                        </div>
                    </div> */}

                </div>

            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: (username) => {
            dispatch(loadPage(username))
        },
        loadPagePosts: (username) => {
            dispatch(loadPagePosts(username))
        },
        loadLeagueTournaments: (league_name) => {
            dispatch(loadLeagueTournaments(league_name))
        },
        loadTournamentPhases: (tournament_id) => {
            dispatch(loadTournamentPhases(tournament_id))
        },
        loadTournamentTeams: (tournament_id) => {
            dispatch(loadTournamentTeams(tournament_id))
        },
        loadTournamentPhaseGroupDates: (tournament_id) => {
            dispatch(loadTournamentPhaseGroupDates(tournament_id))
        },
        loadPageTeams: (page_name) => {
            dispatch(loadPageTeams(page_name));
        },
        loadHomePageSuggestions: () => {
            return dispatch(loadHomePageSuggestions())
        },
        changePageExperiencesModalStateAction: (new_modal_state) => {
            dispatch(changePageExperiencesModalStateAction(new_modal_state))
        },
        changePageUploadPhotoModalStateAction: (new_modal_state) => {
            dispatch(changePageUploadPhotoModalStateAction(new_modal_state))
        },
        changePageDescriptionModalStateAction: (new_modal_state) => {
            dispatch(changePageDescriptionModalStateAction(new_modal_state))
        },
        createOrEditAttributeOnPage: (page, new_attribute_data) => {
            dispatch(createOrEditAttributeOnPage(page, new_attribute_data))
        },
        loadPageErrorsAction: (errors, section) => {
            dispatch(loadPageErrorsAction(errors, section))
        },
        deleteAttribute: (page, attribute_data) => {
            dispatch(deleteAttribute(page, attribute_data))
        },
        followPage: (page) => {
            dispatch(followPage(page))
        },
        unfollowPage: (page) => {
            dispatch(unfollowPage(page))
        },
        loadPageFollowers: (page) => {
            dispatch(loadPageFollowers(page))
        },
        changePageFollowersModalStateAction: (new_modal_state) => {
            dispatch(changePageFollowersModalStateAction(new_modal_state))
        },
        followPageByPagename: (page_pagename, pagename) => {
            dispatch(followPageByPagename(page_pagename, pagename))
        },
        unfollowPageByPagename: (page_pagename, pagename) => {
            dispatch(unfollowPageByPagename(page_pagename, pagename))
        },
        followProfileByPagename: (profile_username, pagename) => {
            dispatch(followProfileByPagename(profile_username, pagename))
        },
        unfollowProfileByPagename: (profile_username, pagename) => {
            dispatch(unfollowProfileByPagename(profile_username, pagename))
        },
        changePageAddMemberToPageModalStateAction: (new_modal_state) => {
            dispatch(changePageAddMemberToPageModalStateAction(new_modal_state))
        },
        changePageAddAdminToPageModalStateAction: (new_modal_state) => {
            dispatch(changePageAddAdminToPageModalStateAction(new_modal_state))
        },
        addAdminToPage: (page_name, member_username) => {
            dispatch(addAdminToPage(page_name, member_username))
        },
        addMemberToPage: (page_name, member_username) => {
            dispatch(addMemberToPage(page_name, member_username))
        },
        loadPageMembers: (page_name) => {
            dispatch(loadPageMembers(page_name))
        },
        searchMembersPage: (searched_profile) => {
            dispatch(searchMembersPage(searched_profile))
        },
        removeMemberOfPage: (page_name, username) => {
            dispatch(removeMemberPage(page_name, username))
        },
        removeAdminOfPage: (page_name, username) => {
            dispatch(removeAdminPage(page_name, username))
        },
        clapPost: (post) => {
            return dispatch(clapPost(post))
        },
        changePostClapsModalStateAction: (new_modal_state) => {
            dispatch(changePostClapsModalStateAction(new_modal_state))
        },
        loadPostClapsById: (post_id) => {
            dispatch(loadPostClapsById(post_id))
        },
        commentPostById: (post_id, comment, pagename, page_id) => {
            return dispatch(commentPostById(post_id, comment, pagename, page_id))
        },
        changeProfileNationalityModalStateAction: (new_modal_state) => {
            dispatch(changeProfileNationalityModalStateAction(new_modal_state))
        },
        searchProfileLocations: (nationality, new_location_search) => {
            return dispatch(searchProfileLocations(nationality, new_location_search))
        },
        searchProfileNationality: (new_nationality_search) => {
            return dispatch(searchProfileNationality(new_nationality_search))
        },
        addPageNationality: (page, new_nationality_data) => {
            return dispatch(addPageNationality(page, new_nationality_data))
        },
        loadPhaseResultsTable: (phase_id) => {
            return dispatch(loadPhaseResultsTable(phase_id))
        },
        switchProfileToPage: (page) => {
            return dispatch(switchProfileToPage(page))
        },
        makeMemberRequest: (pagename) => {
            return dispatch(makeMemberRequest(pagename))
        },
        changeCancelMemberRequestDialogModalStateAction: (new_modal_state) => {
            return dispatch(changeCancelMemberRequestDialogModalStateAction(new_modal_state))
        },
        checkMemberRequest: (pagename) => {
            return dispatch(checkMemberRequest(pagename))
        },
        acceptMemberRequest: (member_request_id, username, pagename) => {
            return dispatch(acceptMemberRequest(member_request_id, username, pagename))
        },
        rejectMemberRequest: (member_request_id, username, pagename, is_admin) => {
            return dispatch(rejectMemberRequest(member_request_id, username, pagename, is_admin))
        },
        leaveGroup: (pagename, username) => {
            return dispatch(leaveGroup(pagename, username))
        },
        kickoutGroupMember: (username, pagename) => {
            return dispatch(kickoutGroupMember(username, pagename))
        },
        changeHomeNewPostModal: (new_modal_state) => {
            return dispatch(changeHomeNewPostModal(new_modal_state));
        },
        changeHomeEditPostModal: (new_state, post) => {
            return dispatch(changeHomeEditPostModal(new_state, post));
        },
        changeNewGroupPagePost: (new_state) => {
            return dispatch(changeNewGroupPagePost(new_state));
        },
        followProfile: (profile) => {
            return dispatch(followProfile(profile))
        },
        unfollowProfile: (profile) => {
            return dispatch(unfollowProfile(profile))
        },
        deletePost: (post) => {
            return dispatch(deletePost(post))
        },
        loadTournamentPosts: (pagename, tournament_id, state, page_type) => {
            return dispatch(loadTournamentPosts(pagename, tournament_id, state, page_type))
        },
        loadMatchReport: (match_id) => {
            return dispatch(loadMatchReport(match_id));
        },
        addEventAction: (data) => {
            return dispatch(addEventAction(data))
        },
        reportPostById: (post_id, denunciation) => {
            dispatch(reportPostById(post_id, denunciation))
        },
        blockProfile: (username) => {
            dispatch(blockProfile(username))
        },
        blockPage: (pagename) => {
            dispatch(blockPage(pagename))
        },
        changeReportPostModalStateAction: (new_modal_state) => {
            dispatch(changeReportPostModalStateAction(new_modal_state))
        },
        changeBlockPostModalStateAction: (new_modal_state) => {
            dispatch(changeBlockPostModalStateAction(new_modal_state))
        },
        changeAcceptReportPostModalStateAction: (new_modal_state) => {
            dispatch(changeAcceptReportPostModalStateAction(new_modal_state))
        },
        sendReaction: (post_id, reaction, type, typename) => {
            dispatch(sendReaction(post_id, reaction, type, typename))
        },
        overrideReaction: (post_id, reaction, reaction_id, type, typename) => {
            dispatch(overrideReaction(post_id, reaction, reaction_id, type, typename))
        },
        changePostReactionsModalStateAction: (new_modal_state) => {
            dispatch(changePostReactionsModalStateAction(new_modal_state))
        },
        loadPostReactionsById: (post_id) => {
            dispatch(loadPostReactionsById(post_id))
        },
        loadPostReactionsNext: (next_page, reaction_type) => {
            dispatch(loadPostReactionsNext(next_page, reaction_type))
        },
        loadPostReactionsByReactionType: (post_id, reaction_type) => {
            dispatch(loadPostReactionsByReactionType(post_id, reaction_type))
        },
        deleteReaction: (post_id, reaction_id, type, typename) => {
            dispatch(deleteReaction(post_id, reaction_id, type, typename))
        },
        followPostProfile:( username, post_id ) => {
            dispatch( followPostProfile( username, post_id ))
        }, 
        unfollowPostProfile:( username, post_id ) => {
            dispatch( unfollowPostProfile( username, post_id ))
        }, 
        followPostPage:( pagename, post_id ) => {
            dispatch( followPostPage( pagename, post_id ))
        }, 
        unfollowPostPage:( pagename, post_id ) => {
            dispatch( unfollowPostPage( pagename, post_id ))
        },
        getTeamPlayers: ( teamname ) => {
            dispatch( getTeamPlayers( teamname ) );
        },
        loadTeamData: ( teamname ) => {
            dispatch( loadTeamData( teamname ) );
        },
        changeTeamMemberDetailModalStateAction: (new_modal_state) => {
            dispatch(changeTeamMemberDetailModalStateAction(new_modal_state))
        },
        loadPhaseMatchesByDate: async ( phase_id, calendar_date ) => {
            return await dispatch( loadPhaseMatchesByDate( phase_id, calendar_date ))
        },
        loadPhaseDates: async ( phase_id ) => {
            return await dispatch( loadPhaseDates( phase_id ))
        },
        changeMatchDetailModalStateAction: (new_modal_state) => {
            dispatch(changeMatchDetailModalStateAction(new_modal_state))
        },
        changeErrorMessageSnackBar: ( new_state ) => {
            return dispatch( changeErrorMessageSnackBar( new_state ) );
        },
        answerQuiz: ( post_id, answer_id, type, pagename ) => { //TODO: verificar su uso
            dispatch( answerQuiz( post_id, answer_id, type, pagename ) );
        },
        answerPoll: ( post_id, answer_id, type, pagename ) => { //TODO: verificar su uso
            dispatch( answerPoll( post_id, answer_id, type, pagename ) );
        },
        changeQuotePostModalStateAction: ( new_modal_state ) => {
            dispatch( changeQuotePostModalStateAction( new_modal_state ) )
        },
        postToQuoteAction: ( post ) => {
            dispatch( postToQuoteAction( post ) )
        },
        uploadPost: ( post_data ) => {
            dispatch( uploadPost( post_data,null,true,null) )
        },
        changePostIsFollowingStateAction: (username) => {
            dispatch( changePostIsFollowingStateAction(username))
        },
        changePostIsFollowingStateFromReactionTypeAction: (username, reaction_type) => {
            dispatch( changePostIsFollowingStateFromReactionTypeAction(username, reaction_type))
        },
        loadfilterTournament: ( league_pagename, type_filter ) => {
            dispatch(loadfilterTournament( league_pagename, type_filter ));
        },
        changeTeamMembersModalStateAction: ( new_modal_state ) => {
            dispatch( changeTeamMembersModalStateAction( new_modal_state ) );
        },
        loadTournamentTeamPlayers: ( team_id, state ) => {
            dispatch( loadTournamentTeamPlayers( team_id, state ) );
        },
        followPlayerTeam: (player) => {
            dispatch( followPlayerTeam(player));
        },
        unFollowPlayerTeam: (player) => {
            dispatch( unFollowPlayerTeam(player));
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        changeAdminResponsiveModalStateAction: (new_modal_state) =>{
            dispatch (changeAdminResponsiveModalStateAction(new_modal_state));
        },
        changeAdminResponsiveLockModalStateAction: (new_modal_state) =>{
            dispatch (changeAdminResponsiveLockModalStateAction(new_modal_state));
        },
        changeShareIframeLinkStateAction: ( new_state ) => {
            return dispatch( changeShareIframeLinkStateAction( new_state ) )
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        loadTournamentMembersTeams: ( team_id) => {
            dispatch( loadTournamentMembersTeams( team_id ));
        },
        loadTournamentStaff: (tournament_id) => {
            dispatch( loadTournamentStaff(tournament_id) );
        },
        assignRefereesMatch:( match_id, referees_match_data, match_date_id ) => {
            dispatch( assignRefereesMatch( match_id, referees_match_data, match_date_id ))
        },
        loadRefeeringPaymentUrl: async ( payment_data, match_id ) => {
            await dispatch( loadRefeeringPaymentUrl(payment_data, match_id) );
        },
        makeRefereePayment: ( match_id ) => {
            dispatch( makeRefereePayment( match_id ) );
        },
        changeSelectTeamInscriptionModalStateAction: ( new_state ) => {
            return dispatch( changeSelectTeamInscriptionModalStateAction( new_state ) )
        },
        loadPageInscriptionTeams: (pagename) => {
            dispatch(loadPageInscriptionTeams(pagename))
        },
        addMemberWithRolToTeam: (teamname, member) => {
            dispatch(addMemberWithRolToTeam(teamname, member));
        },
        changeShareInscriptionLinkModalStateAction: ( new_state ) => {
            return dispatch( changeShareInscriptionLinkModalStateAction( new_state ) )
        },
        loadTeamsToMatchAssistanceAligment: (matchId)=>{
            dispatch(loadTeamsToMatchAssistanceAligment(matchId))
        },
        updatePlayersAssistanceAligment: (matchId, playerArray) => {
            dispatch(updatePlayersAssistanceAligment (matchId, playerArray))
        },
    }
}

export default compose(
    withTranslation(["page", "common","league"]),
    connect(mapStateToProps, mapDispatchToProps)
)(PageView);

