// React
import React, { useState } from "react";

// Material UI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import TournamentInvitationInscriptionSetupComponent from "../tournament-invitation-inscription-setup/TournamentInvitationInscriptionSetupComponent";
//import LeagueAddMemberToTournamentComponent from "../league-add-member-to-tournament/LeagueAddMemberToTournamentComponent";
//import TournamentGeneralSummayComponent from "../tournament-general-summay/TournamentGeneralSummayComponent";
import TournamentBasicDataInfoComponent from "../tournament-basic-data-info/TournamentBasicDataInfoComponent";
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueStaffComponent from '../league-staff/LeagueStaffComponent';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import "./TournamentTeamsEnrollment.styles.css";

//i18
import { withTranslation } from "react-i18next";


function TabPanel(props) {
	const { children, value, index,  ...other } = props;


	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			
            {/* tab setup invitation inscription tournament teams */}
            {value === index && index === 0 && (
                <TournamentInvitationInscriptionSetupComponent 
                    tournament={props.tournament}
                    tournament_teams={ props.tournament_teams }
                    handleOnAddTeamToTournament={ props.handleOnAddTeamToTournament}
                    sendInvitationToTeamTournament={props.sendInvitationToTeamTournament}
                    handleSearchBaloaTeam={ props.handleSearchBaloaTeam}
                    baloaTeams={ props.baloaTeams }
                    handleCleanBaloaTeamsSearch={props.handleCleanBaloaTeamsSearch}
                    pageRoles={ props.pageRoles }
                    handleOnInviteTeamModal={ props.handleOnInviteTeamModal }
                    handleOnValidateTeamInscriptionModal={ props.handleOnValidateTeamInscriptionModal }
                    handleInscriptionInvitationModalActions={ props.handleInscriptionInvitationModalActions }
                    tournament_id={ props.tournament.id }
                    handleOnDownloadForms= { props.handleOnDownloadForms }
                    handleOnDownloadPayments={ props.handleOnDownloadPayments }
                    handleLoadformPlayer = {props.handleLoadformPlayer}
                    sendCarnet = {props.sendCarnet}
                    GetCodeFormTemplate = {props.GetCodeFormTemplate}
                    card_id_template={props.card_id_template}
                    deleteCardIdTemplate={props.deleteCardIdTemplate}
                    handleOnDeleteCardIdTemplate={props.handleOnDeleteCardIdTemplate}
                    handleOnDownloadCardIds={props.handleOnDownloadCardIds}
                    next_tournament_teams={props.next_tournament_teams}
                    loadMoreTeams={props.loadMoreTeams}
                    tournament_teams_loading={props.tournament_teams_loading}
                    tournament_teams_count={props.tournament_teams_count}
                    loadInfoFormModal={props.loadInfoFormModal}
                    handleTeamPlayerPaymentInfo={ props.handleTeamPlayerPaymentInfo }
                    handleOnSearchTeams={props.handleOnSearchTeams}
                />
            )}

            {/* tab tournament staff */}
            {value === index && index === 1 && (
                <div className='tournament-teams-enrollment__add-member'>
                    {/* <LeagueAddMemberToTournamentComponent
                        league_members={props.league_members}
                        tournament_members={props.tournament_members}                
                        handleOnAddMemberToTournament={ props.handleOnAddMemberToTournament }
                        handleOnDeleteMemberFromTournament={ props.handleOnDeleteMemberFromTournament }
                        basic_data_info = { props.basic_data_info }
                        pageRoles = { props.pageRoles }
                    /> */}
                    <LeagueStaffComponent 
                        handleAddStaf={ props.handleAddStaf }
                        staff={props.staff}
                        handleOnAcceptStaffClick = { props.handleOnAcceptStaffClick }
                        handleOnRejectStaffClick = { props.handleOnRejectStaffClick }
                        handleOnEditStaffClick = { props.handleOnEditStaffClick }
                        handleOnDeleteStaffClick = { props.handleOnDeleteStaffClick }
                        selected_tournament={props.tournament}
                    />
                </div>
            )}

            {/* tab tournament information */}
            {value === index && index === 2 && (
                <TournamentBasicDataInfoComponent
                    selected_tournament = { props.tournament }
                    handleOnEditTournamentInfo = { props.handleOnEditTournamentInfo }
                    pageRoles = { props.pageRoles }
                ></TournamentBasicDataInfoComponent> 
            )}
		</div>
	);
}

function TournamentTeamsEnrollment(props) {
    
    const [ tabSelected, setTabSelected ] = useState(0);
    const [ cancelTournament, setCancelTournament ] = useState(false);
    
    // const renderDate = (dateText) => {
    //     const options = { year: 'numeric', month: 'short', day: 'numeric' };
    //     const date = new Date(dateText);
    //     return date.toLocaleDateString("es-ES", options);
    // }

    const handleOnCancelTournament = () => {        
        props.handleChangeTournamentState('Cancelled');
        setCancelTournament(false);
    }

    const handleCancelBtn = (state) => {
        setCancelTournament(!state);
    }

    return (
        <div className="tournament-teams-enrollment__container">
            <div className="tournament-teams-enrollment__nav-container">
                <span className="icon-arrow-left" onClick={() => props.handleTeamsEnrollmentBackBtn() } />
                <div className="baloa-headline-5">{props.t('options.tournaments.pages.tournamentInfo.labelBack')}</div>
            </div>
            {props.tournament?.tournament_photo &&
                <div className="tournament-teams-enrollment-tournament-img_container">
                    <img className="tournament-teams-enrollment__image__background-img" src={props.tournament?.tournament_photo} alt={props.tournament?.name} />
                </div>
            }
            <div className='tournament-teams-enrollment__image'>
                {props.tournament.tournament_photo?
                    <img src={props.tournament?.tournament_photo} alt={props.tournament?.name}/>// eslint-disable-line
                :
                    <span className={"icon-trophy"} color={getRandonIconBallColor()} ></span>
                }
                <div className="tournament-teams-enrollment__tournament-info">
                    <div className="tournament-teams-enrollment__tournament-info__labels">
                        <div className="tournament-teams-enrollment__label">{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.football_type}`)}</div>
                        <div className="tournament-teams-enrollment__label">{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.tournament_gender}`)}</div>
                        <div className="tournament-teams-enrollment__label">{props.tournament.category}</div>
                        <div className='tournament-teams-enrollment__label'>{props.tournament?.tournament_type? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.'+ props.tournament?.tournament_type):''}</div>
                    </div>
                    <SecondaryButtonComponent
                        className='tournament-teams-enrollment__tournament-info__share-btn small'
                        input_type={'button'}
                        onClick={ ()=>{ props.handleOnShareTournamentLinkClick(props.tournament) } }
                    >
                        <span className='hubicon-share_v2'></span>
                        <span className="baloa-table">{props.t('options.share')} </span>
                    </SecondaryButtonComponent>
                </div>
            </div>
            <div className="tournament-teams-enrollment__info-tournament__container" >
                <div className="left-col">
                    { !props.tournament.tournament_logo
                        ?
                        <span className="icon-ball" color={ getRandonIconBallColor() } />
                        :
                        <img alt="" src={props.tournament.tournament_logo} />
                    }
                    
                    <div className="tournament-info">
                        <div className="baloa-headline-2">{props.tournament.name}</div>
                        {/*<div>                            
                             <div className="tournament-info__dates-location">
                                <span className="icon-calendar" />
                                <div className="baloa-username">{`${renderDate(props.tournament.start_date)} - ${renderDate(props.tournament.end_date)}`}</div>
                            </div>
                            <div className="tournament-info__dates-location">
                                <span className="icon-location" />
                                <div className="baloa-username">{`${props.tournament.main_country} - ${props.tournament.main_city}`}</div>
                            </div>  
                        </div>*/}
                    </div>
                </div>
                <div className="right-col">
                    { !cancelTournament &&
                        <PrimaryButtonComponent 
                            className="tournament-teams-enrollment__start-tournament__btn"
                            disabled={ false }
                            onClick={ () =>{ props.tournament.start_tournament? props.handleOnStartTournament('Scheduled'): props.handleStartTournamentInfo(true) } }                    
                        >
                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.startTournamentBtn')}
                        </PrimaryButtonComponent>
                    }
                    { cancelTournament &&
                        <div className='tournament-teams-enrollment__cancel-tournament' onClick={ () => handleOnCancelTournament() } >
                            <span className='icon-x' />
                            <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.cancelTournamentBtn')}</div>
                        </div>
                    }
                    <span className="icon-options" onClick={ () => handleCancelBtn(cancelTournament)} />
                </div>
                

            </div>
            <div className="tournament-teams-enrollment__tabs-content__container">
                <Tabs
                    variant="fullWidth"
                    className="edit-team__center__content__tabs baloa-normal-medium"
                    value={tabSelected}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(ev, new_value) => { setTabSelected(new_value); }}
                    aria-label="Selection between "
                >
                    <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamsAppliancesInscriptionTitle')} />
                    <Tab label={props.t('options.tournaments.pages.newTournament.tabs.tabStaff.labelTitle')} />
                    <Tab label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTitle')} />
                </Tabs>

                {/* tab setup invitation inscription tournament teams */}
                <TabPanel
                    index={0}
                    value={tabSelected}
                    tournament={props.tournament}
                    tournament_teams={ props.tournament_teams }
                    handleOnInviteTeamModal={ props.handleOnInviteTeamModal }
                    handleOnValidateTeamInscriptionModal={ props.handleOnValidateTeamInscriptionModal }
                    handleInscriptionInvitationModalActions={ props.handleInscriptionInvitationModalActions }
                    handleOnDownloadForms= { props.handleOnDownloadForms }
                    handleOnDownloadPayments={ props.handleOnDownloadPayments }
                    handleLoadformPlayer = {props.handleLoadformPlayer}
                    sendCarnet = {props.sendCarnet}
                    GetCodeFormTemplate = {props.GetCodeFormTemplate}
                    card_id_template={props.card_id_template}
                    deleteCardIdTemplate={props.deleteCardIdTemplate}
                    handleOnDeleteCardIdTemplate={props.handleOnDeleteCardIdTemplate}
                    handleOnDownloadCardIds={props.handleOnDownloadCardIds}
                    next_tournament_teams={props.next_tournament_teams}
                    loadMoreTeams={props.loadMoreTeams}
                    tournament_teams_loading={props.tournament_teams_loading}
                    tournament_teams_count={props.tournament_teams_count}
                    loadInfoFormModal={props.loadInfoFormModal}
                    handleTeamPlayerPaymentInfo={ props.handleTeamPlayerPaymentInfo }
                    handleOnSearchTeams={props.handleOnSearchTeams}
                />

                {/* tab tournament staff */}
                <TabPanel
                    index={1}
                    value={tabSelected}
                    tournament={props.tournament}
                    league_members={props.league_members}
                    tournament_members={props.tournament_members}                
                    handleOnAddMemberToTournament={ props.handleOnAddMemberToTournament }
                    handleOnDeleteMemberFromTournament={ props.handleOnDeleteMemberFromTournament }
                    basic_data_info = { props.tournament }
                    pageRoles = { props.pageRoles }
                    //New Staff
                    handleAddStaf={ props.handleAddStaf }
                    staff={props.staff}
                    handleOnAcceptStaffClick = { props.handleOnAcceptStaffClick }
                    handleOnRejectStaffClick = { props.handleOnRejectStaffClick }
                    handleOnEditStaffClick = { props.handleOnEditStaffClick }
                    handleOnDeleteStaffClick = { props.handleOnDeleteStaffClick }
                />

                {/* tab tournament information */}
                <TabPanel
                    index={2}
                    value={tabSelected}
                    tournament={props.tournament}
                    handleOnEditTournamentInfo = { props.handleOnEditTournamentInfo }
                    pageRoles = { props.pageRoles }
                />
            </div>
        </div>
	);
}

export default withTranslation("league")(TournamentTeamsEnrollment);
