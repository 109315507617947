import React from "react";

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import "./AddMembersWithoutAccountModal.styles.css";

// i18n
import { withTranslation } from "react-i18next";
import LeagueBulkAddMembersComponentV2 from "app/leagues/components/league-bulk-add-members/LeagueBulkAddMembersComponentV2";

function AddMembersWithoutAccountModalComponent(props){

    const [addBulk, setAddBulk] = React.useState(false);//eslint-disable-line

    const onSaveBulkButton=()=>{
        setAddBulk(true);
        props.handleOnSaveBulkButton();
    }

    React.useEffect(() => {
        if(addBulk && !props.loading_bulk_file){
            setAddBulk(false);
            props.onClose();
        }
    
    },[props.loading_bulk_file]); // eslint-disable-line

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title="Agregar miembros sin cuenta"            
            className='responsive-hub-modal__container'>
            
            <div className="scroll-parent">
                <div className="scroll">
                    <LeagueBulkAddMembersComponentV2
                        handleOnSaveBulkButton = { ()=>{ onSaveBulkButton() } }  
                        handleOnUploadFile = { props.handleOnUploadFile }
                        bulkMembers = { props.bulkMembers }
                        handleOnBackBulk={ props.onClose }
                        cleanBulkMembers = { props.cleanBulkMembers }
                        uploadProgress = { props.uploadProgress }
                        cleanStatusBar = { props.cleanStatusBar }
                        updateBulkData={ props.updateBulkData }
                        handleOnDeleteMember={props.handleOnDeleteMember}
                        handleOnMoreInfo={props.handleOnMoreInfo}
                        isTournamentHub={true}
                    />
                </div>
            </div>
            <div className="bulk-add-members__buttons__back">
				
				<div className="bulk-add-members__buttons__back__button" onClick={()=>{props.onClose(false)}}>
					{"Cancelar"}
				</div>
				
				<PrimaryButtonComponent 
					disabled={props.bulkMembers.members.length <= 0 || props.bulkMembers.error}
					onClick={() => {
						onSaveBulkButton()
					}}
					className="bulk-add-members__buttons__back__button"
					type="submit"
				>
					<span>
						{props.t(
							"newTeam.forms.players.bulkMemberUpload.saveMembersBtn"
						)}
					</span>
				</PrimaryButtonComponent> 
			</div>
            <br/>
    </Modal2Component>
    )
}
export default withTranslation("team")(AddMembersWithoutAccountModalComponent);