/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayPaymentOptionsModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaPlayPaymentOptionsModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-payment-options-modal"
        >
            <div className={`baloa-play-payment-options-modal__container `}>
                
                <div className='baloa-play-payment-options-modal__message-container'>
                    <span className='hubicon-award_star'/>
                    <div className='baloa-play-payment-options-modal__title'>{props.t('paymentOptionsModal.title')}</div>
                    {/* <div className='baloa-play-payment-options-modal__subtitle'>{props.t('paymentOptionsModal.subTitle')}</div> */}
                    <div className='baloa-play-payment-options-modal__message'>{props.t('paymentOptionsModal.message',{_value: props.match_recording_price?.value,_currency: props.match_recording_price?.currency})}</div>
                    <div className='baloa-play-payment-options-modal__comparative-title'>
                        <div className='option'>{props.t('paymentOptionsModal.titleOption')}</div>
                        <div className='no-pro'>{props.t('paymentOptionsModal.titleNoPro')}</div>
                        <div className='pro'>                            
                            <div className=''>{props.t('paymentOptionsModal.titlePro')}</div>
                            <span className='hubicon-award_star' />
                        </div>
                    </div>
                    <div className='baloa-play-payment-options-modal__comparative-char'>
                        <div className='option'>{props.t('paymentOptionsModal.option1')}</div>
                        <div className='mark'>
                            <span className='hubicon-clear_option'/>
                        </div>
                        <div className='mark'>
                            <span className='hubicon-check'/>
                        </div>
                    </div>
                    <div className='baloa-play-payment-options-modal__comparative-char'>
                        <div className='option'>{props.t('paymentOptionsModal.option2')}</div>
                        <div className='mark'>
                            <span className='hubicon-clear_option'/>
                        </div>
                        <div className='mark'>
                            <span className='hubicon-check'/>
                        </div>
                    </div>
                    <div className='baloa-play-payment-options-modal__comparative-char'>
                        <div className='option'>{props.t('paymentOptionsModal.option3')}</div>
                        <div className='mark'>
                            <span className='hubicon-clear_option'/>
                        </div>
                        <div className='mark'>
                            <span className='hubicon-check'/>
                        </div>
                    </div>
                </div>
                <div>
                    <SecondaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnPay()}}
                    >
                        <span>{props.t('paymentOptionsModal.paymentBtnLbl',{_value: props.match_recording_price?.value,_currency: props.match_recording_price?.currency})}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnSubscribe()}}
                    >
                        <span>{props.t('paymentOptionsModal.subscribeBtnLb')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayPaymentOptionsModalComponent);