/*eslint eqeqeq: "off"*/
// React
import React,{ useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import { ChromePicker } from 'react-color';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Modals
import TournamentCardIdPreviewModalComponent from '../tournament-card-id-preview-modal/TournamentCardIdPreviewModalComponent';

//Material
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ListItemText } from '@mui/material';
import TextField from '@mui/material/TextField';

//assets
import cardIdH from 'assets/images/cardIdH.png';
import cardIdV from 'assets/images/cardIdV.png';

// Styles
import './TournamentCardIdCreatorComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentCardIdCreatorComponent ( props ){
    const [draggedTask, setDraggedTask] = useState({});
    //const [activeDrags, setActiveDrags] = useState(0);
    const [fieldOptions, setFieldOptions] = useState(props.carnet_id_template);
    
    const [cardFields, setCardFields] = useState([]);
    const [dataActive, setDataActive] = useState({id: 99, type: '', caption: '', fontFamily: 'Roboto', color: '#000000', width: '65.5pt', height: '65.5pt', shape: 'square', x: 0, y: 0});
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const [openPreview, setOpenPreview] = useState(false);
    const fontOptions = [
        {value: 'Roboto', content: 'Roboto'},
        {value: 'Poppins', content: 'Poppins'},
        {value: 'OpenSans', content: 'Open Sans'},
        {value: 'LibreBaskerville', content: 'Libre Baskerville'},
        {value: 'Quicksand', content: 'Quicksand'},
    ];

    const fontSizeOptions = [
        {value: '9pt', content: props.t('options.tournaments.cardIds.optionBar.smallOption')},
        {value: '12pt', content: props.t('options.tournaments.cardIds.optionBar.mediumOption')},
        {value: '15pt', content: props.t('options.tournaments.cardIds.optionBar.bigOption')},
    ];

    const imageSizeOptions = [
        {value: '60pt', content: props.t('options.tournaments.cardIds.optionBar.smallOption')},
        {value: '65.5pt', content: props.t('options.tournaments.cardIds.optionBar.mediumOption')},
        {value: '75pt', content: props.t('options.tournaments.cardIds.optionBar.bigOption')},
    ];
    
    const shapeOptions = [
        {value: 'square', content: props.t('options.tournaments.cardIds.optionBar.squareOption')},
        {value: 'round', content: props.t('options.tournaments.cardIds.optionBar.roundOption')},
    ];
    const elementsRef = React.useRef();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const popover = {
        position: 'absolute',
        zIndex: '2',
        zoom: '133%'
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const [color, setColor] = useState('#ffffff'); // define a state for the color prop

    const onDrag = (event, cdata) => {
        event.preventDefault();
        setDraggedTask(cdata);
        /* this.setState({
            draggedTask: cdata
        }); */
        
    }
    const onDragOver = (event) => {
        event.preventDefault();
    }
    const onDrop = (event ) => {
        setCardFields([...cardFields, draggedTask])
        setFieldOptions(fieldOptions.filter(task => task.id !== draggedTask.id));
        setDraggedTask({});
    }
    
    
    const handleDrag = (e, ui, cdata) => {

        let new_carnets = cardFields.map(opt => {
            if(opt.id === cdata.id){
                opt.x = opt.x + ui.deltaX;
                opt.y = opt.y + ui.deltaY;
            }
            return opt;
        });

        setCardFields( new_carnets ) 
    };

    const handleOnData =(id) => {
        let selectedData = cardFields.find(field => field.id === id);
        setDataActive(selectedData);
    }

    const handleOnInData = (e,id) => {
        e.stopPropagation();
        let selectedData = cardFields.find(field => field.id === id);
        setDataActive(selectedData);
    }

    const setDataProperty = (activeId, property, value) => {
        let selValue = '';
        if(value.target){
            selValue=value.target.value;
        }else{
            selValue = value;
        }

        cardFields.find(field => field.id === activeId)[property] = selValue;
        forceUpdate();
    }

    const uploadFile=(e, activeId) => {
        setDataProperty(activeId, 'value', URL.createObjectURL(e.target.files[0]));
        setDataProperty(activeId, 'fileName', e.target.files[0].name);
    }

    const handleClickBtnColor = () => {
        setDisplayColorPicker( !displayColorPicker );
    };

    const handleCloseBtnColor = () => {
        setDisplayColorPicker(false);
    }

    // setState when onChange event triggered
    const handleChangeColor = (color) => {
          setColor(color.hex);
    };
    
    /* const onStart = () => {
        setActiveDrags(++this.state.activeDrags);
    };
    
    const onStop = () => {
        setActiveDrags(--this.state.activeDrags);
    }; */



    /** Codigo para validar el boton de back del navegador */
    //const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logic for showing popup warning on page refresh
        /* window.onbeforeunload = function () {
            //return "Data will be lost if you leave the page, are you sure?";
            return props.t('options.tournaments.cardIds.exitValidationModal.subTitle')+', '+ props.t('options.tournaments.cardIds.exitValidationModal.message'); //Mensaje predeterminado del navegador - No toma el mensaje personalizado
        }; */
        //window.addEventListener("beforeunload", onBackButtonEvent);//Muestra el modal despues de mostrar el modal del navegador
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        props.handleBackWindowButton();
        /* if (!isBackButtonClicked) {            
            if (window.confirm("Do you want to go to Test Listing")) {
                setBackbuttonPress(true)
                //props.history.go(listingpage)
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        } */
    }
    /** Codigo para validar el boton de back del navegador */


    return( 
        <React.Fragment>            
            <div className='carnet'>
                {/**Opciones */}
                <div className='carnet__menu'>
                    <div className='carnet__info-message'>
                        <span className='hubicon-info' />
                        <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionsMsg')}</div>
                    </div>
                    {fieldOptions.map( cdata =>
                        <div
                            className={`carnet__options-elements baloa-table ${cdata.type === 'image'? 'image' : cdata.type === 'file'? 'image' : ''}`}
                            key={cdata.id}
                            //draggable={cdata.type !== 'file'? true : false}
                            draggable
                            onDrag={(event) => onDrag(event, cdata)}
                            
                        >
                            {cdata.caption}
                        </div>
                    )
                    }
                </div>
                <div className='carnet__board scroll-parent'>
                    <div className='carnet__board__buttons'>
                        <div onClick={() => { setOpenPreview(true) }}  className='carnet__board__buttons__preview-btn' >
                            <span className='hubicon-preview' />
                            <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.previewBtn')}</div>
                        </div>
                        <PrimaryButtonComponent
                            className={'carnet__save-button small'}
                            onClick={()=>{ props.saveCard(cardFields, fieldOptions) }}
                        >   
                            <span className='hubicon-save icon' />
                            <span>{props.t('options.tournaments.cardIds.saveBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                    {cardFields.length > 0 &&
                        <div className='carnet__board__options'>
                            {dataActive && cardFields.find(field => field.id === dataActive?.id)?.type === 'json' &&
                                <React.Fragment>
                                    <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionBar.fontLbl')}</div>
                                    <Select 
                                        id={ 'select_font' }
                                        disabled={ false }
                                        onChange={ (ev)=>{setDataProperty(dataActive?.id, 'fontFamily', ev)} } 
                                        className={ ''}
                                        variant='outlined'
                                        //defaultValue={dataActive.fontSize}
                                        value={dataActive.fontFamily}
                                        //displayEmpty
                                    >
                                        {fontOptions.map( option => {
                                            return (
                                                <MenuItem
                                                    key={ option.value } 
                                                    value={ option.value }
                                                >
                                                    <ListItemText primary={option.content}/>
                                                </MenuItem>
                                            )
                                        } )} 
                                    </Select>
                                    <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionBar.sizeLbl')}</div>
                                    <Select 
                                        id={ 'select_font_size' }
                                        disabled={ false }
                                        onChange={ (ev)=>{setDataProperty(dataActive?.id, 'fontSize', ev)} } 
                                        className={ ''}
                                        variant='outlined'
                                        //defaultValue={dataActive.fontSize}
                                        value={dataActive.fontSize}
                                        //displayEmpty
                                    >
                                        {fontSizeOptions.map( option => {
                                            return (
                                                <MenuItem
                                                    key={ option.value } 
                                                    value={ option.value }
                                                >
                                                    <ListItemText primary={option.content}/>
                                                </MenuItem>
                                            )
                                        } )} 
                                    </Select>
                                    <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionBar.colorLbl')}</div>
                                    <div>
                                        <div className='carnet__board__options__color-picker__button' onClick={ handleClickBtnColor }><div style={{backgroundColor: dataActive.color, color: 'transparent', borderRadius: '8px', height: '-webkit-fill-available'}} >.</div></div>
                                        { displayColorPicker ? 
                                            <div style={ popover }>
                                                <div style={ cover } onClick={ handleCloseBtnColor }/>
                                                <ChromePicker 
                                                    color={ color }
                                                    disableAlpha={true}
                                                    onChange={handleChangeColor}
                                                    onChangeComplete={ (color)=>{ setDataProperty(dataActive?.id, 'color', color.hex) } }
                                                />
                                            </div>
                                            : null 
                                        }
                                    </div>
                                    
                                </React.Fragment>
                            }
                            {dataActive && (cardFields.find(field => field.id === dataActive?.id)?.type === 'image' || cardFields.find(field => field.id === dataActive?.id)?.type === 'file') &&
                                <React.Fragment>
                                    <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionBar.sizeLbl')}</div>
                                    <Select 
                                        id={ 'select_size' }
                                        disabled={ false }
                                        onChange={ (ev)=>{setDataProperty(dataActive?.id, 'width', ev); setDataProperty(dataActive?.id, 'height', ev)} } 
                                        className={ ''}
                                        variant='outlined'
                                        //defaultValue={dataActive.width}
                                        value={dataActive.width}
                                        //displayEmpty
                                    >
                                        {imageSizeOptions.map( option => {
                                            return (
                                                <MenuItem
                                                    key={ option.value } 
                                                    value={ option.value }
                                                >
                                                    <ListItemText primary={option.content}/>
                                                </MenuItem>
                                            )
                                        } )} 
                                    </Select>
                                    <div className='baloa-username'>{props.t('options.tournaments.cardIds.optionBar.shapeLbl')}</div>
                                    <Select 
                                        id={ 'select_form' }
                                        disabled={ false }
                                        onChange={ (ev)=>{setDataProperty(dataActive?.id, 'shape', ev)} } 
                                        className={ ''}
                                        variant='outlined'
                                        //defaultValue={shapeOptions[1]}
                                        value={dataActive.shape}
                                        //displayEmpty
                                    >
                                        {shapeOptions.map( option => {
                                            return (
                                                <MenuItem
                                                    key={ option.value } 
                                                    value={ option.value }
                                                >
                                                    <ListItemText primary={option.content}/>
                                                </MenuItem>
                                            )
                                        } )} 
                                    </Select>
                                </React.Fragment>
                            }
                        </div>
                    }
                    
                    {/** Canvas */}
                    <div className='carnet__canvas-parent scroll'>
                        <div 
                            className={`carnet__canvas ${props.orientation === 'horizontal'? '' :'vertical'}`}
                            onDrop={event => onDrop(event)}
                            onDragOver={(event => onDragOver(event))}
                            onClick={()=>{handleOnData('')}}
                            style={
                                props.orientation === 'horizontal'?
                                { 
                                    backgroundImage: `url(${props.background_image? props.background_image: cardIdH})`, 
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '318pt 176.25pt'
                                }
                                :
                                {
                                    backgroundImage: `url(${props.background_image? props.background_image: cardIdV})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '205.50pt 290.55pt'
                                }
                            }
                        >
                            {cardFields.map((cdata, index) =>
                                <Draggable 
                                    onDrag={(e,ui)=>handleDrag(e,ui, cdata)} 
                                    //onStart={onStart}
                                    //onStop={onStop}
                                    bounds="parent"
                                    defaultPosition={{x: cdata.x, y: cdata.y}}
                                >
                                    <div
                                        id={'cdata'+cdata.id}
                                        key={cdata.id}
                                        className={`carnet-data baloa-table ${(cdata.type === 'image' || cdata.type === 'file')? 'image '+cdata.shape :''} ${dataActive?.id === cdata.id? 'selected' : ''} `}
                                        onClick={(e)=>{handleOnInData(e,cdata.id)}}
                                        //draggable
                                        style={{
                                            fontFamily: `${cdata.fontFamily}`,
                                            fontSize: `${cdata.fontSize}`,
                                            width: `${cdata.width? cdata.width : ''}`,
                                            height: `${cdata.height? cdata.height : ''}`,
                                            color: `${cdata.color}`,
                                        }} 
                                        //onDragEnd={(event) => this.onDragCdata(event, cdata)} */
                                    >
                                        {(cdata.value !== '' && (cdata.type === 'image' || cdata.type === 'file'))?
                                            <div className={`carnet-data ${(cdata.type === 'image' || cdata.type === 'file')? 'test-image '+cdata.shape :''} ${dataActive?.id === cdata.id? 'selected' : ''} `}><img src={cdata.value} alt='' draggable="false"/></div>
                                            :
                                            <div>{`${cdata.value !== ''? cdata.value : '{'+cdata.caption+'}'}`}{/*  x: {this.state.deltaPosition.x.toFixed(0)}, y: {this.state.deltaPosition.y.toFixed(0)} */}{/*  x: {cdata.x.toFixed(0)}, y: {cdata.y.toFixed(0) */}</div>
                                        }
                                    </div>
                                </Draggable>
                            )}
                            
                        </div>
                    </div>

                </div>
                <div className='carnet__test-data'>
                    <div className='carnet__info-message'>
                        <span className='hubicon-info' />
                        <div className='baloa-username'>{props.t('options.tournaments.cardIds.testDataMsg')}</div>
                    </div>

                    {//eslint-disable-next-line array-callback-return
                    cardFields.map((cdata, index) => {
                        if(cdata.type === 'json'){
                            return (
                                <div className='carnet__test-data__element'>
                                    <div className='baloa-username'>{cdata.caption}</div>
                                    <TextField 
                                        key={cdata.id} 
                                        name={cdata.id} 
                                        type='text' 
                                        onChange={(ev)=>{setDataProperty(cdata.id, 'value', ev)}} 
                                        value={cdata.value}
                                        variant="outlined"
                                        className='carnet__test-data__input'
                                    />
                                </div>
                            )
                        }
                        if(cdata.type === 'image' || cdata.type === 'file'){
                            const elementsRef = React.createRef();
                            return (
                                <div className='carnet__test-data__element'>
                                    <div className='baloa-username'>{cdata.caption}</div>
                                    <div className={"carnet__test-data__element__file-input"}>
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            accept=".png, .jpg"
                                            
                                            name={cdata.id}
                                            id={cdata.id}
                                            
                                            //ref={(file) => setRefInput(file)}
                                            ref={elementsRef}
                                            onChange={(ev)=>{uploadFile(ev,cdata.id )}} 
                                        />
                                        <PrimaryButtonComponent
                                            disabled={ false}
                                            onClick={() => elementsRef.current.click()}
                                            className={`upload_file__button _empty general_flex xs`}
                                            type="button"
                                        >
                                            <div className="upload_file_icon">
                                                <span className='hubicon-upload' />
                                                <div>{props.t('options.tournaments.cardIds.uploadImgLbl')}</div>
                                                    
                                            </div>
                                        </PrimaryButtonComponent>
                                        {cdata.value !== '' &&
                                            <div className='carnet__test-data__element__file-input__delete-file baloa-username'>{cdata.fileName}<span className="icon-x carnet__test-data__element__file-input__delete-file-icon" onClick={()=>{setDataProperty(cdata.id, 'value', '')}}/></div>
                                        }
                                        
                                    </div>
                                </div>
                            )
                        }                        
                        })
                        
                    }
                    
                </div>
                
            </div>
            <TournamentCardIdPreviewModalComponent
                isOpen={openPreview}
                onClose={() => { setOpenPreview(false) }}
                title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.previewBtn')}
                orientation={props.orientation}
                cardIdData={cardFields}
                cardIdH={props.background_image? props.background_image:cardIdH}
                cardIdV={props.background_image? props.background_image:cardIdV}
            />
            
        </React.Fragment>
    )
}
export default withTranslation('league')(TournamentCardIdCreatorComponent);