import React from "react";

//Styles
import "./TournamentPlayerInfoComponent.styles.css"

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentPlayerInfoComponent (props) {
    return(
        <div className="tournament-player-info__container">
            {props.photo?
                <img src={props.photo} alt="Baloa player"/>
                :
                <span className="icon-ball" color={getRandonIconBallColor()}/>
            }
            <div className="tournament-player-info__player-data">
                <div className="baloa-normal-medium" >{props.full_name}</div>
                <div className="baloa-table" >{props.team_name}</div>
            </div>
        </div>
    )
}

export default TournamentPlayerInfoComponent;