/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMyClipsVideoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

function BaloaPlayMyClipsVideoModalComponent( props ) {

    const videoRef = React.useRef();

    const handleVideoWidth = ( vw, vh) => {
        if(vw >= vh){
            return '100%';
        }else{
            return 'auto'
        }
    }

    const handleVideoHeight= (vw, vh) => {
        if(vh >= vw){
            return '100%';
        }else{
            return '100%'
        }
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.clip_video?.post_video_caption}
            className="baloa-play-my-clips-video-modal"
        >
            <div className={`baloa-play-my-clips-video-modal__container `}>

                <div className="baloa-play-my-clips-video-modal__media-container">
                        <div className="baloa-play-my-clips-video-modal__video-container">
                            {/* Video Player */}
                            {props.clip_video?.post_stream?.hls &&
                                <ReactHlsPlayer
                                    className=''
                                    playerRef={videoRef}
                                    src={props.clip_video?.post_stream?.hls}
                                    preload="auto"
                                    autoPlay={false}
                                    playsinline
                                    width={handleVideoWidth( props.clip_video?.post_stream?.width, props.clip_video?.post_stream?.height )}
                                    height={handleVideoHeight(  props.clip_video?.post_stream?.width, props.clip_video?.post_stream?.height )}
                                    loop={true}
                                    poster={props.clip_video?.post_stream?.thumbnail ? props.clip_video?.post_stream?.thumbnail : ""}
                                    controls={true}
                                    hlsConfig={{
                                        startLevel: 4, // El índice de la calidad deseada
                                        //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                                        capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                                        maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                                        maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                                        maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                                        maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                                        lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                                        highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                                        nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                                        nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
                                      }}
                                />
                            }
                        </div>                
                    </div>                
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMyClipsVideoModalComponent);