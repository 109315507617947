// React
import React from "react";
import { Link } from "react-router-dom";

// Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import TextButtonComponent from "shared/components/text-button/TextButtonComponent";
import FormComponent from "shared/components/form/FormComponent";
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";

//Validators
import { RequiredValidator, EmailValidator, PhoneNumberValidator, OnlyNumbersValidator,  } from "shared/validators/RequiredValidator";
import { DateEmptyFormatValidator } from 'shared/validators/DateValidator';

//Material
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";

// Assets
import edit_icon from 'assets/icons/edit.svg';

// Styles
import "./LeagueBulkAddMembersComponent.styles.css";

// i18n
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	errorMessage: {
		background: "#FFDADC",
		maxWidth: 120,
		margin: 2,
		color: "var(--primary-dark-color)",
	},
	customMessage: {
		background: "var(--white-color)",
		maxWidth: 200,
		margin: 2,
		color: "var(--primary-dark-color)",
		border: "solid 1px gray"
	},
}));

function LeagueBulkAddMembersComponentV2(props) {
	const [bulkFile, setBulkFile] = React.useState({ file_input_ref: null }); // eslint-disable-line
	const [showUploadStatus, setShowUploadStatus] = React.useState(false);
	const [editRow, setEditRow] = React.useState(null);
	const [addBulkMember, setAddBulkMember] = React.useState(false);
	const formRef = React.useRef();

	/* const [state, setState] = React.useState({
		formValues: {
			name: null,
			lastName: null,
			email: null,
			phoneCountryCode: null,
			mobileNumber: null,
			shirtNumber: null,
			birthDate: null,
		}
	}); */
	

	const [initialValues, setInitialValues] = React.useState({
		name: '',
		last_name: '',
		email: '',
		phone_country_code: '',
		mobile_number: '',
		shirtnumber: '',
		birthdate: '',
	})

	const errors = {
		name: '',
		last_name: '',
		email: '',
		phone_country_code: '',
		mobile_number: '',
		shirtnumber: '',
		birthdate: '',
	}

	const classes = useStyles();

	const onFileChange = (event) => {
		props.handleOnUploadFile(event.target.files[0]);
		bulkFile.file_input_ref.value = "";
	};

	React.useEffect(() => {
		if (props.bulkMembers.members.length > 0) {
			const timeout = setTimeout(() => {
				setShowUploadStatus(false);
			}, 1000);
			return () => clearTimeout(timeout);
		}
	}, [props.bulkMembers.members]); // eslint-disable-line

	const handleOnEditRow =(index, row)=>{

		let newInitialValues = initialValues;
		newInitialValues.name= row.name.caption;
		newInitialValues.last_name= row.last_name.caption;
		newInitialValues.email= row.email.caption;
		newInitialValues.phone_country_code= row.phone_country_code.caption;
		newInitialValues.mobile_number= row.mobile_number.caption;
		newInitialValues.shirtnumber= row.shirtnumber.caption;
		newInitialValues.birthdate= row.birthdate.caption;

		formRef.current?.setFieldValue("name", row.name.caption);
		formRef.current?.setFieldValue("last_name", row.last_name.caption);
		formRef.current?.setFieldValue("email", row.email.caption);
		formRef.current?.setFieldValue("phone_country_code", row.phone_country_code.caption);
		formRef.current?.setFieldValue("mobile_number", row.mobile_number.caption);
		formRef.current?.setFieldValue("shirtnumber", row.shirtnumber.caption);
		formRef.current?.setFieldValue("birthdate", row.birthdate.caption);

		setInitialValues(newInitialValues)
		setEditRow(index);
	}


	const saveRow =(row, rowIndex)=>{
		setEditRow(null); 
		props.updateBulkData(row, rowIndex);
	}

	const addMember=(member)=>{
		setAddBulkMember(false);
		props.updateBulkData(member);
	}

	const handleOnDeleteRow=(row)=>{
		props.handleOnDeleteMember(row);
	}

	return (
		<div className="bulk-add-members__container">
			{props.isTournamentHub === undefined && <div className="baloa-subtitle-2 bulk-add-members__title">
				<span
					className="icon-arrow-left bulk-add-members_icon-back"
					onClick={() => {
						props.handleOnBackBulk(false);
					}}
				></span>
				{props.t("newTeam.forms.players.bulkMemberUpload.bulkTitle")}
			</div>}
			<div className="baloa-table bulk-add-members__text">
				{props.t("newTeam.forms.players.bulkMemberUpload.bulkMessage")}&nbsp;<span className="bulk-add-members__text__link" onClick={()=> props.handleOnMoreInfo() }>{props.t("newTeam.forms.players.bulkMemberUpload.bulkMoreInfo")}</span>
				<div className="bulk-add-members__separator" />
				{props.t("newTeam.forms.players.bulkMemberUpload.bulkMessage2")}
			</div>
			<div className="bulk-add-members__buttons ">
				<div className="bulk-add-members__buttons__template-buttons">
					<Link to="/files/PlayersTemplate.xlsx" target="_blank" download>
						<TextButtonComponent
							onClick={() => {}}
							className="bulk-add-members__buttons__button__download-button"
						>
							<span>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.downloadTemplateBtn"
								)}
							</span>
						</TextButtonComponent>
					</Link>
					<PrimaryButtonComponent
						disabled={false}
						onClick={() => {
							bulkFile.file_input_ref.click();
							//props.cleanBulkMembers();
							props.cleanStatusBar();
							setShowUploadStatus(true);
						}}
						className="bulk-add-members__buttons__button-middle"
						type="submit"
					>
						<span>
							{props.t(
								"newTeam.forms.players.bulkMemberUpload.uploadTemplateBtn"
							)}
						</span>
					</PrimaryButtonComponent>
					<PrimaryButtonComponent
						disabled={false}
						onClick={() => {
							setAddBulkMember(true);
						}}
						className="bulk-add-members__buttons__button"
						type="button"
					>
						<span className="bulk-add-members__buttons__button-icon icon icon-plus" />
						<span>						
							{props.t(
								"newTeam.forms.players.bulkMemberUpload.addMemberBtn"
							)}
						</span>
					</PrimaryButtonComponent>
				</div>
				{/* <PrimaryButtonComponent 
					disabled={props.bulkMembers.error}
					onClick={() => {
						props.handleOnSaveBulkButton();
					}}
					className="bulk-add-members__buttons__button"
					type="submit"
				>
					<span>
						{props.t(
							"newTeam.forms.players.bulkMemberUpload.saveMembersBtn"
						)}
					</span>
				</PrimaryButtonComponent> */}
				
			</div>
			
			<div className={"bulk-add-members__member-data__container"}>				
				<React.Fragment>
					{props.bulkMembers.error && (
						<div className={"bulk-add-members__error-message baloa-table"}>
							<ErrorIcon
								style={{
									fontSize: 20,
									color: "var(--new-warning-color)",
									marginRight: "var(--space)",
									alignSelf: "center",
								}}
							/>{" "}
							{props.t(
								"newTeam.forms.players.bulkMemberUpload.bulkUploadError"
							)}
						</div>
					)}
					<FormComponent
						//formInitialValues={ formikvalues.initialValues }
						formInitialValues={initialValues}
						enableReinitialize={true}									
						onSubmit={(values, {resetForm}) => {
							if(addBulkMember){
								addMember(values);
							}else{
								saveRow(values,editRow);
							}
							
							resetForm({values:''})
						}}

						//className="edit-team-form__form form scroll-parent"
						autocomplete="off"
						innerRef={formRef}
					>
					<table className="bulk-add-members-memeber-data__table">
						<tr className={"bulk-add-members__member-data__headers"}>
							<th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberNameLbl"
								)}
							</th>
							<th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberLastNameLbl"
								)}
							</th>
							<th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberEmailLbl"
								)}
								<Tooltip
									title={
										props.t("newTeam.forms.players.bulkMemberUpload.memberEmailTooltip")
									}
									placement="top"
									classes={{ tooltip: classes.customMessage }}
								>
									<ErrorIcon
										style={{
											fontSize: 20,
											color: "var(--new-green-color)",
											marginRight: "var(--space-quarter)",
											alignSelf: "center",
											verticalAlign: "sub",
											marginLeft: "5px",
										}}
									/>
								</Tooltip>
							</th>
							{/* <th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberCountryCodeLbl"
								)}
							</th> */}
							<th className={"bulk-add-members__member-data__header baloa-table-column"} colSpan={2}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberMobileNumberLbl"
								)}
								<Tooltip
									title={
										props.t("newTeam.forms.players.bulkMemberUpload.memberMobileNumberTooltip")
									}
									placement="top"
									classes={{ tooltip: classes.customMessage }}
								>
									<ErrorIcon
										style={{
											fontSize: 20,
											color: "var(--new-green-color)",
											marginRight: "var(--space-quarter)",
											alignSelf: "center",
											verticalAlign: "sub",
											marginLeft: "5px",
										}}
									/>
								</Tooltip>
							</th>
							<th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberNumberLbl"
								)}
							</th>
							<th className={"bulk-add-members__member-data__header baloa-table-column"}>
								{props.t(
									"newTeam.forms.players.bulkMemberUpload.memberBDateLbl"
								)}
							</th>
							<th></th>
							<th></th>
						</tr>
						<tbody>
						{!showUploadStatus && props.bulkMembers.members.length > 0 && (
							props.bulkMembers.members.map((member, idx) => (
								!(idx === editRow)?
									<tr
										key={idx}
										className={"bulk-add-members__member-data__row"}
									>
										<td
											className={`baloa-table bulk-add-members__member-data ${
												member.name.error ? "data-error" : ""
											}`}
										>
											{member.name.caption}
											{member.name.error ? (
												<Tooltip
													title={
													member.name.error_caption
													}
													placement="bottom-end"
													classes={{ tooltip: classes.errorMessage }}
												>
													<ErrorIcon
														style={{
															fontSize: 20,
															color: "var(--error-color)",
															marginRight: "var(--space-quarter)",
															alignSelf: "center",
														}}
													/>
												</Tooltip>
											) : (
												""
											)}
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data ${
												member.last_name.error ? "data-error" : ""
											}`}
										>
											{member.last_name.caption}
											{member.last_name.error ? (
												<Tooltip
													title={member.last_name.error_caption}
													placement="bottom-end"
													classes={{ tooltip: classes.errorMessage }}
												>
													<ErrorIcon
														style={{
															fontSize: 20,
															color: "var(--error-color)",
															marginRight: "var(--space-quarter)",
															alignSelf: "center",
														}}
													/>
												</Tooltip>
											) : (
												""
											)}
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data ${
												member.email.error ? "data-error" : ""
											}`}
										>										
											{(!member.email.caption && !member.mobile_number.caption)?
												<div className="bulk-add-members__member-data__empty-contact">
													<Tooltip
														title={props.t("newTeam.forms.players.bulkMemberUpload.emptyEmail&PhoneTooltip")}
														placement="bottom-end"
														classes={{ tooltip: classes.customMessage }}
													>
														<WarningIcon
															style={{
																fontSize: 20,
																color: "var(--primary-dark-color)",
																marginRight: "var(--space-quarter)",
																alignSelf: "center",
															}}
														/>
													</Tooltip>
												</div>
												:
												member.email.caption
											}
											{member.email.error ? (
												<Tooltip
													title={member.email.error_caption}
													placement="bottom-end"
													classes={{ tooltip: classes.errorMessage }}
												>
													<ErrorIcon
														style={{
															fontSize: 20,
															color: "var(--error-color)",
															marginRight: "var(--space-quarter)",
															alignSelf: "center",
														}}
													/>
												</Tooltip>
											) : (
												""
											)}
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data-num ${
												member.phone_country_code.error
													? "data-error"
													: ""
											}`}
										>
											<div className="baloa-table bulk-add-members__member-data-num__container">
												{member.phone_country_code.caption}
												{member.phone_country_code.error ? (
													<Tooltip
														title={
															member.phone_country_code.error_caption
														}
														placement="bottom-end"
														classes={{ tooltip: classes.errorMessage }}
													>
														<ErrorIcon
															style={{
																fontSize: 20,
																color: "var(--error-color)",
																marginRight: "var(--space-quarter)",
																alignSelf: "center",
															}}
														/>
													</Tooltip>
												) : (
													""
												)}
											</div>
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data ${
												member.mobile_number.error ? "data-error" : ""
											}`}
										>
											{(!member.email.caption && !member.mobile_number.caption)?
												<div className="bulk-add-members__member-data__empty-contact">
													<Tooltip
														title={props.t("newTeam.forms.players.bulkMemberUpload.emptyEmail&PhoneTooltip")}
														placement="bottom-end"
														classes={{ tooltip: classes.customMessage }}
													>
														<WarningIcon
															style={{
																fontSize: 20,
																color: "var(--primary-dark-color)",
																marginRight: "var(--space-quarter)",
																alignSelf: "center",
															}}
														/>
													</Tooltip>
												</div>
												:
												member.mobile_number.caption
											}
											{member.mobile_number.error ? (
												<Tooltip
													title={member.mobile_number.error_caption}
													placement="bottom-end"
													classes={{ tooltip: classes.customMessage }}
												>
													<ErrorIcon
														style={{
															fontSize: 20,
															color: "var(--error-color)",
															marginRight: "var(--space-quarter)",
															alignSelf: "center",
														}}
													/>
												</Tooltip>
											) : (
												""
											)}
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data-num ${
												member.shirtnumber.error ? "data-error" : ""
											}`}
										>
											<div className="baloa-table bulk-add-members__member-data-num__container">
												{member.shirtnumber.caption}
												{member.shirtnumber.error ? (
													<Tooltip
														title={member.shirtnumber.error_caption}
														placement="bottom-end"
														classes={{ tooltip: classes.customMessage }}
													>
														<ErrorIcon
															style={{
																fontSize: 20,
																color: "var(--error-color)",
																marginRight: "var(--space-quarter)",
																alignSelf: "center",
															}}
														/>
													</Tooltip>
												) : (
													""
												)}
											</div>
										</td>
										<td
											className={`baloa-table bulk-add-members__member-data-num ${
												member.birthdate.error ? "data-error" : ""
											}`}
										>
											<div className="baloa-table bulk-add-members__member-data-num__container">
												{member.birthdate.caption}
												{member.birthdate.error ? (
													<Tooltip
														title={member.birthdate.error_caption}
														placement="bottom-end"
														classes={{ tooltip: classes.customMessage }}
													>
														<ErrorIcon
															style={{
																fontSize: 20,
																color: "var(--error-color)",
																marginRight: "var(--space-quarter)",
																alignSelf: "center",
															}}
														/>
													</Tooltip>
												) : (
													""
												)}
											</div>
										</td>
										<td>
											<div className="bulk-add-members__member-data__icon-edit" onClick={ ()=> handleOnEditRow(idx, member) }>
												<img src={ edit_icon } alt=""/>
											</div>
										</td>
										<td>
											<span className="bulk-add-members__member-data__icon-delete icon-x" onClick={ ()=> {handleOnDeleteRow(idx)} } />
										</td>
									</tr>
								:								
									<tr>										
										<td className={'baloa-table bulk-add-members__member-data__edit'}>
											<InputFieldComponent
												//label={}
												input_id="name"
												field_name="name"
												validationFunction={RequiredValidator}
												disabled={false}
												className=""
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													handleName( value );
												}} */
												//message={}
												placeholder=""
												//value={name}
												defaultValue={member.name.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="last_name"
												field_name="last_name"
												validationFunction={RequiredValidator}
												disabled={false}
												className=""
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													//setState({...state.formValues, lastName: value});
												}} */
												//message={}
												placeholder=""
												//value={lastName}
												defaultValue={member.last_name.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="email"
												field_name="email"
												validationFunction={EmailValidator}
												disabled={false}
												className=""
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													//setState({...state.formValues, email: value});
												}} */
												//message={}
												placeholder=""
												// value={email}
												defaultValue={member.email.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="phone_country_code"
												field_name="phone_country_code"
												validationFunction={PhoneNumberValidator}
												disabled={false}
												className="bulk-add-members__member-data__edit__ccode"
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													//setState({...state.formValues, phoneCountryCode: value});
												}} */
												//message={}
												placeholder="+"
												// value={phoneCountryCode}
												defaultValue={member.phone_country_code.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="mobile_number"
												field_name="mobile_number"
												validationFunction={OnlyNumbersValidator}
												disabled={false}
												className=""
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													//setState({...state.formValues, mobileNumber: value});
												}} */
												//message={}
												placeholder=""
												// value={mobileNumber}
												defaultValue={member.mobile_number.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="shirtnumber"
												field_name="shirtnumber"
												validationFunction={OnlyNumbersValidator}
												disabled={false}
												className=""
												input_type="text"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													setShirtNumber( value );
												}} */
												//message={}
												placeholder=""
												// value={shirtNumber}
												defaultValue={member.shirtnumber.caption}
											/>
										</td>
										<td>
											<InputFieldComponent
												//label={}
												input_id="birthdate"
												field_name="birthdate"
												validationFunction={DateEmptyFormatValidator}
												disabled={false}
												className=""
												input_type="date"
												resetError={() => {}}
												error={errors}
												/* onChange={(value) =>{ 
													//setState({...state.formValues, birthDate: value});
												}} */
												//message={}
												placeholder=""
												// value={birthDate}
												defaultValue={member.birthdate.caption}
											/>										
										</td>
										<td>
											
											<TextButtonComponent
												onClick={() => {}}
												//className="bulk-add-members__buttons__button__download-button"
												type={"submit"}
											>
												<span className={"bulk-add-members__member-data__icon-save icon-check-single-72"} /* onClick={ ()=> saveRow(idx) } */></span>	
											</TextButtonComponent>								
										</td>
										<td>
											<span className="bulk-add-members__member-data__icon-delete icon-x" onClick={ ()=> setEditRow(null) } />
										</td>
										
									</tr>
									
						)))}
						{addBulkMember &&
							<tr>										
								<td className={'baloa-table bulk-add-members__member-data__edit'}>
									<InputFieldComponent
										//label={}
										input_id="name"
										field_name="name"
										validationFunction={RequiredValidator}
										disabled={false}
										className=""
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											handleName( value );
										}} */
										//message={}
										placeholder=""
										//value={name}
										//defaultValue={member.name.caption}
									/>
								</td>
								<td>
									<InputFieldComponent
										//label={}
										input_id="last_name"
										field_name="last_name"
										validationFunction={RequiredValidator}
										disabled={false}
										className=""
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											//setState({...state.formValues, lastName: value});
										}} */
										//message={}
										placeholder=""
										//value={lastName}
										//defaultValue={member.last_name.caption}
									/>
								</td>
								<td>
									<InputFieldComponent
										//label={}
										input_id="email"
										field_name="email"
										validationFunction={EmailValidator}
										disabled={false}
										className=""
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											//setState({...state.formValues, email: value});
										}} */
										//message={}
										placeholder=""
										// value={email}
										//defaultValue={member.email.caption}
									/>
								</td>
								<td className="bulk-add-members__member-data__edit__ccode">
									<InputFieldComponent
										//label={}
										input_id="phone_country_code"
										field_name="phone_country_code"
										validationFunction={PhoneNumberValidator}
										disabled={false}
										className="bulk-add-members__member-data__edit__ccode"
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											//setState({...state.formValues, phoneCountryCode: value});
										}} */
										//message={}
										placeholder="+"
										// value={phoneCountryCode}
										//defaultValue={member.phone_country_code.caption}
									/>
								</td>
								<td>
									<InputFieldComponent
										//label={}
										input_id="mobile_number"
										field_name="mobile_number"
										validationFunction={OnlyNumbersValidator}
										disabled={false}
										className=""
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											//setState({...state.formValues, mobileNumber: value});
										}} */
										//message={}
										placeholder=""
										// value={mobileNumber}
										//defaultValue={member.mobile_number.caption}
									/>
								</td>
								<td>
									<InputFieldComponent
										//label={}
										input_id="shirtnumber"
										field_name="shirtnumber"
										validationFunction={OnlyNumbersValidator}
										disabled={false}
										className=""
										input_type="text"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											setShirtNumber( value );
										}} */
										//message={}
										placeholder=""
										// value={shirtNumber}
										//defaultValue={member.shirtnumber.caption}
									/>
								</td>
								<td>
									<InputFieldComponent
										//label={}
										input_id="birthdate"
										field_name="birthdate"
										validationFunction={DateEmptyFormatValidator}
										disabled={false}
										className=""
										input_type="date"
										resetError={() => {}}
										error={errors}
										/* onChange={(value) =>{ 
											//setState({...state.formValues, birthDate: value});
										}} */
										//message={}
										placeholder=""
										// value={birthDate}
										//defaultValue={member.birthdate.caption}
									/>										
								</td>
								<td>
									
									<TextButtonComponent
										onClick={() => {}}
										className="bulk-add-members__buttons__button__save-button"
										type={"submit"}
									>
										<span className={"bulk-add-members__member-data__icon-save icon-check-single-72"} ></span>	
									</TextButtonComponent>								
								</td>
								<td>
									<span className="bulk-add-members__member-data__icon-delete icon-x" onClick={ ()=> setAddBulkMember(false) } />
								</td>
								
							</tr>
						}
						</tbody>
					</table>
					</FormComponent>
					<div>
						{!showUploadStatus && props.bulkMembers.members.length === 0 && !addBulkMember && (
							<div className="bulk-add-members__template-area baloa-subtitle-2">
								{props.t("newTeam.forms.players.bulkMemberUpload.emptyTemplateLbl")}
							</div>
						)}
						{showUploadStatus && (
							<div className="bulk-add-members__loader">
								<div className="bulk-add-members__loader-msg baloa-headline-5">
									{props.t(
										"newTeam.forms.players.bulkMemberUpload.uploadLbl"
									)}
								</div>
								<LinearProgress
									variant="determinate"
									value={props.uploadProgress}
									className="bulk-add-members__loader-bar"
								/>
							</div>
						)}

						<input
							style={{ display: "none" }}
							type="file"
							onChange={(e) => {
								onFileChange(e);
							}}
							ref={(file_input) => (bulkFile.file_input_ref = file_input)}
							accept=".xlsx"
							name={"file"}
						/>
					</div>
				</React.Fragment>
				
			</div>

			<div className="bulk-add-members__buttons__back">
				{(props.isTournamentHub === undefined) &&
					<PrimaryButtonComponent 
						disabled={props.bulkMembers.members.length <= 0 || props.bulkMembers.error}
						onClick={() => {
							props.handleOnSaveBulkButton();
						}}
						className="bulk-add-members__buttons__back__button"
						type="submit"
					>
						<span>
							{props.t(
								"newTeam.forms.players.bulkMemberUpload.saveMembersBtn"
							)}
						</span>
					</PrimaryButtonComponent> 
				}
				
			</div>
		</div>
	);
}

export default withTranslation("team")(LeagueBulkAddMembersComponentV2);
