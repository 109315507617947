/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayAddToPostModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

function BaloaPlayAddToPostModalComponent( props ) {

    const videoRef = React.useRef();

    const [title, setTitle] = React.useState('');
    const [playing, setPlaying] = React.useState(false);
    const formInitialValues = {
        title: props.clip_title
    }

    const playVideo = () => {
        videoRef.current.play();
        setPlaying(true);
    }
    
    const pauseVideo = () => {
        videoRef.current.pause();
        setPlaying(false);
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={ props.is_featured? props.t('featuredClipLbl') : props.t('presentationVideoLbl')}
            className="baloa-play-add-to-post-modal"
        >
            <div className={`baloa-play-add-to-post-modal__container `}>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { }}
                        className = "baloa-play-add-to-post-modal__form"                        
                    >
                        <InputFieldComponent
                            label={ props.is_featured? props.t('featuredClipTitleLbl') : props.t('presentationVideoTitleLbl')}
                            input_id=""
                            field_name="title"
                            className={'baloa-play-add-to-post-modal__title'}
                            validationFunction={()=>{}}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={{'title':''}}
                            message=""
                            onChange={(v) => { setTitle(v)}}
                            defaultValue={props.clip_title? props.clip_title :''}
                        />
                        <div className='baloa-play-add-to-post-modal__video'>
                            <ReactHlsPlayer
                                className=''
                                playerRef={videoRef}
                                src={props.clip_video?.post_stream?.hls}
                                preload="auto"
                                autoPlay={false}
                                playsinline
                                width={'100%'}
                                height={'100%'}
                                loop={true}
                                poster={props.clip_video?.post_stream?.thumbnail ? props.clip_video?.post_stream?.thumbnail : ""}
                                controls={false}
                                
                            />
                            <div className='baloa-play-add-to-post-modal__play-button' onClick={playing? ()=>{pauseVideo()} : ()=>{playVideo()}}>                                
                            </div>
                        </div>
                    </FormComponent>
                    <div className='baloa-play-add-to-post-modal__buttons'>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{ props.handleOnPublish(title)}}
                            className="small"
                        >
                            <span>{props.t('publishBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>  
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayAddToPostModalComponent);