// React
import React from 'react';

//Styles
import './BaloaPlayProWelcomeModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayProWelcomeModalComponent ( props ){
    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className="baloa-play-pro-welcome-modal"
        >
            <div className='baloa-play-pro-welcome-modal__container'>
                <span className='hubicon-award_star'/>
                <div className='baloa-play-pro-welcome-modal__title'>{props.t('welcomeWidget.title')}</div>
                <div className='baloa-play-pro-welcome-modal__message'>{props.t('welcomeWidget.message')}</div>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={()=>{props.onClose()}}
                >
                    <span>{props.t('welcomeWidget.btnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaPlayProWelcomeModalComponent);
