// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-decision-dialog .modal__container__close {
    top: 20px;
    margin-right: var(--space);
}

.modal-decision-dialog__title {
    padding-top: var(--space-and-half);
    max-width: 444px;
}

.modal-decision-dialog__text{
    max-width: 280px;
    margin-top: calc(var(--space-half) + 2px);
}

.modal-decision-dialog__actions {
    margin-top: var(--space-x2);
}

.modal-decision-dialog__actions {
    border-top: 1px solid #ECECEC;
    display: flex;
    justify-content: flex-end;
    padding-top: var(--space-half);
}

.modal-decision-dialog__button {
    flex-basis: 110px;
}

.modal-decision-dialog__button.primary_button_waring{
    background-color: var(--error-color);
}

.modal-decision-dialog__button.secondary_button{
    margin-right: var(--space);
    border: 2px solid var(--basic-gray-color);
    color: var(--basic-gray-color);
}

.modal-decision-dialog__button.secondary_button:hover{
    background-color: var(--white-color);
    color: var(--basic-gray-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/modal-decision-dialog/ModalDecisionDialogComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,0BAA0B;IAC1B,yCAAyC;IACzC,8BAA8B;AAClC;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;AAClC","sourcesContent":[".modal-decision-dialog .modal__container__close {\n    top: 20px;\n    margin-right: var(--space);\n}\n\n.modal-decision-dialog__title {\n    padding-top: var(--space-and-half);\n    max-width: 444px;\n}\n\n.modal-decision-dialog__text{\n    max-width: 280px;\n    margin-top: calc(var(--space-half) + 2px);\n}\n\n.modal-decision-dialog__actions {\n    margin-top: var(--space-x2);\n}\n\n.modal-decision-dialog__actions {\n    border-top: 1px solid #ECECEC;\n    display: flex;\n    justify-content: flex-end;\n    padding-top: var(--space-half);\n}\n\n.modal-decision-dialog__button {\n    flex-basis: 110px;\n}\n\n.modal-decision-dialog__button.primary_button_waring{\n    background-color: var(--error-color);\n}\n\n.modal-decision-dialog__button.secondary_button{\n    margin-right: var(--space);\n    border: 2px solid var(--basic-gray-color);\n    color: var(--basic-gray-color);\n}\n\n.modal-decision-dialog__button.secondary_button:hover{\n    background-color: var(--white-color);\n    color: var(--basic-gray-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
