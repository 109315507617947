// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './BaloaProUnauthSubscriptionModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function BaloaProUnauthSubscriptionModalComponent ( props ){
    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-unauth-subscription-modal'}
        >
            <div className='baloa-pro-unauth-subscription-modal__container'>
                <span className='hubicon-award_star'/>
                <div className='baloa-headline-2'>{props.t('baloaProHeader.title')}</div>
                <div className='baloa-names'>{props.t('unauthSubscriptionModal.message')}</div>
                
                
                <div className='baloa-pro-unauth-subscription-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={''}
                        onClick={ ()=>{props.handleLogin()} }
                    >
                        <span>{props.t('unauthSubscriptionModal.loginBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloapro')(BaloaProUnauthSubscriptionModalComponent);
