// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show_message_container{
    justify-content: center;    
    margin-top: calc(var(--space) * 4);
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
    max-width: 228px;
}
.show_message_container img {
    width: 150px;
    height: 150px;
}

.show_message_container .baloa-names {
    color: var(--primary-dark-color);
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/show-message/ShowMessageComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB","sourcesContent":[".show_message_container{\n    justify-content: center;    \n    margin-top: calc(var(--space) * 4);\n    margin-left: auto;\n    margin-right: auto;\n    width: fit-content;\n    text-align: center;\n    max-width: 228px;\n}\n.show_message_container img {\n    width: 150px;\n    height: 150px;\n}\n\n.show_message_container .baloa-names {\n    color: var(--primary-dark-color);\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
