/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { compose } from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

// Actions
import {
    loadUnauthenticatedHubTournaments,
    loadUnauthenticatedSelectedTournament,
    loadUnauthenticatedHubTournamentTeams,
    loadUnauthenticatedHubTournamentsFilter,
    loadHubTournaments, 
    loadHubTournamentTeams, 
    changeTournamentTeamState,
    loadAllManagedTournaments,
    loadProfileAdminTeams,
    loadHubTournamentsFilter,
    changeTournamentPlayerState,
    loadTournamentInscriptionFormTemplate,
    loadTournamentPlayerFormTemplate,
    loadHubTournamentsSections,
    loadHubTournamentsSubsections,
    loadInformativeTournament,
    getHubTournamentSearch,
    loadPhaseCalendar,
    loadSelectedTournament,
    loadPhaseCalendarGroupMatches,
    loadPhaseGroupsByDate,
    loadPhaseMatchesByGroupDate,
    loadFrontTournamentTeam,
} from 'app/tournaments/actions/TournamentActions';

import {
    getCountries,
    searchCityByCountry,
    loadTournamentPhases,
    loadPhaseResultsTable,
    loadPhaseDates,
    loadPhaseMatchesByDate,
    loadTournamentInfo,
    loadTeamsToMatchAssistanceAligment,
    loadPhaseGroupPositionsTable,
    loadTournamentScorersTable,
    loadYellowCardsTable,
    loadRedCardsTable,
    loadTournamentMembersTeams,
} from 'app/leagues/actions/LeagueTournamentActions';
import { loadMatchReport } from 'app/leagues/actions/LeagueMatchActions';

// Actions Creators
import { showHubTournamentDetailAction, 
    setHubTournamentHubAction, 
    setHubTournamentInscriptionsAction, 
    setHubTournamentMyTournamentsAction, 
    changeResponsiveHubModalStateAction,
    clearHubTournamentSubsectionAction,
    changeShareInscriptionLinkModalStateAction,
    changeIsShareLinkStateAction,
    changeStaffInvitationModalStateAction,
    resetCalendarGroupMatchesAction,
    resetPhaseDateMatchesAction,
    changeShareIframeLinkStateAction,
    changeHubTournamentViewMoreTeamsModalStateAction,
} from '../actions/TournamentActionsCreators';
import { changeTeamMembersModalStateAction } from 'app/pages/actions/PageActionsCreators'
import { changeSignUpLoginModal, changeClickType, setUrlRedirect } from 'app/authentication/actions/LoginActionsCreators';
import {changeErrorMessageSnackBar, } from 'app/home/actions/HomeActionsCreators';
import { changeMatchDetailModalStateAction } from 'app/posts/actions/PostsActionsCreators';
import { changeTournamentViewMoreStatisticsModalStateAction,} from 'app/leagues/actions/LeagueTournamentActionsCreators';

//Components
// import TournamentGridComponent from 'app/tournaments/components/tournament-grid/TournamentGridComponent';
import TournamentDetailComponent from 'app/tournaments/components/tournament-detail/TournamentDetailComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import HubTournamentSectionComponent from '../components/hub-tournament-section/HubTournamentSectionComponent';
import HubTournamentSectionCardComponent from '../components/hub-tournament-section-card/HubTournamentSectionCardComponent';
import TournamentHubFilterComponent from '../components/tournament-hub-filter/TournamentHubFilterComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputSearchWithButtonComponent from 'shared/components/input-search-with-button/InputSearchWithButtonComponent';

// Modals
import ResponsiveHubModalComponent from 'app/tournaments/components/responsive-hub-modal/ResponsiveHubModalComponent';
import ShareInscriptionLinkModalComponent from 'app/tournaments/components/share-inscription-link-modal/ShareInscriptionLinkModalComponent';
import StaffInvitationModalComponent from '../components/staff-invitation-modal/StaffInvitationModalComponent';
import MatchDetailModalComponent from 'app/posts/components/match-detail-modal/MatchDetailModalComponent';
import ShareIframeLinkModalComponent from '../components/share-iframe-link-modal/ShareIframeLinkModalComponent';
import TournamentViewMoreStatisticsModalComponent from 'app/leagues/components/tournament-view-more-statistics-modal/TournamentViewMoreStatisticsModalComponent';
import HubTournamentViewMoreTeamsModalComponent from '../components/hub-tournament-view-more-teams-modal/HubTournamentViewMoreTeamsModalComponent';
import TournamentTeamMembersModalComponent from 'app/leagues/components/tournament-team-members-modal/TournamentTeamMembersModalComponent';

// Variables
import { web_url } from "config/GlobalData";

// Styles
import './TournamentHubView.styles.css';

//Assets
import loading_baloa from 'assets/images/loading-baloa.gif';
import loading_ball from 'assets/images/loading_ball.gif';
import search_off from 'assets/icons/search_off.svg';
class TournamentHubUnauthenticatedView extends React.Component {

    constructor( props ) {
        super( props );
        this.payment_response = this.props.location.pathname;
        this.state = {
            selected_tournament_team: null,
            selected_tournament_teamname: null,
            principalButtonActionLink:'',
            tournament_tag: [
                { value: 'Free', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            selected_tournament_team_logo: null,
            start_flow: null,
            selected_tournament_team_state: null,
            selected_tournament_registration_type: null,
            is_inscription: false,
            profile_type: undefined,
            load_detail: false,
            active_phase_id: '',
            groups_matches_by_date: [],
            tournament_active_phase: {},
            days_calendar_view:[],
            filter_component_activation: false,
            path_next_page:'',
            word_filter: '',
            is_subsection: false,
            staff_profile: undefined,
            statistic_data_type: null,
            modal_selected_team:{},
        }
        this.referees_payment_response = this.props.match?.params;
    }
    componentDidMount() {
        this.setViewPath(this.props.history.location.pathname);
        if(this.props.location.pathname === '/tournament/hub/search'){
            this.setState({filter_component_activation: true});
        }

        /**Refereee an Admin inviation */
        let staff_invitation = this.props.location.search.split('staff=');
        if(staff_invitation.length > 1){
            this.props.changeStaffInvitationModalStateAction(true);
            this.setState({staff_profile: staff_invitation[1]})
        }
        if(this.referees_payment_response.type === 'referee' || this.referees_payment_response.type === 'match') {
            this.props.loadTournamentInfo(this.referees_payment_response.tournamentid)
            if(this.referees_payment_response.type === 'match'){ //social share de matches -> se apunta a detail desde hub, NO desde inscripciones
                if(this.referees_payment_response.participate === 'true'){
                    this.handleOnViewHubTournament({id:this.referees_payment_response.tournamentid}, 'hub', true);
                }else{
                    this.handleOnViewHubTournament({id:this.referees_payment_response.tournamentid}, 'hub', false);//No participa en el torneo
                } 
            }
            
            this.props.loadTournamentPhases(this.referees_payment_response.tournamentid);
        }
    }

    componentDidUpdate(prevProps){

        let tournament_detail_path = this.props.location.pathname.split('/hub/');
        if(tournament_detail_path.length > 1 && !this.state.load_detail){
            //this.props.history.push('/tournament/hub');
            if(tournament_detail_path[1] !== 'search'){
                this.handleOnViewHubTournament({id:tournament_detail_path[1]}, 'hub');
            }
        } 

         /** Validacion hub tournament recomended */
        let participate_state = this.props.location.search.split('participate=');
        
        if((this.props.match.params.tournamentid && !this.state.load_detail) || (prevProps.match.params?.tournamentid !== this.props.match.params?.tournamentid)){
            if(participate_state.length > 1){//No participa en el torneo
                this.handleOnViewHubTournament({id:this.props.match.params.tournamentid}, 'hub', false);
            }else{
                this.handleOnViewHubTournament({id:this.props.match.params.tournamentid}, 'hub', true);
            }          
        }

        if(this.props.location.pathname !== '/tournament/hub/search' && this.state.filter_component_activation){
            this.setState({filter_component_activation: false, is_subsection: false, word_filter: ''});
        }
        
    }

    setViewPath = (path) => {
        let selPath = path.split('/');
        this.handleBackTournamentViewMore();        

        switch (selPath[2]) {
            case "hub": {
                this.setState({is_path: false});
                this.props.loadHubTournamentsSections();
                this.props.loadUnauthenticatedHubTournaments();
                this.props.setHubTournamentMyTournamentsAction(false);
                this.props.setHubTournamentHubAction(true);
                return true;
            }
            // no default
        }
    }

    handleOnViewHubTournament = async (tournament, from, participate) => {
        this.setState({load_detail: true});
        this.props.loadUnauthenticatedHubTournamentTeams(tournament.id, 'Enrolled', 10);
        if(participate){
            await this.props.loadSelectedTournament(tournament.id); //TODO:revisar con el nuevo hub
        }else{//No participa en el torneo
            await this.props.loadInformativeTournament(tournament.id);
        }
        //Carga de nuevo el torneo en el estado si el usuario no participa en el (cuando el parametro no viene en la url)
        if(!this.props.state.tournaments?.selected_tournament){
            await this.props.loadInformativeTournament(tournament.id);
        }
        await this.props.loadTournamentInfo(tournament.id)
        this.props.loadTournamentPhases(tournament.id)
        if(this.props.state.tournaments?.selected_tournament?.tournament_team_id){
            this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament?.tournament_team_id, 'Pending|Enrolled|Rejected|Late_Register');
        }
        this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament?.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament?.teamname, 
            selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament?.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament?.tournament_team_id,
            selected_tournament_team_state: this.props.state.tournaments?.selected_tournament?.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament?.tournament_registration_type,
            selected_tournament_player_id: this.props.state.tournaments?.selected_tournament?.tournament_player_id
        });
        this.props.showHubTournamentDetailAction(true);  
    }

    handleDetailBackButton = () => {
        this.props.showHubTournamentDetailAction(false);//TODO: verificar comportamiento con nuevo hub  
        this.props.setHubTournamentHubAction(true); 
        if(this.props.history.location.pathname.includes('/tournament/hub')){
            this.setState({load_detail: false});
            this.props.history.push('/tournament/hub');
        }else{
            this.setViewPath(this.props.history.location.pathname);
            this.setState({ is_inscription: false });   
        }
    }

    handleCleanFilterHub = () => {
        this.props.loadHubTournaments();
        this.props.loadHubTournamentsSections();
        // this.props.loadUnauthenticatedHubTournaments();
        this.props.setHubTournamentHubAction(true);
    }

    handleOnFilterTournamentHub(filter) {
        // this.props.loadUnauthenticatedHubTournamentsFilter(filter.type_filter ?? '');
        this.props.loadHubTournamentsFilter(filter.type_filter ?? '');
        this.props.setHubTournamentHubAction(true);
    }

    handleOnLoadMoreTournaments = (subsection) =>{
        if(this.props.state.tournaments?.hub_tournament_next_page !== null){
           this.props.loadHubTournamentsSubsections(subsection, this.props.state.tournaments?.loading_tournaments, this.props.state.tournaments?.hub_tournament_next_page );
        }
    }

    cleanMyTeams(my_teams){
        let myTeams=[];
        my_teams.forEach(element => {
            myTeams.push({ value: element.tournament_team_id, id: element.id, team: element.team, team_logo: element.team_logo, teamname: element.teamname  });
        });
        
        return myTeams;
    }

    handleHubTournamentSection = async (tournament, section_hub) => {
        this.setState({load_detail: true});
        await this.props.loadTournamentInfo(tournament.tournament_id)
        this.props.loadTournamentPhases(tournament.tournament_id)
        this.props.history.push('/tournament/hub/'+tournament.tournament_id+'?participate=false');
    }

    handleOnSubsectionViewMore = (subsection) => {
        if(subsection === 'tournament_invitation') {
            this.props.history.push('/tournament/invitations');
        } else if(subsection === 'tournament_participate') {
            this.props.history.push('/tournament/inscriptions');
        } else if(subsection === 'tournament_admin'){
            this.props.history.push('/tournament/my-tournaments');
        } else {            
            this.setState({tournament_subsection: subsection});
            this.setState({is_subsection: true});
            this.props.loadHubTournamentsSubsections(subsection);
        }
    }

    handleBackTournamentViewMore = () => {
        this.setState({tournament_subsection: ''});
        this.setState({is_subsection: false});
        this.setState({load_detail: false});
        this.props.clearHubTournamentSubsectionAction();
    }


    handleFilterSearchTournament = (query_params) => {
        this.setState({tournament_subsection: query_params.state});
        this.setState({is_subsection: true});
        this.setState({filter_component_activation: true});
        this.props.history.push('/tournament/hub/search');

        if(query_params.state === 'registration_open') {
            query_params.state = 'Registration_Open'
        } 
        if(query_params.state === 'in_progress') {
            query_params.state = 'Scheduled'
        }
        let queryParams = '';
        
        if(this.state.word_filter.length > 0) {
            queryParams = `tournament_name=${this.state.word_filter}`;
        }
        for (var key in query_params) {
            if ( query_params[key].length > 0 ) {
                queryParams += `&${key}=${query_params[key]}`;
            }
        }
        
        if(this.state.path_next_page.length > 0 && (this.state.path_next_page !== query_params)){
            this.props.clearHubTournamentSubsectionAction();
            this.props.getHubTournamentSearch(queryParams, this.props.state.tournaments?.loading_tournaments, null);
        }else{
            this.setState({path_next_page: queryParams});
            this.props.clearHubTournamentSubsectionAction();
            this.props.getHubTournamentSearch(queryParams, this.props.state.tournaments?.loading_tournaments, this.props.state.tournaments?.hub_tournament_next_page);
        }
    }

    handleOnShareInscriptionTournament(tournament_team_id){
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/tournament/hub/team/${tournament_team_id}/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.tournament_id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareInscriptionTournamentLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_inscription_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnRejectInscription(){
        this.props.changeIsShareLinkStateAction(false);
        this.props.changeFlexiInscriptionModalStateAction(false);        
        this.props.history.replace({
            pathname: '/tournament/hub',
            search: '',
        });
    }

    handleHomeAnonModal = (modal_state, click_type, tournament_redirect = null) => {
        this.props.changeSignUpLoginModal(modal_state);
        this.props.changeClickType(click_type);
        if(tournament_redirect){
            let url_redirect = '?tournament/hub/'+tournament_redirect.id;
            this.props.setUrlRedirect(url_redirect);
        }
    }
    
    handleTeamInscription = (modal_state, click_type, tournament_redirect = null ) => {
        this.props.changeSignUpLoginModal(modal_state);
        this.props.changeClickType(click_type);
        if(tournament_redirect){
            let url_redirect = '?tournament/hub/'+tournament_redirect.id;
            this.props.setUrlRedirect(url_redirect);
        }
    }

    handleOnFollowTournament = (modal_state,click_type ) =>{
        this.props.changeSignUpLoginModal(modal_state);
        this.props.changeClickType(click_type);
    }

    handleOnPhaseClick = async (phase, tournament_phase_id) => {
        this.setState({tournament_active_phase: phase, active_phase_id: phase.id });
        if(phase.best_third){
            this.setState({is_best_third: true});
        } else {
            this.setState({is_best_third: false});
        }
        this.props.loadPhaseResultsTable(phase?.id);
        await this.props.loadPhaseDates(phase?.id);
        await this.props.loadPhaseCalendar(tournament_phase_id);
        //await this.props.loadPhaseMatchesByDate(phase?.id, phase?.calendar_dates[phase?.calendar_dates.length - 1].calendar_date); 
        this.handleGroupsMatchByDate();
    }

    handleGroupsMatchByDate = () => {
        let groups_by_date = [];
        for(let i = 0; i < this.props.state.leagueTournaments.tournament_results.selected_date_matches.length; i++ ) {
            groups_by_date.push(this.props.state.leagueTournaments.tournament_results.selected_date_matches[i].group_match);            
        }
        let filtered_groups = [...new Set(groups_by_date)]
        this.setState({groups_matches_by_date: filtered_groups})
    }

    handleOnPhaseDate = async (date) => {
        //await this.props.loadPhaseMatchesByDate(this.state.active_phase_id, date);
        //this.handleGroupsMatchByDate();
        await this.props.resetPhaseDateMatchesAction();
        await this.props.loadPhaseGroupsByDate(this.state.active_phase_id, date);
    }

    
    handleMatchStatistics = (match_id) => {
        this.props.loadMatchReport(match_id);
    }

    handleOnMatchDetailModal = () => {
        this.props.changeMatchDetailModalStateAction(true);
    }

    handleValidationFilterComponent = ( word ) => {
        if(word.length >= 3){
            this.setState({filter_component_activation: true});
            this.handleFilterSearchTournament({tournament_name: word});
            this.setState({word_filter: word });
        }else{
            this.setState({filter_component_activation: false, is_subsection: false, word_filter: ''});
        }
    }

    handleTranslateFootballTypes () {
        let football_type_array = [];
        this.props.state.lineups.alignments.map((type) => {
            football_type_array.push(
                { "value": type.description, "content": this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${type.description}`)
            });
            return 'none'
        });
        return football_type_array;
    }
    
    handleStaffInscriptionButton = () => {
        this.props.changeSignUpLoginModal(true);
        this.props.changeClickType('homeAnonStaffInvitation');        
        if(this.state.staff_profile){
            let url_redirect = '?tournament/hub/'+this.props.state.tournaments.selected_tournament.id+'?staff=' + this.state.staff_profile;
            this.props.setUrlRedirect(url_redirect);
        }
    }

    handleOnRejectStaffInvitation = () => {
        this.props.history.push('/');
        this.props.changeStaffInvitationModalStateAction(false);
    }

    handleOpenShareIframeLinkModal(tournament_id, iframe){
        this.props.changeShareIframeLinkStateAction(true);
        let codeIframe = "";
        if(iframe === 'results_table'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/team-positions-table/${tournament_id}/">
            </iframe>`
        }else if(iframe === 'matches'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/tournament-matches/${tournament_id}/">
            </iframe>`
        }
        
        this.setState( {share_iframe_url: codeIframe } );
    };
    
    handleOnShareIFramePositionTableLink = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_iframe_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareMatchLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/match/${this.props.state.leagueMatches.match_report.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOpenBaloaLanding = () => {
        const urlToOpen = 'https://info.baloa.com/';
        window.open(urlToOpen, '_blank');
    }
    
    handleOnPhaseCalendar = async (tournament_phase_id) => {
        await this.props.loadPhaseCalendar(this.state.active_phase_id, tournament_phase_id );
    }

    async handleOnMatchday( tournament_group_id, date_number, next_url ) {
        if(!next_url){
            await this.props.resetCalendarGroupMatchesAction();
        }
        await this.props.loadPhaseCalendarGroupMatches( tournament_group_id, date_number, next_url );
    }

    async handleOnDateGroup( tournament_phase_id, selected_date, group_number, next_url ){
        if(!next_url){
            await this.props.resetPhaseDateMatchesAction();
        }
        await this.props.loadPhaseMatchesByGroupDate( tournament_phase_id, selected_date, group_number, next_url );
    }

    loadStatistics( tournament_id ){
        this.props.loadTournamentScorersTable(tournament_id);
        this.props.loadYellowCardsTable(tournament_id);
        this.props.loadRedCardsTable(tournament_id);
    }

    handleViewMoreStatistics(tournament_id, type){
        this.setState({statistic_data_type: type});
        switch (type){
            case 'scorer':
                this.props.loadTournamentScorersTable(tournament_id, 10);
                break;
            case 'yellow-card':
                this.props.loadYellowCardsTable(tournament_id, 10);
                break;
            case 'red-card':
                this.props.loadRedCardsTable(tournament_id, 10);
                break;
            //no default
        }
        this.props.changeTournamentViewMoreStatisticsModalStateAction(true);
    }

    loadMoreStatistics(next_url){
        switch (this.state.statistic_data_type){
            case 'scorer':
                this.props.loadTournamentScorersTable(null, null, next_url);
                break;
            case 'yellow-card':
                this.props.loadYellowCardsTable(null, null, next_url);
                break;
            case 'red-card':
                this.props.loadRedCardsTable(null, null, next_url);
                break;
            //no default
        }
    }

    handleViewMoreTeams(tournament_id, state, page_size){
        this.props.loadUnauthenticatedHubTournamentTeams(tournament_id, state, page_size);
        this.props.changeHubTournamentViewMoreTeamsModalStateAction( true )
    }

    loadMoreTeams(next_url){
        this.props.loadUnauthenticatedHubTournamentTeams(null, null, null, next_url);
    }

    handleTeamMembersModalPodium = async (tournament_team_id) => {
        await this.props.loadFrontTournamentTeam(tournament_team_id);
        this.setState({modal_selected_team: this.props.state.tournaments.selected_podium_tournament_team});
        this.props.loadTournamentMembersTeams( this.props.state.tournaments.selected_podium_tournament_team?.id);
        this.props.changeTeamMembersModalStateAction(true);
    }

    handleOnProfileClick = ( profile ) => {
        this.props.history.push(`/profile/${profile}`);
    }

    render() {

        return (
            <div className='tournament-hub'>
                <div className="tournament-hub__left-container">
                </div>
                <div className="tournament-hub__center-container">
                    {this.props.state.tournaments.show_hub && !this.state.load_detail &&
                        <React.Fragment>
                            {/* <div className="tournament-hub__center-container-title baloa-headline-2">
                                {this.props.t('menu.hubLabelTitle')}
                            </div> */}
                            {!isMobile &&
                                <div className="tournament-hub__center-container-create_tournament">
                                <div className='baloa-headline-1'>{this.props.t('viewTournament.hubTournamentsTitle')}</div>
                                <div className='baloa-subtitle-1'>{this.props.t('menu.labelCreateTournament')}</div>
                                {/* <div className="tournament-hub__center-container-create_tournament_information">
                                    <span className="tournament-hub__center-container-create_tournament_icon icon-trophy" />
                                    <p clasName="baloa-normal-medium">{this.props.t('menu.labelCreateTournament')}</p>
                                </div> */}
                                {/* <div className="tournament-hub__center-container-create_tournament_button"> */}
                                <div className='tournament-hub__banner-container' >
                                    <div class="tournament-hub__search-tournament">
                                        <InputSearchWithButtonComponent
                                            cleanValue={false}
                                            handleOnSearchClick={ (search)=>{ this.handleValidationFilterComponent(search) } }
                                        />
                                    </div>
                                    <div className='tournament-hub__header-actions'>
                                        <PrimaryButtonComponent
                                            input_type='button'
                                            className=''
                                            onClick={ () => {this.handleHomeAnonModal(true, 'homeAnonTournament')} }
                                        >
                                            <span>{this.props.t('league:options.tournaments.pages.newTournament.labelTitle')}</span>
                                        </PrimaryButtonComponent>
                                        <SecondaryButtonComponent 
                                            type="button" 
                                            onClick={ () => this.handleOpenBaloaLanding() }
                                            className="tournament-hub__baloa-landing"
                                        >
                                            {this.props.t('landingBaloaBtn')}<span className="hubicon-open_in_new" /> 
                                        </SecondaryButtonComponent>
                                    </div>
                                </div>
                            </div>}
                            { isMobile &&
                                <div className="tournament-hub__center-container-create_tournament">
                                    <div className='baloa-headline-1'>{this.props.t('viewTournament.hubTournamentsTitle')}</div>
                                    <div className='baloa-subtitle-1'>{this.props.t('menu.labelCreateTournament')}</div>
                            
                                    <div className='tournament-hub__mobile__filter-search'>
                                        <div className='tournament-hub__mobile__filter-search__btns-action'>
                                            <PrimaryButtonComponent
                                                input_type='button'
                                                className='small tournament-hub__search-tournament__button'
                                                onClick={()=> {this.handleHomeAnonModal(true, 'homeAnonTournament')}}
                                            >
                                                <span>{this.props.t('league:options.tournaments.pages.newTournament.labelTitle')}</span>
                                            </PrimaryButtonComponent>
                                            <SecondaryButtonComponent 
                                                type="button" 
                                                onClick={ () => this.handleOpenBaloaLanding() }
                                                className="tournament-hub__baloa-landing"
                                            >
                                                {this.props.t('landingBaloaBtn')}<span className="hubicon-open_in_new" /> 
                                            </SecondaryButtonComponent>
                                        </div>
                                        <div class="tournament-hub__search-tournament">
                                            <InputSearchWithButtonComponent
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ this.handleValidationFilterComponent(search) } }
                                            />
                                        </div>                                                                        
                                    </div>
                                    
                                </div>
                            }
                            {this.state.filter_component_activation && 
                                <TournamentHubFilterComponent
                                    tournament_tag={this.state.tournament_tag}
                                    word_to_filter={this.state.filter_component_activation}
                                    handleTranslateFootballTypes={this.handleTranslateFootballTypes()}
                                    handleFilterSearchTournament={(filterparams)=>this.handleFilterSearchTournament(filterparams)}
                                    handleSearchWorldCities = {( searchedcity ) => this.props.searchWorldCities(searchedcity )}
                                    world_cities={this.props.state.leagueTournaments.world_cities}
                                    tournament_subsection={this.state.tournament_subsection}
                                />
                            }
                            </React.Fragment>
                    }
                    {this.props.state.tournaments.show_hub && !this.state.is_subsection && !this.state.load_detail &&
                        <React.Fragment>
                            { this.props.state.tournaments?.hub_tournaments_sections?.map((section, index) =>                                
                                    <React.Fragment key={index}>
                                        {section.section === "recommended_tournaments" &&
                                            <HubTournamentSectionComponent 
                                                section_title={section.section}
                                                section_data={section.section_data}
                                                handleOnViewTournament={(tournament, section_hub) => {this.handleHubTournamentSection(tournament, section_hub)}}
                                                handleOnSubsectionViewMore={ (subsection) => {this.handleFilterSearchTournament({state: subsection})} }
                                                handleOnLoadMoreTournaments={ (subsection)=>{this.handleOnLoadMoreTournaments(subsection) } }
                                                handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }
                                            />
                                        }
                                    </React.Fragment>
                                    )
                                }
                                {/* <TournamentGridComponent
                                    tournament_tag={this.state.tournament_tag}
                                    handleBackButton={() => this.handleCleanFilterHub()}
                                    onResetError={(data) => { this.props.changeTournamentBasicDataError(data) }}
                                    errors={this.props.state.leagueTournaments.errors.list_tournament_filters}
                                    handleFilterTournament={(value) => { this.handleOnFilterTournamentHub(value) }}

                                    handleSearchWorldCities = {( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                                    handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search) }}                            
                                    cities = {this.props.state.leagueTournaments?.world_cities}
                                    countries =  { this.props.state.leagueTournaments?.countries}
                                    disableCities = {this.props.state.leagueTournaments.countryId?false:true}
                                    
                                    tournaments={this.props.state.tournaments.tournaments}
                                    type={'hub'}// hub, invitations, inscriptions 
                                    // //handleOnViewTournament = { (tournament)=>{this.handleOnViewHubTournament(tournament, 'hub') } }
                                    handleOnViewTournament = { (tournament)=>{this.props.history.push('/tournament/hub/'+tournament.id) } }
                                    // //handleClickEmptyButton = { ()=>{this.nextPath(`/tournament/hub`)} }
                                    handleOnShareTournamentLinkClick ={ () => {this.handleHomeAnonModal(true, 'homeAnonTournament')} }
                                    handleOnLoadMoreTournaments={ ()=>{this.handleOnLoadMoreTournaments() } }
                                /> */}
                        </React.Fragment>
                    }
                    { this.state.is_subsection && !this.state.load_detail && this.props.state.tournaments?.hub_tournament_subsection.length > 0 &&
                        <HubTournamentSectionCardComponent
                            section_data={this.props.state.tournaments?.hub_tournament_subsection}
                            handleOnViewTournament={(tournament, section_hub) => {this.handleHubTournamentSection(tournament, section_hub)}}
                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }   
                            handleOnRejectBtn={ (tournament) => this.handleOnRejectTeamInvitation(tournament.tournament_team_id, 'Rejected', 'teams')}                                                     
                            handleOnSubsectionViewMore={ (subsection) => {this.handleOnSubsectionViewMore(subsection)} }
                            is_subsection={this.state.is_subsection}
                            is_filterSection={this.state.filter_component_activation}
                            tournament_subsection={this.state.tournament_subsection}
                            handleBackTournamentViewMore={() => {this.handleBackTournamentViewMore()}}
                            handleOnLoadMoreTournaments={ ()=>{this.handleOnLoadMoreTournaments() } }
                            is_path={this.state.is_path}
                        />
                    }

                    { this.state.is_subsection && !this.state.load_detail && 
                        this.props.state.tournaments?.hub_tournament_subsection.length === 0 && 
                        this.state.tournament_subsection !== "tournament_participate" && 
                        this.state.tournament_subsection !== "tournament_invitation" &&
                        <div className='tournament-hub__center-container__empty-section invitations'>
                            <img src={search_off} alt="search empty" />
                            <div className='baloa-subtitle-2'>{this.props.t('emptyMessages.emptyHubTournamentsSearch')}</div>                            
                            <SecondaryButtonComponent 
                                type="button" 
                                onClick={ () => this.handleOpenBaloaLanding() }
                                className="tournament-hub__baloa-landing"
                            >
                                {this.props.t('landingBaloaEmpty')}<span className="hubicon-open_in_new" /> 
                            </SecondaryButtonComponent>
                        </div>
                    }            

                    { this.props.state.tournaments.is_share_link && !isMobile && <div className='tournament-hub__loader'><img src={loading_baloa} alt="loader" /></div> }
                        
                    { this.props.state.tournaments.is_share_link && isMobile && <div className='tournament-hub__loader-mobile'><img src={loading_ball} alt="loader" /></div> }
                    
                    {this.state.load_detail && !this.props.state.tournaments.show_tournament_detail && !isMobile && <div className='tournament-hub__loader'><img src={loading_baloa} alt="loader" /></div> }
                    {this.state.load_detail && !this.props.state.tournaments.show_tournament_detail && isMobile && <div className='tournament-hub__loader-mobile'><img src={loading_ball} alt="loader" /></div> }

                    {this.props.state.tournaments.show_tournament_detail &&
                        <TournamentDetailComponent
                            tournament={this.props.state.tournaments?.selected_tournament}
                            my_teams={this.cleanMyTeams(this.props.state.tournaments?.selected_tournament_my_teams)}
                            tournament_teams={this.props.state.tournaments?.tournament_teams}
                            tournament_teams_next={this.props.state.tournaments?.tournament_teams_next}
                            handleBackButton={() => { this.handleDetailBackButton() }}
                            team={this.state.selected_tournament_team}
                            teamname={this.state.selected_tournament_teamname}
                            team_logo={this.state.selected_tournament_team_logo}
                            handleOnInscriptionTeam = { ()=>{this.handleTeamInscription(true,'homeAnonTournamentInscription:'+this.props.state.tournaments?.selected_tournament.name, this.props.state.tournaments?.selected_tournament)} }
                            // handleOnInscriptionPlayer = { ()=>{this.handlePlayerInscription( this.state.selected_tournament_team_id )} }                            
                            // team_state={this.state.selected_tournament_team_state}
                            team_state={this.props.state.tournaments?.selected_tournament?.team_state}
                            start_flow={this.state.start_flow}
                            team_players={this.props.state.tournaments.team_players}
                            tournament_registration_type={this.state.selected_tournament_registration_type}
                            is_inscription={(this.state.is_inscription || (this.props.state.tournaments?.selected_tournament?.request_is_admin || this.props.state.tournaments?.selected_tournament?.request_is_member))} //Enviamos is_inscription en true para que muestre la pestaña de inscripcion
                            profile_type={this.state.profile_type}
                            is_tournament_admin={this.props.state.tournaments?.selected_tournament?.is_tournament_admin}
                            showCopyPostMessage={(copymessage)=>{this.props.showCopyPostMessage(copymessage)}}
                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }
                            handleRealizePlayerFormButton={(selected_player, type_redirect)=>{this.realizePlayerForm(selected_player, type_redirect)}}
                            handleRealizePlayerPaymentButton={(selected_player, type_redirect)=>{this.realizePlayerPayment(selected_player, type_redirect)}}
                            handleRealizeTeamFormButton={(type_redirect)=>{this.realizeTeamForm(type_redirect)}}
                            handleRealizeTeamPaymentButton={(type_redirect)=>{this.realizeTeamPayment(type_redirect)}}
                            handleOnShareInscriptionTournamentLinkClick ={ (tournament_team_id)=>{this.handleOnShareInscriptionTournament(tournament_team_id) } }
                            changeSelectedTournamentTeam={ (teamname)=>{ this.changeSelectedTournamentTeam(teamname) } }
                            //handleRejectButtonAction={()=>{this.props.changeRejectPlayerInscriptionModalStateAction(true)}}
                            handleOnFollowTournament={() => this.handleOnFollowTournament(true, 'homeAnonTournamentfollow')}
                            // handleOnUnfollowTournament ={(tournamentId)=> this.handleOnUnfollowTournament(tournamentId)}
                            tournament_phases={this.props.state.leagueTournaments.tournament?.tournament_phases}
                            handleOnPhaseClick={ (phase) => { this.handleOnPhaseClick(phase) } }
                            phase_dates={this.props.state.leagueTournaments.tournament_results.tournament_dates}
                            active_phase_id={this.state.active_phase_id}
                            
                            handleOnPhaseDate={ (date) => this.handleOnPhaseDate(date)}
                            phase_matches_by_date={this.props.state.leagueTournaments.tournament_results?.selected_date_matches}
                            groups_matches_by_date={this.state.groups_matches_by_date}
                            handleMatchStatistics={(match_id) => this.handleMatchStatistics(match_id)}
                            match_report={this.props.state.leagueMatches.match_report}
                            handleOnMatchDetailModal = { (match) => this.handleOnMatchDetailModal(match) }

                            tournament_active_phase={this.state.tournament_active_phase}
                            phase_groups_results_table={ this.props.state.leagueTournaments?.tournament?.phase_groups_results_table }
                            tournament_results_table={this.props.state.leagueTournaments.tournament.phase_results_table}
                            handleOnPhaseGroupPositionTable={ (group_id)=>{ this.props.loadPhaseGroupPositionsTable(group_id)} }
                            loading_phase_group_positions_table={ this.props.state.leagueTournaments?.loading_phase_group_positions_table }
                            is_best_third={this.state.is_best_third}
                            league_selected_tournament={this.props.state.leagueTournaments?.tournament}
                            handleTeamMembersModal={()=>{}}
                            handleTeamMembersModalPodium={(tournament_team_id)=>this.handleTeamMembersModalPodium(tournament_team_id)}
                            handleOpenShareIframeLinkModal={(tournament_id, iframe)=>this.handleOpenShareIframeLinkModal(tournament_id, iframe)}

                            referees_payment_response={this.referees_payment_response}
                            handleOnShareMatchLinkClick={ ()=>{ this.handleOnShareMatchLinkClick() } }
                            teams_match_assistance={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                            loadAdminPlayerAttended={()=>{}}
                            loadAllPlayersAttended={(matchId)=> this.props.loadTeamsToMatchAssistanceAligment(matchId)}

                            handleOnPhaseCalendar={ (tournament_phase_id) => this.handleOnPhaseCalendar(tournament_phase_id)}
                            tournament_phase_id={this.state.tournament_phase_id}
                            days_calendar_view={this.props.state.tournaments.days_calendar_view}

                            calendar_matchdays={this.props.state.tournaments.calendar_matchdays}
                            calendar_group_matches={this.props.state.tournaments.calendar_group_matches}
                            calendar_group_matches_next={this.props.state.tournaments.calendar_group_matches_next}
                            handleOnMatchday={ (tournament_group_id, date_number)=>{this.handleOnMatchday( tournament_group_id, date_number )}}
                            loading_phase_calendar={ this.props.state.tournaments.loading_phase_calendar }
                            handleViewMoreMatches={(next_url)=>{this.handleOnMatchday( null, null, next_url )}}

                            phase_date_groups={ this.props.state.tournaments.phase_date_groups }
                            phase_date_group_matches={ this.props.state.tournaments.phase_date_group_matches }
                            phase_date_group_matches_next={ this.props.state.tournaments.phase_date_group_matches_next }
                            loading_phase_date_groups={ this.props.state.tournaments.loading_phase_date_groups }
                            handleOnDateGroup={ ( tournament_phase_id, selected_date, group_number )=>{ this.handleOnDateGroup( tournament_phase_id, selected_date, group_number )}}
                            handleViewMoreDateMatches={(next_url)=>{this.handleOnDateGroup( null, null, null, next_url )}}

                            loadStatistics={ ( tournament_id )=> { this.loadStatistics( tournament_id ) }}
                            tournament_scorers = { this.props.state.leagueTournaments.tournament_scorers }
                            tournament_yellow_cards = { this.props.state.leagueTournaments.tournament_yellow_cards }
                            tournament_red_cards = { this.props.state.leagueTournaments.tournament_red_cards }
                            loading_tournament_scorers = { this.props.state.leagueTournaments.loading_tournament_scorers }
                            loading_tournament_yellow_cards = { this.props.state.leagueTournaments.loading_tournament_yellow_cards }
                            loading_tournament_red_cards = { this.props.state.leagueTournaments.loading_tournament_red_cards }
                            next_tournament_scorers = { this.props.state.leagueTournaments.next_tournament_scorers }
                            next_tournament_yellow_cards = { this.props.state.leagueTournaments.next_tournament_yellow_cards }
                            next_tournament_red_cards = { this.props.state.leagueTournaments.next_tournament_red_cards }
                            handleOnViewMoreStatistics = { (type)=>{ this.handleViewMoreStatistics(this.props.state.tournaments?.selected_tournament.id,type) }}
                            handleOnViewMoreTeams =  { ()=>{ this.handleViewMoreTeams(this.props.state.tournaments?.selected_tournament.id, 'Enrolled', 17) }}
                            loading_tournament_teams = { this.props.state.tournaments.loading_tournament_teams }
                            handleOnProfileClick = { ( profile )=>{ this.handleOnProfileClick( profile ) }}
                        />
                    }
                </div>
                <ResponsiveHubModalComponent
                    isOpen= {this.props.state.tournaments.modals.responsive_hub_modal_is_open}
                    onClose={() => {this.props.changeResponsiveHubModalStateAction(false)}}
                />
                <ShareInscriptionLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_incription_link_modal_is_open}
                    onClose={() => {this.props.changeShareInscriptionLinkModalStateAction(false)}}
                    share_url={this.state.share_inscription_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareInscriptionTournamentLinkClick()}}
                />
                {/* Staff invitation confirmation */}
                <StaffInvitationModalComponent
                    isOpen= {this.props.state.tournaments.modals.staff_invitation_is_open}
                    handleStaffInscriptionButton={()=>{this.handleStaffInscriptionButton() }}
                    tournament_name={this.props.state.tournaments?.selected_tournament?.tournament_name}
                    tournament_logo={this.props.state.tournaments?.selected_tournament?.tournament_logo}
                    handleOnRejectInscription={()=>{this.handleOnRejectStaffInvitation()}}
                    staff_profile={this.state.staff_profile}
                />
                <MatchDetailModalComponent 
                    isOpen={this.props.state.post.modals?.match_detail_is_open}
                    onClose={() => { this.props.changeMatchDetailModalStateAction(false) }}
                    match_detail = { this.props.state.leagueMatches.match_report }
                />
                <ShareIframeLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_iframe_link_modal_is_open}
                    onClose={() => {this.props.changeShareIframeLinkStateAction(false);}}
                    share_url={this.state.share_iframe_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareIFramePositionTableLink()}}
                />
                {/** View more statistics modal */}
                <TournamentViewMoreStatisticsModalComponent
                    isOpen={ this.props.state.leagueTournaments.modals?.view_more_statistics_is_open } 
                    onClose={() => { this.props.changeTournamentViewMoreStatisticsModalStateAction( false ) }}
                    statistic_data={this.state.statistic_data_type === 'scorer'? this.props.state.leagueTournaments.tournament_scorers : this.state.statistic_data_type === 'yellow-card'? this.props.state.leagueTournaments.tournament_yellow_cards : this.state.statistic_data_type === 'red-card'? this.props.state.leagueTournaments.tournament_red_cards : []}
                    card_type={this.state.statistic_data_type}
                    loadMoreStatistics={ (next_url)=>{ this.loadMoreStatistics(next_url)}}
                    statistics_next_page={this.state.statistic_data_type === 'scorer'? this.props.state.leagueTournaments.next_tournament_scorers : this.state.statistic_data_type === 'yellow-card'? this.props.state.leagueTournaments.next_tournament_yellow_cards : this.state.statistic_data_type === 'red-card'? this.props.state.leagueTournaments.next_tournament_red_cards : null}
                />

                {/** View more participant teams modal*/}
                <HubTournamentViewMoreTeamsModalComponent
                    isOpen={ this.props.state.tournaments.modals?.view_more_teams_is_open } 
                    onClose={() => { this.props.loadUnauthenticatedHubTournamentTeams(this.props.state.tournaments?.selected_tournament.id, 'Enrolled', 10); this.props.changeHubTournamentViewMoreTeamsModalStateAction( false ) }}
                    tournament_teams= { this.props.state.tournaments.tournament_teams}
                    tournament_teams_next={ this.props.state.tournaments.tournament_teams_next }
                    loadMoreTeams={ (next_url)=> { this.loadMoreTeams(next_url) }}
                    loading_tournament_teams={this.props.state.tournaments.loading_tournament_teams}
                    handleTeamMembersModal={()=>{}}
                />

                {/* Tournament Team Members Modal */}
                <TournamentTeamMembersModalComponent 
                    isOpen={ this.props.state.page.modals?.team_members_is_open } 
                    onClose={() => { this.props.changeTeamMembersModalStateAction( false ) } }
                    selected_team={this.state.modal_selected_team}
                    team_members={this.props.state.leagueTournaments.tournament?.tournament_members_team_players}
                    carnet_id_template={ null }
                    handleOnDownloadCardIds={ ()=> {} }
                    handleOnDownloadPlayerCardId={ ()=> {} }
                    handleOnDownloadStaffCardId={ ()=> {} }
                />
            </div>
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadUnauthenticatedHubTournaments: (loading = false, next_page) => {
            return dispatch(loadUnauthenticatedHubTournaments(loading, next_page))
        },
        loadUnauthenticatedHubTournamentTeams: (tournament_id, teams_state, page_size, next_page) => {
            return dispatch(loadUnauthenticatedHubTournamentTeams(tournament_id, teams_state, page_size, next_page))
        },
        loadHubTournaments: (loading = false, next_page) => {
            return dispatch(loadHubTournaments(loading, next_page))
        },
        loadHubTournamentTeams: (tournament_id, teams_state) => {
            return dispatch(loadHubTournamentTeams(tournament_id, teams_state))
        },
        showHubTournamentDetailAction: (new_state) => {
            dispatch(showHubTournamentDetailAction(new_state))
        },
        setHubTournamentHubAction: (new_state) => {
            dispatch(setHubTournamentHubAction(new_state))
        },
        loadUnauthenticatedSelectedTournament: async ( tournament_id ) => {
            return await dispatch( loadUnauthenticatedSelectedTournament( tournament_id ) )},
        setHubTournamentInscriptionsAction: (new_state) => {
            dispatch(setHubTournamentInscriptionsAction(new_state))
        },
        setHubTournamentMyTournamentsAction: (new_state) => {
            dispatch(setHubTournamentMyTournamentsAction(new_state))
        },
        loadAllManagedTournaments: (filter) => {
            dispatch(loadAllManagedTournaments(filter))
        },        
        changeTournamentTeamState: (tournament_team_id, new_team_state) => {
            dispatch( changeTournamentTeamState(tournament_team_id, new_team_state))
        },
        loadProfileAdminTeams: (queryParams) => {
            dispatch( loadProfileAdminTeams(queryParams) )
        },
        getCountries: async (country) => {
            await dispatch(getCountries(country));
        },
        searchCityByCountry: async (countryId, searchedCity) => {
            await dispatch(searchCityByCountry(countryId, searchedCity));
        },
        loadUnauthenticatedHubTournamentsFilter: (filter) => {
            dispatch(loadUnauthenticatedHubTournamentsFilter(filter))
        }, 
        loadHubTournamentsFilter: (filter) => {
            dispatch(loadHubTournamentsFilter(filter))
        },        
        changeTournamentPlayerState: ( player_id, new_player_state ) => {
            dispatch(changeTournamentPlayerState( player_id, new_player_state ));
        },
        loadTournamentInscriptionFormTemplate: (form_type, tournament_id) => {
            dispatch(loadTournamentInscriptionFormTemplate(form_type, tournament_id))
        },
        loadTournamentPlayerFormTemplate: (form_type, tournament_id) => {
            dispatch(loadTournamentPlayerFormTemplate(form_type, tournament_id))
        },
        changeResponsiveHubModalStateAction: (new_state) => {
            dispatch(changeResponsiveHubModalStateAction(new_state));
        },
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        setUrlRedirect: ( url_redirect ) => {
            return dispatch( setUrlRedirect( url_redirect ) );
        },
        loadHubTournamentsSections: () => {
            return dispatch(loadHubTournamentsSections())
        },
        loadHubTournamentsSubsections: (tournament_subsection, loading = false, next_page) => {
            return dispatch(loadHubTournamentsSubsections(tournament_subsection, loading, next_page));
        },
        clearHubTournamentSubsectionAction: () => {
            return dispatch(clearHubTournamentSubsectionAction([]))
        },
        loadInformativeTournament: async ( tournament_id ) => {
            await dispatch( loadInformativeTournament( tournament_id ) )
        },
        getHubTournamentSearch: (tournament_query, loading = false, next_page) => {
            return dispatch(getHubTournamentSearch(tournament_query, loading, next_page))
        },
        loadTournamentInfo: async ( id_tournament ) => {       
            await dispatch( loadTournamentInfo(  id_tournament ) )
        },
        loadTournamentPhases: ( tournament_id ) => {
            dispatch( loadTournamentPhases( tournament_id ) );
        },
        loadPhaseResultsTable: (phase_id) => {
            return dispatch(loadPhaseResultsTable(phase_id))
        },
        loadPhaseDates: async ( phase_id ) => {
            return await dispatch( loadPhaseDates( phase_id ))
        },
        loadPhaseMatchesByDate: async ( phase_id, calendar_date ) => {
            return await dispatch( loadPhaseMatchesByDate( phase_id, calendar_date ))
        },
        changeShareInscriptionLinkModalStateAction: ( new_state ) => {
            return dispatch( changeShareInscriptionLinkModalStateAction( new_state ) )
        },
        changeIsShareLinkStateAction: (new_state) => {
            dispatch(changeIsShareLinkStateAction(new_state));
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changeStaffInvitationModalStateAction: ( new_modal_state ) => {
            dispatch( changeStaffInvitationModalStateAction( new_modal_state ) );
        },
        loadMatchReport: (match_id) => {
            return dispatch(loadMatchReport(match_id));
        },
        changeMatchDetailModalStateAction: (new_modal_state) => {
            dispatch(changeMatchDetailModalStateAction(new_modal_state))
        },
        loadTeamsToMatchAssistanceAligment: (matchId)=>{ 
            dispatch(loadTeamsToMatchAssistanceAligment(matchId))
        },
        loadPhaseCalendar: ( tournament_phase_id ) => {
            dispatch(loadPhaseCalendar( tournament_phase_id ))
        },
        loadSelectedTournament: async ( tournament_id ,tournament_team_id) => {
            await dispatch( loadSelectedTournament( tournament_id , tournament_team_id) )
        },
        loadPhaseCalendarGroupMatches: async (tournament_group_id, date_number, next_url)=>{
            await dispatch(loadPhaseCalendarGroupMatches(tournament_group_id, date_number, next_url));
        },
        resetCalendarGroupMatchesAction: async ()=>{
            await dispatch(resetCalendarGroupMatchesAction({}));
        },
        loadPhaseGroupsByDate: async (tournament_phase_id, calendar_date )=>{
            await dispatch(loadPhaseGroupsByDate(tournament_phase_id, calendar_date));
        },
        loadPhaseMatchesByGroupDate: async ( tournament_phase_id, calendar_date, group_number, next_url )=>{
            await dispatch(loadPhaseMatchesByGroupDate( tournament_phase_id, calendar_date, group_number, next_url ));
        },
        resetPhaseDateMatchesAction: async ()=>{
            await dispatch(resetPhaseDateMatchesAction({}));
        },
        changeShareIframeLinkStateAction: ( new_state ) => {
            return dispatch( changeShareIframeLinkStateAction( new_state ) )
        },
        loadPhaseGroupPositionsTable: ( tournament_group_id, page_size, next_url )=>{
            dispatch( loadPhaseGroupPositionsTable( tournament_group_id, page_size, next_url ));
        },
        loadTournamentScorersTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadTournamentScorersTable( tournament_id, page_size, next_url ));
        },
        loadYellowCardsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadYellowCardsTable( tournament_id, page_size, next_url ));
        },
        loadRedCardsTable: ( tournament_id, page_size, next_url )=>{
            dispatch( loadRedCardsTable( tournament_id, page_size, next_url ));
        },
        changeTournamentViewMoreStatisticsModalStateAction: ( new_state ) => {            
            dispatch( changeTournamentViewMoreStatisticsModalStateAction( new_state ) )
        },        
        changeHubTournamentViewMoreTeamsModalStateAction: ( new_state ) => {            
            dispatch( changeHubTournamentViewMoreTeamsModalStateAction( new_state ) )
        },
        loadFrontTournamentTeam: async ( tournament_team_id ) => {            
            await dispatch( loadFrontTournamentTeam( tournament_team_id ) )
        },
        loadTournamentMembersTeams: ( team_id) => {
            dispatch( loadTournamentMembersTeams( team_id ));
        },
        changeTeamMembersModalStateAction: ( new_modal_state ) => {
            dispatch( changeTeamMembersModalStateAction( new_modal_state ) );
        },
    }
}

export default compose(
    withTranslation(["tournament", "league"]),
    connect(mapStateToProps, mapDispatchToProps)
)(TournamentHubUnauthenticatedView);