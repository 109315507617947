//React
import React from 'react';

//Styles
import './LeagueTournamentReportDatesComponent.styles.css'

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/material';

// Moment
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//assets
import empty_baloa_play_matches from 'assets/images/empty_baloa_play_matches.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [groupExpanded, setGroupExpanded] = React.useState('group0');

    const handleGroupsChange = ( panel, group_number ) => (event, newGroupExpanded) => {
        if(newGroupExpanded){
            props.handleOnDateGroup( props.active_phase_id, props.selectedDate, group_number)
        }
        setGroupExpanded(newGroupExpanded ? panel : false);
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {props.phase_date_groups.length > 1?
                props.phase_date_groups.map((group, indGroups) =>(
                    <Accordion
                        key={indGroups} 
                        onChange={ handleGroupsChange(`group${indGroups}`, group.group_number) } 
                        expanded={ groupExpanded === `group${indGroups}` }
                        className='league-tournament-report-dates__accordion-item'
                    >
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-label="Expand"
                            aria-controls={ `aria_controls_group_${indGroups}` }
                            id={ `group_${indGroups}` }
                            className='league-tournament-report-dates__accordion-item__summary'
                        >                            
                            <div className='baloa-normal-medium'>
                                {((props.phase_type === 'Groups' || props.phase_type === 'Free' ) && !group.group_name.includes('Draw') && !props.loading_phase_date_groups )
                                    ?
                                        props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.group_name.split(" ")[0]}`,{_number: group.group_name.split(" ")[1]})
                                    :
                                    (props.phase_name && !group.group_name.includes('Group')&& !props.loading_phase_date_groups)?
                                        props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${group.group_name.split(" ")[0]}`, {phase_name: props.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.phase_name}`), _number: group.group_name.split(" ")[1] })
                                        :
                                        ''
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={`league-tournament-report-dates__date-by-group ${(props.phase_date_group_matches.length === 0 && !props.loading_phase_date_groups)? 'hidden' : ''}`}>
                                {props.phase_date_group_matches.length > 0 && props.phase_date_group_matches?.map((match) => (
                                    <div key={match.id} className={props.phase_type === 'Groups' ? "league-tournament-report-dates__match-detail-container" : "league-tournament-report-dates__match-detail-container_margin"} onClick={() => props.handleOnPostMatch(match)} >
                                        <div className="league-tournament-report-dates__match-info-container">
                                            {match.state === "Finalized" ?
                                                <div className="league-tournament-report-dates__teams-result-container">
                                                    <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                                </div>
                                                :match.match_date ?
                                                    <div className="league-tournament-report-dates__teams-result-container">
                                                        <span className='hubicon-finish_date' />
                                                        <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                                        <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                                    </div>
                                                    :
                                                    <div className="league-tournament-report-dates__teams-result-container">
                                                        <span className='hubicon-finish_date' />
                                                        <div className="baloa-table">N/A</div>
                                                    </div>
                                            }
                                            <div className='league-tournament-report-dates__teams-stadium-container'>
                                                <span className='hubicon-stadium' />
                                                <div className='baloa-table' >
                                                    {match.state !== "Not_scheduled"
                                                        ? match.field
                                                        : ' N/A'
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="league-tournament-report-dates__teams-names-container">
                                            <div className="league-tournament-report-dates__teams_local-container">
                                                <div className='league-tournament-report-dates__teams-container '>
                                                    <div className="baloa-names">{match.local_team_name}</div>
                                                    {match.local_team_photo === null
                                                        ?
                                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                                        :
                                                        <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="league-tournament-report-dates-score__teams-container">
                                                {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                                {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                                {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                            </div>
                                            <div className="league-tournament-report-dates__teams_visitant-container">
                                                <div className="league-tournament-report-dates__teams-container">

                                                    {match.visitant_team_photo === null
                                                        ?
                                                        <span className="icon-ball" color={getRandonIconBallColor()} />
                                                        :
                                                        <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                                    }
                                                    <div className="baloa-names">{match.visitant_team_name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {props.phase_date_group_matches.length === 0 && props.loading_phase_date_groups && 
                                    [...Array(Number(4))].map((e, i) =>
                                        <div className='league-tournament-report-dates__skeleton'>
                                            <div className='league-tournament-report-dates__skeleton__match'>
                                                <Skeleton animation="wave" component={'div'} width='25%' />
                                                <Skeleton animation="wave" component={'div'} width='40%' />
                                            </div>
                                            <div className='league-tournament-report-dates__skeleton__results'>
                                                <Skeleton animation="wave" component={'div'} width='30%' />
                                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                <Skeleton animation="wave" component={'div'} width='5%' />
                                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                <Skeleton animation="wave" component={'div'} width='30%' />
                                            </div>
                                        </div>
                                    )
                                }
                                {props.phase_date_group_matches.length > 0 && props.loading_phase_date_groups && 
                                    [...Array(Number(2))].map((e, i) =>
                                        <div className='league-tournament-report-dates__skeleton'>
                                            <div className='league-tournament-report-dates__skeleton__match'>
                                                <Skeleton animation="wave" component={'div'} width='25%' />
                                                <Skeleton animation="wave" component={'div'} width='40%' />
                                            </div>
                                            <div className='league-tournament-report-dates__skeleton__results'>
                                                <Skeleton animation="wave" component={'div'} width='30%' />
                                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                <Skeleton animation="wave" component={'div'} width='5%' />
                                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                                <Skeleton animation="wave" component={'div'} width='30%' />
                                            </div>
                                        </div>
                                    )
                                }
                                {props.phase_date_group_matches_next && !props.loading_phase_date_groups &&
                                    <div className='baloa-names view-more' onClick={()=>{props.handleViewMoreDateMatches(props.phase_date_group_matches_next)}}>{props.t('matchByCalendar.viewMoreLbl')}</div>
                                }                                            
                            </div>
                            {props.phase_date_group_matches.length === 0 && !props.loading_phase_date_groups && 
                                <div className='league-tournament-report-dates__empty-message'>
                                    <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                                    <div className='baloa-table-column'>{props.t('matchByCalendar.emptyGroup')}</div>
                                </div>
                            }
                        </AccordionDetails>
                    </Accordion>
                ))
            :props.phase_date_groups.length === 1?
                <React.Fragment>
                    <div className={`league-tournament-report-dates__date-by-group ${(props.phase_date_group_matches.length === 0 && !props.loading_phase_date_groups)? 'hidden' : ''}`}>
                        {props.phase_date_group_matches.length > 0 && props.phase_date_group_matches?.map((match) => (
                            <div key={match.id} className={props.phase_type === 'Groups' ? "league-tournament-report-dates__match-detail-container" : "league-tournament-report-dates__match-detail-container_margin"} onClick={() => props.handleOnPostMatch(match)} >
                                <div className="league-tournament-report-dates__match-info-container">
                                    {match.state === "Finalized" ?
                                        <div className="league-tournament-report-dates__teams-result-container">
                                            <div className="baloa-table finalized">{props.t('league:ended')}</div>
                                        </div>
                                        :match.match_date ?
                                            <div className="league-tournament-report-dates__teams-result-container">
                                                <span className='hubicon-finish_date' />
                                                <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                                <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                            </div>
                                            :
                                            <div className="league-tournament-report-dates__teams-result-container">
                                                <span className='hubicon-finish_date' />
                                                <div className="baloa-table">N/A</div>
                                            </div>
                                    }
                                    <div className='league-tournament-report-dates__teams-stadium-container'>
                                        <span className='hubicon-stadium' />
                                        <div className='baloa-table' >
                                            {match.state !== "Not_scheduled"
                                                ? match.field
                                                : ' N/A'
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="league-tournament-report-dates__teams-names-container">
                                    <div className="league-tournament-report-dates__teams_local-container">
                                        <div className='league-tournament-report-dates__teams-container '>
                                            <div className="baloa-names">{match.local_team_name}</div>
                                            {match.local_team_photo === null
                                                ?
                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                :
                                                <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                            }
                                        </div>
                                    </div>
                                    <div className="league-tournament-report-dates-score__teams-container">
                                        {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                        {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                        {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                    </div>
                                    <div className="league-tournament-report-dates__teams_visitant-container">
                                        <div className="league-tournament-report-dates__teams-container">

                                            {match.visitant_team_photo === null
                                                ?
                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                :
                                                <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                            }
                                            <div className="baloa-names">{match.visitant_team_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                                                       
                        ))}
                        {props.phase_date_group_matches.length === 0 && props.loading_phase_date_groups && 
                            [...Array(Number(4))].map((e, i) =>
                                <div className='league-tournament-report-dates__skeleton'>
                                    <div className='league-tournament-report-dates__skeleton__match'>
                                        <Skeleton animation="wave" component={'div'} width='25%' />
                                        <Skeleton animation="wave" component={'div'} width='40%' />
                                    </div>
                                    <div className='league-tournament-report-dates__skeleton__results'>
                                        <Skeleton animation="wave" component={'div'} width='30%' />
                                        <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                        <Skeleton animation="wave" component={'div'} width='5%' />
                                        <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                        <Skeleton animation="wave" component={'div'} width='30%' />
                                    </div>
                                </div>
                            )
                        }
                        {props.phase_date_group_matches.length > 0 && props.loading_phase_date_groups && 
                            [...Array(Number(2))].map((e, i) =>
                                <div className='league-tournament-report-dates__skeleton'>
                                    <div className='league-tournament-report-dates__skeleton__match'>
                                        <Skeleton animation="wave" component={'div'} width='25%' />
                                        <Skeleton animation="wave" component={'div'} width='40%' />
                                    </div>
                                    <div className='league-tournament-report-dates__skeleton__results'>
                                        <Skeleton animation="wave" component={'div'} width='30%' />
                                        <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                        <Skeleton animation="wave" component={'div'} width='5%' />
                                        <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                        <Skeleton animation="wave" component={'div'} width='30%' />
                                    </div>
                                </div>
                            )
                        }
                        {props.phase_date_group_matches_next && !props.loading_phase_date_groups &&
                            <div className='baloa-names view-more' onClick={()=>{props.handleViewMoreDateMatches(props.phase_date_group_matches_next)}}>{props.t('matchByCalendar.viewMoreLbl')}</div>
                        }
                    </div>
                    {props.phase_date_group_matches.length === 0 && !props.loading_phase_date_groups && 
                        <div className='league-tournament-report-dates__empty-message'>
                            <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                            <div className='baloa-table-column'>{props.t('matchByCalendar.emptyMatchday')}</div>
                        </div>
                    }
                </React.Fragment>
            :
            'no hay grupos'
            }

        </div>
    );
}


function LeagueTournamentReportDatesComponent(props) {

    const [tabSelected, setTabSelected] = React.useState(props.dateTab ? props.dateTab : 0);
    // const [tabSelected, setTabSelected] = React.useState(undefined);
    const [phaseIndex, setPhaseIndex] = React.useState(0);
    const [selectedDate, setSelectedDate] = React.useState('');

    // READ_ME: Se agrega la nueva variable " date_to_show ", para difereciar la  phase activa y no devolver siempre la ultima phase
    React.useEffect(() => {
        if (props.phase_dates.length > 0 && props.active_phase_id) {
            //const phaseIndex = props.phase_dates?.findIndex(date => date.date_to_show === true);
            const newPhaseIndex = props.phase_dates?.findIndex(date => date.date_to_show === true);
            if (newPhaseIndex !== phaseIndex || props.groups_matches_by_date?.length === 0) {
                setPhaseIndex(newPhaseIndex);
                props.handleOnPhaseDate(props.phase_dates[newPhaseIndex].calendar_date);
                props.setDateTab(newPhaseIndex)
                setTabSelected(newPhaseIndex)
                setSelectedDate(props.phase_dates[newPhaseIndex].calendar_date);
            }
        }
    }, [props.phase_dates, props.active_phase_id]); // eslint-disable-line    

    
    return (

        <React.Fragment>
            {props.phase_dates?.length > 0 &&
                <Tabs
                    className="league-tournament-report-dates__tabs"
                    value={tabSelected}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(ev, new_value) => { setTabSelected(new_value); props.setDateTab(new_value) }}
                    aria-label="Selection between "
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        props.phase_dates.map((date, index) => {
                            return (
                                <Tab key={index} label={moment(date.calendar_date).format('MMMM DD')} onClick={() => { props.handleOnPhaseDate(date.calendar_date); setSelectedDate(date.calendar_date); }} />
                            )
                        })
                    }

                </Tabs>
            }
            <div className={`league-tournament-report-dates__container ${props.phase_dates?.length === 0? 'border': ''}`}>
                {props.phase_date_groups?.length > 0 &&
                    props.phase_dates.map((date, index) => {

                        return (
                            <TabPanel
                                index={index}
                                value={tabSelected}
                                key={index}
                                phase_dates={props.phase_dates}
                                selected_tournament={props.selected_tournament}
                                handleOnPostMatch={(post_match) => props.handleOnPostMatch(post_match)}
                                phase_matches_by_date={props.phase_matches_by_date}
                                groups_matches_by_date={props.groups_matches_by_date}
                                phase_type={props.tournament_active_phase?.phase_type}
                                phase_name={props.tournament_active_phase?.phase_name}

                                phase_date_groups={ props.phase_date_groups }
                                phase_date_group_matches={ props.phase_date_group_matches }
                                phase_date_group_matches_next={ props.phase_date_group_matches_next }
                                loading_phase_date_groups={ props.loading_phase_date_groups }
                                handleOnDateGroup={ props.handleOnDateGroup }
                                handleViewMoreDateMatches={props.handleViewMoreDateMatches}

                                selectedDate={selectedDate}
                                active_phase_id={props.active_phase_id}
                                t={props.t}
                            />
                        )
                    })
                }
                {props.phase_date_groups?.length === 0 && props.loading_phase_date_groups &&
                    <div className={`league-tournament-report-dates__date-by-group`}>
                    {[...Array(Number(4))].map((e, i) =>
                        <div className='league-tournament-report-dates__skeleton'>
                            <div className='league-tournament-report-dates__skeleton__match'>
                                <Skeleton animation="wave" component={'div'} width='25%' />
                                <Skeleton animation="wave" component={'div'} width='40%' />
                            </div>
                            <div className='league-tournament-report-dates__skeleton__results'>
                                <Skeleton animation="wave" component={'div'} width='30%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='5%' />
                                <Skeleton animation="wave" variant='circular' component={'div'} width='30px' height={'30px'} />
                                <Skeleton animation="wave" component={'div'} width='30%' />
                            </div>
                        </div>
                    )}
                    </div>
                }

                {(props.phase_dates?.length === 0 || props.phase_date_groups?.length === 0) && !props.loading_phase_date_groups &&
                    <div className='league-tournament-report-dates__empty-message'>
                        <img src={empty_baloa_play_matches} alt='Baloa No Matches'/>
                        <div className='baloa-table-column'>{props.t('matchByCalendar.emptyPhase')}</div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default withTranslation(["tournament", "league"])(LeagueTournamentReportDatesComponent);