import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Globals
import { stripe_public_key, stripe_connected_account } from './GlobalData';

// Reemplaza con tu clave publicable de Stripe
const stripePromise = loadStripe(stripe_public_key,
    {
        stripeAccount: stripe_connected_account
    }
);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise} >{children}</Elements>;
};

export default StripeProvider;