// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-player-info__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.tournament-player-info__container .icon-ball {
    font-size: 34px;
    font-weight: var(--font-bold);
    border-radius: 50%;
    padding: 4px;
    color: var(--white-color);
}

.tournament-player-info__container img {
    width: 42px;
    height: 42px;
    border-radius: 50%;    
}

.tournament-player-info__player-data .baloa-normal-medium {
    color: var(--primary-very-dark-color);
}

.tournament-player-info__player-data .baloa-table {
    color: var(--basic-gray-color);
    margin-top: var(--space-quarter);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC","sourcesContent":[".tournament-player-info__container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 16px;\n}\n\n.tournament-player-info__container .icon-ball {\n    font-size: 34px;\n    font-weight: var(--font-bold);\n    border-radius: 50%;\n    padding: 4px;\n    color: var(--white-color);\n}\n\n.tournament-player-info__container img {\n    width: 42px;\n    height: 42px;\n    border-radius: 50%;    \n}\n\n.tournament-player-info__player-data .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n}\n\n.tournament-player-info__player-data .baloa-table {\n    color: var(--basic-gray-color);\n    margin-top: var(--space-quarter);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
