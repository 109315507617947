/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlaySubsectionComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import BaloaPlayMatchCardComponent from 'app/baloa-play/components/baloa-play-match-card-component/BaloaPlayMatchCardComponent';

function BaloaPlaySubsectionComponent( props ) {

    const [scrollX, setscrollX] = React.useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = React.useState(false); // For detecting end of scrolling
    const scrl = React.useRef();
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    React.useEffect(() => {
        //Check width of the scollings
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
    
        //For checking if the scroll has ended
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    return(
        <div className='baloa-play-subsection__container'>
            <div className='baloa-play-subsection__title' >
                <div className='baloa-subtitle-1'>{props.t(props.seccion?.filter_type)}</div>
                <div className='baloa-subtitle-1 green'>{props.t('viewAllLbl')}</div>                
            </div>
            <div className='baloa-play-subsection__row-container'>
                {!isMobile && scrollX !== 0 &&                
                    <div class={`hub-tournament-section-card__section__subsection-arrow prev ${props.section_data?.subsection === 'tournament_follow'? 'follow-view' : ''}`} onClick={() => slide(-580)}>
                        <span class="hubicon-arrow_circle_left">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }

                {!isMobile && !scrolEnd &&                    
                    <div class={`hub-tournament-section-card__section__subsection-arrow next ${props.section_data?.subsection === 'tournament_follow'? 'follow-view' : ''}`} onClick={() => slide(+580)}>
                        <span class="hubicon-arrow_circle_right">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>                    
                }
                <div className='baloa-play-subsection__cards-container' ref={ scrl } onScroll={ scrollCheck }>
                    {props.seccion?.matches?.map((match, index) => (                
                        <BaloaPlayMatchCardComponent
                            key={index}
                            match={match}
                            is_live_video={props.seccion?.filter_type === 'Live_Video'}
                            handleOnMatch={props.handleOnMatch}
                        />                
                    ))}
                </div> 
            </div>
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlaySubsectionComponent);