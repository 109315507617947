
// React
import React from 'react';

// Globals
// import { api_url } from "config/GlobalData";

// Cropper
import Cropper from 'react-easy-crop'

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
// import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './FormsUploadImageModalComponent.styles.css';

// Assets
import blankProfile from 'assets/icons/blankProfile.svg';


// Utilities
import getCroppedImg from 'shared/functions/CropImage';
import Loader from "react-loader-spinner";

// i18n
import { withTranslation } from 'react-i18next';
import { stringify } from 'ajv';


function FormsUploadImageModalComponent(props) {

   const [selectedImage, setSelectedImage] = React.useState(null);
   const [selectedImageUrl, setSelectedImageUrl] = React.useState(null);
   const [crop, setCrop] = React.useState( { x: 0, y: 0 } );
   const [zoom, setZoom] = React.useState(1);
   const [aspect, setAspect] = React.useState(props.aspect);  // eslint-disable-line
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState( {} );
   const [croppedImage, setCroppedImage] = React.useState(null);
   const [croppedImageUrl, setCroppedImageUrl] = React.useState(null);

   const [imageB64, setImageB64] = React.useState('');


   React.useEffect(() => { 
      if(props.selectedImage){
         setSelectedImage(props.selectedImage.file);
         setSelectedImageUrl(URL.createObjectURL(props.selectedImage.file));
      }
  }, [ JSON.stringify(props.selectedImage)]);// eslint-disable-line 
 
   const onCropChange = crop => {
      setCrop(crop);
   }

   const onCropComplete = (cropped_area, cropped_area_pixels) => {
      setCroppedAreaPixels(cropped_area_pixels);
   }

   const onZoomChange = zoom => {
      setZoom(zoom);
   }

   const executeImageAction = () =>  {
      if (selectedImage) {
         showCroppedImage()
            .then(()=> {
               onFileUpload(selectedImage.name)
            }
            )
            .catch(err => console.log(err));
      }
    }

   const onFileUpload = async (imageName) => {

      if (croppedImage) {
         /* const image = new File([croppedImage], imageName, {
            type: 'image/png'
         }); */
         
         //props.setTeamImage(image, croppedImageUrl, imageB64 );
         props.setTeamImage(imageB64 );

         onRemoveImage();
         props.onClose();
      }

   };

   const handleReaderLoaded = ( readerEvent ) => {
      let binaString = readerEvent.target.result;
      setImageB64( selectedImage.name+";"+btoa(binaString) )
   }

   const showCroppedImage = async () => {
      try {
         const cropped_image = await getCroppedImg(
            selectedImageUrl,
            croppedAreaPixels,
            0
         )

         setCroppedImage(cropped_image);

         if(cropped_image) {
               const reader = new FileReader();
               reader.onload = handleReaderLoaded.bind(this);
               reader.readAsBinaryString(cropped_image); 
         }

         setCroppedImageUrl(URL.createObjectURL(cropped_image));
      } catch (e) {
         console.error(e)
      }
   }

   const onRemoveImage = () => {
      setCroppedImage(null);
      setCroppedImageUrl(null);
      setSelectedImage(null);
      setSelectedImageUrl(null);
   }

   const resetModalData = () => {
      onRemoveImage();
      props.cleanImage();
   }


      return (
         <Modal2Component
            isOpen={props.isOpen}
            onClose={resetModalData}
            title={props.t('uploadPhotoProfile.title')}
             className="forms-upload-image-modal"
         >
            <div className="forms-upload-image-modal__container">
               {/* Content */}
               <div className="">
                  {/* Crop Image */}
                  {props.cropImage && selectedImageUrl && !croppedImage && <div className="forms-upload-image-modal__content__media">
                     <Cropper
                        image={selectedImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={ onCropChange }
                        onCropComplete={ onCropComplete }
                        onZoomChange={ onZoomChange }
                        cropSize={{width: 350, height: 350}}
                     />                   
                  </div>}
                  {/* Display Image */}
                  {props.cropImage && croppedImage && <div className="forms-upload-image-modal__content__result">
                     <div className="forms-upload-image-modal__content__result__container">
                        <div className="forms-upload-image-modal__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="forms-upload-image-modal__content__result"/>                           
                        </div>                        
                     <img src={ croppedImageUrl} alt=""/>                        
                     </div>
                  </div>}

                  {/* Display Image */}
                  {!props.cropImage && selectedImageUrl && <div className="forms-upload-image-modal__content__result">
                     <div className="forms-upload-image-modal__content__result__container">
                        <div className="forms-upload-image-modal__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="forms-upload-image-modal__content__result"/>                           
                        </div>                        
                     <img src={ selectedImageUrl} alt=""/>                        
                     </div>
                  </div>}

                  {/* Blank Image */}
                  {!croppedImage && !selectedImageUrl && <div className="forms-upload-image-modal__content__result">
                     <div className="forms-upload-image-modal__content__result__container">
                        <img src={blankProfile} alt="" />
                     </div>
                  </div>}

               </div>
            </div>

            <div className="forms-upload-image-modal__form">
                  <div className="forms-upload-image-modal__form__group">
                     <SecondaryButtonComponent
                        type="button"
                        onClick={ resetModalData }
                        disabled={(!selectedImage) ? true : false}
                        className="forms-upload-image-modal__form__submit">
                        <span>{props.t('common:actions.delete')}</span>
                     </SecondaryButtonComponent>
                     <PrimaryButtonComponent
                        type="button"
                        disabled={(!selectedImage) ? true : false}
                        onClick={()=>{executeImageAction()}}
                        className="forms-upload-image-modal__form__submit">
                        <span>{props.t('uploadPhotoProfile.buttonOk')}</span>
                     </PrimaryButtonComponent>
                  </div>
            </div>
         </Modal2Component>
      )

}



export default withTranslation(["profile","common"])(FormsUploadImageModalComponent);
