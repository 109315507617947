/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaProHeaderComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaProHeaderComponent( props ) {

    const [montValue, setMonthValue] = React.useState(null);
    const [yearValue, setYearValue] = React.useState(null);
    const [monthCurrency, setMonthCurrency] = React.useState(null);
    const [yearCurrency, setYearCurrency] = React.useState(null);

    React.useEffect(() => {
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'month') ){
            setMonthValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').value );
            setMonthCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'month').currency );
        }else{
            setMonthValue(null);
            setMonthCurrency(null);
        }
        if( props.prices?.find(price => price.metadata?.recurring?.interval === 'year') ){
            setYearValue( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').value );
            setYearCurrency( props.prices?.find(price => price.metadata?.recurring?.interval === 'year').currency );
        }else{
            setYearValue(null);
            setYearCurrency(null);
        }
    }, [props.prices]);// eslint-disable-line

    return(
        <div className={`baloa-pro-header__container ${props.is_baloa_pro? 'baloa-pro' : ''}`}>
            <div className='baloa-pro-header__content'>
                <div className='baloa-pro-header__title'>
                    <span className='hubicon-award_star'/>
                    <div className='baloa-headline-1'>{props.t('baloaProHeader.title')}</div>
                </div>
                {props.is_baloa_pro &&
                    <div className='baloa-pro-header__title-message'>{props.t('baloaProHeader.proMsg')}</div>
                }
                {!props.is_baloa_pro &&
                    <React.Fragment>
                        {isMobile &&
                            <div className='baloa-pro-header__title-message'>{props.t('baloaProHeader.subscriptionMsg')}</div>
                        }
                        {!isMobile &&
                            <React.Fragment>
                                {//!props.is_new_baloa_pro_user && //Usuario antiguo de pro - NO prueba gratuita
                                    <React.Fragment>
                                        <div className='baloa-pro-header__title-message'>{props.t('baloaProHeader.subscriptionMsg')}</div>
                                        {/* <div className='baloa-pro-header__buttons'>                                    
                                                <PrimaryButtonComponent
                                                    type={'button'}
                                                    className={''}
                                                    onClick={()=>{ props.handleOnClickYearly(yearValue, yearCurrency) }}
                                                    disable={ !yearValue }
                                                >
                                                    <span>{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue, _currency: yearCurrency })}</span>
                                                </PrimaryButtonComponent>
                                                <SecondaryButtonComponent
                                                    type={'button'}
                                                    className={'large'}
                                                    onClick={()=>{ props.handleOnClickMonthly(montValue, monthCurrency) }}
                                                    disable={ !montValue }
                                                >
                                                    <span>{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue, _currency: monthCurrency })}</span>
                                                </SecondaryButtonComponent>
                                        </div> */}
                                    </React.Fragment>
                                }
                                {/* props.is_new_baloa_pro_user && //Usuario nuevo - prueba gratuita
                                    <React.Fragment>
                                        <div className='baloa-pro-header__title-message'>{props.t('baloaProHeader.subscriptionMsg2')}</div>
                                        <div className='baloa-pro-header__title-price'>&bull;{props.t('baloaProHeader.yearlyButtonLbl',{_price: yearValue, _currency: yearCurrency })}</div>
                                        <div className='baloa-pro-header__title-price'>&bull;{props.t('baloaProHeader.monthlyButtonLbl',{_price: montValue, _currency: monthCurrency })}</div>
                                        <div className='baloa-pro-header__buttons'>
                                            <PrimaryButtonComponent
                                                type={'button'}
                                                className={''}
                                                onClick={()=>{ props.handleOnClickSubscription() }}
                                                disable={ false }
                                            >
                                                <span>{props.t('baloaProHeader.freeTrialBtnLbl')}</span>
                                            </PrimaryButtonComponent>
                                        
                                        </div>
                                    </React.Fragment> */
                                }
                                {/* <div className='baloa-username'>{props.t('baloaProHeader.autoRenewMsg')}</div> */}
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>

        </div>
    )
}

export default withTranslation('baloapro')(BaloaProHeaderComponent);
