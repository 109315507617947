
export const changeHomePostsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_POSTS", data }
}

export const loadHomePostsAction = ( data ) => {
    return { type: "HOME:LOAD_POSTS", data }
}

export const updateHomeFeedAction = ( data ) => {
    return { type: "HOME:UPDATE_HOME_FEED", data }
}

export const clapHomePostAction = ( post ) => {
    return { type: "HOME:CLAP_POST", post }
}

export const changeHomeProfileSuggestionsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_PROFILE_SUGGESTIONS", data }
}

export const loadHomeProfileSuggestionsAction = ( data ) => {
    return { type: "HOME:LOAD_PROFILE_SUGGESTIONS", data }
}

export const changeHomePageSuggestionsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_PAGE_SUGGESTIONS", data }
}

/*export const changeHomeGroupsSuggestionsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_GROUP_SUGGESTIONS", data }
}*/

export const changeHomeLeaguesSuggestionsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_LEAGUE_SUGGESTIONS", data }
}

export const loadHomePageSuggestionsAction = ( data ) => {
    return { type: "HOME:LOAD_PAGE_SUGGESTIONS", data }
}

/*export const loadHomeGroupsSuggestionsAction = ( data ) => {
    return { type: "HOME:LOAD_GROUP_SUGGESTIONS", data }
}*/

export const loadHomeLeaguesSuggestionsAction = ( data ) => {
    return { type: "HOME:LOAD_LEAGUE_SUGGESTIONS", data }
}

export const removeFromProfileSuggestions = ( profile_suggestion ) => {
    return { type: "HOME:REMOVE_FROM_PROFILE_SUGGESTIONS", profile_suggestion }
}

export const removeFromPageSuggestions = ( page_suggestion ) => {
    return { type: "HOME:REMOVE_FROM_PAGE_SUGGESTIONS", page_suggestion }
}

export const changeHomeNewPostModal = ( new_state ) => {
    return { type: "HOME:CHANGE_NEW_POST_MODAL", new_state }
}

export const changeHomeEditPostModal = ( new_state, post ) => {        
    return { type: "HOME:CHANGE_EDIT_POST_MODAL", new_state, post }    
}

export const changePostNewFormationModal = ( new_state ) => {    
    return { type: "HOME:CHANGE_NEW_FORMATION_MODAL", new_state }
}

export const addUserFormation = ( formation ) => {
    //console.log(formation)
    return { type: "HOME:ADD_USER_FORMATION", formation }
}

export const changeErrorMessageSnackBar = ( new_state ) => {
    return { type: "HOME:CHANGE_ERROR_MESSAGE_SNACKBAR", new_state }
}

export const loadNewPost = ( data ) => {
    return { type: "HOME:LOAD_NEW_POST", data }
}

export const updateHomePostAction= ( data ) => {
    return { type: "HOME:UPDATE_HOME_POST", data }
}

export const changeNewHomePostAction = ( new_state ) => {    
    return { type: "HOME:CHANGE_NEW_HOME_POST", new_state }
}

export const deleteHomePostAction = ( data ) => {   
    return { type: "HOME:DELETE_HOME_POST", data }
}

export const changePageSuggestionsModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_PAGE_SUGGESTIONS_MODAL", new_state }
}

export const changeProfileSuggestionsModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_PROFILE_SUGGESTIONS_MODAL", new_state }
}

export const resetHomeFeedAction = ( data ) => {
    return { type: "HOME:RESET_HOME_FEED", data }
}

export const loadHomePagesShortcutsAction = ( data ) => {
    return { type: "HOME:LOAD_HOME_PAGES_SHORTCUTS", data }
}

export const changeMobileMenuStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_MOBILE_MENU_STATE", new_state }
}

export const changeResponsiveRestrictionModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_RESPONSIVE_RESTRICTION_MODAL_STATE", new_state }
}

export const loadFollowedPagesAction = ( data ) => {
    return { type: "HOME:LOAD_FOLLOWED_PAGES", data }
}

export const loadExplorePagesAction = ( data ) => {
    return { type: "HOME:LOAD_EXPLORE_PAGES", data }
}

export const resetExplorePagesAction = ( data ) => {
    return { type: "HOME:RESET_EXPLORE_PAGES", data }
}

export const updateExploreFollowPageAction = ( data ) => {
    return { type: "HOME:UPDATE_EXPLORE_FOLLOW_PAGE", data }
}

export const updateExploreUnFollowPageAction = ( data ) => {
    return { type: "HOME:UPDATE_EXPLORE_UNFOLLOW_PAGE", data }
}

export const updateHomePageSuggestionFollowAction = ( data ) => {
    return { type: "HOME:UPDATE_HOME_PAGE_SUGGESTION_FOLLOW", data }
}

export const updateHomePageSuggestionUnFollowAction = ( data ) => {
    return { type: "HOME:UPDATE_HOME_PAGE_SUGGESTION_UNFOLLOW", data }
}

export const loadHomePlayersAction = ( data ) => {
    return { type: "HOME:LOAD_HOME_PLAYERS", data }
}

export const loadPlayerAttributesAction = ( data ) => {
    return { type: "HOME:LOAD_HOME_PLAYER_ATTRIBUTES", data }
}

export const loadHomeFeaturedClipsAction = ( data ) => {
    return { type: "HOME:LOAD_HOME_FEATURED_CLIPS", data }
}

export const updateHomeFeaturedClipAction = ( data ) => {
    return { type: "HOME:UPDATE_HOME_FEATURED_CLIPS", data }
}

export const changeHomeFeaturedClipsModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_HOME_FEATURED_CLIPS_MODAL_STATE", new_state }
}

export const changeFeaturedProfileAddVideoModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_FEATURED_PROFILE_ADD_VIDEO_MODAL_STATE", new_state }
}

export const changeFeaturedClipsUnauthPlayModalStateAction = ( new_state ) => {
    return { type: "HOME:CHANGE_HOME_FEATURED_CLIPS_UNAUTH_PLAY_MODAL_STATE", new_state }
}