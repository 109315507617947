// React
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import RouteWithSubRoutes from './RouteWithSubRoutes';
import RoutesLayout from './RoutesLayout'

// Layouts
import GeneralUnauthenticatedLayout from 'shared/layouts/general-unauthenticated/GeneralUnauthenticatedLayout';
import SignUpLayout from 'shared/layouts/signup/SignUpLayout';
//import RoutesLayout from 'shared/layouts/routes/RoutesLayout';
import BaloaPlayUnauthMenuLayout from 'shared/layouts/baloa-play-menu/BaloaPlayUnauthMenuLayout';

// Views
import HomeAnonView from 'app/home/views/home-anon/HomeAnonView';
import NewAccountView from 'app/authentication/views/new-account/NewAccountView';
import LoginAccountView from 'app/authentication/views/login-account/LoginAccountView';
import TermsAndConditionsView from 'app/terms-and-conditions/views/TermsAndConditionsView';
import PostDetailUnauthenticatedView from 'app/posts/views/post-detail/PostDetailUnauthenticatedView';
import ProfileUnauthenticatedView from 'app/profiles/views/ProfileUnauthenticatedView';
import PageUnauthenticatedView from 'app/pages/views/PageUnauthenticatedView';
import CampaignBaloersView from 'app/campaigns/views/CampaignBaloersView';
import UnauthenticatedTournamentMenuComponent from 'shared/layouts/tournament-menu/UnauthenticatedTournamentMenuComponent';
import TournamentHubUnauthenticatedView from 'app/tournaments/views/TournamentHubUnauthenticatedView';
import SearchUnauthenticatedView from 'app/search/views/SearchUnauthenticatedView';
import TournamentHubView from 'app/tournaments/views/TournamentHubView';
import AccountDeleteInfoView from 'app/account-delete-info/views/AccountDeleteInfoView';
import CardsView from 'app/cards/views/CardsView';
import BaloaProUnauthenticatedView from 'app/baloa-pro/views/BaloaProUnauthenticatedView';
import BaloaPlayUnauthenticadedView from 'app/baloa-play/views/BaloaPlayUnauthenticadedView';

const LAYOUTS = [
  {
    path: '/',
    key: 'ROUTES',
    component: RoutesLayout, // TODO: Refactor
    routes: [
      {
        path: '/cuenta',
        key: 'ACCOUNT',
        component: SignUpLayout, // Sign Up Layout
        routes: [
          /* {
            path: '/cuenta/crear',
            key: 'ACCOUNT:NEW',
            exact: true,
            component: NewAccountView, // New Account View
          }, */
          {
            path: '/cuenta/ingresar',
            key: 'ACCOUNT:LOGIN',
            exact: true,
            component: LoginAccountView, // Login Account View
          }
        ]
      },
      {
        path: '/cards/team/:tournament_id&tournament_teams=:tournament_teams&value=:value',
        key: 'SHOW_TEAM_CARDS',
        exact: true,
        component: CardsView, // Cards View
      },
      {
        path: '/cards/player/:tournament_player_id&value=:value',
        key: 'SHOW_TEAM_CARDS',
        exact: true,
        component: CardsView, // Cards View
      },
      {
        path: '/cards/staff/:team_profile_id&tournament_team_id=:tournament_team_id&value=:value',
        key: 'SHOW_TEAM_CARDS',
        exact: true,
        component: CardsView, // Cards View
      },
      {
        path: '/',
        key: 'GENERAL',
        component: GeneralUnauthenticatedLayout, // General Authenticated Layout
        routes: [
          {
            path: '/',
            key: 'HOME',
            exact: true,
            component: HomeAnonView, // Home Anon View
          },
          {
            path: '/pages',
            key: 'HOME_PAGES',
            exact: true,
            component: HomeAnonView, // Home pages section
          },
          {
            path: '/players',
            key: 'HOME_PLAYERS',
            exact: true,
            component: HomeAnonView, // Home players section
          },
          {
            path: '/posts/:post_id',
            key: 'POST_DETAIL',
            exact: true,
            component: PostDetailUnauthenticatedView, // Post Detail View
          },
          {
            path: '/profile/:username',
            key: 'PROFILE',
            exact: true,
            component: ProfileUnauthenticatedView, // Profile View
          },
          {
            path: '/page/:pagename&setshowtournament=:setshowtournament&showtournament=:showtournament',
            key: 'PAGE',
            exact: true,
            component: PageUnauthenticatedView, // Page View
          },
          {
            path: '/page/:pagename&setshowteam=:setshowteam&showteam=:showteam',
            key: 'PAGE',
            exact: true,
            component: PageUnauthenticatedView, // Page View
          },          
          {
            path: '/page/:pagename',
            key: 'PAGE',
            exact: true,
            component: PageUnauthenticatedView, // Page View
          },
          {
            path: '/search/search=:keyword&filter=:filter',
            key: 'SEARCH',
            exact: true,
            component: SearchUnauthenticatedView, // Search View
          },
          {
            path: '/search/search=:keyword',
            key: 'SEARCH_RESULTS',
            exact: true,
            component: SearchUnauthenticatedView, // Search View
          },
          {
            path: '/BaloaPro',
            key: 'BALOA_PRO',
            exact: true,
            component: BaloaProUnauthenticatedView, // Baloa Pro View
          },
          {
            path: '/BaloaPlay',
            key: 'BALOA_PLAY_MENU',
            component: BaloaPlayUnauthMenuLayout, // Baloa Play Menu Layout
            routes: [
              {
                path: '/BaloaPlay',
                key: 'BALOA_PLAY',
                exact: true,
                component: BaloaPlayUnauthenticadedView, // Baloa Play View
              },          
              {
                path: '/BaloaPlay/match/:match_id',
                key: 'BALOA_PLAY_MATCH',
                exact: true,
                component: BaloaPlayUnauthenticadedView, // Baloa Play View
              },
            ]
          },
          {
            path: '/tournament',
            key: 'TOURNAMENT_MENU',
            component: UnauthenticatedTournamentMenuComponent, // League Menu Layout
            routes: [
              {
                path: '/tournament/hub/search',
                key: 'TOURNAMENT_HUB',
                exact: true,
                component: TournamentHubUnauthenticatedView, // Tournament Hub View
              },
              {
                path: '/tournament/hub',
                key: 'TOURNAMENT_HUB',
                exact: true,
                component: TournamentHubUnauthenticatedView, // Tournament Hub View
              },
              {
                path: '/tournament/hub/:tournamentid&phase=:phase_id&match_date=:match_date_id&match=:match_id&type=:type&participate=:participate',
                key: 'TOURNAMENT_HUB_TOURNAMENT_MATCH_DETAIL',
                exact: true,
                component: TournamentHubUnauthenticatedView, // Tournament Hub View
              },
              {
                path: '/tournament/hub/:tournamentid',
                key: 'TOURNAMENT_HUB_TOURNAMENT_DETAIL',
                exact: true,
                component: TournamentHubUnauthenticatedView, // Tournament Hub View
              },
              {
                path: '/tournament/inscriptions',
                key: 'TOURNAMENT_INSCRIPTIOS',
                exact: true,
                component: TournamentHubView, // Tournament Hub View
              },             
              {
                path: '/tournament/my-tournaments',
                key: 'TOURNAMENT_MYTOURNAMENTS',
                exact: true,
                component: TournamentHubUnauthenticatedView, // Tournament Hub View
              },         
            ]
          },
          {
            path: '/terms',
            key: 'TERMS_AND_CONDITIONS',
            exact: true,
            component: TermsAndConditionsView, // Terms & Conditions View
          },
          {
            path: '/ganaconbaloa',
            key: 'GANACONBALOA',
            exact: true,
            component: CampaignBaloersView, // Camapana Baloers View
          },
          {
            path: '/help/delete_account',
            key: 'DELETE_ACCOUNT_INFO',
            exact: true,
            component: AccountDeleteInfoView, // Delete Account Info View
          },          
        ]
      },      
      {
        path: '*',
        key: 'REDIRECT',
        exact: true,
        component: () => <Redirect to="/" />,
      },
    ]
  }
];

export default function UnauthenticatedRoutes() {
  return (
    <Switch>
      {LAYOUTS.map( route => (
        <RouteWithSubRoutes {...route} key={route.key} />
      ))}
    </Switch>
  );
}