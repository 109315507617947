/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlaySearchComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import InputSearchWithButtonComponent from 'shared/components/input-search-with-button/InputSearchWithButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function BaloaPlaySearchComponent( props ) {

    return(
        <div className='baloa-play-search__container'>
            <div className='baloa-play-search__header'>
                <div className='baloa-body-bold'>{props.t('baloaPlayTitle')}</div>
                {isMobile && !props.unauth &&
                    <TextButtonComponent
                        input_type={'button'}
                        onClick={()=>{props.handleOnMyClips()}}
                    >
                        <span>{props.t('searchComponent.myClipsBtnLbl')}</span>
                        <span className='hubicon-video_library1 icon'/>
                    </TextButtonComponent>
                }
            </div>            
            <div className='baloa-subtitle-1'>{props.t('searchComponent.message')}</div>
            <div className='baloa-play-search__buttons'>
                <div className='baloa-play-search__buttons__search'>
                    <InputSearchWithButtonComponent
                        cleanValue={false}
                        handleOnSearchClick={ (search)=>{ props.handleOnSearchVideo(search) } }
                        placeholder={props.t('searchComponent.placeHolder')} 
                    />
                </div>
                {/* <PrimaryButtonComponent //TODO:se oculta mientras se implementa funcionalidad
                    input_type={'button'}
                    onClick={()=>{ props.requestRecording()} }
                >
                    <span>{props.t('searchComponent.requestMatchRecBtnLbl')}</span>
                </PrimaryButtonComponent>  */}
                <div className='baloa-play-search__buttons__links-container'>
                    <a className='baloa-play-search__buttons__link' target='_blank' href={'https://wa.link/pwhk7x'}>
                        <TextButtonComponent>
                            <span className='hubicon-open_in_new icon'/>
                            <span>{props.t('searchComponent.requestMatchRecBtnLbl')}</span>
                        </TextButtonComponent>
                    </a>
                    <a className='baloa-play-search__buttons__link' target='_blank' href={'https://baloa.notion.site/Cobertura-y-T-rminos-y-condiciones-Baloa-Play-e5df83a2bca04815bcca5430281c1fd8'}>
                        <TextButtonComponent>
                            <span className='hubicon-open_in_new icon'/>
                            <span>{props.t('searchComponent.checkCoverage')}</span>
                        </TextButtonComponent>
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default withTranslation('baloaplay')(BaloaPlaySearchComponent);