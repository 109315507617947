/*eslint eqeqeq: "off"*/
// React
import React from 'react';

//Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Styles
import './HomeFeaturedClipsWidgetComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HomeFeaturedClipsWidgetComponent( props ) {

    const [fileInputRef, setFileInputRef] = React.useState(null);
    const [scrollX, setscrollX] = React.useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = React.useState(false); // For detecting end of scrolling
    const scrl = React.useRef();
    
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
        ) {
            if(props.home_featured_clips_next_page){
                props.fetchMoreData();
            }else{
               setscrolEnd(true);
            }
            
        } else {
            setscrolEnd(false);
        }
    };

    /* React.useEffect(() => {
        //Check width of the scollings        
        if (
            scrl.current && (scrl?.current?.scrollWidth === scrl?.current?.offsetWidth)
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]); */

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
        //For checking if the scroll has ended
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    /**
     * Takes the file type input file for the video presentation and sends it to the view so 
     * that the other components know what type of video it is.
     * @param {*} event 
     */
    const onVideoChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'featuredVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    return (
        <div className='home-featured-clips-widget__container'>
            <div className='baloa-normal-medium'>{props.t('featuredClips.fcTitle')}</div>            
            <div className='home-featured-clips-widget__clips-container' ref={scrl} onScroll={scrollCheck}>
                {!isMobile && scrollX !== 0 &&
                    <div class="profile-featured-horizontal-clips_arrow prev" onClick={() => slide(-200)}>
                        <span class="hubicon-arrow_circle_left">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }
                <div className='home-featured-clips-widget__scroll-clips-container'>
                {(!props.featured_clips || props.featured_clips.lenght <= 0 )?
                    isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111} height={191} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                        </React.Fragment>
                    ||
                    !isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176} height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                        </React.Fragment>

                :
                    props.featured_clips?.map( (clip, index) => (
                        <div className='home-featured-clips-widget__clip-link' onClick={()=>{props.handleOnClip( index ) }} key={index}>
                            <div className='home-featured-clips-widget__clip-link__author-photo'>
                                {clip.author?.photo?
                                    <img src={clip.author?.photo} alt={''}/>
                                    :
                                    <span className='hubicon-default_pp'>
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                    </span>
                                }
                                
                            </div>
                            {clip.caption &&
                                <div className='home-featured-clips-widget__clip-link__author-name baloa-names'>{clip.caption}</div>
                            }
                            {clip.post_stream?.thumbnail &&
                                <img src={clip.post_stream?.thumbnail} alt={ clip.caption }/>
                            }
                            
                        </div>
                        ))
                }
                </div>
                {!isMobile && !scrolEnd &&
                    <div class="profile-featured-horizontal-clips_arrow next" onClick={() => slide(+200)}>
                        <span class="hubicon-arrow_circle_right">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }
            </div>            
            {/* !props.is_baloa_pro && props.profile_type === 'player' && props.is_new_baloa_pro_user &&
                <div className='home-featured-clips-widget__baloer-message new-user'>
                    <div>
                        <div className='baloa-normal-medium'>
                            {props.t('featuredClips.baloaProLbl')}
                            <span class="hubicon-award_star" />                                
                        </div>
                        <div className='baloa-names'>{props.t('featuredClips.baloaProSubscriptionMsg2')}</div>
                    </div>
                    <div>
                        <PrimaryButtonComponent
                            type={'button'}
                            className={'home-featured-clips-widget__button'}
                            onClick={()=>{ props.handleOnBaloaProSubscription() }}
                        >
                            <span>{props.t('featuredClips.baloaProSubscriptionBtnLbl2')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </div> */
            }
            {/* !props.is_baloa_pro && props.profile_type === 'player' && !props.is_new_baloa_pro_user &&
                <div className='home-featured-clips-widget__baloer-message'>
                    <div>
                        <div className='baloa-normal-medium'>
                            {props.t('featuredClips.baloaProLbl')}
                            <span class="hubicon-award_star" />                                
                        </div>
                        <div className='baloa-names'>{props.t('featuredClips.baloaProSubscriptionMsg')}</div>
                    </div>
                    <div>
                        <SecondaryButtonComponent
                            type={'button'}
                            className={'home-featured-clips-widget__button'}
                            onClick={()=>{ props.handleOnBaloaProSubscription() }}
                        >
                            <span>{props.t('featuredClips.baloaProSubscriptionBtnLbl')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div> */
            }
            {props.is_baloa_pro && props.profile_type === 'player' &&
                <div className='home-featured-clips-widget__baloer-message'>
                    <div>                        
                        <div className='baloa-names'>{props.t('featuredClips.baloaProUploadMsg')}</div>
                    </div>
                    <div>
                        <SecondaryButtonComponent
                            type={'button'}
                            className={'home-featured-clips-widget__button xs'}
                            onClick={() => { fileInputRef.click() }}
                        >
                            <span>{props.t('featuredClips.baloaProUploadBtnLbl')}</span>
                        </SecondaryButtonComponent>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            onChange={(event) => onVideoChange(event)}
                            ref={video_input => setFileInputRef(video_input)}
                            accept="video/*"
                            value=""
                        />
                    </div>
                </div>
            }
        </div>
        

    )
}

// Exports
export default withTranslation('home')(HomeFeaturedClipsWidgetComponent);