import React from 'react';

import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentPhaseCreatedComponent from '../tournament-phase-created/TournamentPhaseCreatedComponent';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

//Styles
import './TournamentPhaseSummaryComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

const TournamentPhaseSummaryComponent = (props) => {
    return (
        <div className='tournament_phase_summary-container'>
            <div className='tournament_phase_summary_header'>
                <div className="tournament_phase_summary">
                    <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelTitle')}</p>
                </div>
                <SecondaryButtonComponent
                    disabled={false}
                    onClick={() => props.handleOnClickNewPhase('onClickNewPhase_Summary')}
                    className=""
                >
                    <div className='tournament_phase_summary_btn_create_phase'>
                        <span className='hubicon-add_option' />
                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</span>
                    </div>
                </SecondaryButtonComponent>
            </div>
            <p className="tournament_phase_summary-container_sub_title">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDescriptionPhase')}</p>
            <div className='tournament_phase_summary_container_show_phases'>
                {props.tournament_phases?.map((phase, index) =>

                    <div key={phase?.id} className="tournament_phase_summary__content__add">

                        <TournamentPhaseCreatedComponent
                            phase={phase}
                            index={index}
                            selectedPhase={props.tournament_active_phase?.id}
                            edit_tournament={props.edit_tournament}
                            onClickPhaseTab={(phase) => { props.handleOnClickPhaseTab(phase, 'createPhase'); }}
                            tournament_phases={props.tournament_phases}
                            showOptionMenu={
                                props.edit_tournament && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                    ? phase.state === "Active"
                                    : phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                            }
                            showDeletePhaseMenu={
                                props.edit_tournament && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                    ? phase.state === "Active"
                                    : phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                            }
                            showEndPhaseMenu={
                                index === 0
                                    ? props.tournament_phases.length === 1 && phase.state === "Active" && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                        ? true
                                        : phase.state === "Active" && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                    : phase.state === "Active" && props.tournament_phases[index - 1].state === "Finalized" && phase.phase_type && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                            }
                            showAddTeams={
                                /* phase.groups[0]?.teams.length === 0 &&  */ phase.group_0_teams_count === 0 && props.tournament_phases[index - 1]?.state === "Finalized" && phase.phase_type === 'Groups' && permissionsController(props.pageRoles, ['TOURNAMENTAD'])//Change new api phases
                                    ? true
                                    : false
                            }
                            showEditPhaseMenu={true} // Validar si es activa o no
                            onClickNewPhase={() => { }}
                            deleteTournamentPhase={props.deleteTournamentPhase}
                            editTournamentPhaseV2={props.editTournamentPhaseV2}
                            tournament={props.tournament}
                            handleOnClickFinishPhase={(phase) => { props.handleOnClickFinishPhase(phase) }}
                            AddTeamToGroupModal={() => props.AddTeamToGroupModal()}
                            phase_name_values={props.phase_name_values}
                            handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                            unfinished_matches={props.unfinished_matches}
                            is_tournament_finalized={props.is_tournament_finalized}
                            handleCreateNewAtomaticGroups={(is_shuffle) => console.log('handleCreateNewAtomaticGroups', is_shuffle)}
                            handleLastPhaseValidation={() => { }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentPhaseSummaryComponent);