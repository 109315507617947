/*eslint eqeqeq: "off"*/
import { cloneDeep } from 'lodash';

// Home State
const home_state = {
    loading_posts: false,
    posts: [],
    posts_next_page: undefined,
    loading_profile_suggestions: false,
    profile_suggestions: [],
    profile_suggestions_next_page: '',
    loading_page_suggestions: false,
    //loading_group_suggestions: false,
    loading_league_suggestions: false,
    page_suggestions: [],
    //group_suggestions: [],
    league_suggestions: [],
    page_suggestions_next_page: '',
    group_suggestions_next_page: '',
    league_suggestions_next_page: '',
    modals: {
        new_post_is_open: false,
        new_formation_is_open: false,
        page_suggestions_is_open: false,
        profile_suggestions_is_open: false,
        responsive_restriction_modal_is_open: false,
        home_featured_clips_is_open: false,
        profile_video_modal_is_open: false,
        featured_clips_unauth_play_is_open: false,
    },
    formation: {},
    error_message: {
        message: '',
        error_message_is_open: false,
    },
    post: null,
    home_post: false,
    pages_shortcuts: undefined,
    is_mobile_menu_on: false,
    followed_pages: [],
    followed_pages_next_page: '',
    explore_pages: undefined,
    explore_pages_next_page: '',
    home_players: [],
    home_players_next_page: '',
    home_player_attributes: {},
    home_featured_clips: undefined,
    home_featured_clips_next_page: '',
}


export default function HomeReducer ( state = home_state, action ) { 
    switch( action.type ) {

        case "HOME:CHANGE_LOADING_POSTS": {
            return { ...state, loading_posts: action.data }
        }

        case "HOME:LOAD_POSTS": {
            let new_posts = null;
            if(state.posts_next_page !== action.data.next){
                new_posts = state.posts.concat( action.data.results );
            }else{
                new_posts = state.posts;
            }
            return { ...state, posts: new_posts, posts_next_page: action.data.next }
        }

        case "HOME:UPDATE_HOME_FEED": {
            let results = action.data.results;
            let updated_posts = { posts: state.posts.map((postss, i) =>
                results.find(res => res.id == postss.id)? results.find(res => res.id == postss.id) : postss                
            )}
            return { ...state, posts: updated_posts.posts }
        }

        case "HOME:LOAD_NEW_POST": {
            let new_posts = cloneDeep(state.posts);
            new_posts.unshift( action.data );
            return { ...state, posts: new_posts }
        }

        // case "HOME:CLAP_POST": {

        //     let clapped_post = state.posts.filter( 
        //         post => post.id !== action.post.id
        //     )
        //     clapped_post = { ...clapped_post, is_clapped: true }

        //     return { ...state, posts: action.data.results, posts_next_page: action.data.next }
        // }

        case "HOME:CHANGE_LOADING_PROFILE_SUGGESTIONS": {
            return { ...state, loading_profile_suggestions: action.data }
        }

        case "HOME:LOAD_PROFILE_SUGGESTIONS": {
            return { ...state, profile_suggestions: action.data.results, profile_suggestions_next_page: action.data.next }
        }

        case "HOME:REMOVE_FROM_PROFILE_SUGGESTIONS": {
            let new_profile_suggestions = state.profile_suggestions.filter( 
                suggestion => suggestion.user.username !== action.profile_suggestion.user.username
            )
            return { ...state, profile_suggestions: new_profile_suggestions }
        }

        case "HOME:CHANGE_LOADING_PAGE_SUGGESTIONS": {
            return { ...state, loading_page_suggestions: action.data }
        }

        /*case "HOME:CHANGE_LOADING_GROUP_SUGGESTIONS": {
            return { ...state, loading_group_suggestions: action.data }
        }*/

        case "HOME:CHANGE_LOADING_LEAGUE_SUGGESTIONS": {
            return { ...state, loading_league_suggestions: action.data }
        }

        case "HOME:LOAD_PAGE_SUGGESTIONS": {
            return { ...state, page_suggestions: action.data.results, page_suggestions_next_page: action.data.next }
        }

        /*case "HOME:LOAD_GROUP_SUGGESTIONS": {
            return { ...state, group_suggestions: action.data.results, group_suggestions_next_page: action.data.next }
        }*/

        case "HOME:LOAD_LEAGUE_SUGGESTIONS": {
            return { ...state, league_suggestions: action.data.results, league_suggestions_next_page: action.data.next }
        }

        case "HOME:REMOVE_FROM_PAGE_SUGGESTIONS": {
            let new_page_suggestions = state.page_suggestions.filter( 
                suggestion => suggestion.id !== action.page_suggestion.id
            )
            return { ...state, page_suggestions: new_page_suggestions }
        }

        case "HOME:CHANGE_NEW_POST_MODAL": {
            let new_modals_state = { ...state['modals'], new_post_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:CHANGE_EDIT_POST_MODAL": {
            let new_modals_state = { ...state['modals'], new_post_is_open: action.new_state }
            return { ...state, modals: new_modals_state, post: action.post }
        }

        case "HOME:CHANGE_NEW_FORMATION_MODAL": {
            let new_modals_state = { ...state['modals'], new_formation_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:ADD_USER_FORMATION": {
            let new_formation = { ...state['formation'], new_formation: action.formation }
            return { ...state, formation: new_formation }
        }
        
        case "HOME:CHANGE_ERROR_MESSAGE_SNACKBAR": {
            return { ...state, error_message: action.new_state }
            //let new_modals_state = { ...state['modals'], error_message_is_open: action.new_state }
            //return { ...state, modals: new_modals_state }
        }

        case "HOME:LOAD_POSTS_NEXT": {     
            let arrayPosts = [...state['posts']];
            for (const element in action.data.results){
                arrayPosts.push(action.data.results[element]); 
            }
           return { ...state, posts: arrayPosts, posts_next_page: action.data.next }
        }

        case "HOME:UPDATE_HOME_POST":{
            let updated_posts = { posts: state.posts.map(postss =>
                postss.id == action.data.data.id ? action.data.data : postss
            )}
            return { ...state, posts: updated_posts.posts }
        }

        case "HOME:CHANGE_NEW_HOME_POST": {
            return { ...state, home_post: action.new_state }
        }

        case "HOME:DELETE_HOME_POST": {
            let new_posts = state.posts.filter(
                newposts => newposts.id !== action.data.id
            )
            return { ...state, posts: new_posts }
        }

        case "HOME:CHANGE_PAGE_SUGGESTIONS_MODAL": {
            let new_modals_state = { ...state['modals'], page_suggestions_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:CHANGE_PROFILE_SUGGESTIONS_MODAL": {
            let new_modals_state = { ...state['modals'], profile_suggestions_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:RESET_HOME_FEED": {            
            return { ...state, posts: action.data.results, posts_next_page: action.data.next }
        }

        case "HOME:LOAD_HOME_PAGES_SHORTCUTS": {
            return { ...state, pages_shortcuts: action.data.results }
        }

        case "HOME:CHANGE_MOBILE_MENU_STATE": {
            return { ...state, is_mobile_menu_on: action.new_state }
        }

        case "HOME:CHANGE_RESPONSIVE_RESTRICTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], responsive_restriction_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:LOAD_FOLLOWED_PAGES": {
            let new_followed_pages = null;
            if(state.followed_pages_next_page !== action.data.next && action.data.previous){
                new_followed_pages = state.followed_pages.concat( action.data.results );
            }else if(!action.data.previous){
                new_followed_pages = action.data.results;
            }else{
                new_followed_pages = state.followed_pages;
            }
            return { ...state, followed_pages: new_followed_pages, followed_pages_next_page: action.data.next }
        }

        case "HOME:LOAD_EXPLORE_PAGES": {
            let new_explore_pages = null;
            if(state.explore_pages_next_page !== action.data.next && action.data.previous){
                new_explore_pages = state.explore_pages.concat( action.data.results );
            }else if(!action.data.previous){
                new_explore_pages = action.data.results;
            }else{
                new_explore_pages = state.explore_pages;
            }
            return { ...state, explore_pages: new_explore_pages, explore_pages_next_page: action.data.next }
        }

        case "HOME:RESET_EXPLORE_PAGES": {
            return { ...state, explore_pages: undefined, explore_pages_next_page: '' }
        }

        case "HOME:UPDATE_EXPLORE_FOLLOW_PAGE": {
            let new_follow_page = state.explore_pages.find(expp => expp.pagename === action.data.pagename );
            new_follow_page.is_following = true;
            let updated_exp_pags = { explore_pages: state.explore_pages.map(exp_page =>
                exp_page.pagename == action.data.pagename ? new_follow_page : exp_page
            )}
            return { ...state, explore_pages: updated_exp_pags.explore_pages }
        }

        case "HOME:UPDATE_EXPLORE_UNFOLLOW_PAGE": {
            let new_unfollow_page = state.explore_pages.find(expp => expp.pagename === action.data.pagename );
            new_unfollow_page.is_following = false;
            let updated_exp_pags = { explore_pages: state.explore_pages.map(exp_page =>
                exp_page.pagename == action.data.pagename ? new_unfollow_page : exp_page
            )}
            return { ...state, explore_pages: updated_exp_pags.explore_pages }
        }

        case "HOME:UPDATE_HOME_PAGE_SUGGESTION_FOLLOW": {
            let new_follow_page = state.page_suggestions.find(suggp => suggp.pagename === action.data.pagename );
            new_follow_page.is_following = true;
            let updated_sugg_pags = { page_suggestions: state.page_suggestions.map(sugg_page =>
                sugg_page.pagename == action.data.pagename ? new_follow_page : sugg_page
            )}
            return { ...state, page_suggestions: updated_sugg_pags.page_suggestions }
        }

        case "HOME:UPDATE_HOME_PAGE_SUGGESTION_UNFOLLOW": {
            let new_unfollow_page = state.page_suggestions.find(suggp => suggp.pagename === action.data.pagename );
            new_unfollow_page.is_following = false;
            let updated_sugg_pags = { page_suggestions: state.page_suggestions.map(sugg_page =>
                sugg_page.pagename == action.data.pagename ? new_unfollow_page : sugg_page
            )}
            return { ...state, page_suggestions: updated_sugg_pags.page_suggestions }
        }

        case "HOME:LOAD_HOME_PLAYERS": {
            let new_home_players = null;
            if(state.home_players_next_page !== action.data.next && action.data.previous){
                new_home_players = state.home_players.concat( action.data.results );
            }else if(!action.data.previous){
                new_home_players = action.data.results;
            }else{
                new_home_players = state.home_players;
            }
            return { ...state, home_players: new_home_players, home_players_next_page: action.data.next }
        }

        case "HOME:LOAD_HOME_PLAYER_ATTRIBUTES": {
            return { ...state, home_player_attributes: {laterality: action.data.attributes.laterality.options, primary_position: action.data.attributes['primary-position'].options, 
                secondary_position: action.data.attributes['secondary-position'].options} }
        }

        case "HOME:LOAD_HOME_FEATURED_CLIPS": {
            let new_home_featured_clips = null;
            if(state.home_featured_clips_next_page !== action.data.next && action.data.previous){
                new_home_featured_clips = state.home_featured_clips.concat( action.data.results );
            }else if(!action.data.previous){
                new_home_featured_clips = action.data.results;
            }else{
                new_home_featured_clips = state.home_featured_clips;
            }
            return { ...state, home_featured_clips: new_home_featured_clips, home_featured_clips_next_page: action.data.next }
        }

        case "HOME:UPDATE_HOME_FEATURED_CLIPS": {
            let updated_clips = state.home_featured_clips.map(clip =>
                clip.id == action.data.data.id ? action.data.data : clip
            )
            return { ...state, home_featured_clips: updated_clips }
        }

        case "HOME:CHANGE_HOME_FEATURED_CLIPS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], home_featured_clips_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:CHANGE_FEATURED_PROFILE_ADD_VIDEO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_video_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "HOME:CHANGE_HOME_FEATURED_CLIPS_UNAUTH_PLAY_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], featured_clips_unauth_play_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt 
        // no default

    }
    return state;
}
