/*eslint eqeqeq: "off"*/
// React
import React, { useState, useEffect } from 'react';

// Components
//import TournamentTypeComponent from 'app/leagues/components/tournament-tournament-type/TournamentTypeComponent';
//import TounamentPhasesConfigSetComponent from 'app/leagues/components/tournament-phases-config-set/TounamentPhasesConfigSetComponent';
import TournamentGroupsComponent from 'app/leagues/components/tournament-groups/TournamentGroupsComponent';
// import TournamentPhasesReport from 'app/leagues/components/tournament-phases-report/TournamentPhasesReport';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ShowMessageComponent from 'shared/components/show-message/ShowMessageComponent';
import ModalConfirmDialogComponent from 'shared/components/modal-confirm-dialog/ModalConfirmDialogComponent';
import TournamentPhasesSummary from '../tournament-phases-summary/TournamentPhasesSummaryComponent';
import TournamentPhaseCreatedComponent from 'app/leagues/components/tournament-phase-created/TournamentPhaseCreatedComponent';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

// Styles
import './TournamentPhasesComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentPhasesComponentV2(props) {

    const [tournamnetType, setTournamnetType] = React.useState(null); //eslint-disable-line
    const [numberOfGroups, setNumberOfGroups] = React.useState(null); //eslint-disable-line
    const [showTeamsByGroup, setShowTeamsByGroup] = React.useState(null); //eslint-disable-line
    const [showFinishFreeDialog, setShowFinishFreeDialog] = React.useState(false);
    const [showFinishGroupDialog, setShowFinishGroupDialog] = React.useState(false);
    const [showTypePhaseComponent, setShowTypePhaseComponent] = React.useState(handleTypePhase());
    // const [configGroupsInfo, setConfigGroupsInfo] = React.useState("");
    // const [clasifiedByGroup, setClasifiedByGroup] = React.useState(null);
    const [showOneClasifiedMessage, setShowOneClasifiedMessage] = React.useState(false);
    const [newGroupPhases, setNewGroupPhases] = React.useState({});
    const [showInvalidTeamsNumber, setShowInvalidTeamsNumber] = React.useState(false);
    const [showPhasesPhases, setShowPhasesPhases] = React.useState(false);
    const [phaseName, setPhaseName] = useState('');
    const [groupsCount, setGroupsCount] = useState([]);
    const [classifiedGroup, setClasifiedGroup] = useState('');
    const [nameSelectedPhase, setNameSelectedPhase] = useState('');
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [boxPosition, setBoxPosition] = useState(false);
    const [isBoxOpen, setBoxOpen] = useState(false);
    const [selectedPhasesIndex, setSelectedPhasesIndex] = useState(0);
    const [editPhasesComponent, setEditPhasesComponent] = useState(false);
    const [showCreateNewPhase, setCreateNewPhase] = useState(false);
    const [showPhasesReviewAndCreate, setShowPhasesReviewAndCreate] = useState(false);
    const [showSummaryCreatePhase, setShowSummaryCreatePhase] = useState(false);
    const [reviewLastStep, setReviewLastStep] = useState(false);

    React.useEffect(() => {
        if (props.tournament_phases === undefined || props.tournament_phases.length === 0) {
            setShowOneClasifiedMessage(false);
        } else {
            if (props.tournament_phases[props.tournament_phases.length - 1].state === "Finalized"&& 
                (props.tournament_phases[props.tournament_phases.length - 1].classified_count * props.tournament_phases[props.tournament_phases.length - 1].groups_count) +
                props.filter_phase_teams?.filter(team => team.tournament_phase_team_assing === 'not_assigned')?.length === 1) {
                setShowOneClasifiedMessage(false); //last version = true
            }
        }
    }, [props]);

    useEffect(() => {
        if (props.tournament_phases.length > 0) {       
            validationActivePhase(props.tournament_phases.find(phase => phase.is_last_active_phase)); //NOTE: In last phases is Finalize that cant edit ? 
            //if(selectedPhase) props.handle_team_filter(selectedPhase?.id, 'not_assigned');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.tournament_phases)]);

    /**
     * Check if the active phase has groups, in order to take it as active phase.
     * This function is started when the component is rendered.
     * @param {Object} activePhase  -- Object of one active phase
     */
    const validationActivePhase = (activePhase) => {
        //if (activePhase?.groups) {
        if (activePhase?.groups_count > 0) {//Change new api phases
            setSelectedPhase(activePhase);
            setSelectedPhasesIndex(props.tournament_phases.findIndex(phase => phase.id === activePhase.id));
            setNameSelectedPhase(activePhase.id);
            props.handleOnClickPhaseTab(activePhase, 'tabPhase');
        }
    }

    /**
     * Visually activates the components to create a new phase, by means of flags.
     */
    const handleOnClickNewPhase = () => {
        setShowTypePhaseComponent(true);
        setCreateNewPhase(true);
        setShowFinishFreeDialog(false);
        setShowFinishGroupDialog(false);
        setShowTeamsByGroup(false);
        setTournamnetType(false);
        setNumberOfGroups(null);
        setShowPhasesReviewAndCreate(false);
        setReviewLastStep(false);
    }

    /**
     * Receives for parameters only a phase on which you want to position or click and the index of the phase in the phase array. 
     * @param {Object} phase -- Json object containing phase data
     * @param {Number} index  -- Index of the phase in the phase array
     */
    function onClickPhaseTab(phase, index) {
        setSelectedPhase(phase);
        setSelectedPhasesIndex(index)
        props.handleOnClickPhaseTab(phase,'tabPhase');
    }

    /**
     * Deletes the selected phase in which it is stored within the component state and sends this phase with the indez to the view
     */
    function deleteTournamentPhase() {
        props.deleteTournamentPhase(selectedPhase, selectedPhasesIndex);
    }

    /**
     * Edits the selected phase in which it is stored within the component state and change if thge state flag "editPhasesComponent" to render component
     */
    function editTournamentPhase() {
        setEditPhasesComponent(true);
        props.loadTieBreakerByPhase(selectedPhase.id);
    }

    /**
     *  
     * @returns {Boolean} A boolean state in which it checks that the last phase has no groups.
     */
    function handleTypePhase() {
        let team_count = props.tournament_phases[props.tournament_phases.length - 1]?.groups_count * props.tournament_phases[props.tournament_phases.length - 1]?.classified_count;
        let best_third_teams = props.tournament_phases[props.tournament_phases.length - 1]?.best_third_teams;
        if (props.tournament_phases.length === 0) {
            return true;
        } else {
            if (best_third_teams !== 0 && props.tournament_phases[props.tournament_phases.length - 1]?.groups_count > 1) {
                if ((team_count + best_third_teams) <= 1) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (team_count <= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }

    const handleCreateNewAtomaticGroups = (is_shuffle) => {
        let is__shuffle = newGroupPhases
        is__shuffle.is_shuffle = is_shuffle
        setNewGroupPhases(is__shuffle);
        setShowTeamsByGroup(true);
        setShowPhasesPhases(true);
        // asignar automatico es newGroupPhases = true
        props.createNewGroupPhases(props.tournament, props.tournament_active_phase.id, newGroupPhases);
    }

    const handleOnClickNotEnoughTeams = () => {
        setShowTypePhaseComponent(true);
        setTournamnetType(null);
        setShowInvalidTeamsNumber(false);
        props.deleteTournamentPhaseWihtOutType(props.tournament, props.tournament_phases[props.tournament_phases.length - 1])
        setShowSummaryCreatePhase(false);
    }

    const handleCreatedPhaseBackBtn = () => {
        handleOnClickNewPhase();
        if (props.tournament_active_phase.phase_name === null && props.tournament_active_phase.phase_type === null) {
            props.deleteTournamentPhase(props.tournament_phases[props.tournament_phases.length - 1], props.tournament_phases.length - 1);
        }
        setClasifiedGroup('');
        setGroupsCount([]);
        setPhaseName('');
        setShowSummaryCreatePhase(false);
    }

    function handleLastPhaseValidation() {
        if (showFinishGroupDialog && permissionsController(props.pageRoles, ['TOURNAMENTAD'])) {
            if (props.tournament_phases[props.tournament_phases.length - 1].best_third_teams !== 0 && props.tournament_phases[props.tournament_phases.length - 1].groups_count > 1) {
                if (((props.tournament_phases[props.tournament_phases.length - 1].groups_count * props.tournament_phases[props.tournament_phases.length - 1].classified_count) + props.tournament_phases[props.tournament_phases.length - 1].best_third_teams) <= 1) {
                    return false;
                }
            } else if ((props.tournament_phases[props.tournament_phases.length - 1].groups_count * props.tournament_phases[props.tournament_phases.length - 1].classified_count) <= 1) {
                return false;
            }

            if (showFinishGroupDialog && permissionsController(props.pageRoles, ['TOURNAMENTAD'])) {
                return true
            } else {
                showFinishFreeDialog && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
            }
        }
    }

    const handleOnBoxOpen = (index) => {
        if (!isBoxOpen) {
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    };

    const handleFilterPhaseIdTeams = (filter_type) => {
        props.handle_team_filter(selectedPhase.id, filter_type);
    };

    /**
     * A Boolean that indicates if there are teams within the groups and to be able to show or hide a "Tournament Group" component.
     * @returns  A Boolean indicating if there are teams within the groups
     */
    const lastPhaseValidation = () => {
        let lastActivePhase = false;
        props.tournament_active_phase?.groups.forEach(group => {
            if (group.teams.length > 0 ) {
                lastActivePhase = true;
            }
        });
        return lastActivePhase;
    }

    function validatePhase () {
        if(props.tournament_phases.length > 0) {
            let phase_index = props.tournament_phases.findIndex(phase => phase.id === props.tournament_active_phase?.id) 
            if(phase_index > 0 && props.tournament_phases[phase_index - 1].state !== 'Finalized'){
                return true;
            }
        }
    }

    return (

        <div className="tournament-phases">
            {/* Validation of team number */}
            {showInvalidTeamsNumber &&
                <ModalConfirmDialogComponent
                    onPrincipalButtonClick={() => { handleOnClickNotEnoughTeams() }}
                    principalButtonLabel={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.notEnoughModalButtonLbl')}
                    secondaryButton={false}
                    onSecondaryButtonClick={() => { }}
                    secondaryButtonLabel={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.notEnoughModalButtonLbl')}
                    isOpen={showInvalidTeamsNumber}
                    onClose={() => { handleOnClickNotEnoughTeams() }}
                    modalTitle={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.notEnoughModalTitle')}
                    modalContent={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.notEnoughModalText')}
                />
            }

            {!showInvalidTeamsNumber &&
                <React.Fragment>
                    {/* Tournament phases - New Phases*/}
                    <div className={`tournament-phases__phases ${showPhasesPhases ? 'hidden' : ''}`}>
                        {/* Title and Subtitle */}
                        { props.tournament_phases.length === 0 &&
                            <div className="tournament-phases__no-phases__container">
                                <div className='tournament-phases__no-phases'>
                                    <span className='hubicon-info'/>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.labelNoTournamentPhases')}</div>                                        
                                </div>
                                <PrimaryButtonComponent
                                    className=''
                                    onClick={ () => {props.handleOnClickNewPhase(true, 'onClickNewPhase')} }
                                    >
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelCreatePhase')}
                                </PrimaryButtonComponent>
                            </div>
                            // <div className="tournament-phases__phases__arrow title">
                            //     <div className="tournament-phases__phases__arrow_content_text">
                            //         <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</p>
                            //         <p className="baloa-names">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelSubtitleNewPhase')}</p>
                            //     </div>
                            // </div>
                        }
                        {/* Back button arrow and Phases Summary*/}
                        {showSummaryCreatePhase && !showPhasesReviewAndCreate &&
                            <React.Fragment>
                                {/* Back button arrow */}
                                <div className="tournament-phases__phases__arrow">
                                    <span className="icon-arrow-left" onClick={() => handleCreatedPhaseBackBtn()} />
                                    <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.tournamentInfo.labelLabelPhases')}</p>
                                </div>
                                {/* Phases Summary*/}
                                <TournamentPhasesSummary
                                    groupsCount={groupsCount}
                                    phaseName={phaseName}
                                    classifiedGroup={classifiedGroup}
                                    t={props.t}
                                    tournament_phases={props.tournament_phases}
                                />
                            </React.Fragment>
                        }
                        {/* Component showing phases created */}
                        <div className="tournament-phases__phases__content">
                            <div className={'tournament-phases__phases__content-container initial-phase'} >
                                { props.tournament_phases.length > 0 &&  !editPhasesComponent && !showCreateNewPhase &&
                                    <div className='tournament-phases__container'>
                                        {/* Component to Add Team in Phase*/}
                                        { props.tournament_active_phase?.groups[0]?.teams.length === 0 && props.tournament_phases[props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) - 1]?.state === "Finalized" && permissionsController( props.pageRoles, ['TOURNAMENTAD']) &&
                                            <div className="tournament-phases__add-teams__container">
                                                <div className='tournament-phases__add-team__text baloa-names'>
                                                    <span className='hubicon-info'/>
                                                    <div>{props.t('options.tournaments.pages.tournamentInfo.labelBeginPhaseText')}</div>                                        
                                                </div>
                                                <PrimaryButtonComponent
                                                    className='tournament-phases__add-teams__phase-button'
                                                    onClick={() => {  props.AddTeamToGroupModal() }}
                                                >
                                                    <span className='hubicon-low_priority' />
                                                    <div>{props.t('options.tournaments.pages.tournamentInfo.labelAddTeamsToPhase')}</div>
                                                </PrimaryButtonComponent>
                                            </div>
                                        }
                                        <div className='tournament-phases__container_phases-created'>
                                            <div className='league-tournament-report__phases__list tournament-phases'>
                                                <FormControl >
                                                    <Select
                                                        value={nameSelectedPhase ? nameSelectedPhase : props.nameActivePhase}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        variant='standard'
                                                    >
                                                        {props.tournament_phases?.map((phase, index) => (
                                                            <MenuItem
                                                                // disabled={phase.calendar_dates.length === 0}
                                                                key={`Phase ${index + 1}`}
                                                                value={phase.id}
                                                                onClick={() => {
                                                                    onClickPhaseTab(phase, index);
                                                                    props.finishedMatchesByPhase(phase);
                                                                    setNameSelectedPhase(phase.id);
                                                                }}
                                                            >
                                                                <div>
                                                                    <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhase')}
                                                                        {index + 1} - {props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase.phase_name}`)}
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        )
                                                        )
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {props.selected_tournament.state !== 'Finalized' &&
                                                    <div className='tournament-phases__phase-options__container'>
                                                        <span className='icon-options' onClick={() => { handleOnBoxOpen(true) }} />
                                                        <div className={`tournament-phases-information_options-box ${boxPosition ? "active" : ""}`} >
                                                            <div className='tournament-phases-information_options-box__option'
                                                                onClick={() => {
                                                                    handleOnBoxOpen(false);
                                                                    editTournamentPhase();
                                                                }}>
                                                                <span className='hubicon-edit' />
                                                                <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')}</div>
                                                            </div>
                                                            <div className='tournament-phases-information_options-box__option'
                                                                onClick={() => {
                                                                    handleOnBoxOpen(false);
                                                                    deleteTournamentPhase();
                                                                }}>
                                                                    <span className='hubicon-delete_question' />
                                                                <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDeletePhase')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                            {props.selected_tournament.state !== 'Finalized' &&
                                                <div className='tournament-phases__action-btns'>
                                                    <div className='tournament-phases__finish-current-phase'>
                                                        { props.unfinished_matches && 
                                                            <div className='tournament-phases__finish-current-phase__tooltip baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.textEndCurrentPhaseTooltip')}</div>
                                                        }
                                                        <SecondaryButtonComponent 
                                                            disabled={(props.unfinished_matches || props.tournament_active_phase?.state === 'Finalized')}
                                                            onClick={() => {props.handleOnClickFinishPhase(props.tournament_active_phase)}} 
                                                        >
                                                            {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndCurrentPhase')}
                                                        </SecondaryButtonComponent>
                                                    </div>
                                                    <PrimaryButtonComponent
                                                        disabled={false}
                                                        onClick={() => { 
                                                            props.handleOnClickNewPhase(true, 'onClickNewPhase');
                                                        }}
                                                        className="add-phase"
                                                    >
                                                        <div className='tournament-players-information_options-box_button_addPhase'>
                                                            <span className='hubicon-add_option' />
                                                            <div>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</div>
                                                        </div>
                                                    </PrimaryButtonComponent>
                                                </div>
                                            }
                                        </div>
                                        {/* {!lastPhaseValidation() &&
                                            <ShowMessageComponent
                                                lock={true}
                                                message={props.t('options.tournaments.pages.tournamentInfo.labelMessageBlockedPhase')}
                                            />
                                        } */}
                                        
                                        {lastPhaseValidation() &&
                                            <TournamentGroupsComponent
                                                groups={props.tournament_active_phase?.groups}
                                                teams={props.tournament_teams ?? []}
                                                tournament_active_phase={props.tournament_active_phase ?? []}
                                                handleAddTeamToGroup={props.handleAddTeamToGroup}
                                                handleDeleteTeamFromGroup={props.handleDeleteTeamFromGroup}
                                                handleActivePhaseCreateState={props.handleActivePhaseCreateState}
                                                active_phase_groups_create={props.active_phase_groups_create}
                                                handleCreateNewAtomaticGroups={(is_suffle) => handleCreateNewAtomaticGroups(is_suffle)}
                                                tournament_criteria_tie_breaking={props.tournament_criteria_tie_breaking}
                                                tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                                                handle_team_filter={handleFilterPhaseIdTeams}
                                                handleBestThirdByGroup={props.handleBestThirdByGroup}
                                                selectedPhaseBestThird={selectedPhase?.best_third}
                                                handleMoveToGroup={props.handleMoveToGroup}
                                                handleTeamGroupPhaseknockout={props.handleTeamGroupPhaseknockout}
                                                groups_best_third={props.tournament_phases} 
                                                disable_phase_fields={props.tournament_active_phase.state === 'Finalized'} // true
                                                select_groups_teams={true} // Show - Hidden Buttons on Section Groups
                                                isCreatePhase={false}
                                                handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                                                handleDeleteGroupPhase={props.handleDeleteGroupPhase}
                                                handleNumberClassified={props.handleNumberClassified}
                                                deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                                                teams_filter_type={props.teams_filter_type}
                                                selected_tournament = { props.selected_tournament }
                                            />
                                        }
                                    </div>
                                }
                                { props.tournament_phases?.length === 0 &&
                                    <ShowMessageComponent
                                        lock={true}
                                        message={props.t('options.tournaments.pages.tournamentInfo.labelTournamentWithoutPhases')}
                                    />
                                }

                                { props.tournament_phases?.length > 0 && props.tournament_active_phase?.is_last_active_phase && props.tournament_active_phase.groups.some(group => group.teams.length === 0) &&
                                    <ShowMessageComponent
                                        lock={true}
                                        message={props.t('options.tournaments.pages.tournamentInfo.labelMessageUninitializedPhase')}
                                    />
                                }

                                { validatePhase() &&
                                    <ShowMessageComponent
                                        lock={true}
                                        message={props.t('options.tournaments.pages.tournamentInfo.labelMessageBlockedPhase')}
                                    />
                                }
                            </div>
                        </div>

                        {showOneClasifiedMessage &&
                            <ShowMessageComponent
                                lock={false}
                                message={props.selected_tournament.state !== "Finalized"
                                    ? props.t('options.tournaments.pages.tournamentInfo.labelMessageTournamentWithChampion')
                                    : props.t('options.tournaments.pages.tournamentInfo.labelMessageTournamentEnded')
                                }
                            />
                        }

                    </div>

                    {/* Report of the phases and show phases created */}
                    {showPhasesReviewAndCreate && !showTypePhaseComponent && reviewLastStep &&
                        <React.Fragment>
                            <div className="grops-description_phases_review">
                                <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelTitle')}</p>
                            </div>
                            <PrimaryButtonComponent
                                disabled={false}
                                onClick={() => { handleOnClickNewPhase() }}
                                className=""
                            >
                                <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</span>
                            </PrimaryButtonComponent>
                            <p className="grops-description_phases_review_sub_title">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDescriptionPhase')}</p>
                            <div className='tournament-phases__container_show_phases'>
                                {props.tournament_phases?.map((phase, index) =>

                                    <div key={phase} className="tournament-phases__phases__content__add">

                                        <TournamentPhaseCreatedComponent
                                            phase={phase}
                                            index={index}
                                            selectedPhase={props.tournament_active_phase?.id}
                                            edit_tournament={props.edit_tournament}
                                            onClickPhaseTab={(phase) => { onClickPhaseTab(phase) }}
                                            tournament_phases={props.tournament_phases}
                                            showOptionMenu={
                                                props.edit_tournament && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                                    ? phase.state === "Active"
                                                    : phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                            }
                                            showDeletePhaseMenu={
                                                props.edit_tournament && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                                    ? phase.state === "Active"
                                                    : phase.state === "Active" && index === props.tournament_phases.length - 1 && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                            }
                                            showEndPhaseMenu={
                                                index === 0
                                                    ? props.tournament_phases.length === 1 && phase.state === "Active" && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                                        ? true
                                                        : phase.state === "Active" && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                                    : phase.state === "Active" && props.tournament_phases[index - 1].state === "Finalized" && phase.phase_type && permissionsController(props.pageRoles, ['TOURNAMENTAD'])
                                            }
                                            showAddTeams={
                                                /* phase.groups[0]?.teams.length === 0 &&  */phase.group_0_teams_count === 0 && props.tournament_phases[index - 1]?.state === "Finalized" && phase.phase_type === 'Groups' && permissionsController(props.pageRoles, ['TOURNAMENTAD'])//Change new api phases
                                                    ? true
                                                    : false
                                            }
                                            showEditPhaseMenu={true} // Validar si es activa o no
                                            onClickNewPhase={() => { handleOnClickNewPhase() }}
                                            deleteTournamentPhase={(phase, index) => { deleteTournamentPhase(phase, index) }}
                                            editTournamentPhase={(id_tournament, phase) => { editTournamentPhase(id_tournament, phase) }}
                                            editTournamentPhaseV2={(id_tournament, phase) => { editTournamentPhase(id_tournament, phase) }}
                                            tournament={props.tournament}
                                            handleOnClickFinishPhase={(phase) => { props.handleOnClickFinishPhase(phase) }}
                                            AddTeamToGroupModal={() => props.AddTeamToGroupModal()}
                                            phase_name_values={props.phase_name_values}
                                            handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                                            unfinished_matches={props.unfinished_matches}
                                            is_tournament_finalized={props.is_tournament_finalized}
                                            handleCreateNewAtomaticGroups={(is_shuffle) => handleCreateNewAtomaticGroups(is_shuffle)}
                                            handleLastPhaseValidation={() => handleLastPhaseValidation()}
                                        />
                                    </div>

                                )}
                            </div>
                            <div className="grops-description_phases_review_button">
                                <PrimaryButtonComponent
                                    className='report-secondary-button small'
                                    onClick={() => {
                                        props.handleCalendarStep();
                                        setReviewLastStep(false);
                                    }}
                                >
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseFinished.labelActionGoToFixture')}
                                </PrimaryButtonComponent>
                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </div>
    )

}

export default withTranslation('league')(TournamentPhasesComponentV2);