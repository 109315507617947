import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

import './TournamentCardIdPreviewModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentCardIdPreviewModalComponent(props) {

    return (
            <Modal2Component
                isOpen={props.isOpen}
                onClose={()=>{ props.onClose()}}
                title={props.title}
                className='card-id-preview-modal'
            >
                <div 
                    className={`carnet__canvas preview ${props.orientation === 'horizontal'? '' :'vertical'}`}
                    style={
                        props.orientation === 'horizontal'?
                        { 
                            backgroundImage: `url(${props.cardIdH})`, 
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '318pt 176.25pt'
                        }
                        :
                        {
                            backgroundImage: `url(${props.cardIdV})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '205.50pt 290.55pt'
                        }
                    }
                >
                    {props.cardIdData.map((cdata, index) =>
                        <div
                            id={'cdata'+cdata.id}
                            key={cdata.id}
                            className={`carnet-data baloa-table ${(cdata.type === 'image' || cdata.type === 'file')? 'image '+cdata.shape :''} `}
                            style={{
                                position: "absolute",
                                left: `${cdata.x}px`,
                                top: `${cdata.y}px`,
                                fontFamily: `${cdata.fontFamily}`,
                                fontSize: `${cdata.fontSize}`,
                                width: `${cdata.width? cdata.width : ''}`,
                                height: `${cdata.height? cdata.height : ''}`,
                                color: `${cdata.color}`,
                            }} 
                        >
                            {(cdata.value !== '' && (cdata.type === 'image' || cdata.type === 'file'))?
                                <div className={`carnet-data ${(cdata.type === 'image' || cdata.type === 'file')? 'test-image '+cdata.shape :''}`}><img src={cdata.value} alt='' draggable="false"/></div>
                                :
                                <div>{`${cdata.value !== ''? cdata.value : '{'+cdata.caption+'}'}`}</div>
                            }
                        </div>
                    )}
                    
                </div>
            </Modal2Component>

        )
}

export default withTranslation('league')(TournamentCardIdPreviewModalComponent);