import React, { useState, useEffect} from 'react';

import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentGroupsComponent from 'app/leagues/components/tournament-groups/TournamentGroupsComponent';
import TournamentNewPhaseComponent from '../tournament-new-phase/TournamentNewPhaseComponent';
import TournamentPhaseSummaryComponent from '../tournament-phase-summary/TournamentPhaseSummaryComponent';

//i18
import { withTranslation } from 'react-i18next';

import './TournamentPhasesContainerComponent.styles.css';

function TournamentPhasesContainerComponent(props) {

    const [step, setStep] = useState(0);
    const [ submitForm, setSubmitForm ] = useState(false);
    const [ backToCreate, setBackToCreate ] = useState(false);
    const [phaseToEdit, setPhaseToEdit ] = useState(null);
    const [stepEditPhase, setStepEditPhase ] = useState(false);
    const [phaseDataToEdit, setPhaseDataToEdit ] = useState(null);
    const [stepCreatePhase, setStepCreatePhase ] = useState('');
    const [stepToEditAction, setStepToEditAction] = useState(null);
    const [statusRememberPhase, setStatusRememberPhase] = useState(false);
    const [selectedPhaseType, setSelectedPhaseType] = useState(null);

    useEffect(()=>{
        if(props.click_source_create_phase === 'onClickNewPhase' || stepCreatePhase === 'onClickNewPhase_Summary'){
            setPhaseToEdit(null);
        }
    },[props.click_source_create_phase,stepCreatePhase]);

    const handleFilterForCreatePhase =(filter_type)=>{
        props.handle_team_filter(props.tournament_active_phase?.id, filter_type);
    };

    const handleNextStep = ()=>{
        if( props.tournament_phases.length >= 2 && validationNexStepCreatePhase() && !stepEditPhase){
            setStep(2);
        //}else if(stepEditPhase && phaseToEdit.groups[0].teams.length === 0 ){
        }else if(stepEditPhase && phaseToEdit.group_0_teams_count === 0 ){//Change new api phases
            setStepEditPhase(false);
            setStatusRememberPhase(false);
            setStep(2);
        }else {
            setStep(step + 1);
        }
    }

    const handleBackStep = ()=>{
        if( props.tournament_phases.length === 2 && props.tournament_phases[props.tournament_phases.length - 2]?.is_last_active_phase && !stepEditPhase){
            setStep(0);
            setStatusRememberPhase(true);
            setBackToCreate(true);
        }else if(props.tournament_phases.length >= 3 && validationNexStepCreatePhase() ){
            setStep(0);
            setStatusRememberPhase(true);
            setBackToCreate(true);
        }else if(props.tournament_active_phase.state !== 'Active' && stepEditPhase){
            setStep(0);
        }else if(phaseToEdit && stepEditPhase ){
            setStep(2);
        }else {
            setStep(step - 1);
        }
    }

    const handleOnClickNewPhase= (stateClick) =>{
        setPhaseToEdit(null);
        setStepEditPhase(false);
        setStep(0);
        setStepCreatePhase(stateClick); // stateClick = onClickNewPhase_Summary
        setStatusRememberPhase(false);
        setSelectedPhaseType(null)
    }

    const handleValidationNextStep = () =>{
        let validationResponse = false;
        props.tournament_active_phase?.groups.forEach((group, index) => {
            if (group && group.teams?.length < 2) {
                validationResponse = true;
            }
        });
        return validationResponse;
    }

    const validationNexStepCreatePhase = () =>{
        let validationResponse = false;
        /* props.tournament_phases[props.tournament_phases.length - 2]?.groups.forEach((group, index) => {
            if (group && props.tournament_phases[props.tournament_phases.length - 2]?.state === 'Active' ) {
                validationResponse = true;
            }
        }); */
        if(props.tournament_phases[props.tournament_phases.length - 2]?.groups_count > 0 && props.tournament_phases[props.tournament_phases.length - 2]?.state === 'Active'){//cahnge new api phases
            validationResponse = true;
        }

        return validationResponse;
    }

    const handleOnClickEditPhase = (tournamentId, phase)=>{
        if(phase.id !== props.tournament_active_phase.id){
            props.loadTournamentPhaseTeams(phase.id, 'not_assigned');
        }
        setStepCreatePhase('');
        setPhaseToEdit(phase);
        setStepEditPhase(true);
        setStatusRememberPhase(true);
        setStep(0);
        /* if(phase.groups[0].teams.length === 0 ){
            setStepToEditAction(0);
        }else{
            setStepToEditAction(1);
        } */

        if(phase.group_0_teams_count === 0 ){//Change new api phases
            setStepToEditAction(0);
        }else{
            setStepToEditAction(1);
        }
    }

    const handleValidationEditPhase =(phaseDataObject)=>{
        setPhaseDataToEdit(phaseDataObject);
        if(stepToEditAction === 0){
            props.handleEditPhase(phaseDataObject);
        }
    }

    const hanldeDeleteNewPhase = async (data) =>{
        await props.deleteNewPhase(props.tournament_id, data);
        props.addTournamentPhase(props.tournament_id);
    }

    const backValidationDeletePhase = (phaseDataObject)=>{
        let phaseIndex = undefined;
        if(phaseDataObject){
            phaseIndex = props.tournament_phases.findIndex(phase => phase.id === phaseDataObject?.id);   
        }

        if(step !== 2 && props.tournament_phases.length > 0 && phaseIndex){
            if(phaseIndex === 0){                
                if(!stepEditPhase && selectedPhaseType){
                    props.deleteNewPhase(props.tournament_id, props.selected_phase);
                }
                setTimeout(()=>{
                    props.handleOnClickNewPhase(false);
                },1000);
            }else if(phaseIndex > 0 && phaseDataObject.phase_type){
                if(!stepEditPhase && handleValidationNextStep()){
                    props.deleteNewPhase(props.tournament_id, phaseDataObject);
                }
                setTimeout(()=>{
                    props.handleOnClickNewPhase(false);
                },1000);
            }else if(phaseIndex > 0 && !phaseDataObject.phase_type){
                props.deleteNewPhase(props.tournament_id, phaseDataObject);
                setTimeout(()=>{
                    props.handleOnClickNewPhase(false);
                },1000);
            }
        }else{
            props.handleOnClickNewPhase(false);
        }
    };

    const handleSetSelectedPhaseType = (phaseType) => setSelectedPhaseType(phaseType);

    return (
        <React.Fragment>
            {(props.click_source_create_phase === 'onClickNewPhase' ||  stepCreatePhase === 'onClickNewPhase') &&
                <div className='tournament-phases-container_title'>
                    <span className="icon-arrow-left" onClick={() => {
                            backValidationDeletePhase(props.selected_phase);
                        }} />
                    <div className='baloa-headline-2'>
                        {props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase'))}
                    </div>
                </div>
            }
            <div className='tournament-phases-container'>
                {props.click_source_create_phase !== 'onClickNewPhase' && step === 0 &&
                    <div className='tournament-phases-container_global_title baloa-subtitle-1'> 
                        {stepEditPhase 
                            ? props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase'))
                            : props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase'))
                        }
                    </div>
                }

                {step === 0 &&
                    <TournamentNewPhaseComponent
                        selected_phase = {props.selected_phase}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        league_page={props.league_page}
                        phase_teams={props.phase_teams}
                        tournament_phases={ props.tournament_phases }
                        edit_tournament={stepEditPhase}
                        stepEditPhase={stepEditPhase }//prop to edit the phase only this flow
                        statusRememberPhase={statusRememberPhase} // Prop to remember the phase in the configuration phase Component
                        //phase_name_values={ this.props.state.leagueTournaments.phase_name_values}
                        tournament_teams={props.tournament_setup_teams}
                        tournament_criteria_tie_breaking = { props.tournament_criteria_tie_breaking}
                        tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                        handleEditPhase={(phaseDataObject)=> handleValidationEditPhase(phaseDataObject)}
                        handleReturnPhaseComponent={props.handleReturnPhaseComponent}
                        onResetError={props.onResetError}
                        //handle_team_filter= {(phaseId, type_filter) => this.handleTeamsFilterinPhases(phaseId, type_filter)}
                        pageRoles = { props.pageRoles}
                        addTournamentPhase = {props.addTournamentPhase}
                        tournament_id={ props.tournament_id }
                        createNewGroupPhases = {props.createNewGroupPhases}                          
                        submitForm={submitForm}
                        handleNextStep={()=>{handleNextStep()}}
                        resetButton={ () => {setSubmitForm(false)} }
                        backToCreate={backToCreate}
                        phaseToEdit={phaseToEdit ?? props.selected_phase}
                        hanldeDeleteNewPhase={hanldeDeleteNewPhase}
                        hanldeSelectedPhaseType={(phaseType)=> handleSetSelectedPhaseType(phaseType)}
                    />
                }

                {step === 1 &&
                    <TournamentGroupsComponent
                        //groups={props.tournament_phases[props.tournament_phases.length - 1]?.groups}
                        groups={props.tournament_active_phase?.groups}
                        teams={props.tournament_teams ?? []}
                        //tournament_active_phase={props.tournament_phases[props.tournament_phases.length - 1]}
                        tournament_active_phase={props.tournament_active_phase}
                        handleAddTeamToGroup={props.handleAddTeamToGroup}
                        handleDeleteTeamFromGroup={props.handleDeleteTeamFromGroup}
                        handleActivePhaseCreateState={props.handleActivePhaseCreateState}
                        active_phase_groups_create={props.active_phase_groups_create}
                        handleCreateNewAtomaticGroups={(filter_state) => props.handleCreateAutomaticGroupPhases(props.tournament_active_phase.id, filter_state)}
                        tournament_criteria_tie_breaking={props.tournament_criteria_tie_breaking}
                        tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                        select_groups_teams={true} // Show - Hidden Buttons on Section Groups
                        // handleBestThirdByGroup={props.handleBestThirdByGroup}
                        handle_team_filter={handleFilterForCreatePhase}
                        isCreatePhase={true}
                        handleDeleteGroupPhase={props.handleDeleteGroupPhase}
                        handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                        handleNumberClassified={props.handleNumberClassified}
                        handleCleanAllGroups={props.handleCleanAllGroups}
                        clickSourseCreatePhase={props.click_source_create_phase}
                        stepEditPhase={stepEditPhase}
                        deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                        handleOnAddAllTeamsToGroup={props.handleOnAddAllTeamsToGroup}
                        teams_filter_type={props.teams_filter_type}
                    />
                }
                {step === 2 &&
                    <TournamentPhaseSummaryComponent
                        tournament_phases={ props.tournament_phases}
                        tournament_active_phase={props.tournament_phases[props.tournament_phases.length - 1]}
                        edit_tournament={true}
                        pageRoles = { props.pageRoles}
                        tournament={ props.tournament_id }
                        handleOnClickNewPhase={(stateClick) => handleOnClickNewPhase(stateClick)}
                        deleteTournamentPhase={props.deleteTournamentPhase}
                        editTournamentPhaseV2={(tournamentId, phase)=> handleOnClickEditPhase(tournamentId, phase)}
                        handleOnClickPhaseTab={props.handleOnClickPhaseTab}
                    />
                }

                <div className="tournament-phases-container__actions">
                    {(step === 0 && !props.isNewTournament)?
                        <SecondaryButtonComponent
                            type="button"
                            disabled={step !== 0}
                            id="tournament-basic-data__back-btn"
                            onClick={() => {
                                props.handleOnClickNewPhase(false); 
                                if(!stepEditPhase && selectedPhaseType){
                                    props.deleteNewPhase(props.tournament_id, props.selected_phase);
                                }
                            }}
                        >
                            <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelCancelButton')}</span>
                        </SecondaryButtonComponent>
                    :
                        <SecondaryButtonComponent
                            type="button"
                            disabled={step === 0 && !stepEditPhase}
                            id="tournament-basic-data__back-btn"
                            onClick={() => {
                                setStatusRememberPhase(false);
                                if(step===1){
                                    // setBackToCreate(true);
                                    props.deleteNewPhase(props.tournament_id, props.selected_phase);
                                    setSelectedPhaseType(null);
                                }else if(stepCreatePhase === 'onClickNewPhase'){
                                    setStep(2);
                                }
                                handleBackStep();
                                // setStep(step - 1);
                            }}
                        >
                            <span>
                            {stepEditPhase
                                ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelCancelButton')
                                : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelBackButton')
                            }
                            </span>
                        </SecondaryButtonComponent>
                    }
                    {step === 2?
                        <PrimaryButtonComponent
                            type="button"
                            disabled={false}
                            onClick={() => {                            
                                props.handleOnClickNewPhase(false);
                                props.handleCalendarStep();
                            }}
                            className="tournament-phases-container__btn small"
                        >
                            <span>
                                {props.isNewTournament
                                    ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelStepEndPhase') 
                                    : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelStepEndPhase')
                                }
                            </span>
                        </PrimaryButtonComponent>
                    :
                        <PrimaryButtonComponent
                            type="button"
                            disabled={step === 2}
                            onClick={() => {
                                setStatusRememberPhase(false);
                                if(step === 0){
                                    setSubmitForm(true);
                                    setBackToCreate(false);
                                }else if(step === 1 && handleValidationNextStep()){
                                    props.handleOnPhaseValidation(`createPhaseType${props.tournament_active_phase?.phase_type}`);
                                }else if(stepEditPhase && step === stepToEditAction){
                                    props.handleEditPhase(phaseDataToEdit);
                                    setStepEditPhase(false);
                                    setStep(step + 1);
                                }else{                                    
                                    setStep(step + 1);
                                }
                            }}
                            className="tournament-phases-container__btn small"
                        >
                            <span>
                            {stepEditPhase && step === stepToEditAction
                                ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelSaveEditButton')
                                : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelNextButton')
                            }
                            </span>
                        </PrimaryButtonComponent>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default withTranslation('league')(TournamentPhasesContainerComponent)