// React
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import RouteWithSubRoutes from './RouteWithSubRoutes';
import RoutesLayout from './RoutesLayout'

// Layouts
import GeneralAuthenticatedLayout from 'shared/layouts/general-authenticated/GeneralAuthenticatedLayout';
import CreatePostLayout from 'shared/layouts/create-post/CreatePostLayout';
import LeagueMenuLayout from 'shared/layouts/league-menu/LeagueMenuLayout';
import TournamentMenuLayout from 'shared/layouts/tournament-menu/TournamentMenuLayout';
import BaloaPlayMenuLayout from 'shared/layouts/baloa-play-menu/BaloaPlayMenuLayout';

// Views
import HomeView from 'app/home/views/home/HomeView';
import ProfileView from 'app/profiles/views/ProfileView';
import PageView from 'app/pages/views/PageView';
import PostDetailView from 'app/posts/views/post-detail/PostDetailView';
import SearchView from 'app/search/views/SearchView';
import UserSettingsView from 'app/user-settings/views/UserSettingsView';
import LeagueView from 'app/leagues/views/LeagueView';
import MemberView from 'app/leagues/views/MemberView';
import TeamView from 'app/leagues/views/TeamView';
import TournamentView from 'app/leagues/views/TournamentView';
import HelpView from 'app/leagues/views/HelpView';
import TermsAndConditionsView from 'app/terms-and-conditions/views/TermsAndConditionsView';
import CampaignBaloersView from 'app/campaigns/views/CampaignBaloersView';
import TournamentHubView from 'app/tournaments/views/TournamentHubView';
import PaymentsView from 'app/payments/views/PaymentsView';
import AccountDeleteInfoView from 'app/account-delete-info/views/AccountDeleteInfoView';
import BaloaProView from 'app/baloa-pro/views/BaloaProView';
import BaloaPlayView from 'app/baloa-play/views/BaloaPlayView';


const ROUTES = [
  {
    path: '/',
    key: 'ROUTES',
    component: RoutesLayout, // TODO: Refactor
    routes: [
      {
        path: '/',
        key: 'CREATE_POST',
        component: CreatePostLayout, // Create Post Layout
        routes: [
          {
            path: '/',
            key: 'GENERAL',
            component: GeneralAuthenticatedLayout, // General Authenticated Layout
            routes: [
              {
                path: '/',
                key: 'HOME',
                exact: true,
                component: HomeView, // Home View
              },
              {
                path: '/pages',
                key: 'HOME_PAGES',
                exact: true,
                component: HomeView, // Home pages section
              },
              {
                path: '/players',
                key: 'HOME_PLAYERS',
                exact: true,
                component: HomeView, // Home players section
              },
              {
                path: '/posts/:post_id',
                key: 'POST_DETAIL',
                exact: true,
                component: PostDetailView, // Post Detail View
              },
              {
                path: '/profile/:username',
                key: 'PROFILE',
                exact: true,
                component: ProfileView, // Profile View
              },
              {
                path: '/page',
                key: 'LEAGUE_MENU',
                component: LeagueMenuLayout, // League Menu Layout
                routes: [
                  {
                    path: '/page/:pagename&tournamentid=:tournamentid&phase=:phase_id&match_date=:match_date_id&match=:match_id&type=:type',
                    key: 'PAGE',
                    exact: true,
                    component: PageView, // Page View
                  },
                  {
                    path: '/page/:pagename&setshowtournament=:setshowtournament&showtournament=:showtournament',
                    key: 'PAGE',
                    exact: true,
                    component: PageView, // Page View
                  },
                  {
                    path: '/page/:pagename&setshowteam=:setshowteam&showteam=:showteam',
                    key: 'PAGE',
                    exact: true,
                    component: PageView, // Page View
                  },
                  {
                    path: '/page/:pagename',
                    key: 'PAGE',
                    exact: true,
                    component: PageView, // Page View
                  },
                ]
              },
              {
                path: '/search/search=:keyword&filter=:filter',
                key: 'SEARCH',
                exact: true,
                component: SearchView, // Search View
              },
              {
                path: '/search/search=:keyword',
                key: 'SEARCH_RESULTS',
                exact: true,
                component: SearchView, // Search View
              },
              {
                path: '/user/:username',
                key: 'USER_SETTINGS',
                exact: true,
                component: UserSettingsView, // User settings
              },
              {
                path: '/BaloaPro',
                key: 'BALOA_PRO',
                exact: true,
                component: BaloaProView, // Baloa Pro View
              },
              {
                path: '/BaloaPlay',
                key: 'BALOA_PLAY_MENU',
                component: BaloaPlayMenuLayout, // Baloa Play Menu Layout
                routes: [
                  {
                    path: '/BaloaPlay',
                    key: 'BALOA_PLAY',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                  {
                    path: '/BaloaPlay/mis-clips',
                    key: 'BALOA_PLAY',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                  {
                    path: '/BaloaPlay/mis-clips/:clip_id',
                    key: 'BALOA_PLAY_CLIP',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                  {
                    path: '/BaloaPlay/en-vivo',
                    key: 'BALOA_PLAY',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                  {
                    path: '/baloaPlay/transmisiones-finalizadas',
                    key: 'BALOA_PLAY',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                  {
                    path: '/baloaPlay/match/:match_id',
                    key: 'BALOA_PLAY_MATCH',
                    exact: true,
                    component: BaloaPlayView, // Baloa Play View
                  },
                ]
              },
              {
                path: '/league',
                key: 'LEAGUE_MENU',
                component: LeagueMenuLayout, // League Menu Layout
                routes: [
                  {
                    path: '/league/tournament/:pagename&tournamentid=:tournamentid&phase=:phase_id&match_date=:match_date_id&match=:match_id&type=:type',
                    key: 'LEAGUE_TOURNAMENT',
                    exact: true,
                    component: TournamentView, // Tournament View
                  },
                  {
                    path: '/league/help',
                    key: 'LEAGUE_HELP',
                    exact: true,
                    component: HelpView, // League Help View
                  },
                  {
                    path: '/league/payments',
                    key: 'LEAGUE_PAYMENTS',
                    exact: true,
                    component: PaymentsView, // Tournament Payments View
                  },
                  {
                    path: '/league/:pagename',
                    key: 'LEAGUE',
                    exact: true,
                    component: LeagueView, // League View
                  },
                  {
                    path: '/league/tournament/:pagename&setshowtournament=:setshowtournament&showtournament=:showtournament',
                    key: 'LEAGUE_TOURNAMENT',
                    exact: true,
                    component: TournamentView, // Tournament View
                  },
                  {
                    path: '/league/tournament/:pagename',
                    key: 'LEAGUE_TOURNAMENT',
                    exact: true,
                    component: TournamentView, // Tournament View
                  },
                  {
                    path: '/league/member/:pagename',
                    key: 'LEAGUE_MEMBER',
                    exact: true,
                    component: MemberView, // League Member View
                  },
                  {
                    path: '/league/team/:pagename&setshowteam=:setshowteam&showteam=:showteam',
                    key: 'LEAGUE_TEAM',
                    exact: true,
                    component: TeamView, // League Team View
                  },
                  {
                    path: '/league/team/:pagename',
                    key: 'LEAGUE_TEAM',
                    exact: true,
                    component: TeamView, // League Team View
                  },
                ]
              },
              {
                path: '/tournament',
                key: 'TOURNAMENT_MENU',
                component: TournamentMenuLayout, // League Menu Layout
                routes: [
                  {
                    path: '/tournament/hub/search',
                    key: 'TOURNAMENT_HUB',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/hub',
                    key: 'TOURNAMENT_HUB',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/hub/:tournamentid&phase=:phase_id&match_date=:match_date_id&match=:match_id&type=:type&participate=:participate',
                    key: 'TOURNAMENT_HUB_TOURNAMENT_MATCH_DETAIL',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/hub/:tournamentid',
                    key: 'TOURNAMENT_HUB_TOURNAMENT_DETAIL',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/inscriptions/confirmpayment/:team_tournament_id',
                    key: 'TOURNAMENT_INSCRIPTIOS_CONFIRM_PAYMENT',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/inscriptions/:tournamentid&phase=:phase_id&match_date=:match_date_id&match=:match_id&type=:type',
                    key: 'TOURNAMENT_HUB_TOURNAMENT_DETAIL',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/inscriptions',
                    key: 'TOURNAMENT_INSCRIPTIOS',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/my-tournaments',
                    key: 'TOURNAMENT_MYTOURNAMENTS',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/invitations',
                    key: 'TOURNAMENT_INVITATIONS',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                  {
                    path: '/tournament/matches',
                    key: 'TOURNAMENT_MATCHES',
                    exact: true,
                    component: TournamentHubView, // Tournament Hub View
                  },
                ]
              },
              {
                path: '/terms',
                key: 'TERMS_AND_CONDITIONS',
                exact: true,
                component: TermsAndConditionsView, // Terms & Conditions View
              },
              {
                path: '/ganaconbaloa',
                key: 'GANACONBALOA',
                exact: true,
                component: CampaignBaloersView, // Camapana Baloers View
              },
              {
                path: '/cuenta/ingresar',
                key: 'LOGIN',
                exact: true,
                component: () => <Redirect to="/" />, // Redirect login url to home
              },
              {
                path: '/help/delete_account',
                key: 'DELETE_ACCOUNT_INFO',
                exact: true,
                component: AccountDeleteInfoView, // Delete Account Info View
              },
            ]
          },
        ]
      },
      {
        path: '*',
        key: 'REDIRECT',
        exact: true,
        component: () => <Redirect to="/" />,
      },
    ]
  }
];

export default function AuthenticatedRoutes() {
  return (
    <Switch>
      {ROUTES.map( route => (
        <RouteWithSubRoutes {...route} key={route.key} />
      ))}
    </Switch>
  );
}
