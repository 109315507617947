// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './BaloaPlayUnauthPlayModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function BaloaPlayUnauthPlayModalComponent ( props ){
    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'baloa-pro-unauth-subscription-modal'}
        >
            <div className='baloa-pro-unauth-subscription-modal__container'>
                <span className='hubicon-play_circle'/>
                <div className='baloa-headline-2'>{props.t('unauthPlayModal.title')}</div>
                <div className='baloa-names'>{props.t('unauthPlayModal.message')}</div>
                
                
                <div className='baloa-pro-unauth-subscription-modal__buttons'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        className={''}
                        onClick={ ()=>{props.handleLogin()} }
                    >
                        <span>{props.t('unauthPlayModal.loginBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloaplay')(BaloaPlayUnauthPlayModalComponent);
