// React
import React from 'react';

//Styles
import './HomeBaloaProWelcomeWidgetComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function HomeBaloaProWelcomeWidgetComponent ( props ){
    return(
        <div className='home-baloa-pro-welcome-widget__container'>
            <div className='home-baloa-pro-welcome-widget__title'>
                <span className='hubicon-award_star'/>
                <div className='baloa-subtitle-2'>{props.t('welcomeWidget.title')}</div>
                <span className='hubicon-clear_option' onClick={()=>{props.onClose()}}/>                
            </div>
            <div className='baloa-normal'>{props.t('welcomeWidget.message')}</div>
        </div>
    )

}

export default withTranslation('baloapro')(HomeBaloaProWelcomeWidgetComponent);
