// React
import React from 'react';

// Formik
import { useFormik } from 'formik';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectActionBtnComponent from 'shared/components/select-action-btn/SelectActionBtnComponent';

//i18
import { useTranslation } from 'react-i18next';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';
// import { DateFormatValidator } from 'shared/validators/DateValidator';

// Styles
import './TournamentMatchEditMainInfoModalComponent.styles.css';

function TournamentMatchEditMainInfoModalComponent( props ) {    
    const { t } = useTranslation('league');
    const [actionType, setActionType] = React.useState('');
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line
    const formRef = React.useRef();

    const formikvalues = useFormik({
        initialValues: {
            match_date:  '',
            referee: '', 
            field: '',          
        },
    });

    React.useEffect( () => {        
        if(props.new_page_field) {
            if(actionType === 'create-tournament-field') {
                let field = [ { field:  props.new_page_field.id } ]
                props.handleTournamentFieldActions('create-tournament-field', field);
                setActionType('');
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.new_page_field]);

    const handleCreateField = (action_type) => {
        props.handleFieldsAdminActions(action_type, {}); 
        setActionType(action_type)
    }

    const handleSubmitForm = () => {
        props.handleEditMatchInfo(formRef.current.values);
        formRef.current?.submitForm();
        formRef.current?.resetForm();
        formRef.current?.setFieldValue("match_date", "");
        forceUpdate();
    }
     
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.title}
            className='tournament-match-edit-main-info-modal'
        >
            <div className='tournament-match-edit-main-info__container' >
                {/* <div className='tournament-match-edit-main-info__header'>
                    <div className='baloa-hyperlink-2'>{props.title}</div>
                    <span className='hubicon-clear_option baloa-hyperlink-2' onClick={ () => props.handleClose() } />
                </div> */}
                <FormComponent 
                    formInitialValues={ formikvalues.initialValues } 
                    onSubmit={(values, { resetForm }) => {   
                        //props.handleEditMatchInfo(values);
                        resetForm();
                    }}
                    className=""
                    innerRef={formRef}
                    enableReinitialize={true}
                >
                    { props.info_type === 'date-time' && 
                        <div className='tournament-match-edit-main-info__input'>
                            <InputFieldComponent
                                label={''} 
                                input_id="match_date" 
                                field_name="match_date"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className=""
                                input_type="datetime-local"
                                resetError={ () => {} }
                                error={{ match_date: '' }}
                                message=""
                            />
                        </div> 
                    }
                    { props.info_type === 'field' && 
                        <div className='tournament-match-edit-main-info__input'>
                            <SelectActionBtnComponent 
                                id='field'
                                field_name="field"
                                className=""
                                disabled={false}
                                resetError={() => { }}
                                error={{ field: '' }}
                                message=""
                                placeholder={ t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectField') }
                                btn_add_label={ t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewField') }
                                options={ props.tournament_fields }
                                defaultValue={''}
                                onChange={ (value) => {} }
                                validationFunction={ RequiredValidator }  
                                //select_type='text'
                                select_type='fields'
                                addItem={ () => handleCreateField('create-tournament-field') }
                                optionLabelField={"caption"}
                                optionSelectedField={"value"}
                            />
                        </div> 
                    }
                    { props.info_type === 'referee' && 
                        <div className='tournament-match-edit-main-info__input'>
                            <SelectActionBtnComponent 
                                id='referee'
                                field_name="referee"
                                className=""
                                disabled={false}
                                resetError={ () => { } }
                                error={{ referee: '' }}
                                message=""
                                placeholder={ t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectReferee') }
                                options={props.staff}
                                defaultValue={''}
                                onChange={(value) => {}}
                                validationFunction={ RequiredValidator }  
                                //select_type='img_text'
                                select_type='referees'
                                addItem={ ()=>{ props.handleAddStaf('referee') } } 
                                btn_add_label={ t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddRefereeToTournament') }
                                optionLabelField={"first_name"}
                                optionSelectedField={"profile"}     
                            />
                        </div>
                    }
                    <div className='tournament-match-edit-main-info__actions'>
                        <SecondaryButtonComponent type='button' onClick={ () => props.onClose() } >{ t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.modals.modalDisableTeam.principalButtonLabel') }</SecondaryButtonComponent>
                        <PrimaryButtonComponent type='button' onClick={()=>{ handleSubmitForm()}} >{ t('options.tournaments.pages.newTournament.saveButton') }</PrimaryButtonComponent>
                    </div>
                </FormComponent>
            </div>
        </Modal2Component>
    );
}

export default TournamentMatchEditMainInfoModalComponent;