/*eslint eqeqeq: "off"*/
// Baloa Pro State
const baloapro_state = {
    stripe_prices: [],
    stripe_payment_intent: undefined,
    stripe_subscription: undefined,
    countries_search: [],
    modals: {
        stripe_payment_modal: false,
        stripe_resume_modal: false,
        payment_methods_modal: false,
        cancel_subscription_modal: false,
        add_payment_method_modal: false,
        unauth_subscription_modal: false,
    },
    loading_uanuth_stripe_prices: false,
    stripe_user_account: undefined,
    stripe_setup_intent: undefined,
}

export default function BaloaProReducer ( state = baloapro_state, action ) { 
    switch( action.type ) {

        case "BALOAPRO:CHANGE_STRIPE_PAYMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], stripe_payment_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPRO:LOAD_STRIPE_PRICES": {
            return { ...state, stripe_prices: action.data.results }
        }

        case "BALOAPRO:CREATE_STRIPE_PAYMENT_INTENT": {
            return { ...state, stripe_payment_intent: action.data }
        }

        case "BALOAPRO:SET_COUNTRIES_SEARCH": {
            return { ...state, countries_search: action.data.data.results }
        }

        case "BALOAPRO:CREATE_STRIPE_SUBSCRIPTION": {
            return { ...state, stripe_subscription: action.data }
        }

        case "BALOAPRO:CHANGE_STRIPE_RESUME_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], stripe_resume_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPRO:RESET_STRIPE_SUBSCRIPTION": {
            return { ...state, stripe_subscription: undefined }
        }

        case "BALOAPRO:CHANGE_PAYMENT_METHODS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_methods_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPRO:CHANGE_CANCEL_SUBSCRIPTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], cancel_subscription_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "BALOAPRO:CHANGE_ADD_PAYMENT_METHOD_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], add_payment_method_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPRO:CHANGE_UNAUTH_SUBSCRIPTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], unauth_subscription_modal: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "BALOAPRO:LOADING_UNAUTH_STRIPE_PRICES": {
            return { ...state, loading_uanuth_stripe_prices: action.new_state}
        }

        case "BALOAPRO:LOAD_STRIPE_USER_ACCOUNT": {
            return { ...state, stripe_user_account: action.data }
        }

        case "BALOAPRO:CREATE_STRIPE_SETUP_INTENT": {
            return { ...state, stripe_setup_intent: action.data }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt 
        // no default

    }
    return state;
}
