/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayClipsVideoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function BaloaPlayClipsVideoModalComponent( props ) {

    const videoRef = React.useRef();
    const [disablePrev, setDisablePrev] = React.useState(true);
    const [disableNext, setDisableNext] = React.useState(false);

    const handleBackClip = ( ev ) => {
        ev.preventDefault( ev );
        if(props.selected_video_index > 0){
            let new_index = props.selected_video_index -1;
            props.handleOnPlayClip(new_index);
            if(new_index === 0){
                setDisablePrev(true);
            }else{
                setDisablePrev(false);
            }
            if(new_index < (props.clips_number -1)){
                setDisableNext(false);
            }
        }
    }
    
    const handleNextClip = ( ev ) => {
        ev.preventDefault();
        if(props.selected_video_index >= 0){
            let new_index = props.selected_video_index + 1;
            props.handleOnPlayClip(new_index);
            if(new_index === (props.clips_number -1)){
                setDisableNext(true);
            }else{
                setDisableNext(false);
            }
            if(new_index > 0){
                setDisablePrev(false);
            }
        }
    }
    const handleVideoWidth = ( vw, vh) => {
        if(vw >= vh){
            return '100%';
        }else{
            return 'auto'
        }
    }

    const handleVideoHeight= (vw, vh) => {
        if(vh >= vw){
            return '100%';
        }else{
            return 'auto'
        }
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.clip_video?.post_video_caption}
            className="baloa-play-clips-video-modal"
        >
            <div className={`baloa-play-clips-video-modal__container `}>
                {!isMobile && 
                    <div className={`baloa-play-clips-video-modal__slide-button left ${disablePrev? 'disabled':''}`} onClick={disablePrev? ()=>{} : (e)=>{ handleBackClip(e)}} >
                        <span class="hubicon-arrow_circle_left">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }
                <div className="baloa-play-clips-video-modal__media-container">

                        <div className="baloa-play-clips-video-modal__video-container">
                            {/* Video Player */}
                            {props.clip_video?.post_stream?.hls &&
                                <ReactHlsPlayer
                                    className=''
                                    playerRef={videoRef}
                                    src={props.clip_video?.post_stream?.hls}
                                    preload="auto"
                                    autoPlay={false}
                                    playsinline
                                    width={handleVideoWidth( props.clip_video?.post_stream?.width, props.clip_video?.post_stream?.height )}
                                    height={handleVideoHeight(  props.clip_video?.post_stream?.width, props.clip_video?.post_stream?.height )}
                                    loop={true}
                                    poster={props.clip_video?.post_stream?.thumbnail ? props.clip_video?.post_stream?.thumbnail : ""}
                                    controls={true}
                                    controlsList={`${props.clip_video?.is_blocked? 'nofullscreen':''}`}
                                    hlsConfig={{
                                        startLevel: 4, // El índice de la calidad deseada
                                        //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                                        capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                                        maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                                        maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                                        maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                                        maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                                        lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                                        highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                                        nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                                        nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
                                      }}
                                />
                            }
                            {props.clip_video?.is_blocked &&
                                <div className='baloa-play-clips-video-modal__watermark'>
                                    <span className='hubicon-lock_fill'/>
                                    <div className='baloa-normal'>
                                        {props.match_is_blocked?
                                            props.t('blockedMatchWatermarkMsg')
                                        :
                                            props.t('blockedClipWatermarkMsg')
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                        {//props.clip_video?.is_blocked &&
                            <div className='baloa-play-clips-video-modal__blocked-message'>
                                <div className='baloa-play-clips-video-modal__blocked-message__container'>                                    
                                    {props.match_is_blocked? //Debe pagar el partido
                                        <div>
                                            <div className='baloa-play-clips-video-modal__blocked-message__title'>
                                                <span className='hubicon-play_arrow'/>
                                                <div className='baloa-normal-medium'>{props.t('clipsPaymentTitle')}</div>
                                            </div>
                                            {/* <div className='baloa-names'>{props.t('clipsPaymentMessage',{_valueMonth: props.baloa_pro_month_price, _currencyMonth: props.baloa_pro_month_currency, _valueMatch: props.selected_match?.baloa_match_recording_price?.value, _currencyMatch: props.selected_match?.baloa_match_recording_price?.currency })}</div> */}
                                            <div className='baloa-names'>{props.t('paymentMessageProDisabled',{_valueMatch: props.selected_match?.baloa_match_recording_price?.value, _currencyMatch: props.selected_match?.baloa_match_recording_price?.currency })}</div>
                                        </div>
                                        :
                                        props.selected_match?.is_claimed?//Ya reclamo un clip en el partido
                                        <div>
                                            <div className='baloa-play-clips-video-modal__blocked-message__title'>
                                                <span className='hubicon-video_library'/>
                                                <div className='baloa-normal-medium'>{props.t('matchClipClaimedTitle')}</div>
                                            </div>
                                            <div className='baloa-names'>{props.t('matchClipClaimedMsg')}</div>
                                        </div>
                                        :
                                        props.clip_video?.is_claimed?//Ya reclamaron este clip
                                        <div>
                                            <div className='baloa-play-clips-video-modal__blocked-message__title'>
                                                <span className='hubicon-video_library'/>
                                                <div className='baloa-normal-medium'>{props.t('claimedClipTitle')}</div>
                                            </div>
                                            <div className='baloa-names'>{props.t('claimedClipMsg')}</div>
                                        </div>
                                        ://Puede reclamar el clip
                                        <div>
                                            <div className='baloa-play-clips-video-modal__blocked-message__title'>
                                                <span className='hubicon-video_library'/>
                                                <div className='baloa-normal-medium'>{props.t('availableToClaimTitle')}</div>
                                            </div>
                                            <div className='baloa-names'>{props.is_baloa_pro?  props.t('availableToClaimMsgPro') : props.t('availableToClaimMsg')}</div>
                                        </div>
                                    }
                                    {(!props.selected_match?.is_claimed && !props.match_is_blocked) &&
                                        <PrimaryButtonComponent
                                            input_type={'button'}
                                            onClick={()=>{ props.handleOnPayClip(props.clip_video) }}
                                        >
                                            <span>{props.t('clipsPaymentBtnLbl')}</span>
                                        </PrimaryButtonComponent>
                                    }
                                    {(props.match_is_blocked) &&
                                        <React.Fragment>
                                            {/* <PrimaryButtonComponent
                                                input_type={'button'}
                                                onClick={()=>{props.handleOnBaloaProSubscription()}}
                                            >
                                                <span>{props.t('paymentOptionsModal.subscribeBtnLb')}</span>
                                            </PrimaryButtonComponent>
                                            <SecondaryButtonComponent
                                                input_type={'button'}
                                                onClick={()=>{ props.handleOnPayMatchFromClip( ) }}
                                            >
                                                <span>{props.t('paymentBtnLbl')}</span>
                                            </SecondaryButtonComponent> */}

                                            <PrimaryButtonComponent
                                                input_type={'button'}
                                                onClick={()=>{ props.handleOnPayMatchFromClip( ) }}
                                            >
                                                <span>{props.t('paymentBtnLblProDisabled')}</span>
                                            </PrimaryButtonComponent>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        }                       
                    </div>
                
                {!isMobile && 
                    <div className={`baloa-play-clips-video-modal__slide-button right ${disableNext? 'disabled':''}`} onClick={disableNext? ()=>{} : (e)=>{ handleNextClip(e)}} >
                        <span class="hubicon-arrow_circle_right">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }                
            </div>
        </Modal2Component>
    )
}

export default withTranslation('baloaplay')(BaloaPlayClipsVideoModalComponent);