/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Shapes
import { PostShape } from 'app/posts/shapes/PostShape';

// Styles
import './FeedComponent.styles.css';

// Components
import PostComponentV2 from 'app/posts/components/post/PostComponentV2';
import PostProfileComponent from 'app/posts/components/post-profile/PostProfileComponent';


// Feed Component:
//   Renders Posts and handles their actions.

function FeedComponentV2( props ) {

    const [active_post, setActivePost]= useState(undefined);
    const [options, setOptions] = React.useState();
    /* eslint-disable */
    React.useEffect( () => {
        // Need more posts?
        if( active_post == props.posts[ props.posts.length -1 ] || active_post == props.posts[ props.posts.length -2 ] ) { 
            props.handleOnLoadMorePosts();
        }
    }, [ active_post ] )
    /* eslint-enable */

    const beforeRender = () => {
        let optArray = [];
        if(props.user != undefined){
            optArray.push([props.user.me.username, props.user.me.profile_id, props.user.me.photo]);
        }
        
        if (props.profile?.loggedin.pages) {
           props.profile.loggedin.pages.map((p) =>
             optArray.push([p.pagename, p.id, p.photo])
            
           );
           setOptions(optArray);
        } 
    }

    React.useEffect(() => {
        beforeRender();
    }, [props.posts, props.user]); // eslint-disable-line

    const [muted, setMuted] = useState(true);

    const [play_videos, setPlayVideos] = useState(false);
    
    return (
        
        <div className={ "feed " + ( props.className ? props.className : '' ) }>
            {props.feedView !== 'profile' && props.posts?.map( ( post, index ) =>                 
                <PostComponentV2 
                    className = "feed__post"
                    
                    key = { post.id }
                    post = { post } 
                    me_photo = { props.me_photo }
                    show_group = { props.show_group }
                    onEnterViewport = { () => { 
                        setActivePost( post );
                    } }
                    onExitViewport = { () => {} }

                    play_video = { ( active_post?.id == post.id ) && play_videos }
                    play_audio = { ( active_post?.id == post.id ) && !muted }
                    handleOnPlayVideo = { () => setPlayVideos( !play_videos ) }
                    handleOnMuteVideo = { () => setMuted( !muted ) }

                    //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                    //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                    
                    handleClap = { ( post ) => props.handleClap( post ) }
                    onCloseClapsModal={ () => { props.onCloseClapsModal(true) } }
                    loadPostClapsById={ ( post_id ) => props.loadPostClapsById( post_id ) }
                    
                    handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                    handleOnComment = { ( comment, pagename, page_id ) => props.handleOnComment( post, comment, pagename, page_id ) }

                    openReportPostModal = { () => props.openReportPostModal( true ) }
                    handleReportPost = { ( post_id ) => props.handleReportPost( post_id ) }

                    openBlockPostModal = { () => props.openBlockPostModal( true ) }
                    handleBlockProfile = { ( username ) => props.handleBlockProfile( username ) }
                    handleBlockPage={(pagename) => {props.handleBlockPage(pagename)}}

                    handleOnDeletePostClick = { ( post ) => props.handleOnDeletePostClick( post ) }

                    handleOnCopyLinkClick = { ( post ) => props.handleOnCopyLinkClick( post ) }

                    handleOnAuthorClick = { props.handleOnAuthorClick }

                    menu_is_open = {props.menu_is_open}

                    profile = { props.profile }
                    listUserPages = {options} 
                    postItemIndex = {index}
                    is_group = {props.is_group}
                    page = { props.page }
                    sendReaction= { props.sendReaction }
                    overrideReaction= { props.overrideReaction }
                    handleReactionsDetails = { props.handleReactionsDetails }
                    loadPostReactionsById = { props.loadPostReactionsById }
                    deleteReaction = { props.deleteReaction }
                    followPostProfile = { props.followPostProfile }                            
                    unfollowPostProfile = { props.unfollowPostProfile }                            
                    followPostPage = { props.followPostPage }                            
                    unfollowPostPage = { props.unfollowPostPage }

                    handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                    handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                    handleOnQuoteIconClick = { props.handleOnQuoteIconClick }
                    handleOnImageRecord = { props.handleOnImageRecord }
                />                            
            ) }
            <div className='feed__profile-container'>
                {props.feedView === 'profile' && props.posts?./* filter(p => (p.post_image || p.post_video || p.formation || p.post_quoted)). */map( ( post, index ) =>  //TODO: temporal el filter mientras se define UI de los otros tipòs de post               
                    <PostProfileComponent
                        className={''}
                        key = { post.id }
                        post = { post } 

                        onEnterViewport = { () => { 
                            setActivePost( post );
                        } }
                        onExitViewport = { () => {} }                        
                        postItemIndex = {index}
                        handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                        handleProfilePostModal={ props.handleProfilePostModal }
                    />                
                )}
            </div>
        </div>
    )
}

// Types
FeedComponentV2.propTypes = {

    className: PropTypes.string,

    // Data
    posts: PropTypes.arrayOf( PropTypes.shape( PostShape ) ).isRequired,
    me_photo: PropTypes.string.isRequired,

    // Scroll
    handleOnLoadMorePosts: PropTypes.func.isRequired,

    // Profile/Page clicks
    handleOnAuthorClick: PropTypes.func.isRequired,
    
    // Claps
    handleClap: PropTypes.func.isRequired,
    onCloseClapsModal: PropTypes.func, // TODO: Bad name
    loadPostClapsById: PropTypes.func, // TODO: To be removed
    
    // Comments
    handleOnMessageIconClick: PropTypes.func.isRequired,
    handleOnCommentsClick: PropTypes.func.isRequired,
    handleOnComment: PropTypes.func.isRequired,
    
    // Abuse report
    openReportPostModal: PropTypes.func, // TODO: Bad name
    handleReportPost: PropTypes.func,

    // Block author
    openBlockPostModal: PropTypes.func, // TODO: Bad name
    handleBlockProfile: PropTypes.func,

    // Options
    handleOnEditPostClick: PropTypes.func, // TODO: To be implemented
    handleOnDeletePostClick: PropTypes.func,

    // Share
    handleOnCopyLinkClick: PropTypes.func.isRequired,

    feedView: PropTypes.string,
}

// Exports
export default FeedComponentV2;
