// React
import React from 'react';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import { AnyValidator } from 'shared/validators/SignUpValidator';

// Styles
import './LeagueAddedMemberRolComponent.styles.css';

/* Function  update rol */
function LeagueAddedMemberRolComponent(props ) {

    if(props.member_rol.role == null)
    {
        let role = {
          role: {
            role: '',
            position: '',
            player_number: '',  
          }
        }
        props.member_rol.role  = role
    }

    /*Hooks for states select roles members */
    const [memberRol, setMemberRol] = React.useState(props.member_rol.role?.team_role);
    const [memberPosition, setMemberPosition] = React.useState(props.member_rol.role?.team_profile_position); 
    const [memberNumber, setMemberNumber] = React.useState(props.member_rol.role?.number); 
    const [isClickRol, setIsClickRol] = React.useState(false);
    const [isClickPosition, setIsClickPosition] = React.useState(false);
    const [isClickNumber, setIsClickNumber] = React.useState(false);
    const [isPlayer, setIsPlayer] = React.useState(false);

    const formInitialValues = {
        rol: props.member_rol.role?.team_role,
        number: props.member_rol.role?.number,
        position: props.member_rol.role?.team_profile_position
    }

    /* eslint-disable */
    React.useEffect(() => {
        if( props.member_rol.role.team_role  === "player"){
        
            setIsPlayer(true);
        }
    }, [props.member_rol.role.team_role])
    /* eslint-enable */


    /* Send data at API and clear position fields when is not player  */
    const sendData = (dataSend) =>{
        let member_rol = {
            username: props.member_rol?.username,
            role: {
                role: dataSend.rol,
                position: dataSend.position === "" ? null : dataSend.position,
                player_number: dataSend.number === "" ? null : dataSend.number,  
            },
        };
        
        member_rol.role.player_number = member_rol.role.role  !== "player" ? null : member_rol.role.player_number;
        member_rol.role.position  = member_rol.role.role  !== "player" ? null : member_rol.role.position;
        
        props.onSelectRolMember(member_rol);

        setIsClickRol(false);
        setIsClickPosition(false);
        setIsClickNumber(false);
    }

    const handleSaveRol = (value) =>  {
        if (isClickRol) {
            setIsPlayer(value === "player" ? true : false)
            let dataSend  = {
                rol: value,
                number: memberNumber,
                position: memberPosition
            }
            sendData(dataSend);
            setMemberRol(value);
        }        
    }

    const handleSaveNumber = (value) =>  {
        if (isClickNumber) {
            let dataSend  = {
                rol: memberRol,
                number: value,
                position: memberPosition,
            }
            if(value !== memberNumber)
            {
                sendData(dataSend);
            }
         }        
    }

    const handleSavePosition = (value) =>  {
        if (isClickPosition) {
            let dataSend  = {
                rol: memberRol,
                number: memberNumber,
                position: value,
            }
            sendData(dataSend);
            setMemberPosition(value);
        }        
    }

    return(
       <React.Fragment>
           <div className="added-member-rol-data">
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions)=>{
                
                    }}
                >
                    <div className="add-member-rol">
                        <div className="add-member-rol__roles">
                            <SelectFieldComponent
                                field_name="rol"
                                className="add-member-rol__select-input"
                                validationFunction={AnyValidator}
                                disabled={false}
                                resetError={()=>{}}
                                error={props.errors_member_rol}
                                message=""
                                placeholder=""
                                options={props.roles}
                                onChange={(value) => {
                                    if(value && value !== undefined){                                            
                                        handleSaveRol(value);
                                    }                                    
                                }}
                                onClick = {(e) => e &&  setIsClickRol(true) }
                            />
                        </div>
                        { isPlayer  && ( 
                            <div className="add-member-rol__positions__number">
                                <InputFieldComponent
                                    field_name="number"
                                    className="add-member-rol__select-input"
                                    disabled={false}
                                    input_type="number"   
                                    resetError={()=>{}}
                                    error ={props.errors_member_rol}
                                    message=""
                                    placeholder="#"
                                    value={memberNumber}
                                    onChange={(value) => {
                                        if(value && value !== undefined){                                            
                                            setMemberNumber(value);
                                            setTimeout(function(){handleSaveNumber(value)},3000);
                                        }
                                    }}
                                    onClick = {(e) => e && setIsClickNumber(true) }
                                    min="1"
                                />
                            </div>
                        )} 
                        { isPlayer && (
                            <div className="add-member-rol__positions__positions">
                            <SelectFieldComponent
                                field_name="position"
                                className="add-member-rol__select-input"
                                validationFunction={AnyValidator}
                                disabled={false}
                                resetError={()=>{}}
                                error={props.errors_member_rol}
                                message=""
                                placeholder=""
                                options={props.positions}
                                onChange={(value) => {
                                    handleSavePosition(value); 
                                }}
                                onClick = {(e) => e &&  setIsClickPosition(true) }
                            
                            />
                            </div>
                        )}
                    </div>
                </FormComponent>
                            
            </div>                
       </React.Fragment>
        
    )

}

export default LeagueAddedMemberRolComponent;