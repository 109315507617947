// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-payment-message-modal .modal__container {
    width: 440px;
    padding: 24px;
}   

.baloa-play-payment-message-modal__container {
    text-align: center;
    margin-top: 10px;
}

.baloa-play-payment-message-modal__container .hubicon-play_circle::before {
    font-size: 45px;
    color: var(--new-green-color);
}

.baloa-play-payment-message-modal__container .baloa-normal-medium {
    color: var(--primary-very-dark-color);
    margin: 24px;
}

.baloa-play-payment-message-modal__container .baloa-names {
    color: var(--primary-very-dark-color);
    font-weight: var(--font-regular);
}

.baloa-play-payment-message-modal__container .button {
    margin-top: 24px;
    width: 100%;
    font-size: 18px;
    padding: 10px 43px;
}

.baloa-play-payment-message-modal__container .button.secondary-button {
    margin-top: 8px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 

    .baloa-play-payment-message-modal__container .button {
        font-size: 15px;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-payment-message-modal-component/BaloaPlayPaymentMessageModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,qCAAqC;IACrC,YAAY;AAChB;;AAEA;IACI,qCAAqC;IACrC,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".baloa-play-payment-message-modal .modal__container {\n    width: 440px;\n    padding: 24px;\n}   \n\n.baloa-play-payment-message-modal__container {\n    text-align: center;\n    margin-top: 10px;\n}\n\n.baloa-play-payment-message-modal__container .hubicon-play_circle::before {\n    font-size: 45px;\n    color: var(--new-green-color);\n}\n\n.baloa-play-payment-message-modal__container .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    margin: 24px;\n}\n\n.baloa-play-payment-message-modal__container .baloa-names {\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-regular);\n}\n\n.baloa-play-payment-message-modal__container .button {\n    margin-top: 24px;\n    width: 100%;\n    font-size: 18px;\n    padding: 10px 43px;\n}\n\n.baloa-play-payment-message-modal__container .button.secondary-button {\n    margin-top: 8px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n\n    .baloa-play-payment-message-modal__container .button {\n        font-size: 15px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
