// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import moment from 'moment';

// Matherial UI
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Styles
import './TournamentInscriptionViewFormDataModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentInscriptionViewFormDataModalComponent( props ){

    function handleListAnswer(question) {
        let selected_list_answer = question.answers.find(el => el.is_checked === true);
        return selected_list_answer?.form_template_answer?.caption;
    }

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}            
            className="tournament-inscription-view-for-data-modal"
        >
            <div className="tournament-inscription-view-for-data-modal__container scroll-parent" >
                <div className='tournament-inscription-view-for-data-modal__container_data scroll'>
                    {props.formData?.questions.map((question, index)=>(
                        <div className='tournament-inscription-view-for-data-modal__question' key={index}>
                            <div className='tournament-inscription-view-for-data-modal__question__caption baloa-username'>{question.form_question_template.caption}</div>
                            {(question.form_question_template.question_type.caption === 'text' || question.form_question_template.question_type.caption === 'email' || question.form_question_template.question_type.caption === 'phone') &&
                                <div className='tournament-inscription-view-for-data-modal__question__answer baloa-table'>{question.text_answer}</div>
                            }
                            {question.form_question_template.question_type.caption === 'date' &&
                                <div className='tournament-inscription-view-for-data-modal__question__answer date baloa-table'>{moment(question.date_field).format('DD/MM/YYYY')}</div>
                            }                            
                            {question.form_question_template.question_type.caption === 'file' &&
                            
                                <a href={question.file_document} target='_blank' rel="noopener noreferrer" download className='tournament-inscription-view-for-data-modal__question__download' ><span className='hubicon-file_download'></span>{props.t("registerPlayer.viewForm.downloadFile")}</a>
                            }
                            {question.form_question_template.question_type.caption === 'image' &&
                                <img src={question.file_document} alt={''}/>
                            }
                            {question.form_question_template.question_type.caption === 'single_choice' &&
                                question.answers.map((ans ,ansIndex) => (
                                //question.answers.filter(answ => answ.is_checked === true).map(ans => (
                                    <FormControlLabel
                                        key={`${index}-${ansIndex}`}  
                                        control={<Radio color="secondary" checked={ans.is_checked}/>}
                                        label={ans.form_template_answer.caption}
                                        //labelPlacement="end"
                                        className={'tournament-inscription-view-for-data-modal__question__single-choice'}
                                    />
                                ))                                                               
                            }
                            {question.form_question_template.question_type.caption === 'multi_choice' &&
                                question.answers.map((ans ,ansIndex) => (                                    
                                    <FormControlLabel
                                        //value="start"
                                        key={`${index}-${ansIndex}`}
                                        control={<Checkbox color="secondary" checked={ans.is_checked}/>}
                                        label={ans.form_template_answer.caption}
                                        //labelPlacement="end"
                                        className={'tournament-inscription-view-for-data-modal__question__multiple-choice'}
                                    />
                                ))                                                               
                            } 
                            {question.form_question_template.question_type.caption === 'boolean' && //TODO: test this type of question response
                                <div className='tournament-inscription-view-for-data-modal__question__answer baloa-table'>{question.boolean_answer? props.t("registerPlayer.viewForm.trueAnswer") : props.t("registerPlayer.viewForm.falseAnswer")}</div>
                            }
                            {question.form_question_template.question_type.caption === 'list' &&
                                <div className='tournament-inscription-view-for-data-modal__question__answer list baloa-table' >{handleListAnswer(question)} <span className='icon-arrow-down' /> </div>
                            }                            
                            
                        </div>

                    ))
                    }
                </div>
            </div>
            
        </Modal2Component>
    )
}

export default withTranslation('tournament')(TournamentInscriptionViewFormDataModalComponent);