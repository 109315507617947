// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baloa-play-subsection__container {
    margin: 24px 64px 0 64px;
    padding: 12px 0;
}

.baloa-play-subsection__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.baloa-play-subsection__title .baloa-subtitle-1 {
    color: var(--primary-very-dark-color);
}

.baloa-play-subsection__title .baloa-subtitle-1.green {
    color: var(--new-green-color);
    cursor: pointer;
}

.baloa-play-subsection__row-container {
    margin-top: 17px;
}

.baloa-play-subsection__cards-container {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-y: hidden;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .baloa-play-subsection__container {
        padding: 0;
        margin: 12px 9px 0 9px;
    }

    .baloa-play-subsection__title .baloa-subtitle-1 {
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/baloa-play/components/baloa-play-subsection-component/BaloaPlaySubsectionComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,6BAA6B;IAC7B,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,UAAU;QACV,sBAAsB;IAC1B;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".baloa-play-subsection__container {\n    margin: 24px 64px 0 64px;\n    padding: 12px 0;\n}\n\n.baloa-play-subsection__title {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.baloa-play-subsection__title .baloa-subtitle-1 {\n    color: var(--primary-very-dark-color);\n}\n\n.baloa-play-subsection__title .baloa-subtitle-1.green {\n    color: var(--new-green-color);\n    cursor: pointer;\n}\n\n.baloa-play-subsection__row-container {\n    margin-top: 17px;\n}\n\n.baloa-play-subsection__cards-container {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    position: relative;\n    overflow-x: hidden;\n    scroll-snap-type: x mandatory;\n    scroll-behavior: smooth;\n    overflow-y: hidden;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .baloa-play-subsection__container {\n        padding: 0;\n        margin: 12px 9px 0 9px;\n    }\n\n    .baloa-play-subsection__title .baloa-subtitle-1 {\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
