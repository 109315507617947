// Action creators
import { 
    changeBaloaProStripePaymentModalStateAction,
    loadStripePricesAction,
    createStripePaymentIntentAction,
    searchCountriesAction,
    createStripeSubscriptionAction,
    loadingUnauthStripePricesAction,
    loadStripeUserAccountAction,
    createStripeSetupIntentAction,
} from './BaloaProActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

//Apis
import {
    GetStripePricesApi,
    CreateStripePaymentIntentKeyApi,
    CreateStripeSubscriptionApi,
    GetUnauthStripePricesApi,
    GetStripeUserAccountApi,
    CreateStripeSetupIntentApi,
    SetPredefinedSubscriptionPaymentMethodApi,
    CancelStripeSubscriptionApi,
} from 'app/baloa-pro/apis/BaloaProApi';
import {
    GetCountriesApi
} from 'app/leagues/apis/LeagueTournamentApi';

// i18next 
import i18n from 'i18next';

export const loadStripePrices = ( profile ) => ( dispatch ) => {
     
    GetStripePricesApi( profile )
        .then( response => {
            dispatch( loadStripePricesAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const createStripePaymentIntent = ( profile, interval ) => ( dispatch ) => {
     
    CreateStripePaymentIntentKeyApi(profile, interval)
        .then( response => {
            dispatch( createStripePaymentIntentAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const searchCountries = ( country ) => {
    
    return async ( dispatch ) =>  {
        
       await GetCountriesApi( country )
            .then( response => {
                dispatch( searchCountriesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const createStripeSubscription = ( subscription_type, name, email, country_code ) => {
    return async ( dispatch ) =>  {
        await CreateStripeSubscriptionApi(subscription_type, name, email, country_code)
            .then( response => {
                dispatch( createStripeSubscriptionAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadUnauthStripePrices = () => ( dispatch ) => {
    dispatch( loadingUnauthStripePricesAction(true) )
    GetUnauthStripePricesApi()
        .then( response => {
            dispatch( loadStripePricesAction( response.data ) );
            dispatch( loadingUnauthStripePricesAction(false) )
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
                dispatch( loadingUnauthStripePricesAction(false) )
            }
        })            
}

export const loadStripeUserAccount = ( profile ) => {
    return async ( dispatch ) =>  {
        await GetStripeUserAccountApi( profile )
            .then( response => {
                dispatch( loadStripeUserAccountAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        }
}

export const createStripeSetupIntent = ( profile, customer_id, stripe_account_id ) => {
    return async ( dispatch ) =>  {
        await CreateStripeSetupIntentApi( profile, customer_id, stripe_account_id )
            .then( response => {
                dispatch( createStripeSetupIntentAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        }
}

export const setPredefinedSubscriptionPaymentMethod = ( profile, payment_id ) => {
    return async ( dispatch ) =>  {
        await SetPredefinedSubscriptionPaymentMethodApi( profile, payment_id )
            .then( response => {
                dispatch( loadStripeUserAccount( profile ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        }
}

export const cancelBaloaProSubscription = ( profile ) => {
    return ( dispatch ) =>  {
        CancelStripeSubscriptionApi( profile )
            .then( response => {
                dispatch( loadStripeUserAccount( profile ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
        }
}