/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMatchCardComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components

function BaloaPlayMatchCardComponent( props ) {

    return(
        <div className='baloa-play-match-card__container' onClick={()=>{props.handleOnMatch( props.match.id)}}>
            <ScrollTrigger 
                onEnter={ props.onEnterViewport } 
                onExit={ props.onExitViewport }
                className={`baloa-play-match-card__card `}                
            >
                <div className='baloa-play-match-card__card-image'>
                    <img src={props.match?.assets? props.match?.assets[0].post_stream?.thumbnail: ''} alt={props.match.match_name} className='baloa-play-match-card__card-image__image' />
                    <div className='baloa-play-match-card__card-image__play-image' ></div>
                    {props.is_live_video &&
                        <div className='baloa-play-match-card__card-image__live-tag baloa-table'>{props.t('filter_types.Live_Matches')}</div>
                    }
                </div>
                <div className='baloa-play-match-card__match-info'>
                    <div className='baloa-play-match-card__match-info__title baloa-normal-medium'>{props.match.match_name}</div>
                    <div className='baloa-play-match-card__match-info__category-info'>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.match.tournament_type}`)}</div>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.match.football_type}`)}</div>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.match.gender}`)}</div>                        
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabTeams.${props.match.category}`)}</div>
                    </div>
                    <div className='baloa-play-match-card__match-info__location'>
                        <span className='hubicon-location'/>
                        <div className='baloa-table-column'>{props.match.main_city}</div>-
                        <div className='baloa-table-column'>{props.match.main_country}</div>
                    </div>
                </div>
            </ScrollTrigger>
        </div>
    )
}

export default withTranslation(['baloaplay', 'league'])(BaloaPlayMatchCardComponent);