// React
import React from 'react';

// Styles
import './TournamentNewPodiumComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import {getRandonIconBallColor} from "../../../../shared/functions/GetRandomIconColor";

function TournamentNewPodiumComponent ( props ){

    return(           
        <div className="new-podium-content">
            {/* Resultados */}
            <div className='new-podium-content__position_content'> 
                {/* <div className="mew-new-podium-content__recognition__title"> */}
                        <p className="baloa-headline-5 new-podium-content__title">
                            {props.t('options.tournaments.tabs.tabsTournamentsList.tabResults')}
                        </p>
                    {/* </div> */}
                <div className="new-podium-content__position"
                    onClick = {()=>{props.handleTeamMembersModalPodium(props.selected_tournament?.champion_id)} }
                >
                    <div className="new-podium-content__position__champion">
                        {/* <div className="new-podium-content__position__champion__text"> */}
                        <div className="new-podium-content__position__image__content">
                            {props.selected_tournament?.champion_photo && <img className='has_img' src={ props.selected_tournament.champion_photo } alt=""/>}
                            {!props.selected_tournament?.champion_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}

                        </div>
                        <div className='new-podium-content__position__champion__text'>
                            <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                {props.selected_tournament?.champion_name}  
                            </p>

                            <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                @{props.selected_tournament?.champion}
                            </p>
                        </div>
                        <div className="new-podium-content__position__champion__subtitle">
                            <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelChampion')}  
                            </p>
                        </div>
                    </div>
                </div>
                <div className="new-podium-content__position" 
                    onClick = {()=>{props.handleTeamMembersModalPodium( props.selected_tournament?.runner_up_id)} }
                >
                    <div className="new-podium-content__position__champion">
                        <div className="new-podium-content__position__image__content">
                            {props.selected_tournament?.runner_up_photo && <img className={'has_img'}  src={ props.selected_tournament?.runner_up_photo } alt=""/>}
                            {!props.selected_tournament?.runner_up_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}

                        </div>
                        <div className='new-podium-content__position__champion__text'>
                            <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                {props.selected_tournament?.runner_up_name}
                            </p>
                            <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                @{props.selected_tournament?.runner_up}
                            </p>
                            
                        </div>
                        <div className="new-podium-content__position__champion__subtitle">
                            <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelRunnerUp')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="new-podium-content__position"
                    onClick = {
                        props.selected_tournament?.third_place?
                        ()=>{props.handleTeamMembersModalPodium( props.selected_tournament?.third_place_id )}
                        : undefined
                    }
                >
                    <div className="new-podium-content__position__champion">
                        <div className="new-podium-content__position__image__content">
                            {props.selected_tournament?.third_place_photo && <img className='has_img' src={ props.selected_tournament.third_place_photo } alt=""/>}
                            {!props.selected_tournament?.third_place_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}

                        </div>
                        <div className='new-podium-content__position__champion__text '>
                            <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                            {props.selected_tournament?.third_place_name}
                            </p>
                            <p className="new-podium-content__position__champion__text__username baloa-table-column">
                            @{props.selected_tournament?.third_place}
                            </p>
                            
                        </div>
                        <div className="new-podium-content__position__champion__subtitle">
                            <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelThirdPlace')}                        </p>
                            </div>
                    </div>
                    
                </div>
            </div>
            {/* Reconocimientos */}
            <div className='new-podium-content__recognition'>
                {/** Goleador */}
                <div className="new-podium-content__recognition__box left">
                    <div className="new-podium-content__position"
                        onClick = {
                            ()=>{ props.handleOnProfileClick( props.selected_tournament.scorer ) }
                        }
                    >
                        <div className="new-podium-content-title">
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelScorer')}
                            </p>
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals')}
                            </p>
                        </div>
                        <div className="new-podium-content__position__champion">
                            {/* <div className="new-podium-content__position__champion__text"> */}
                            <div className="new-podium-content__position__image__content">
                                {props.selected_tournament?.scorer_photo && <img className='has_img' src={ props.selected_tournament.scorer_photo } alt=""/>}
                                {!props.selected_tournament?.scorer_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>} 
                            </div>
                            <div className='new-podium-content__position__champion__text'>
                                <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                    {props.selected_tournament?.scorer_name} 
                                </p>

                                <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                    {props.selected_tournament?.team_name_scorer }
                                </p>
                            </div>
                            <div className="new-podium-content__position__champion__subtitle">
                                <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                    {props.selected_tournament.total_goals_scorer}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-podium-content__recognition__box right">
                    {/** Valla menos vencida */}
                    <div className="new-podium-content__position"
                        onClick = {
                            ()=>{ props.handleOnProfileClick( props.selected_tournament.least_beaten_fence ) }
                        }
                    >
                        <div className="new-podium-content-title">
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelLeastBeatenFence')}
                            </p>
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelConceded')}
                            </p>
                        </div>
                        <div className="new-podium-content__position__champion">
                            <div className="new-podium-content__recognition__box__image__content">
                                {props.selected_tournament?.least_beaten_fence_photo && <img className='has_img' src={ props.selected_tournament.least_beaten_fence_photo } alt=""/>}
                                {!props.selected_tournament?.least_beaten_fence_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}
                            </div>
                            <div className='new-podium-content__position__champion__text'>
                                <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                    {props.selected_tournament?.least_beaten_fence_name}
                                </p>
                                <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                    { props.selected_tournament?.team_name_least_beaten_fence }
                                </p>
                            </div>
                            <div className="new-podium-content__position__champion__subtitle">
                                <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                    { props.selected_tournament.total_goals_conceded }
                                </p>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
            <div className='new-podium-content__recognition'>
                {/** Jugador del trorneo */}
                <div className="new-podium-content__recognition__box left">
                    <div className="new-podium-content__position"
                        onClick = { ()=>{ props.handleOnProfileClick( props.selected_tournament.tournament_player ) } }
                    >
                        <div className="new-podium-content-title">
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelTournamentPlayer')}
                            </p>
                        </div>
                        <div className="new-podium-content__position__champion">
                            {/* <div className="new-podium-content__position__champion__text"> */}
                            <div className="new-podium-content__position__image__content">
                                {props.selected_tournament?.tournament_player_photo && <img className='has_img' src={ props.selected_tournament.tournament_player_photo } alt=""/>}
                                {!props.selected_tournament?.tournament_player_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>} 
                            </div>
                            <div className='new-podium-content__position__champion__text'>
                                <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                    {props.selected_tournament?.tournament_player_name} 
                                </p>

                                <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                    {props.selected_tournament?.team_tournament_player_name }
                                </p>
                            </div>
                            <div className="new-podium-content__position__champion__subtitle">
                                <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                    {/* {props.tournament_results_table.scorers_table}   */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-podium-content__recognition__box right">
                    {/** Jugador revelacion */}
                    <div className="new-podium-content__position"
                        onClick = {
                            ()=>{ props.handleOnProfileClick( props.selected_tournament.revelation_player ) }
                        }
                    >
                        <div className="new-podium-content-title">
                            <p className="new-podium-content__recognition__box__text__title baloa-subtitle-2">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelRevelationPlayer')}
                            </p>
                        </div>
                        <div className="new-podium-content__position__champion">
                            <div className="new-podium-content__recognition__box__image__content">
                                {props.selected_tournament?.revelation_player_photo && <img className='has_img' src={ props.selected_tournament.revelation_player_photo } alt=""/>}
                                {!props.selected_tournament?.revelation_player_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}
                            </div>
                            <div className='new-podium-content__position__champion__text'>
                                <p className="new-podium-content__position__champion__text__name baloa-subtitle-2">
                                    {props.selected_tournament?.revelation_player_name}
                                </p>
                                <p className="new-podium-content__position__champion__text__username baloa-table-column">
                                    { props.selected_tournament?.team_revelation_player_name }
                                </p>
                            </div>
                            <div className="new-podium-content__position__champion__subtitle">
                                <p className="baloa-subtitle-2 new-podium-content__position__champion__text__title">
                                    {/* {props.selected_tournament.}   */}
                                </p>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    )

}

export default withTranslation('league')(TournamentNewPodiumComponent);
