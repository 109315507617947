/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import {produce} from "immer" //Libreria para evitar errores de mutacion de objetos


// Components
import ScrollTrigger from 'react-scroll-trigger';


// Functions
import convertBirthdayToAge from 'shared/functions/ConvertBirthdayToAge';
import convertFieldPositionCode from 'shared/functions/ConvertFieldPositionCode';
import convertLateralityCode from 'shared/functions/ConvertLateralityCode';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Assets
import explore_pages_empty from 'assets/images/explore_pages_empty.png';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './HomePlayersComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import BaloaProSubscriptionWidgetComponent from 'shared/components/baloa-pro-subscription-widget/BaloaProSubscriptionWidgetComponent';

function HomePlayersComponent( props ) {
    const [newPlayers, setNewPlayers] = React.useState([]);

    /* eslint-disable */
    React.useEffect(() => {
        if(props.home_players.length > 0){
            const nPlayers = produce(props.home_players, draft => {
                draft.map(player => {
                    player.primary_position = player.attributes.find(attr => attr.extra?.atype === 'primary-position')?.extra?.value;
                    player.secondary_position = player.attributes.find(attr => attr.extra?.atype === 'secondary-position')?.extra?.value;
                    player.description = player.attributes.find(attr => attr.extra?.atype === 'description')?.extra?.value;
                    player.laterality = player.attributes.find(attr => attr.extra?.atype === 'laterality')?.extra?.value;
                    player.weight = player.attributes.find(attr => attr.extra?.atype === 'weight')?.extra;
                    player.height = player.attributes.find(attr => attr.extra?.atype === 'height')?.extra;
                    return player;
                })
            });
            setNewPlayers(nPlayers)
        }else{
            setNewPlayers(props.home_players)
        }
    }, [props.home_players])
    /* eslint-enable */

    const [activePage, setActivePage]= React.useState(undefined);

    React.useEffect( () => {
        // Need more pages?
        if(props.home_players_next_page && (activePage == newPlayers[ newPlayers.length -1 ] || activePage == newPlayers[ newPlayers.length -2 ] ) ) { 
            props.fetchMoreData();
        }
    }, [ activePage ] );// eslint-disable-line

    return(
        <React.Fragment>
        <div className='home-players__container' >
            {/** Baloa Pro Subscription Widget */}
            {/* !props.is_baloa_pro && props.profile_type === 'player' &&
                <BaloaProSubscriptionWidgetComponent
                    handleOnBaloaProSubscription={props.handleOnBaloaProSubscription}
                    is_new_baloa_pro_user={props.is_new_baloa_pro_user}
                /> */
            }
            
            { newPlayers.map(player => (                
                <div className='' key={player.id} onClick={()=>{ props.handleOnProfileClick(player.user.username) }}>
                    <ScrollTrigger 
                        onEnter = { () => { 
                            setActivePage( player );
                        } }
                        onExit = { () => { } }
                        key={player.user.id}
                        className="home-players__player"
                    >
                        <div className='home-players__player__photo'>                       
                            {player.user.photo && 
                                <img src={ player.user.photo } alt={`${player.user.username} img`}/>
                            }
                            {!player.user.photo && 
                                <span className="icon-ball" color={getRandonIconBallColor()}/>
                            }
                        </div>
                        <div className='home-players__player__info'>
                            <div className='home-players__player__info__data'>
                                <div className='home-players__player__info__data-player'>
                                    <div className='baloa-names'>
                                        {`${player.user.first_name} ${player.user.last_name}`}
                                    </div>
                                    <div className='baloa-table'>
                                        @{player.user.username}
                                    </div>
                                    {(player.primary_position || player.secondary_position) &&
                                        <div className='home-players__player__info__data-player__positions'>
                                            {player.primary_position && <div className='primary-position baloa-table'>{convertFieldPositionCode(player.primary_position)}</div>}
                                            {player.secondary_position && player.secondary_position.map( position =>
                                                <div className='secondary-position baloa-table'>{convertFieldPositionCode(position)}</div>
                                            )}
                                        </div>
                                    }
                                    <div className='home-players__player__info__data-player__attributes'>
                                        <span class="hubicon-assists">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>
                                            <span class="baloa-table">{player.laterality? convertLateralityCode(player.laterality) : '-'}</span>
                                        </span>
                                        <span class="hubicon-weight-scale">
                                            <span class="baloa-table">{player.height?.value? `${player.weight?.value} ${player.weight?.units}` : '-'}</span>
                                        </span>
                                        <span class="hubicon-ruler">                                        
                                            <span class="baloa-table">{player.height?.value? `${player.height?.value} ${player.height?.units}` : '-'}</span>
                                        </span>
                                    </div>
                                </div>
                                {!isMobile &&
                                    <div className='home-players__player__info__data-location'>
                                        {player.nationality &&
                                            <div className='home-players__player__info__data-location__row baloa-names'>
                                                { player.nationality?.flag_icon
                                                    ? <img src={player.nationality?.flag_icon } alt=""/>
                                                    : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                }
                                                {player.nationality?.name}
                                            </div>
                                        }
                                        {player.location &&
                                            <div className='home-players__player__info__data-location__row baloa-names'>                                
                                                <span className='icon-location'></span> <p className="baloa-names">{`${player.location?.name}, `} <span>{`${player.location?.country_name}`}</span></p>                                 
                                            </div>
                                        }
                                        {player.user.birthday && 
                                            <div className='home-players__player__info__data-location__row baloa-names'>
                                                {String(convertBirthdayToAge( player.user.birthday )) + ' ' + props.t('profile:data.textYears') }
                                            </div>
                                        }
                                        {player.has_profile_video &&
                                            <div className='home-players__player__info__data-location__row baloa-table'>
                                                <span className='hubicon-play_circle'></span>
                                                {props.playerVideo}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {!isMobile && player.description &&
                                <div className='home-players__player__info__description'>
                                    {player.description}
                                </div>
                            }
                            
                        </div>
                        {isMobile &&
                            <div className='home-players__player-mobile'>
                                <div className='home-players__player__info__data-location'>
                                    {player.nationality &&
                                        <div className='home-players__player__info__data-location__row baloa-names'>
                                            { player.nationality?.flag_icon
                                                ? <img src={player.nationality?.flag_icon } alt=""/>
                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                            }
                                            {player.nationality?.name}
                                        </div>
                                    }
                                    {player.location &&                                   
                                        <div className='home-players__player__info__data-location__row baloa-names'>
                                            {player.nationality && <span className='home-players__player__info__data-location__row__bullet'>&bull;</span>}                                
                                            <span className='icon-location'></span> <p className="baloa-names">{`${player.location?.name}, `} <span>{`${player.location?.country_name}`}</span></p>                                 
                                        </div>
                                    }
                                    {player.user.birthday && 
                                        <div className='home-players__player__info__data-location__row baloa-names'>
                                            {(player.nationality || player.location) && <span className='home-players__player__info__data-location__row__bullet'>&bull;</span>}
                                            {String(convertBirthdayToAge( player.user.birthday )) + ' ' + props.t('profile:data.textYears') }
                                        </div>
                                    }
                                    
                                </div>
                                {player.has_profile_video &&
                                    <div className='home-players__player__info__data-location__row baloa-table'>
                                        <span className='hubicon-play_circle'></span>
                                        {props.playerVideo}
                                    </div>
                                }
                                {player.description &&
                                    <div className='home-players__player__info__description'>
                                        {player.description}
                                    </div>
                                }
                            </div>
                        }

                    </ScrollTrigger>
                    
                </div>
                
            ))}
            {newPlayers.length === 0 &&
                <div className='home-players__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.emptyPlayers}</div>                    
                </div>
            } 
        </div>
        </React.Fragment>
    )
}

export default withTranslation(['home','page','profile'])(HomePlayersComponent);
