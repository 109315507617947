// React
import React from 'react';

//Styles
import './BaloaPlayCreateClipWelcomeModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayCreateClipWelcomeModalComponent ( props ){
    return(
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            className="baloa-play-create-clip-welcome-modal"
        >
            <div className='baloa-play-create-clip-welcome-modal__container'>
                <div className='baloa-play-create-clip-welcome-modal__button'>
                    <PrimaryButtonComponent
                        input_type={'button'}
                        onClick={()=>{}}
                    >
                        <span className='hubicon-video_library-1 icon' />
                        <span>{props.t('createClipBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div>
                <div className='baloa-play-create-clip-welcome-modal__call-widget'>
                    <div className='baloa-play-create-clip-welcome-modal__call-widget__message'>
                        <div className='baloa-normal-medium'>{props.t('createClipWelcomeModal.callTitle')}</div>
                        <div className='baloa-names'>{props.t('createClipWelcomeModal.callMessage')}</div>
                    </div>
                    <div className='baloa-play-create-clip-welcome-modal__call-widget__button'>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={()=>{props.onClose()}}
                            className={'small'}
                        >
                            <span>{props.t('createClipWelcomeModal.understandBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('baloaplay')(BaloaPlayCreateClipWelcomeModalComponent);
