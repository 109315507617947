// Envs

export const debug = process.env.REACT_APP_DEBUG;
export const api_url = process.env.REACT_APP_API_URL;
export const api_media_url = process.env.REACT_APP_API_MEDIA_URL;
export const clickstream_api_url =process.env.REACT_APP_CLICKSTREAM_URL;
export const clickstream_interval =process.env.REACT_APP_CLICKSTREAM_INTERVAL;
export const web_url = process.env.REACT_APP_WEB_URL;
export const ga_tracking_id = process.env.REACT_APP_GA_TRACKING_ID;
export const client_id = process.env.REACT_APP_BALOA_CLIENT_ID;
export const client_secret = process.env.REACT_APP_BALOA_CLIENT_SECRET;
export const wompi_public_key = process.env.REACT_APP_BALOA_WOMPI_PUBLIC_KEY;
export const team_form_id = process.env.REACT_APP_BALOA_TEAM_FORM_ID;
export const player_form_id = process.env.REACT_APP_BALOA_PLAYER_FORM_ID;
export const recaptcha_public_key = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
export const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const stripe_connected_account = process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT;