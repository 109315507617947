// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components

// Styles
import './HomePagePagesShortcutsComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';


function HomePagePagesShortcutsComponent( props ) {

    return(
        <div className="homepage-pages-shortcuts__container">
            <div className="homepage-pages-shortcuts__title baloa-subtitle-2">{props.title}</div>   
            <div className="homepage-pages-shortcuts__list">
                {props.pages?.map((page) => (
                    <React.Fragment>    
                    <Link to={`/page/${page.pagename}/`} >
                        <div className="homepage-pages-shortcuts__list__page-item">
                            
                            {page.photo &&
                                <img src={ page.photo } alt=""/>
                            }
                            {!page.photo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            }                            
                            <div className="homepage-pages-shortcuts__list__page-item__data">
                                <div className="homepage-pages-shortcuts__list__page-item__data-name baloa-names">{ page.name }</div>
                                <div className='homepage-pages-shortcuts__list__page-item__data-username baloa-username'>@{ page.pagename }</div>
                                
                            </div>
                            {page.admin &&
                                <div className='homepage-pages-shortcuts__list__page-item__data__admin baloa-username'>{ props.t('shortcuts.adminTag') }</div>
                            }
                        </div>
                    </Link>
                    {page.tournaments.length > 0 &&
                        page.tournaments.map((tournament) => (
                         !isMobile
                            ? <Link to={`${page.admin? '/league/tournament/': '/page/'}${page.pagename}&setshowtournament=${tournament.id}&showtournament=true`} >  
                            <div className="homepage-pages-shortcuts__list__team-item">    
                                <span className="icon-trophy" />
                                {tournament.tournament_logo &&
                                    <img src={ tournament.tournament_logo } alt=""/>
                                }
                                {!tournament.tournament_logo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }                            
                                <div className="homepage-pages-shortcuts__list__page-item__data">
                                    <div className="homepage-pages-shortcuts__list__page-item__data-name baloa-names">{ tournament.name }</div>
                                    <div className='homepage-pages-shortcuts__list__page-item__data-tags'>
                                        {tournament.tournament_type && <div className='homepage-pages-shortcuts__list__page-item__data-tag__green'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + tournament.tournament_type) }</div>}
                                        {tournament.tournament_gender && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + tournament.tournament_gender) }</div>}                                    
                                        {tournament.category &&<div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + tournament.category)  }</div>}
                                    </div>
                                </div>
                            </div>
                            </Link>
                            : <Link to={`${'/page/'}${page.pagename}&setshowtournament=${tournament.id}&showtournament=true`} > 
                            <div className="homepage-pages-shortcuts__list__team-item"
                                onClick={()=> {
                                    props.handleOpenTournamentDetailModal(page.admin);
                                    }
                                }>                                
                                <span className="icon-trophy" />
                                {tournament.tournament_logo &&
                                    <img src={ tournament.tournament_logo } alt=""/>
                                }
                                {!tournament.tournament_logo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }                            
                                <div className="homepage-pages-shortcuts__list__page-item__data">
                                    <div className="homepage-pages-shortcuts__list__page-item__data-name baloa-names">{ tournament.name }</div>
                                    <div className='homepage-pages-shortcuts__list__page-item__data-tags'>
                                        {tournament.tournament_type && <div className='homepage-pages-shortcuts__list__page-item__data-tag__green'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + tournament.tournament_type) }</div>}
                                        {tournament.tournament_gender && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + tournament.tournament_gender) }</div>}                                    
                                        {tournament.category &&<div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + tournament.category)  }</div>}
                                    </div>
                                </div>
                            </div>
                            </Link>
                        ))
                    }
                    {page.teams.length > 0 && 
                        page.teams.map((team) => (
                        !isMobile
                            ? <Link to={`${page.admin? '/league/team/': '/page/'}${page.pagename}&setshowteam=${team.teamname}&showteam=true`} >
                            <div className="homepage-pages-shortcuts__list__team-item">                         
                                <span className="icon-team-2-64" />
                                {team.logo &&
                                    <img src={ team.logo } alt=""/>
                                }
                                {!team.logo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }                            
                                <div className="homepage-pages-shortcuts__list__page-item__data">
                                    <div className="homepage-pages-shortcuts__list__page-item__data-name baloa-names">{ team.name }</div>
                                    <div className='homepage-pages-shortcuts__list__page-item__data-tags'>
                                        {team.team_gender && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + team.team_gender) }</div>}
                                        {team.category && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + team.category) }</div>}
                                    </div>
                                </div>
                            </div>
                            </Link>
                            : <div className="homepage-pages-shortcuts__list__team-item" onClick={()=> {
                                        props.handleOpenTeamDetailModal(team.teamname, page.admin);
                                    }
                                }>
                                <span className="icon-team-2-64" />
                                {team.logo &&
                                    <img src={ team.logo } alt=""/>
                                }
                                {!team.logo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }                            
                                <div className="homepage-pages-shortcuts__list__page-item__data">
                                    <div className="homepage-pages-shortcuts__list__page-item__data-name baloa-names">{ team.name }</div>
                                    <div className='homepage-pages-shortcuts__list__page-item__data-tags'>
                                        {team.team_gender && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabBasicData." + team.team_gender) }</div>}
                                        {team.category && <div className='homepage-pages-shortcuts__list__page-item__data-tag'>{ props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + team.category) }</div>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </React.Fragment>    
                    
                    ))

                }                                        
            </div>
            {/* <div className="homepage-pages-shortcuts__separator"></div> */}
        </div>
    )
}

export default withTranslation(['home','league'])(HomePagePagesShortcutsComponent);
